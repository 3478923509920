import { Col, Form, Row, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { isNumber } from '../../../../utils/validations';
import { Store } from '../../../../state/store.interfaces';
import { LrvTabs } from '../../../../common/components/LrvTabs/LrvTabs';
import { LrvForm } from '../../../../common/components/LrvForm/LrvForm';
import { LrvModal } from '../../../../common/components/LrvModal/LrvModal';
import { CommercialSizeRange, ProcessedCommercialSizeRange } from '../interfaces';
import { LrvDatePicker } from '../../../../common/components/LrvDatePicker/LrvDatePicker';
import { LrvInputNumber } from '../../../../common/components/LrvInputNumber/LrvInputNumber';
import { applyParserThousandsSeparator, applyThousandsSeparator } from '../../../../utils/strings';
import { commercialSizeTypes, weightUnits, weightUnitsByCompany } from '../../../../config/commons';
import { calcYieldPorcentageTail, calcYieldPorcentageWaste, disablePriceTableForm, getPrices } from '../../../../helpers/commercial-size-price-table';
import { generateCommercialSizeRanges, getMaxValueDisplay, MIN_VALUE_DISPLAY_GROW_OUT } from '../../../../helpers/commercial-size.helpers';

import { DistributionBar } from './DistributionBar';
import styles from './NewTablePriceForm.module.scss';
import { CommercialSizePriceTable } from './interfaces';
import * as commercialSizeSlice from './tablePriceSlice';
import { CommercialSizeTable } from './CommercialSizeTable';

interface Props {
  theme?: 'dark' | 'light';
}

const { TabPane } = Tabs;

export const NewTablePriceForm = (props: Props) => {
  const { theme = 'dark' } = props;
  
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const {
    isCreating,
    showCreateModal,
    packer,
    commercialSize,
  } = useSelector((state: Store) => state.tablePrices);

  const [date, setDate] = useState('');
  const [paymentDelayDays, setPaymentDelayDays] = useState<number | string | null | undefined>();
  const [yieldPercentageWhole, setYieldPercentageWhole] = useState<number | string | null | undefined>();
  const [yieldPercentageTail, setYieldPercentageTail] = useState<number | string | null | undefined>();
  const [wastePrice, setWastePrice] = useState<number | string | null | undefined>();
  const [commercialSizesWhole, setCommercialSizesWhole] = useState<number[]>([]);
  const [commercialSizesTail, setCommercialSizesTail] = useState<number[]>([]);
  const [commercialSizeRangesWhole, setCommercialSizeRangesWhole] = useState<CommercialSizeRange[]>([]);
  const [commercialSizeRangesTail, setCommercialSizeRangesTail] = useState<CommercialSizeRange[]>([]);
  const [processedDataSourceWhole, setProcessedDataSourceWhole] = useState<ProcessedCommercialSizeRange[]>([]);
  const [processedDataSourceTail, setProcessedDataSourceTail] = useState<ProcessedCommercialSizeRange[]>([]);
  const [currentTab, setCurrentTab] = useState<string>(commercialSizeTypes.GROW_OUT_WHOLE);

  const yieldPercentageWaste = calcYieldPorcentageWaste({ whole: Number(yieldPercentageWhole), tail: Number(yieldPercentageTail) });

  useEffect(() => {
    if (!commercialSize?._id || !showCreateModal) {
      return;
    }

    setCommercialSizesTail(commercialSize.commercialSizes.tail);
    setCommercialSizesWhole(commercialSize.commercialSizes.whole);
  }, [form, commercialSize, showCreateModal]);

  useEffect(() => {
    if (commercialSizesWhole.length === 0) {
      return;
    }

    const maxValueDisplay = getMaxValueDisplay({ commercialSizeType: commercialSizeTypes.GROW_OUT_WHOLE });
    const commercialSizeRanges = generateCommercialSizeRanges({ commercialSizes: commercialSizesWhole, minValueDisplay: MIN_VALUE_DISPLAY_GROW_OUT, maxValueDisplay, weightUnit: weightUnitsByCompany.KILOGRAM });
    setCommercialSizeRangesWhole(commercialSizeRanges);
  }, [commercialSizesWhole]);

  useEffect(() => {
    if (commercialSizesTail.length === 0) {
      return;
    }

    const maxValueDisplay = getMaxValueDisplay({ commercialSizeType: commercialSizeTypes.GROW_OUT_TAIL });
    const commercialSizeRanges = generateCommercialSizeRanges({ commercialSizes: commercialSizesTail, minValueDisplay: MIN_VALUE_DISPLAY_GROW_OUT, maxValueDisplay, weightUnit: weightUnitsByCompany.POUND });
    setCommercialSizeRangesTail(commercialSizeRanges);
  }, [commercialSizesTail]);

  const closeModal = () => {
    form.resetFields();
    setDate('');
    setCommercialSizesTail([]);
    setCommercialSizesWhole([]);
    setCommercialSizeRangesTail([]);
    setCommercialSizeRangesWhole([]);
    setProcessedDataSourceTail([]);
    setProcessedDataSourceWhole([]);
    setPaymentDelayDays('');
    setYieldPercentageWhole('');
    setYieldPercentageTail('');
    setWastePrice('');
    
    setCurrentTab(commercialSizeTypes.GROW_OUT_WHOLE);
    dispatch(commercialSizeSlice.setShowCreateModal(false));
  };

  const addCommercialSize = () => {
    if (!packer?._id || !commercialSize?._id) {
      return;
    }

    const body: CommercialSizePriceTable = {
      date,
      paymentDelayDays: parseInt(`${paymentDelayDays}`),
      active: true,
      commercialSizes: {
        whole: commercialSizesWhole,
        tail: commercialSizesTail,
      },
      prices: {
        whole: getPrices({ processedDataSource: processedDataSourceWhole }),
        tail: getPrices({ processedDataSource: processedDataSourceTail }),
      },
      yield: {
        whole: isNumber(yieldPercentageWhole) ? parseFloat(`${yieldPercentageWhole}`) : undefined,
        tail: isNumber(yieldPercentageTail) ? parseFloat(`${yieldPercentageTail}`) : undefined,
      },
      waste: {
        price: isNumber(wastePrice) ? parseFloat(`${wastePrice}`) : undefined,
      },
      packerId: packer._id,
      companyId: packer.companyId,
      commercialSizeId: commercialSize._id,
    };

    const props = {
      body,
      onSuccess: closeModal,
    };

    dispatch(commercialSizeSlice.createCommercialSizePriceTable(props));
  };

  const renderCommercialSizeTable = (props: {commercialSizeRanges: CommercialSizeRange[]; commercialSizeType: string; processedDataSource: ProcessedCommercialSizeRange[], setProcessedDataSource: Dispatch<SetStateAction<ProcessedCommercialSizeRange[]>>; weightUnit: string; }) => {
    const { commercialSizeRanges, commercialSizeType, processedDataSource, setProcessedDataSource, weightUnit } = props;
    
    return (
      <CommercialSizeTable
        commercialSizeRanges={commercialSizeRanges}
        commercialSizeType={commercialSizeType}
        processedDataSource={processedDataSource}
        setProcessedDataSource={setProcessedDataSource}
        weightUnit={weightUnit}
      />
    );
  };

  return (
    <LrvModal
      theme='light'
      title={t('priceTable.newPriceTable')}
      open={showCreateModal}
      destroyOnClose={true}
      okButtonProps={{
        id: 'submit-price-table',
        htmlType: 'submit',
        form: 'form',
        loading: isCreating,
        disabled: disablePriceTableForm({ date, paymentDelayDays, processedDataSourceTail, processedDataSourceWhole, yieldPercentageTail, yieldPercentageWhole, wastePrice, yieldPercentageWaste }),
      }}
      onOk={addCommercialSize}
      okText={t('priceTable.save')}
      cancelText={t('priceTable.cancel')}
      onCancel={closeModal}
      className={styles.priceTableModal}
    >
      <LrvForm
        theme={theme}
        form={form}
        name='form'
        id='form-price-table'
        layout='vertical'
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='date'
              label={t('priceTable.date')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <LrvDatePicker
                theme={theme}
                placeholder=''
                onChange={(date) => {
                  const dateSelected = date?.toString() as string;
                  setDate(dateSelected);
                }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name='paymentDelayDays'
              label={t('priceTable.paymentDelayDays')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <LrvInputNumber
                theme={theme}
                value={paymentDelayDays}
                formatter={value => applyThousandsSeparator(value)}
                parser={value => applyParserThousandsSeparator(value)}
                onChange={setPaymentDelayDays}
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <DistributionBar whole={Number(yieldPercentageWhole)} tail={calcYieldPorcentageTail({ whole: Number(yieldPercentageWhole), tail: Number(yieldPercentageTail) })} waste={calcYieldPorcentageWaste({ whole: Number(yieldPercentageWhole), tail: Number(yieldPercentageTail) })}/>
        </Form.Item>

        <LrvTabs
          theme={theme}
          activeKey={currentTab}
          defaultActiveKey={currentTab}
          onChange={setCurrentTab}
        >
          <TabPane tab={t(`commercialSizes.commercialSizeTypes.${commercialSizeTypes.GROW_OUT_WHOLE}`)} key={commercialSizeTypes.GROW_OUT_WHOLE}>
            <Form.Item
              name='yieldPercentageWhole'
              label={t('priceTable.yieldPercentage')}
              required={false}
            >
              <LrvInputNumber
                theme={theme}
                value={yieldPercentageWhole}
                min={1}
                max={100}
                onChange={setYieldPercentageWhole}
              />
            </Form.Item>

            <Form.Item>
              {renderCommercialSizeTable({ commercialSizeRanges: commercialSizeRangesWhole, commercialSizeType: commercialSizeTypes.GROW_OUT_WHOLE, processedDataSource: processedDataSourceWhole, setProcessedDataSource: setProcessedDataSourceWhole, weightUnit: weightUnitsByCompany.KILOGRAM, })}
            </Form.Item>
          </TabPane>

          <TabPane tab={t(`commercialSizes.commercialSizeTypes.${commercialSizeTypes.GROW_OUT_TAIL}`)} key={commercialSizeTypes.GROW_OUT_TAIL}>
            <Form.Item
              name='yieldPercentageTail'
              label={t('priceTable.yieldPercentage')}
              required={false}
            >
              <LrvInputNumber
                theme={theme}
                value={yieldPercentageTail}
                min={1}
                max={100}
                onChange={setYieldPercentageTail}
              />
            </Form.Item>

            <Form.Item>
              {renderCommercialSizeTable({ commercialSizeRanges: commercialSizeRangesTail, commercialSizeType: commercialSizeTypes.GROW_OUT_TAIL, processedDataSource: processedDataSourceTail, setProcessedDataSource: setProcessedDataSourceTail, weightUnit: weightUnitsByCompany.POUND, })}
            </Form.Item>
          </TabPane>

          <TabPane tab={t('priceTable.waste')} key='WASTE'>
            <Form.Item
              name='wastePrice'
              label={t('priceTable.wastePrice', { unit: weightUnits.KG })}
              required={false}
            >
              <LrvInputNumber
                theme={theme}
                value={wastePrice}
                onChange={setWastePrice}
              />
            </Form.Item>
          </TabPane>
        </LrvTabs>
      </LrvForm>
    </LrvModal>
  );
};