import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { FeedingStrategy } from '../OptimalHarvestPoint/Indicators/interfaces';
import { LrvInputNumber } from '../../common/components/LrvInputNumber/LrvInputNumber';

import { TankPocParameter } from './interfaces';
import * as tankPocParameterSlice from './tankPocParameterSlice';

interface Props {
  theme?: 'dark' | 'light';
}

export const TankPocParameterForm = (props: Props) => {
  const { theme = 'light' } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [costPerVolumeDay, setCostPerVolumeDay] = useState<number | string | null | undefined>(0);
  const [loadCapacity, setLoadCapacity] = useState<number | string | null | undefined>(0);

  const {
    showCreateModal,
    tankId,
    tankPocParameter,
  } = useSelector((state: Store) => state.tankPocParameter);

  const { company } = useSelector((state: Store) => state.header);
  const { selectedCampus } = useSelector((state: Store) => state.campus);

  useEffect(() => {
    if (!tankPocParameter?._id || !showCreateModal) {
      return;
    }

    setCostPerVolumeDay(tankPocParameter.costPerVolumeDay);
    setLoadCapacity(tankPocParameter.loadCapacity);

    form.setFieldsValue({
      costPerVolumeDay: tankPocParameter.costPerVolumeDay,
      loadCapacity: tankPocParameter.loadCapacity,
    });
  }, [tankPocParameter && showCreateModal]);

  const closeModal = () => {
    dispatch(tankPocParameterSlice.setShowCreateModal(false));
    dispatch(tankPocParameterSlice.resetTankPocParameter());

    form.resetFields();

    setCostPerVolumeDay(0);
    setLoadCapacity(0);
  };

  const getModalTitle = () => {
    return tankPocParameter?._id ? t('formPocParameters.edit') : t('formPocParameters.new');
  };

  const getSubmitButtonLabel = () => {
    return tankPocParameter?._id ? t('actions.edit') : t('actions.save');
  };

  return (
    <LrvModal
      theme={theme}
      title={getModalTitle()}
      open={showCreateModal}
      destroyOnClose={true}
      okButtonProps={{ htmlType: 'submit', form: 'form' }}
      onOk={() => {
        if (!costPerVolumeDay || !loadCapacity) {
          return;
        }

        const body: TankPocParameter = {
          _id: tankPocParameter?._id,
          companyId: company._id,
          campusId: selectedCampus._id,
          tankId,
          costPerVolumeDay: Number(costPerVolumeDay),
          loadCapacity: Number(loadCapacity),
          dataset: [],
          feedingStrategy: FeedingStrategy.NORMAL,
        };

        if (tankPocParameter?._id) {
          dispatch(tankPocParameterSlice.updateTankPocParameter({ body, onSuccess: closeModal }));
          return;
        }

        dispatch(tankPocParameterSlice.createTankPocParameter({ body, onSuccess: closeModal }));
      }}
      okText={getSubmitButtonLabel()}
      cancelText={t('actions.cancel')}
      onCancel={closeModal}
    >
      <LrvForm
        theme={theme}
        form={form}
        layout='vertical'
        name='form'
        id='form'
      >
        <Form.Item
          label={t('tankPocParameters.loadCapacity')}
          name='loadCapacity'
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <LrvInputNumber
            theme={theme}
            autoFocus
            value={loadCapacity}
            onChange={setLoadCapacity}
            parser={(input) => `${input}`.replace(',', '.')}
            min={0}
          />
        </Form.Item>

        <Form.Item
          label={t('tankPocParameters.costPerVolumeDay')}
          name='costPerVolumeDay'
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <LrvInputNumber
            theme={theme}
            value={costPerVolumeDay}
            onChange={setCostPerVolumeDay}
            parser={(input) => `${input}`.replace(',', '.')}
            min={0}
          />
        </Form.Item>
      </LrvForm>
    </LrvModal>
  );
};
