import moment from 'moment';
import cx from 'classnames';
import { Button } from 'antd';
import { groupBy } from 'lodash';
import { useState } from 'react';
import { Moon } from 'lunarphase-js';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getModalWidth } from '../helpers';
import { Tank } from '../../Tanks/interfaces';
import { DATE_FORMATS } from '../../../config/commons';
import Icon from '../../../common/components/Icon/Icon';
import { Store } from '../../../state/store.interfaces';
import { getCalendarLocale } from '../../../utils/locale';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import * as optimalHarvestPointSlice from '../optimalHarvestPointSlice';
import { LrvTooltip } from '../../../common/components/LrvTooltip/LrvTooltip';
import { LrvCalendar } from '../../../common/components/LrvCalendar/LrvCalendar';
import { LrvDatePicker } from '../../../common/components/LrvDatePicker/LrvDatePicker';
import { getBackgroundColorCell } from '../../ActivityDashboard/UserActivityDashboard/helpers';

import styles from './HeatMapModal.module.scss';

interface Props {
  theme?: 'dark' | 'light';
}

export const HeatMapModal = (props: Props) => {
  const { theme = 'light' } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();
  
  const { harvestOptimalPlan, showHeatMapModal, modules } = useSelector((state: Store) => state.optimalHarvestPoint);

  const [selectedMonth, setSelectedMonth] = useState<moment.Moment | null>(moment());
  const { modalWidth } = getModalWidth();

  const renderContainers = (date: moment.Moment) => {
    const formattedDate = date.format(DATE_FORMATS.YYYY_MM_DD);
    const harvestOptimalPlans = harvestOptimalPlan.harvestOptimalPlans.filter((harvestOptimalPlan) => moment(harvestOptimalPlan.harvestDate).format(DATE_FORMATS.YYYY_MM_DD) === formattedDate);

    if (!harvestOptimalPlans.length) {
      return null;
    }

    const items: JSX.Element[] = [];
    const tanks: Tank[] = harvestOptimalPlans.map((harvestOptimalPlan) => harvestOptimalPlan.tankId as Tank);
    const tanksGroupByModule = groupBy(tanks, 'moduleId');

    for (const moduleId in tanksGroupByModule) {
      if (Object.prototype.hasOwnProperty.call(tanksGroupByModule, moduleId)) {
        const tanks = tanksGroupByModule[moduleId];
        const module = modules.find((module) => module._id.toString() === moduleId);

        const item = (
          <>
            <div>
              <LrvText text={`${t('campus.module')}: `} theme={theme} className={styles.label} />
              <LrvText text={module?.name} theme={theme} /> <br/>
            </div>

            <div>
              <LrvText text={`${t('optimalHarvestPoint.heatMap.harvestedPonds')}: `} theme={theme} className={styles.label} />
              {tanks.map((tank, index) => {
                const value = harvestOptimalPlans.length - 1 === index ? ` ${tank.name}` : ` ${tank.name}, `;

                return (
                  <LrvText text={value} theme={theme} />
                );
              })}
            </div>
            {items.length !== 0 && <br/>}
          </>
        );

        items.push(item);
      }
    }

    return (
      <div className={styles.tooltip}>
        <div>
          <LrvText text={`${t('optimalHarvestPoint.heatMap.selectedDate')}: `} theme={theme} className={styles.label} />
          <LrvText text={formattedDate} theme={theme} /> <br/>
        </div>

        {items}
      </div>
    );
  };

  const renderDateCell = (date: moment.Moment) => {
    const formattedDate = date.format(DATE_FORMATS.YYYY_MM_DD);
    const harvestOptimalPlans = harvestOptimalPlan.harvestOptimalPlans.filter(
      (harvestOptimalPlan) =>
        moment(harvestOptimalPlan.harvestDate).format(DATE_FORMATS.YYYY_MM_DD) === formattedDate
    );
    const emoji = Moon.lunarPhaseEmoji(new Date(formattedDate));

    return (
      <LrvTooltip
        themeStyle='dark'
        title={renderContainers(date)}
      >
        <div
          className={styles.cell}
          style={{
            backgroundColor: getBackgroundColorCell(harvestOptimalPlans.length)
          }}
        >
          <div className={styles.day}>
            {date.date()}
          </div>
        
          <div className={styles.count}>
            {harvestOptimalPlans.length || ''}
          </div>

          <div className={styles.emoji}>{emoji}</div>
        </div>
      </LrvTooltip>
    );
  };
  
  const onCancel = () => {
    setSelectedMonth(moment());
    dispatch(optimalHarvestPointSlice.setShowHeatMapModal(false));
  };

  const renderFooter = () => {
    return (
      <Button
        type='default'
        onClick={onCancel}
      >
        {t('priceTable.cancel')}
      </Button>
    );
  };

  return (
    <LrvModal
      theme={theme}
      title={t('optimalHarvestPoint.heatMap.title')}
      open={showHeatMapModal}
      destroyOnClose={false}
      closeIcon={<Icon id='close_modal' name='close' />}
      onCancel={onCancel}
      className={cx(styles.heatMapModal, 'heatMapModal')}
      width={modalWidth}
      footer={[renderFooter()]}
    >
      <div className={styles.container}>
        <div className={styles.row}>
          <LrvText text={t('optimalHarvestPoint.heatMap.description')} theme={theme}/>
        
          <LrvDatePicker
            theme='light'
            picker='month'
            className={styles.datePicker}
            value={selectedMonth}
            allowClear={false}
            format='MMMM YYYY'
            onChange={(date) => {
              setSelectedMonth(date);
            }}
          />
        </div>

        <div className={styles.containerHeatMap}>
          <LrvCalendar
            theme='light'
            dateFullCellRender={renderDateCell}
            fullscreen={true}
            value={selectedMonth}
            headerRender={() => null}
            locale={getCalendarLocale()}
            onSelect={(date) => {
              setSelectedMonth(date);
            }}
          />
        </div>
      </div>
    </LrvModal>
  );
};
