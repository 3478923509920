import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import * as headerSlice from '../../AppHeader/headerSlice';
import { ParameterType } from '../../Reports/TransferGraph/interfaces';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import * as capsuleChartSlice from '../../Reports/TransferGraph/transferGraphSlice';

import './TransferGraphPdf.scss';
import { TransferData } from './TransferData';
import { TransferGraph } from './TransferGraph';
import { organizeDataByVariable } from './helpers';
import styles from './TransferGraphPdf.module.scss';

type TParams = {
  companyId: string;
  transferId: string;
  language: string;
  accessToken: string;
};

export const TransferGraphPdf = ({ match }: RouteComponentProps<TParams>) => {
  const {
    companyId,
    accessToken,
    language,
    transferId,
  } = match.params;

  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const { isLoading, transferParameters } = useSelector((state: Store) => state.transferGraph);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n, i18n.language]);

  useEffect(() => {
    dispatch(headerSlice.fetchCompany(companyId, accessToken));
    dispatch(capsuleChartSlice.fetchTransferChartData({ transferId, accessToken }));
  }, [dispatch, companyId, accessToken, transferId]);

  if (isLoading) {
    return (
      <div className={styles.spinnerContainer}>
        <div className={styles.spinner}>
          <DotSpinner />
        </div>
      </div>
    );
  }

  const renderChart = () => {
    const result = organizeDataByVariable(transferParameters);

    const items: JSX.Element[] = [
      <TransferData transferMetricEntry={result}/>
    ];

    for (const key in result) {
      if (Object.prototype.hasOwnProperty.call(result, key)) {
        const transferMetrics = result[key];
        
        items.push(
          <TransferGraph transferMetrics={transferMetrics} parameter={key as ParameterType} />
        );
      }
    }

    return items;
  };
  
  return (
    <div id='transfer-graphs' className="transfers">
      {renderChart()}
    </div>
  );
};
