import cx from 'classnames';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatMonthYear } from '../../../utils/date';
import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import { getCalendarLocale } from '../../../utils/locale';
import { DATE_FORMATS, THEME } from '../../../config/commons';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import * as optimalHarvestPointSlice from '../optimalHarvestPointSlice';
import { LrvCalendar } from '../../../common/components/LrvCalendar/LrvCalendar';
import { getBackgroundColorCell } from '../../ActivityDashboard/UserActivityDashboard/helpers';

import HeatMapD3 from './HeatMapD3';
import styles from './HeatMap.module.scss';
import { HeatMapModal } from './HeatMapModal';
import { countByHarvestDateArray, getHeatMapValues } from './helpers';

export const CustomHeatMap = () => {
  const dispatch = useDispatch();
  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  const { harvestOptimalPlan } = useSelector((state: Store) => state.optimalHarvestPoint);

  const refChart = useRef<HTMLDivElement>(null);
  const data = countByHarvestDateArray(harvestOptimalPlan.harvestOptimalPlans);

  useEffect(() => {
    const values = getHeatMapValues(data);

    new HeatMapD3({
      container: refChart.current,
      height: 148,
      width: 16,
      values,
      marginTop: 4,
      marginBottom: 4,
      legendWidth: 4,
    });
  }, [data]);

  const renderDateCell = (date: moment.Moment) => {
    const formattedDate = date.format(DATE_FORMATS.YYYY_MM_DD);
    const harvestOptimalPlans = harvestOptimalPlan.harvestOptimalPlans.filter(
      (harvestOptimalPlan) =>
        moment(harvestOptimalPlan.harvestDate).format(DATE_FORMATS.YYYY_MM_DD) === formattedDate
    );

    return (
      <div
        className={styles.cell}
        style={{
          backgroundColor: getBackgroundColorCell(harvestOptimalPlans.length),
        }}
      >
        <div
          className={styles.day}
          style={{
            color: (harvestOptimalPlans.length > 0 && !isLightTheme) ? 'black' : undefined,
          }}
        >
          {date.date()}
        </div>
      </div>
    );
  };
  
  return (
    <div>
      <div
        className={cx(styles.container, isLightTheme ? styles.lightContainer : styles.darkContainer)}
        onClick={() => {
          dispatch(optimalHarvestPointSlice.setShowHeatMapModal(true));
        }}
      >
        <div className={styles.containerHeatMap}>
          <div className={styles.iconContainer}>
            <Icon name='expand-diagonal' theme={theme} className={styles.icon} />
          </div>

          <div>
            <LrvText
              className={styles.month}
              text={formatMonthYear((new Date()).toString())}
              theme={theme}
            />
          </div>
        
          <LrvCalendar
            theme={theme}
            dateFullCellRender={renderDateCell}
            fullscreen={true}
            className={styles.calendar}
            headerRender={() => null}
            locale={getCalendarLocale()}
          />
        </div>

        <div className={styles.containerChart}>
          <div ref={refChart} className={styles.chart}/>
        </div>
      </div>
      
      <HeatMapModal/>
    </div>
  );
};
