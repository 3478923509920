import cx from 'classnames';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { isNumber } from '../../../../utils/validations';
import { roundOneDecimal } from '../../../../config/commons';
import { LrvText } from '../../../../common/components/LrvText/LrvText';

import styles from './DistributionBar.module.scss';

interface Props {
  whole?: number;
  tail?: number;
  waste?: number;
  theme?: 'dark' | 'light';
}

export const DistributionBar = (props: Props) => {
  const { whole, tail, waste, theme = 'light' } = props;
  
  const [t] = useTranslation();

  const isValidWhole = !!whole && isNumber(whole) && whole > 0;
  const isValidTail = !!tail && isNumber(tail) && tail > 0;
  const isValidWaste = !!waste && isNumber(waste) && waste > 0;

  const renderWholeLabel = () => {
    if (!isValidWhole) {
      return null;
    }

    return (
      <div className={styles.row}>
        <div className={cx(styles.square, styles.whole)}/>
        <LrvText theme={theme} text={`${t('commercialSizes.commercialSizeTypes.GROW_OUT_WHOLE')} ${roundOneDecimal(whole)} % `} />
      </div>
    );
  };

  const renderTailLabel = () => {
    if (!isValidTail) {
      return null;
    }

    return (
      <div className={styles.row}>
        <div className={cx(styles.square, styles.tail)}/>
        <LrvText theme={theme} text={`${t('commercialSizes.commercialSizeTypes.GROW_OUT_TAIL')} ${roundOneDecimal(tail)} % `} />
      </div>
    );
  };

  const renderWasteLabel = () => {
    if (!isValidWaste) {
      return null;
    }

    return (
      <div className={styles.row}>
        <div className={cx(styles.square, styles.waste)}/>
        <LrvText theme={theme} text={`${t('priceTable.waste')} ${roundOneDecimal(waste)} % `} />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Space className={styles.labels}>
        {renderWholeLabel()}
        {renderTailLabel()}
        {renderWasteLabel()}
      </Space>

      <div className={styles.bar}>
        {isValidWhole && <div className={styles.whole} style={{ width: `${whole}%` }}></div>}
        {isValidTail && <div className={styles.tail} style={{ width: `${tail}%` }}></div>}
        {isValidWaste && <div className={styles.waste} style={{ width: `${waste}%` }}></div>}
      </div>
    </div>
  );
};
