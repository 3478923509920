import { cloneDeep } from 'lodash';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect } from 'react';

import { weightUnits } from '../../../../config/commons';
import { LrvTable } from '../../../../common/components/LrvTable/LrvTable';
import { CommercialSizeRange, ProcessedCommercialSizeRange } from '../interfaces';
import { LrvInputNumber } from '../../../../common/components/LrvInputNumber/LrvInputNumber';
import { assignPricesToProcessedData, getWeightUnit } from '../../../../helpers/commercial-size-price-table';
import { getMaxValueDisplay, processCommercialSizeRanges, getClickableMinValue } from '../../../../helpers/commercial-size.helpers';

import styles from './CommercialSizeTable.module.scss';

interface Props {
  commercialSizeRanges: CommercialSizeRange[];
  commercialSizeType: string;
  processedDataSource: ProcessedCommercialSizeRange[];
  setProcessedDataSource: Dispatch<SetStateAction<ProcessedCommercialSizeRange[]>>;
  prices?: number[];
  weightUnit: string;
}

export const CommercialSizeTable = (props: Props) => {
  const { commercialSizeRanges, commercialSizeType, processedDataSource, setProcessedDataSource, prices, weightUnit } = props;

  const [t] = useTranslation();

  const maxValueDisplay = getMaxValueDisplay({ commercialSizeType });
  const clickableMinValue = getClickableMinValue({ commercialSizeType });
  
  useEffect(() => {
    const processedDataSource = processCommercialSizeRanges({
      ranges: commercialSizeRanges,
      maxValueDisplay,
      commercialSizeType,
      clickableMinValue,
    });

    const updatedDataSource = assignPricesToProcessedData({ processedDataSource, prices });
    setProcessedDataSource(updatedDataSource);
  }, [commercialSizeRanges, commercialSizeType, prices]);

  const updateProcessedDataSource = (record: ProcessedCommercialSizeRange, value: string | number | null, index: number) => {
    const processedDataSourceCopy: ProcessedCommercialSizeRange[] = cloneDeep(processedDataSource);
    record.price = value ? parseFloat(value.toString()) : undefined;
    processedDataSourceCopy[index] = record;
    setProcessedDataSource(processedDataSourceCopy);
  };

  const columns: ColumnProps<ProcessedCommercialSizeRange>[] = [
    {
      key: 1,
      title: t('commercialSizes.labels'),
      width: '28%',
      dataIndex: 'commercialSizeLabel',
    },
    {
      key: 2,
      title: `${t('commercialSizes.weightRange')} (${weightUnits.G})`,
      width: '30%',
      dataIndex: 'weightRange',
    },
    {
      key: 3,
      title: t('priceTable.price', { unit: getWeightUnit({ weightUnit }) }),
      dataIndex: 'price',
      width: '32%',
      render: (_, record: ProcessedCommercialSizeRange, index: number) => {
        return (
          <>
            <LrvInputNumber
              theme='light'
              value={record.price}
              className={styles.input}
              onChange={(value) => updateProcessedDataSource(record, value, index)}
              parser={(input) => `${input}`.replace(',', '.')}
            />
          </>
        );
      },
    },
  ];

  return (
    <LrvTable
      theme='light'
      className={styles.table}
      columns={columns}
      dataSource={processedDataSource}
      size='small'
      pagination={false}
      scroll={{ y: 360 }}
    />
  );
};