import cx from 'classnames';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { HarvestOptimalPlan } from '../interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { Store } from '../../../state/store.interfaces';
import { getCurrentTheme } from '../../../helpers/theme';
import { formatLongDateWithZone } from '../../../utils/date';
import * as optimalHarvestPointSlice from '../optimalHarvestPointSlice';
import ActionButton from '../../../common/components/buttons/ActionButton';
import { LrvTooltip } from '../../../common/components/LrvTooltip/LrvTooltip';
import { lrvConfirm } from '../../../common/components/LrvConfirm/LrvConfirm';
import { roundTwoDecimals, THEME, weightUnits, weightUnitsByCompany } from '../../../config/commons';

import { FoodChart } from './FoodChart';
import { Indicators } from './Indicators';
import { DetailHarvest } from './DetailHarvest';
import styles from './IndicatorsPanel.module.scss';
import * as feedingTableSlice from './feedingTableSlice';

export const IndicatorsPanel = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const theme = getCurrentTheme();

  const isLightTheme = theme === THEME.LIGHT;

  const { company } = useSelector((state: Store) => state.header);
  const { predictionSelected, allPredictions, pocPoint, filters, harvestOptimalPlan, tankPocParameter } = useSelector((state: Store) => state.optimalHarvestPoint);
  
  const showAddToPlanConfirmation = () => {
    const point = predictionSelected || pocPoint;
    
    const prediction = allPredictions.find((prediction) => prediction.x === point?.x);

    if (!prediction) {
      return;
    }

    const biomass = company.weightUnit === weightUnitsByCompany.KILOGRAM ? `${roundTwoDecimals(prediction.biomass)} ${weightUnits.KG}` : `${roundTwoDecimals(prediction.biomassLb)} ${weightUnits.LB}`;

    lrvConfirm({
      theme: 'light',
      title: t('optimalHarvestPoint.addPlan'),
      icon: <ExclamationCircleOutlined />,
      content: t('optimalHarvestPoint.addPlanDescription', { biomass, packerName: point?.packerName, date: formatLongDateWithZone({ date: prediction.predictionDate }) }),
      okText: t('optimalHarvestPoint.buttons.accept'),
      cancelText: t('optimalHarvestPoint.buttons.cancel'),
      onOk () {
        const point = predictionSelected || pocPoint;
        const prediction = allPredictions.find((prediction) => prediction.x === point?.x);

        if (!point || !prediction) {
          return;
        }
        
        const body: HarvestOptimalPlan = {
          companyId: company._id,
          harvestDate: prediction.predictionDate,
          biomassKg: prediction.biomass,

          campusId: filters.unitId,
          foodPricePerKg: filters.foodPricePerKg,
          costPerHp: filters.costPerHp,
          linearBiomassCoefficient: filters.linearBiomassCoefficientKg,
          packerId: point.packerId,

          tankId: filters.tankId,
          loadCapacity: filters.loadCapacity,
          costPerVolumeDay: filters.costPerVolumeDay,
          feedingStrategy: filters.feedingStrategy,
          
          stockingId: filters.selectedStocking._id,
          survival: filters.survival,
          mortality: filters.mortality,
          weeklyFeeding: filters.weeklyFeeding,
          accumulatedCost: filters.accumulatedCost,
        };
        dispatch(optimalHarvestPointSlice.createHarvestOptimalPlan({ body }));
      },
    });
  };

  return (
    <>
      <Space className={styles.container}>
        <Indicators />

        <Space className={styles.options}>
          <LrvTooltip
            themeStyle={theme}
            title={t('optimalHarvestPoint.feedingTable.title')}
          >
            <div
              className={cx(styles.detailHarvest, isLightTheme ? styles.lightDetailHarvest : styles.darkDetailHarvest)}
              onClick={() => {
                if (tankPocParameter) {
                  dispatch(feedingTableSlice.setTankPocParameter(tankPocParameter));
                }
                dispatch(feedingTableSlice.setShowFoodChartModal(true));
              }}
            >
              <Icon name='line-chart' theme={theme} className={styles.icon} />
            </div>
          </LrvTooltip>

          <LrvTooltip
            themeStyle={theme}
            title={t('optimalHarvestPoint.detailHarvest')}
          >
            <div
              className={cx(styles.detailHarvest, isLightTheme ? styles.lightDetailHarvest : styles.darkDetailHarvest)}
              onClick={() => {
                dispatch(optimalHarvestPointSlice.setShowDetailHarvestModal(true));
              }}
            >
              <Icon name='bar-chart-2' theme={theme} className={styles.icon} />
            </div>
          </LrvTooltip>

          <ActionButton
            type='primary'
            loading={harvestOptimalPlan.isCreating}
            onClick={showAddToPlanConfirmation}
            className={styles.addPlan}
          >
            {window.innerWidth > 1360 && <>
              {t('optimalHarvestPoint.addPlan')}
              &nbsp;
            </>
            }
            <Icon name='corner-up-right' theme={theme} className={styles.icon} />
          </ActionButton>
        </Space>
      </Space>
      
      <DetailHarvest />
      <FoodChart />
    </>
  );
};
