import React from 'react';
import moment from 'moment';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import { getDayLabel, getDayOfMonth, getMonthLabel } from '../../../utils/date';

import { UserActivity } from './interfaces';
import { getBackgroundColorCell } from './helpers';
import styles from './WeeklyUserActivity.module.scss';

export default function WeeklyUserActivity () {
  const { userActivity } = useSelector((state: Store) => state.userActivityDashboard);

  const getCountUserActivity = (activity: UserActivity) => {
    const date = moment(activity.day);
    const tomorrow = moment().add(1, 'days').startOf('days');

    if (date.isSameOrAfter(tomorrow) || activity.count === 0) {
      return '-';
    }

    return activity.count;
  };

  const getClassUserActivity = (activity: UserActivity) => {
    const date = moment(activity.day);
    const tomorrow = moment().add(1, 'days').startOf('days');

    if (date.isSameOrAfter(tomorrow) || activity.count === 0) {
      return cx(styles.count, styles.dash);
    }

    return styles.count;
  };

  return (
    <div className={styles.content}>
      <table className={styles.table}>
        <tbody className={styles.tbody}>
          {userActivity.map((activity: UserActivity) => {
            return (
              <tr className={styles.userActivity}>
                <td className={styles.date}>
                  <div className={styles.left}>
                    {getDayOfMonth(activity.day)}
                  </div>

                  <div className={styles.right}>
                    <div className={styles.day}>
                      {getDayLabel(activity.day).toUpperCase()}
                    </div>
                    <div className={styles.month}>
                      {getMonthLabel(activity.day)}
                    </div>
                  </div>
                </td>
                <td className={getClassUserActivity(activity)} style={{ backgroundColor: getBackgroundColorCell(activity.count) }}>
                  {getCountUserActivity(activity)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}