import * as d3 from 'd3';
import cx from 'classnames';

import { THEME } from '../../../config/commons';
import { getCurrentTheme } from '../../../helpers/theme';

import { panelColors } from './helpers';
import styles from './HeatMapD3.module.scss';

let isLightTheme = true;

interface Props {
  container: HTMLDivElement | null;
  width: number;
  height: number;
  values: number[];
  marginTop: number;
  marginBottom: number;
  legendWidth: number;
}

export default class HeatMapD3 {
  container: HTMLDivElement | null;
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined> = d3.select<SVGSVGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'svg'));
  groupMain: d3.Selection<SVGGElement, unknown, null, undefined>;

  width: number;
  height: number;
  values: number[];
  marginTop: number;
  marginBottom: number;
  legendWidth: number;

  // eslint-disable-next-line
  constructor(props: Props) {
    const { container, width, height, values, marginTop, legendWidth, marginBottom } = props;

    this.container = container;
    this.values = values;

    this.width = width;
    this.height = height + marginTop + marginBottom;
    this.marginTop = marginTop;
    this.marginBottom = marginBottom;
    this.legendWidth = legendWidth;

    d3.select(container).select('#heatMap').remove();
    const theme = getCurrentTheme();
    isLightTheme = theme === THEME.LIGHT;

    this.svg = d3.select(container)
      .append('svg')
      .attr('id', 'heatMap')
      .attr('width', this.width)
      .attr('height', this.height);

    this.groupMain = this.svg
      .append('g')
      .attr('id', 'content')
      .attr('transform', `translate(0, ${marginTop})`);

    this.updateDataPoints();
  }

  updateDataPoints = () => {
    this.renderColorLegend();
  };

  getColorScale = () => {
    const { values } = this;

    const min = Math.min(...values);
    const max = Math.max(...values);

    return d3.scaleQuantize<string, never>()
      .domain([min, max])
      .range(panelColors);
  };

  renderNumericLegend = (props: { colorScale: d3.ScaleQuantize<string, never>; values: number[]; }) => {
    const { colorScale, values } = props;
    const { groupMain, height, legendWidth, marginTop, marginBottom } = this;

    const min = Math.min(...values);
    const max = Math.max(...values);
    const colorDomain = [min, max];

    // Create a group for the color bar
    const colorBarGroup = groupMain.append('g')
      .attr('id', 'colorBarGroup');

    // Gradient for the color bar
    const defs = groupMain.append('defs');
    const linearGradient = defs.append('linearGradient')
      .attr('id', 'linear-gradient')
      .attr('x1', '0%')
      .attr('y1', '100%')
      .attr('x2', '0%')
      .attr('y2', '0%');

    // Manually create stops
    const stops = d3.range(colorDomain[0], colorDomain[1], (colorDomain[1] - colorDomain[0]) / 10).map(value => ({
      offset: `${(value - colorDomain[0]) / (colorDomain[1] - colorDomain[0]) * 100}%`,
      color: colorScale(value)
    }));

    linearGradient.selectAll('stop')
      .data(stops)
      .enter()
      .append('stop')
      .attr('offset', d => d.offset)
      .attr('stop-color', d => d.color);

    // Draw the rectangle and fill with gradient
    colorBarGroup.append('rect')
      .attr('width', legendWidth)
      .attr('height', height - marginTop - marginBottom)
      .style('fill', 'url(#linear-gradient)')
      .attr('rx', 4)
      .attr('ry', 4);

    // Create the axis scale and draw the axis
    const axisScale = d3.scaleLinear()
      .domain(colorDomain)
      .range([height - marginTop - marginBottom, 0]);

    const axisRight = d3.axisRight(axisScale)
      .tickPadding(0)
      .ticks(4);

    colorBarGroup.append('g')
      .attr('class', cx(styles.axisY, isLightTheme ? styles.axisLight : styles.axisDark))
      .call(axisRight);
  };

  renderColorLegend = () => {
    const { container, values } = this;

    d3.select(container).select('#colorBarGroup').remove();
    this.renderNumericLegend({ colorScale: this.getColorScale(), values });
  }
}
