import moment from 'moment';
import cx from 'classnames';
import { Col, Row } from 'antd';
import { cloneDeep } from 'lodash';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';

import { RootState } from '../../../state/store';
import * as stockingsSlice from '../sowingsSlice';
import Icon from '../../../common/components/Icon/Icon';
import { Breadcrumb } from '../../AppHeader/interfaces';
import { getCurrentTheme } from '../../../helpers/theme';
import PopulationTypes, { PopulationData } from '../interfaces';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import IconButton from '../../../common/components/buttons/IconButton';
import { LrvTable } from '../../../common/components/LrvTable/LrvTable';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import ActionButton from '../../../common/components/buttons/ActionButton';
import Breadcrumbs from '../../../common/components/Breadcrumb/Breadcrumbs';
import { LrvDivider } from '../../../common/components/LrvDivider/LrvDivider';
import { LrvPopover } from '../../../common/components/LrvPopover/LrvPopover';
import { generatePopulationPDF } from '../../../helpers/population.pdf.helpers';
import { LrvDatePicker } from '../../../common/components/LrvDatePicker/LrvDatePicker';
import { LrvInputNumber } from '../../../common/components/LrvInputNumber/LrvInputNumber';
import { applyParserThousandsSeparator, applyThousandsSeparator } from '../../../utils/strings';
import { changeBreadcrumb, changeHeader, fetchCompany, setPhaseType } from '../../AppHeader/headerSlice';
import { getStockingVolumeUnit, getTitleByStockingPhaseType, getUnitDensity } from '../../../helpers/stocking.helpers';
import { MAX_SURVIVAL_RATE, stockingPhaseTypes, stockingStatuses, THEME, transferTypes } from '../../../config/commons';
import { calcDaysInProduction, calcNewDensity, calcNewNumberAnimalsByDensity, calcNewNumberAnimalsBySurvivalRate, calcNewSurvivalRate, checkIfDisableAddButton, disableDate, findAnimalsPopulationError, getCurrentStateInfo, getHarvestInfo, getPopulationAnimalsNumber, getPopulationTypeIcon, getStockingInfo, sortAndBuildPopulationData, updatePopulationItems } from '../../../helpers/population.helpers';

import styles from './StockingPopulations.module.scss';
import * as editStockingPopulationSlice from './editStockingPopulationSlice';

const populationIconClassName = {
  [PopulationTypes.FULL_HARVEST]: styles.fullHarvestIcon,
  [PopulationTypes.FULL_TRANSFER]: styles.fullTransferIcon,
  [PopulationTypes.MANUAL_POPULATION]: styles.manualPopulationIcon,
  [PopulationTypes.HARVEST]: styles.harvestIcon,
  [PopulationTypes.TRANSFER]: styles.transferIcon,
};

type TParams = { id: string };

const StockingPopulations = ({ match }: RouteComponentProps<TParams>) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const theme = getCurrentTheme();
  const stockingId = match.params.id;
  const isLightTheme = theme === THEME.LIGHT;

  const { lastAnalysis, isLoadingLastAnalysis } = useSelector((state: RootState) => state.stockings);
  const { selectedStocking, stockingTransfers, isUpdating, isLoadingStocking } = useSelector((state: RootState) => state.editStockingPopulation);
  const { breadcrumb, company } = useSelector((state: RootState) => state.header);
  const { _id: companyId } = company;

  const [disabledButton, setDisabledButton] = useState(true);
  const populationsRef = useRef<HTMLDivElement | null>(null);
  const [unitDensity, setUnitDensity] = useState<string>('');
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [populationData, setPopulationData] = useState<PopulationData[]>([]);
  const [showPopulationAnimalError, setShowPopulationAnimalError] = useState(false);

  const volumeUnit = getStockingVolumeUnit(selectedStocking.tankId?.type || '');
  const hideAddPopulationButton = checkIfDisableAddButton({ selectedStocking, populations: populationData });
  const showHarvestInfo = selectedStocking?.status === stockingStatuses.HARVESTED || selectedStocking?.status === transferTypes.FULL_TRANSFER;
  const showCurrentStateInfo = !showHarvestInfo;

  /*useEffect section*/
  useEffect(() => {
    dispatch(changeHeader({ title: 'stockings.populations.addPopulations' }));

    return () => {
      setDisabledButton(true);
      setShowPopulationAnimalError(false);
      dispatch(stockingsSlice.setLastAnalysis(undefined));
    };
  }, [dispatch]);

  useEffect(() => {
    if (companyId) {
      return;
    }

    dispatch(fetchCompany(selectedStocking.companyId));
  }, [dispatch, companyId, selectedStocking.companyId]);

  useEffect(() => {
    if (!selectedStocking.name) {
      return;
    }

    const breadcrumb: Breadcrumb[] = [{
      id: 'sowings',
      name: getTitleByStockingPhaseType(selectedStocking.phaseType),
      url: '/production/stockings',
    },
    {
      id: selectedStocking.name,
      name: `${t('stockings.stocking')} ${selectedStocking.name}`,
      url: `/production/stockings/${stockingId}`,
    },
    {
      id: 'populations',
      name: t('stockings.population').toLowerCase(),
      url: `/production/stockings/${stockingId}/population`,
    }];
    dispatch(changeBreadcrumb(breadcrumb));
    dispatch(setPhaseType(selectedStocking.phaseType));
  }, [dispatch, stockingId, selectedStocking.name, selectedStocking.phaseType]);

  useEffect(() => {
    dispatch(editStockingPopulationSlice.fetchStocking(stockingId));
    dispatch(stockingsSlice.fetchLastAnalysisData({ stockingId }));
  }, [dispatch, stockingId]);

  useEffect(() => {
    if (!selectedStocking._id) {
      setPopulationData([{ populationDate: moment().toISOString(), productionDays: calcDaysInProduction(selectedStocking, moment()), animalsTransferred: 0, animalsNumber: 0, density: 0, survivalRate: 0, type: PopulationTypes.MANUAL_POPULATION }]);
      return;
    }

    // get populations data sorted by population date
    const populationsData = sortAndBuildPopulationData(selectedStocking);
    setPopulationData(populationsData);

    // get density unit
    const unitDensity = getUnitDensity(selectedStocking);
    setUnitDensity(unitDensity);

    // get and set the end date from the stocking if it was finished
    if (selectedStocking.status === stockingStatuses.HARVESTED) {
      setEndDate(selectedStocking.endDate);
    }

    if (selectedStocking.status === transferTypes.FULL_TRANSFER) {
      setEndDate(selectedStocking.endDate);
    }
  }, [dispatch, selectedStocking]);

  useEffect(() => {
    if (!populationsRef.current) {
      return;
    }

    if (populationData.length === 1 && populationData[0].animalsNumber === 0) {
      setDisabledButton(true);
      return;
    }

    for (let index = 0; index < populationData.length; index++) {
      const population = populationData[index];
      if (population.animalsNumber <= 0) {
        setDisabledButton(true);
        return;
      }
    }

    const populationAnimalElements = populationsRef.current.querySelectorAll('.ant-input-number.hasPopulationError');
    const hasPopulationAnimalError = populationAnimalElements.length > 0;

    setShowPopulationAnimalError(hasPopulationAnimalError);

    setDisabledButton(hasPopulationAnimalError);
  }, [populationsRef, populationData]);

  useEffect(() => {
    if (!selectedStocking?._id) {
      return;
    }

    const stockingIds = selectedStocking.transfers.map(item => item.stockingId);
    dispatch(editStockingPopulationSlice.fetchStockingTransfersData({ companyId, stockingIds }));
  }, [dispatch, selectedStocking, companyId]);
  /*End of useEffect section*/

  /*functions section */
  const addPopulationItem = () => {
    if (disabledButton) {
      return;
    }

    const newData: PopulationData[] = cloneDeep(populationData);
    newData.push({
      populationDate: moment().toISOString(),
      type: PopulationTypes.MANUAL_POPULATION,
      productionDays: calcDaysInProduction(selectedStocking, moment()),
      animalsTransferred: 0, animalsNumber: 0, density: 0, survivalRate: 0,
    });
    setPopulationData(newData);
  };

  const getPopoverContent = (population: PopulationData) => {
    const stockingTransferred = stockingTransfers.find(element => element._id === population.stockingId);
    return (
      <div className={cx(styles.popoverContent, styles.transferPopover)}>
        <li className={styles.stockingName}><a target='_blank' href={`/production/stockings/${stockingTransferred?._id}`}><strong>{t('stockings.destinationStocking')}: </strong>{stockingTransferred?.name}</a></li>
        <li className={styles.animals}><strong>{t('stockings.populations.animalsTransferred')}: </strong>{applyThousandsSeparator(population.animalsTransferred)}</li>
      </div>
    );
  };

  const onSubmit = () => {
    dispatch(editStockingPopulationSlice.patchStockingPopulations({ stockingId: selectedStocking._id, data: populationData }));
  };
  /*End of functions section */

  /*render components section */
  const renderButtonGeneratePdf = () => {
    return (
      <IconButton
        className={styles.generatePdf}
        onClick={() => generatePopulationPDF({ populationData, companyName: company.name, endDate, stocking: selectedStocking, volumeUnit, showHarvestInfo, showCurrentStateInfo, lastAnalysis, weightUnit: company.weightUnit })}
        iconName='download'
        placement='left'
        tooltipText={t('survivalRate.generate')}
      />
    );
  };

  const renderSubHeader = () => {
    if (!selectedStocking._id) {
      return null;
    }

    return (
      <Row className={styles.rowHeader} align='middle' justify='space-between'>
        <Breadcrumbs breadcrumb={breadcrumb} noCapitalize theme={theme} />
        {renderButtonGeneratePdf()}
      </Row>
    );
  };

  const renderInfoCell = (props: { title: string; description: string; className?: string }) => {
    const { className, title, description } = props;

    return (
      <div className={cx(className || '', styles.infoCell)}>
        <LrvText className={cx(styles.title, isLightTheme ? styles.titleLight : styles.titleDark)} text={title} />
        <LrvText className={cx(styles.description, isLightTheme ? styles.descriptionLight : styles.descriptionDark)} text={description} />
      </div>
    );
  };

  const renderStockingInfo = () => {
    const stockingInfo = getStockingInfo({ stocking: selectedStocking });

    return (
      <div className={styles.stockingInfoSection}>
        <LrvText className={cx(styles.sectionTitle, isLightTheme ? styles.sectionTitleLight : styles.sectionTitleDark)} text={t('production.filters.stockingInfo')} />
        <div className={styles.row}>
          {renderInfoCell({ title: stockingInfo.stockingName.label, description: stockingInfo.stockingName.value })}
          {renderInfoCell({ title: stockingInfo.unitName.label, description: stockingInfo.unitName.value })}
          {renderInfoCell({ title: stockingInfo.moduleName.label, description: stockingInfo.moduleName.value })}
          {renderInfoCell({ title: stockingInfo.containerName.label, description: stockingInfo.containerName.value })}
          {renderInfoCell({ title: stockingInfo.stockingCode.label, description: stockingInfo.stockingCode.value })}

          {/**This are the same that the second row, but these are going to appear when max-width is 900px, check styles of // Responsive sections */}
          {renderInfoCell({ title: stockingInfo.stockingDate.label, description: stockingInfo.stockingDate.value, className: styles.infoCellToAppear })}
          {renderInfoCell({ title: stockingInfo.sownAnimals.label, description: stockingInfo.sownAnimals.value, className: styles.infoCellToAppear })}
          {renderInfoCell({ title: stockingInfo.volume.label, description: stockingInfo.volume.value, className: styles.infoCellToAppear })}
          {renderInfoCell({ title: stockingInfo.stockingDensity.label, description: stockingInfo.stockingDensity.value, className: cx(styles.infoDensityCell, styles.infoCellToAppear) })}
        </div>

        <div className={cx(styles.row, styles.rowToDisappear)}>
          {renderInfoCell({ title: stockingInfo.stockingDate.label, description: stockingInfo.stockingDate.value })}
          {renderInfoCell({ title: stockingInfo.sownAnimals.label, description: stockingInfo.sownAnimals.value })}
          {renderInfoCell({ title: stockingInfo.volume.label, description: stockingInfo.volume.value })}
          {renderInfoCell({ title: stockingInfo.stockingDensity.label, description: stockingInfo.stockingDensity.value, className: styles.infoDensityCell })}
        </div>
      </div>
    );
  };

  const renderCurrentStateInfo = () => {
    const currentStateInfo = getCurrentStateInfo({ stocking: selectedStocking, populationData, lastAnalysis, weightUnit: company.weightUnit });

    return (
      <div className={styles.currentStateInfoSection}>
        <LrvText className={cx(styles.sectionTitle, isLightTheme ? styles.sectionTitleLight : styles.sectionTitleDark)} text={t('stockings.populations.currentState')} />
        <div className={styles.row}>
          {renderInfoCell({ title: currentStateInfo.lastWeight.label, description: currentStateInfo.lastWeight.value })}
          {renderInfoCell({ title: currentStateInfo.lastPopulation.label, description: currentStateInfo.lastPopulation.value })}
          {renderInfoCell({ title: currentStateInfo.survival.label, description: currentStateInfo.survival.value })}
          {renderInfoCell({ title: currentStateInfo.totalBiomass.label, description: currentStateInfo.totalBiomass.value })}
          {renderInfoCell({ title: currentStateInfo.biomass.label, description: currentStateInfo.biomass.value })}
        </div>
      </div>
    );
  };

  const renderHarvestInfo = () => {
    const harvestInfo = getHarvestInfo({ stocking: selectedStocking, populationData, weightUnit: company.weightUnit });
    const isLarvaeStocking = selectedStocking.phaseType === stockingPhaseTypes.LARVAE;

    return (
      <div className={styles.currentStateInfoSection}>
        <LrvText className={cx(styles.sectionTitle, isLightTheme ? styles.sectionTitleLight : styles.sectionTitleDark)} text={t('stockings.populations.harvest')} />
        <div className={styles.row}>
          {isLarvaeStocking && renderInfoCell({ title: harvestInfo.harvestPlg.label, description: harvestInfo.harvestPlg.value })}
          {!isLarvaeStocking && renderInfoCell({ title: harvestInfo.harvestWeight.label, description: harvestInfo.harvestWeight.value })}
          {renderInfoCell({ title: harvestInfo.animalsHarvested.label, description: harvestInfo.animalsHarvested.value })}
          {renderInfoCell({ title: harvestInfo.survival.label, description: harvestInfo.survival.value })}
          {renderInfoCell({ title: harvestInfo.totalBiomass.label, description: harvestInfo.totalBiomass.value })}
          {renderInfoCell({ title:harvestInfo.biomass.label, description: harvestInfo.biomass.value })}
        </div>
      </div>
    );
  };

  const renderPopulationAction = (populationType: PopulationTypes) => {
    const hideIcon = populationType === PopulationTypes.FULL_HARVEST || populationType === PopulationTypes.FULL_TRANSFER;

    return (
      <>
        {!hideIcon && <Icon
          type='line'
          theme={theme}
          name={getPopulationTypeIcon(populationType)}
          className={cx(styles.populationTypeIcon, populationIconClassName[populationType])}
        />}
        <LrvText theme={theme} text={t(`stockings.populations.populationType.${populationType}`)} />
      </>
    );
  };

  const renderPopulationDate = (props: { population: PopulationData; index: number }) => {
    const { population, index } = props;
    const allowValidation = population.type === PopulationTypes.MANUAL_POPULATION;

    const populationDate = population.populationDate ? moment(population.populationDate) : moment();

    return (
      <LrvDatePicker
        id={`population_date_${index}`}
        titleClassName={styles.titleInput}
        containerClassName={cx(styles.populationDateContainer, allowValidation ? '' : styles.readOnly)}
        theme={theme}
        disabled={!allowValidation}
        className={cx(styles.populationDate, allowValidation ? '' : styles.readOnly)}
        value={populationDate}
        allowClear={false}
        disabledDate={(value) => allowValidation ? disableDate({ current: value, index, populationData, selectedStocking }) : false}
        placeholder={t('stockings.populationDate')}
        onChange={(date) => {
          const newData: PopulationData[] = cloneDeep(populationData);
          const productionDays = calcDaysInProduction(selectedStocking, date);
          const value = date?.toISOString() || '';
          newData[index].populationDate = value;
          newData[index].productionDays = productionDays;
          setPopulationData(newData);
        }}
      />
    );
  };

  const renderPopulationNumber = (props: { population: PopulationData; index: number }) => {
    const { population, index } = props;

    if (population.type === PopulationTypes.FULL_TRANSFER || population.type === PopulationTypes.FULL_HARVEST) {
      return '-';
    }

    const isReadOnly = population.type !== PopulationTypes.MANUAL_POPULATION;

    const hasPopulationAnimalsError = findAnimalsPopulationError({ index, selectedStocking, populationData });

    return (
      <LrvInputNumber
        id={`txt_animals_number_${index}`}
        titleClassName={styles.titleInput}
        className={cx(styles.populationAnimalsNumber, (hasPopulationAnimalsError) ? 'hasPopulationError' : '', isReadOnly ? styles.readOnly : '')}
        theme={theme}
        value={population.animalsNumber}
        min={0}
        readOnly={isReadOnly}
        formatter={value => applyThousandsSeparator(value)}
        parser={value => applyParserThousandsSeparator(value)}
        placeholder={t('stockings.animalsNumber')}
        autoFocus
        onChange={(e) => {
          const populations: PopulationData[] = cloneDeep(populationData);
          const value = Number(e);

          populations[index].animalsNumber = value;
          populations[index].density = calcNewDensity({ animals: populations[index].animalsNumber, stocking: selectedStocking });
          populations[index].survivalRate = calcNewSurvivalRate({ population: populations[index], stocking: selectedStocking, populations, index });
          const populationsUpdated = updatePopulationItems(populations, selectedStocking);

          setPopulationData(populationsUpdated);
        }}
      />
    );
  };

  const renderPopulationDensity = (props: { population: PopulationData; index: number }) => {
    const { population, index } = props;

    if (population.type === PopulationTypes.TRANSFER && selectedStocking.status === transferTypes.FULL_TRANSFER) {
      const fullTransferEndDate = moment(endDate).startOf('day');
      const populationDate = moment(population.populationDate).startOf('day');
      if (fullTransferEndDate.isSame(populationDate)) {
        return '-';
      }
    }

    const isReadOnly = population.type !== PopulationTypes.MANUAL_POPULATION;

    const hasPopulationAnimalsError = findAnimalsPopulationError({ index, selectedStocking, populationData });

    return (
      <div className={styles.cell}>
        <LrvInputNumber
          id={`txt_density_${index}`}
          titleClassName={styles.titleInput}
          className={cx(styles.populationAnimalsNumber, (hasPopulationAnimalsError) ? 'hasPopulationError' : '', isReadOnly ? styles.readOnly : '')}
          theme={theme}
          value={population.density}
          min={0}
          readOnly={isReadOnly}
          formatter={value => applyThousandsSeparator(value)}
          parser={value => applyParserThousandsSeparator(value)}
          placeholder={`${t('stockings.density')} ${unitDensity}`}
          autoFocus
          onChange={(e) => {
            const populations: PopulationData[] = cloneDeep(populationData);
            const value = Number(e);

            populations[index].density = value;
            populations[index].animalsNumber = calcNewNumberAnimalsByDensity({ density: value, stocking: selectedStocking });
            populations[index].survivalRate = calcNewSurvivalRate({ population: populations[index], stocking: selectedStocking, index, populations });
            const populationsUpdated = updatePopulationItems(populations, selectedStocking);

            setPopulationData(populationsUpdated);
          }}
        />
      </div>
    );
  };

  const renderPopulationSurvivalRate = (props: { population: PopulationData; index: number }) => {
    const { population, index } = props;

    if (population.type === PopulationTypes.TRANSFER && selectedStocking.status === transferTypes.FULL_TRANSFER) {
      const fullTransferEndDate = moment(endDate).startOf('day');
      const populationDate = moment(population.populationDate).startOf('day');
      if (fullTransferEndDate.isSame(populationDate)) {
        return '-';
      }
    }

    const isReadOnly = population.type !== PopulationTypes.MANUAL_POPULATION;

    const hasPopulationAnimalsError = findAnimalsPopulationError({ index, selectedStocking, populationData });

    return (
      <div className={styles.cell}>
        <LrvInputNumber
          id={`txt_survival_rate_${index}`}
          titleClassName={styles.titleInput}
          className={cx(styles.populationAnimalsNumber, (hasPopulationAnimalsError) ? 'hasPopulationError' : '', isReadOnly ? styles.readOnly : '')}
          theme={theme}
          value={population.survivalRate}
          min={0}
          max={MAX_SURVIVAL_RATE}
          readOnly={isReadOnly}
          placeholder={`${t('survivalRate.title')} (%)`}
          autoFocus
          onChange={(e) => {
            const populations: PopulationData[] = cloneDeep(populationData);
            const value = Number(e);

            populations[index].survivalRate = value;
            populations[index].animalsNumber = calcNewNumberAnimalsBySurvivalRate({ population: populations[index], stocking: selectedStocking, index, populations });
            populations[index].density = calcNewDensity({ animals: populations[index].animalsNumber, stocking: selectedStocking });
            const populationsUpdated = updatePopulationItems(populations, selectedStocking);

            setPopulationData(populationsUpdated);
          }}
        />
      </div>
    );
  };

  const renderRightIcon = (params: { population: PopulationData, index: number }) => {
    const { index, population } = params;

    switch (population.type) {
      case PopulationTypes.MANUAL_POPULATION:
        return (
          <IconButton
            className={cx(styles.btnRemovePopulationRow, styles.rightIcon)}
            onClick={() => {
              const populations: PopulationData[] = cloneDeep(populationData);
              populations.splice(index, 1);
              const populationsUpdated = updatePopulationItems(populations, selectedStocking);
              setPopulationData(populationsUpdated);
            }}
            theme={theme}
            disabled={populationData.length === 1}
            iconName='delete-bin'
          />
        );

      case PopulationTypes.TRANSFER:
        return (
          <LrvPopover theme={isLightTheme ? 'dark' : 'light'} placement='topRight' content={getPopoverContent(population)} trigger='click'>
            <IconButton
              className={cx(styles.btnMoreTransferInfo, styles.rightIcon)}
              theme={theme}
              iconName='information'
            />
          </LrvPopover>
        );

      default:
        return <div className={styles.iconCell} />;
    }
  };

  const columns: ColumnsType<PopulationData> = [
    {
      key: 1,
      width: '16%',
      dataIndex: 'type',
      title: t('stockings.populations.action'),
      className: cx(styles.cell, styles.populationTypeCell),
      render: (type: PopulationTypes) => renderPopulationAction(type),
    },
    {
      key: 2,
      width: '12%',
      className: styles.cell,
      dataIndex: 'populationDate',
      title: t('stockings.populations.date'),
      render: (name: string, population, index) => renderPopulationDate({ population, index }),
    },
    {
      key: 3,
      width: '14%',
      className: styles.cell,
      dataIndex: 'productionDays',
      title: t('stockings.populations.productionDays'),
    },
    {
      key: 4,
      width: '11%',
      className: styles.cell,
      dataIndex: 'animalsNumber',
      title: t('stockings.populations.animalsNumber'),
      render: (name: string, population) => {
        const animalsNumber = getPopulationAnimalsNumber(population);
        return animalsNumber ? applyThousandsSeparator(animalsNumber) : '-';
      }
    },
    {
      key: 5,
      width: '14%',
      className: styles.cell,
      dataIndex: 'population',
      title: t('stockings.populations.population'),
      render: (name: string, population, index) => renderPopulationNumber({ population, index }),
    },
    {
      key: 6,
      width: '14%',
      className: styles.cell,
      dataIndex: 'density',
      title: `${t('stockings.populations.density')} (anim/${volumeUnit || '?'})`,
      render: (name: string, population, index) => renderPopulationDensity({ population, index }),
    },
    {
      key: 7,
      width: '14%',
      className: styles.cell,
      dataIndex: 'survivalRate',
      title: `${t('stockings.populations.survival')} (%)`,
      render: (name: string, population, index) => renderPopulationSurvivalRate({ population, index }),
    },
    {
      key: 8,
      width: '5%',
      className: styles.iconCell,
      render: (name: string, population, index) => renderRightIcon({ index, population }),
    }
  ];

  const renderPopulationsSection = () => {
    return (
      <div ref={populationsRef} className={styles.populationsSection}>
        <LrvTable
          size='small'
          theme={theme}
          columns={columns}
          pagination={false}
          dataSource={populationData}
          className={styles.populationsTable}
          rowClassName={styles.populationRow}
          scroll={{ y: '' }}
        />
      </div>
    );
  };

  const renderErrorLabel = (text: string) => {
    return <LrvText className={styles.errorLabel} text={showPopulationAnimalError ? text : ''} />;
  };

  const renderAddPopulationButton = () => {
    return (
      <ActionButton
        theme={theme}
        id='btn_add_population'
        disabled={disabledButton}
        icon={<Icon theme={theme} name='add' />}
        onClick={addPopulationItem}
        className={styles.addPopulationBtn}
        containerClassName={cx(styles.addPopulationBtnContainer)}
      >
        <span>{t('stockings.populations.newPopulation')}</span>
      </ActionButton>
    );
  };

  const renderSubmitButton = () => {
    return (
      <ActionButton
        theme={theme}
        type='primary'
        onClick={onSubmit}
        loading={isUpdating}
        disabled={disabledButton}
        className={styles.submitButton}
        id='submit_edit_stocking_population'
      >
        <span>{t('stockings.populations.savePopulation')}</span>
      </ActionButton>
    );
  };

  const renderFooterButtons = () => {
    return (
      <div className={styles.footerButtons}>
        {!hideAddPopulationButton && renderAddPopulationButton()}
        {renderSubmitButton()}
      </div>
    );
  };

  const renderSpinner = () => {
    if (!(isLoadingStocking || isLoadingLastAnalysis)) {
      return null;
    }

    return (
      <div className={cx(styles.spinner, isLightTheme ? styles.spinnerLight : styles.spinnerDark)}>
        <DotSpinner theme={theme} />
      </div>
    );
  };
  /* End of render components section */

  return (
    <div className={styles.stockingPopulations}>
      <Row className={styles.rowFlex}>
        <Col className={styles.columnFlex} >
          {renderSubHeader()}
          {renderSpinner()}
          {renderStockingInfo()}
          <LrvDivider theme={theme} className={styles.divider} />
          {showHarvestInfo && renderHarvestInfo()}
          {showCurrentStateInfo && renderCurrentStateInfo()}
          <LrvDivider theme={theme} className={styles.divider} />
          {renderPopulationsSection()}
          {renderErrorLabel(t('stockings.populations.populationAnimalError'))}
          {renderFooterButtons()}
        </Col>
      </Row>
    </div>
  );
};

export default StockingPopulations;