import moment from 'moment';
import { Col, Form, Row, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { areDatesEqual } from '../../../utils/date';
import { isNumber } from '../../../utils/validations';
import { Store } from '../../../state/store.interfaces';
import { LrvForm } from '../../../common/components/LrvForm/LrvForm';
import { LrvTabs } from '../../../common/components/LrvTabs/LrvTabs';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import * as optimalHarvesPointSlice from '../optimalHarvestPointSlice';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import { DistributionBar } from '../../Company/Packers/TablePrices/DistributionBar';
import { LrvDatePicker } from '../../../common/components/LrvDatePicker/LrvDatePicker';
import { CommercialSizePriceTable } from '../../Company/Packers/TablePrices/interfaces';
import { LrvInputNumber } from '../../../common/components/LrvInputNumber/LrvInputNumber';
import { CommercialSizeTable } from '../../Company/Packers/TablePrices/CommercialSizeTable';
import { applyParserThousandsSeparator, applyThousandsSeparator } from '../../../utils/strings';
import { commercialSizeTypes, weightUnits, weightUnitsByCompany } from '../../../config/commons';
import { CommercialSizeRange, ProcessedCommercialSizeRange } from '../../Company/Packers/interfaces';
import { generateCommercialSizeRanges, getMaxValueDisplay, MIN_VALUE_DISPLAY_GROW_OUT } from '../../../helpers/commercial-size.helpers';
import { calcYieldPorcentageTail, calcYieldPorcentageWaste, disablePriceTableForm, getPrices } from '../../../helpers/commercial-size-price-table';

import styles from './PriceTableForm.module.scss';

interface Props {
  theme?: 'dark' | 'light';
}

const { TabPane } = Tabs;

export const PriceTableForm = (props: Props) => {
  const { theme = 'dark' } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const {
    showUpdatePriceTableModal,
    isUpdatingPriceTable,
    filters
  } = useSelector((state: Store) => state.optimalHarvestPoint);
  const { commercialSizePriceTable } = filters;

  const [date, setDate] = useState('');
  const [paymentDelayDays, setPaymentDelayDays] = useState<number | string | null | undefined>();
  const [yieldPercentageWhole, setYieldPercentageWhole] = useState<number | string | null | undefined>();
  const [yieldPercentageTail, setYieldPercentageTail] = useState<number | string | null | undefined>();
  const [wastePrice, setWastePrice] = useState<number | string | null | undefined>();
  const [commercialSizesWhole, setCommercialSizesWhole] = useState<number[]>([]);
  const [commercialSizesTail, setCommercialSizesTail] = useState<number[]>([]);
  const [commercialSizeRangesWhole, setCommercialSizeRangesWhole] = useState<CommercialSizeRange[]>([]);
  const [commercialSizeRangesTail, setCommercialSizeRangesTail] = useState<CommercialSizeRange[]>([]);
  const [processedDataSourceWhole, setProcessedDataSourceWhole] = useState<ProcessedCommercialSizeRange[]>([]);
  const [processedDataSourceTail, setProcessedDataSourceTail] = useState<ProcessedCommercialSizeRange[]>([]);
  const [currentTab, setCurrentTab] = useState<string>(commercialSizeTypes.GROW_OUT_WHOLE);

  const yieldPercentageWaste = calcYieldPorcentageWaste({ whole: Number(yieldPercentageWhole), tail: Number(yieldPercentageTail) });
  const isSameDate = commercialSizePriceTable?.date ? areDatesEqual({ date1: date, date2: commercialSizePriceTable?.date }) : true;

  useEffect(() => {
    if (!commercialSizePriceTable?._id || !showUpdatePriceTableModal) {
      return;
    }

    setDate(commercialSizePriceTable.date);
    setCommercialSizesTail(commercialSizePriceTable.commercialSizes.tail);
    setCommercialSizesWhole(commercialSizePriceTable.commercialSizes.whole);
    setPaymentDelayDays(commercialSizePriceTable?.paymentDelayDays);
    setYieldPercentageWhole(commercialSizePriceTable.yield?.whole);
    setYieldPercentageTail(commercialSizePriceTable.yield?.tail);
    setWastePrice(commercialSizePriceTable.waste?.price);

    form.setFieldsValue({
      date: moment.parseZone(commercialSizePriceTable.date),
      paymentDelayDays: commercialSizePriceTable.paymentDelayDays,
      yieldPercentageWhole: commercialSizePriceTable.yield?.whole,
      yieldPercentageTail: commercialSizePriceTable.yield?.tail,
      wastePrice: commercialSizePriceTable.waste?.price,
    });

  }, [commercialSizePriceTable, showUpdatePriceTableModal]);

  useEffect(() => {
    if (commercialSizesWhole.length === 0) {
      return;
    }

    const maxValueDisplay = getMaxValueDisplay({ commercialSizeType: commercialSizeTypes.GROW_OUT_WHOLE });
    const commercialSizeRanges = generateCommercialSizeRanges({ commercialSizes: commercialSizesWhole, minValueDisplay: MIN_VALUE_DISPLAY_GROW_OUT, maxValueDisplay, weightUnit: weightUnitsByCompany.KILOGRAM });
    setCommercialSizeRangesWhole(commercialSizeRanges);
  }, [commercialSizesWhole]);

  useEffect(() => {
    if (commercialSizesTail.length === 0) {
      return;
    }

    const maxValueDisplay = getMaxValueDisplay({ commercialSizeType: commercialSizeTypes.GROW_OUT_TAIL });
    const commercialSizeRanges = generateCommercialSizeRanges({ commercialSizes: commercialSizesTail, minValueDisplay: MIN_VALUE_DISPLAY_GROW_OUT, maxValueDisplay, weightUnit: weightUnitsByCompany.POUND });
    setCommercialSizeRangesTail(commercialSizeRanges);
  }, [commercialSizesTail]);

  const closeModal = () => {
    form.resetFields();
    setDate('');
    setCommercialSizesTail([]);
    setCommercialSizesWhole([]);
    setCommercialSizeRangesTail([]);
    setCommercialSizeRangesWhole([]);
    setProcessedDataSourceTail([]);
    setProcessedDataSourceWhole([]);
    setPaymentDelayDays('');
    setYieldPercentageWhole('');
    setYieldPercentageTail('');
    setWastePrice('');
    
    dispatch(optimalHarvesPointSlice.setShowUpdatePriceTableModal(false));
  };

  const updateCommercialSize = () => {
    if (!commercialSizePriceTable?._id || !commercialSizePriceTable) {
      return;
    }

    const pricesWhole = getPrices({ processedDataSource: processedDataSourceWhole });
    const pricesTail = getPrices({ processedDataSource: processedDataSourceTail });

    const body: CommercialSizePriceTable = {
      date,
      active: commercialSizePriceTable.active,
      paymentDelayDays: parseInt(`${paymentDelayDays}`),
      commercialSizes: {
        whole: commercialSizesWhole,
        tail: commercialSizesTail,
      },
      prices: {
        whole: pricesWhole.length === 0 ? commercialSizePriceTable.prices.whole : pricesWhole,
        tail: pricesTail.length === 0 ? commercialSizePriceTable.prices.tail : pricesTail,
      },
      yield: {
        whole: isNumber(yieldPercentageWhole) ? parseFloat(`${yieldPercentageWhole}`) : undefined,
        tail: isNumber(yieldPercentageTail) ? parseFloat(`${yieldPercentageTail}`) : undefined,
      },
      waste: {
        price: isNumber(wastePrice) ? parseFloat(`${wastePrice}`) : undefined,
      },
      packerId: commercialSizePriceTable.packerId,
      companyId: commercialSizePriceTable.companyId,
      commercialSizeId: commercialSizePriceTable.commercialSizeId,
    };

    const props = {
      body,
      commercialSizePriceTableId: commercialSizePriceTable._id,
      onSuccess: closeModal,
    };

    if (isSameDate) {
      dispatch(optimalHarvesPointSlice.updateCommercialSizePriceTable(props));
      return;
    }

    dispatch(optimalHarvesPointSlice.createCommercialSizePriceTable(props));
  };

  const renderCommercialSizeTable = (props: {commercialSizeRanges: CommercialSizeRange[]; prices?: number[]; commercialSizeType: string; processedDataSource: ProcessedCommercialSizeRange[], setProcessedDataSource: Dispatch<SetStateAction<ProcessedCommercialSizeRange[]>>; weightUnit: string; }) => {
    const { commercialSizeRanges, prices, commercialSizeType, processedDataSource, setProcessedDataSource, weightUnit } = props;
    
    return (
      <CommercialSizeTable
        commercialSizeRanges={commercialSizeRanges}
        commercialSizeType={commercialSizeType}
        processedDataSource={processedDataSource}
        setProcessedDataSource={setProcessedDataSource}
        prices={prices}
        weightUnit={weightUnit}
      />
    );
  };

  return (
    <LrvModal
      theme={theme}
      title={`${t('priceTable.editPriceTable')}`}
      open={showUpdatePriceTableModal}
      destroyOnClose={true}
      okButtonProps={{
        id: 'submit-commercial-size-price-table',
        htmlType: 'submit',
        form: 'form',
        loading: isUpdatingPriceTable,
        disabled: disablePriceTableForm({ date, paymentDelayDays, processedDataSourceTail, processedDataSourceWhole, yieldPercentageTail, yieldPercentageWhole, wastePrice, yieldPercentageWaste }),
      }}
      onOk={updateCommercialSize}
      okText={isSameDate ? t('priceTable.edit') : t('priceTable.save')}
      cancelText={t('priceTable.cancel')}
      onCancel={closeModal}
      className={styles.priceTable}
    >
      <LrvForm
        theme={theme}
        form={form}
        name='form'
        id='form-edit-commercial-size-price-table'
        layout='vertical'
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item>
              <LrvText
                text={commercialSizePriceTable?.packerName}
                theme={theme}
                className={styles.packerName}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='date'
              label={t('priceTable.date')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <LrvDatePicker
                theme={theme}
                placeholder=''
                onChange={(date) => {
                  const dateSelected = date?.toString() as string;
                  setDate(dateSelected);
                }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name='paymentDelayDays'
              label={t('priceTable.paymentDelayDays')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <LrvInputNumber
                theme={theme}
                value={paymentDelayDays}
                formatter={value => applyThousandsSeparator(value)}
                parser={value => applyParserThousandsSeparator(value)}
                onChange={setPaymentDelayDays}
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <DistributionBar
            whole={Number(yieldPercentageWhole)}
            tail={calcYieldPorcentageTail({ whole: Number(yieldPercentageWhole), tail: Number(yieldPercentageTail) })}
            waste={calcYieldPorcentageWaste({ whole: Number(yieldPercentageWhole), tail: Number(yieldPercentageTail) })}
          />
        </Form.Item>

        <LrvTabs
          theme={theme}
          activeKey={currentTab}
          defaultActiveKey={currentTab}
          onChange={(activeKey) => {
            setCurrentTab(activeKey);
          }}
        >
          <TabPane tab={t(`commercialSizes.commercialSizeTypes.${commercialSizeTypes.GROW_OUT_WHOLE}`)} key={commercialSizeTypes.GROW_OUT_WHOLE}>
            <Form.Item
              name='yieldPercentageWhole'
              label={t('priceTable.yieldPercentage')}
              required={false}
            >
              <LrvInputNumber
                theme={theme}
                value={yieldPercentageWhole}
                min={1}
                max={100}
                onChange={setYieldPercentageWhole}
              />
            </Form.Item>

            <Form.Item>
              {renderCommercialSizeTable({ commercialSizeRanges: commercialSizeRangesWhole, prices: commercialSizePriceTable?.prices.whole, commercialSizeType: commercialSizeTypes.GROW_OUT_WHOLE, processedDataSource: processedDataSourceWhole, setProcessedDataSource: setProcessedDataSourceWhole, weightUnit: weightUnitsByCompany.KILOGRAM, })}
            </Form.Item>
          </TabPane>

          <TabPane tab={t(`commercialSizes.commercialSizeTypes.${commercialSizeTypes.GROW_OUT_TAIL}`)} key={commercialSizeTypes.GROW_OUT_TAIL}>
            <Form.Item
              name='yieldPercentageTail'
              label={t('priceTable.yieldPercentage')}
              required={false}
            >
              <LrvInputNumber
                theme={theme}
                value={yieldPercentageTail}
                min={1}
                max={100}
                onChange={setYieldPercentageTail}
              />
            </Form.Item>
            
            <Form.Item>
              {renderCommercialSizeTable({ commercialSizeRanges: commercialSizeRangesTail, prices: commercialSizePriceTable?.prices.tail, commercialSizeType: commercialSizeTypes.GROW_OUT_TAIL, processedDataSource: processedDataSourceTail, setProcessedDataSource: setProcessedDataSourceTail, weightUnit: weightUnitsByCompany.POUND, })}
            </Form.Item>
          </TabPane>

          <TabPane tab={t('priceTable.waste')} key='WASTE'>
            <Form.Item
              name='wastePrice'
              label={t('priceTable.wastePrice', { unit: weightUnits.KG })}
              required={false}
            >
              <LrvInputNumber
                theme={theme}
                value={wastePrice}
                onChange={setWastePrice}
              />
            </Form.Item>
          </TabPane>
        </LrvTabs>

        {
          !isSameDate &&
          <LrvText
            text={`* ${t('priceTable.isDifferentDate')}`}
            theme={theme}
            className={styles.isDifferentDate}
          />
        }
      </LrvForm>
    </LrvModal>
  );
};