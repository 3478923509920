import localeEs from 'antd/lib/locale/es_ES';
import localePt from 'antd/lib/locale/pt_PT';
import localeEn from 'antd/lib/locale/en_US';
import calendarlocaleEs from 'antd/lib/calendar/locale/es_ES';
import calendarlocaleEn from 'antd/lib/calendar/locale/en_US';
import calendarlocalePt from 'antd/lib/calendar/locale/pt_PT';

import { LANGUAGES } from '../config/commons';

import { getLanguage } from './language';

export const getLocale = () => {
  const language = getLanguage().toUpperCase();

  switch (language) {
    case LANGUAGES.ENGLISH:
    default:
      return localeEn;

    case LANGUAGES.PORTUGUESE:
      return localePt;

    case LANGUAGES.SPANISH:
      return localeEs;
  }
};

export const getCalendarLocale = () => {
  const language = getLanguage().toUpperCase();

  switch (language) {
    case LANGUAGES.ENGLISH:
    default:
      return calendarlocaleEn;

    case LANGUAGES.PORTUGUESE:
      return calendarlocalePt;

    case LANGUAGES.SPANISH:
      return calendarlocaleEs;
  }
};
