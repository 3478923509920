import { Point } from '../../home/interfaces';
import { getCurrentElementHeight } from '../../../utils/dimensions';

interface HeightProps {
  filters: React.RefObject<HTMLDivElement>;
  legends: React.RefObject<HTMLDivElement>;
  slider: React.RefObject<HTMLDivElement>;
}

export const getHeightOfTheOtherElements = (props: HeightProps) => {
  const { filters, legends, slider } = props;

  const headerHeight = 64;
  const axisXHeight = 24;

  const extraHeight = 38;
  const value = getCurrentElementHeight(filters) + getCurrentElementHeight(legends) + getCurrentElementHeight(slider) + axisXHeight + headerHeight + extraHeight;
  return value;
};

export const getWidthOfTheOtherElements = () => {
  const sidebarRightWidth = 200;
  const extraWidth = 60;

  if (window.innerWidth <= 950) {
    return sidebarRightWidth + extraWidth;
  }

  return sidebarRightWidth + extraWidth + 80;
};

export const hasAtLeastThreeDifferentStages = (props: { points: Point[], targetType: string }): boolean => {
  const { points, targetType } = props;
  const stages = new Set<number>();

  for (const point of points) {
    if (point.type !== targetType) {
      continue;
    }

    stages.add(point.inputData.stage);
    if (stages.size >= 3) {
      return true;
    }
  }

  return false;
};
