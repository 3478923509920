import cx from 'classnames';
import { Select } from 'antd';
import { useEffect, useState } from 'react';

import { LrvSelectProps } from '../../interfaces/lrvSelect';

import styles from './LrvSelect.module.scss';

export const LrvSelectV2 = (props: LrvSelectProps) => {
  const { options, title, value, disabled, containerClassName, popupClassName, titleClassName, className, children, theme = 'dark' } = props;
  const { Option } = Select;
  const isDarkTheme = theme === 'dark';

  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const hasValue = !!value;
  
  useEffect(() => {
    if (!hasValue) {
      setIsFocused(false);
    }
  }, [hasValue]);

  const handleClick = () => {
    if (disabled) {
      return;
    }

    setIsOpen(true);
    setIsFocused(true);
  };

  const handleSelect = () => {
    setTimeout(() => {
      setIsOpen(false);
      setIsFocused(false);
    }, 0);
  };

  const handleDropdownVisibleChange = (open: boolean) => {
    setIsOpen(open);
    if (!open) {
      setIsFocused(false);
    }
  };
  
  const LrvSelectProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkSelect : styles.lightSelect, disabled ? styles.disabled : '', className),
    popupClassName: cx(styles.lightPopup, popupClassName),
    open: isOpen,
    onDropdownVisibleChange: handleDropdownVisibleChange,
    onSelect: handleSelect,
    value,
  };

  const lrvSelectOptions = options?.map((option) => {
    const { value, id, label, key } = option;

    return (
      <Option key={key} value={value}>
        <div id={id}>{label}</div>
      </Option>
    );
  });

  return (
    <div
      className={cx(
        styles.container,
        containerClassName,
        isDarkTheme ? styles.darkContainer : styles.lightContainer,
        disabled ? styles.disabled : '',
        isFocused ? styles.focus : '',
      )}
      onClick={handleClick}
    >
      {
        (!!title) && (
          <div
            className={cx(
              isDarkTheme ? styles.darkTitle : styles.lightTitle,
              titleClassName,
              (hasValue || isFocused) ? '' : styles.titleUnfocused
            )}
          >
            {title}
          </div>
        )
      }
      <Select
        {...LrvSelectProps}
      >
        {lrvSelectOptions ?? children}
      </Select>
    </div>
  );
};
