import { useSelector } from 'react-redux';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

import Header from '../Header';
import Footer from '../Footer';
import { Store } from '../../../state/store.interfaces';
import { Dataset } from '../../Analysis/Detail/interfaces';
import { formatLongDateWithZone } from '../../../utils/date';
import { getDataset } from '../../Analysis/Detail/histogram.helpers';
import { roundTwoDecimals, weightUnits } from '../../../config/commons';
import { TransferMetricEntry } from '../../Reports/TransferGraph/interfaces';

import { Histogram } from './Histogram';
import styles from './TransferData.module.scss';
import { getDaysDifference, getGlobalSmallAnimalsPercentage, weightedAverage } from './helpers';

interface Props {
  transferMetricEntry: Record<string, TransferMetricEntry[]>;
}

export const TransferData = (props: Props) => {
  const { transferMetricEntry } = props;

  const [t] = useTranslation();

  const { company } = useSelector((state: Store) => state.header);
  const { transfer, transferParameters, stocking } = useSelector((state: Store) => state.transferGraph);

  if (!transferParameters || !transferMetricEntry || (!transfer.histogramWeight || !transfer.histogramWeight?.limits?.length)) {
    return null;
  }

  const frequency = transfer.histogramWeight.frequencies;
  const total = frequency.reduce((accumulator, value) => accumulator + value, 0);
  const dataset: Dataset[] = getDataset(transfer.histogramWeight, total);

  const renderTitle = () => {
    const campusName = stocking.campusName?.toLowerCase();
    if (!campusName) {
      return company.name;
    }

    return company.name + ' - ' + t('analysis.campus') + ' ' + campusName.charAt(0).toUpperCase() + campusName.slice(1);
  };

  const renderItem = (props: {value: string | number; label: string; unit?: string}) => {
    const { value, label, unit = '' } = props;

    return (
      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {value} {unit}
        </Text>
        <Text className={styles.field}>
          {label}
        </Text>
      </div>
    );
  };
  
  const renderTransferData = () => {
    const averageWeights = 'averageWeight' in transferMetricEntry ? transferMetricEntry['averageWeight'].map((item) => item.value) : [];
    const uniformityWeights = 'uniformityWeight' in transferMetricEntry ? transferMetricEntry['uniformityWeight'].map((item) => item.value) : [];
    const variationCoefficientWeights = 'variationCoefficientWeight' in transferMetricEntry ? transferMetricEntry['variationCoefficientWeight'].map((item) => item.value) : [];
    const transferredWeight = 'transferredInCapsule' in transferMetricEntry ? transferMetricEntry['transferredInCapsule'].map((item) => item.value) : [];
    const animalsTransferred = 'animalsInCapsule' in transferMetricEntry ? transferMetricEntry['animalsInCapsule'].map((item) => item.value) : [];
    const animalsSmallerThan = 'animalsSmallerThan' in transferMetricEntry ? transferMetricEntry['animalsSmallerThan'].map((item) => item.value) : [];
    
    const averageWeight = weightedAverage({ values: averageWeights, weights: transferredWeight });
    const uniformityWeight = weightedAverage({ values: uniformityWeights, weights: transferredWeight });
    const variationCoefficientWeight = weightedAverage({ values: variationCoefficientWeights, weights: transferredWeight });

    const totalTransferredWeight = transferredWeight.reduce((sum, weight) => sum + weight, 0);
    const totalAnimalsTransferred = animalsTransferred.reduce((sum, weight) => sum + weight, 0);
    const smallAnimalsPercentage = getGlobalSmallAnimalsPercentage({ animalsTransferred, animalsSmallerThan, totalAnimalsTransferred });

    return (
      <div className={styles.transferData}>
        <div className={styles.row}>
          {renderItem({ value: stocking.moduleName, label: t('transfers.moduleName') })}
          {renderItem({ value: stocking.tankName, label: t('transfers.tankName') })}
          {renderItem({ value: stocking.name, label: t('transfers.stockingName') })}
          {renderItem({ value: transfer.name, label: t('transfers.transferName') })}
        </div>

        <div className={styles.row}>
          {renderItem({ value: formatLongDateWithZone({ date: stocking.startDateJuvenile }), label: t('transfers.stockingDate') })}
          {renderItem({ value: formatLongDateWithZone({ date: transfer.transferDate }), label: t('transfers.transferDate') })}
          {renderItem({ value: getDaysDifference({ startDate: stocking.startDateJuvenile, transferDate: transfer.transferDate }), label: t('transfers.productionDays') })}
          {renderItem({ value: totalAnimalsTransferred, label: t('transfers.animals') })}
        </div>

        <div className={styles.row}>
          {renderItem({ value: `${smallAnimalsPercentage}%`, label: `${t('transfers.parameters.animalsSmallerThan', { value: transfer.maxSmallAnimalsWeight })} ${weightUnits.G}` })}
          {renderItem({ value: roundTwoDecimals(averageWeight), label: t('transfers.parameters.averageWeight'), unit: weightUnits.G })}
          {renderItem({ value: roundTwoDecimals(uniformityWeight), label: t('transfers.parameters.uniformityWeight'), unit: '%' })}
          {renderItem({ value: roundTwoDecimals(variationCoefficientWeight), label: t('transfers.parameters.variationCoefficientWeight'), unit: '%' })}
          {renderItem({ value: roundTwoDecimals(totalTransferredWeight), label: t('transfers.parameters.transferredInCapsule'), unit: weightUnits.KG })}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Header
        title={renderTitle()}
        subtitle={t('transfers.summary')}
      />

      {renderTransferData()}

      <div className={styles.histogram}>
        <Histogram
          histogram='transfer'
          primaryLimits={transfer.histogramWeight.limits}
          primaryDataset={dataset}
          secondaryLimits={[]}
          secondaryDataset={[]}
          renderPdf={true}
        />
      </div>
      <Footer/>
    </div>
  );
};
