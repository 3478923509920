import cx from 'classnames';
import { useEffect, useState } from 'react';
import { Button, Descriptions, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Icon from '../../../common/components/Icon/Icon';
import { Store } from '../../../state/store.interfaces';
import { GrowOutSizesChart } from '../MiniCharts/GrowOutSizesChart';
import { LrvTabs } from '../../../common/components/LrvTabs/LrvTabs';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { useCurrency } from '../../../hooks/currency-codes/useCurrency';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import * as optimalHarvestPointSlice from '../optimalHarvestPointSlice';
import { getCurrentTab, getDataset, getYieldPercent } from '../MiniCharts/helpers';
import { DistributionBar } from '../../Company/Packers/TablePrices/DistributionBar';
import { LrvDescriptions } from '../../../common/components/LrvDescriptions/LrvDescriptions';
import { commercialSizeTypes, DEFAULT_CURRENCY_CODE, roundTwoDecimals } from '../../../config/commons';
import { calcYieldPorcentageTail, calcYieldPorcentageWaste } from '../../../helpers/commercial-size-price-table';

import styles from './DetailHarvest.module.scss';

const { TabPane } = Tabs;

interface Props {
  theme?: 'dark' | 'light';
}

export const DetailHarvest = (props: Props) => {
  const { theme = 'light' } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const currencyCode = localStorage.getItem('currencyCode') || DEFAULT_CURRENCY_CODE;
  
  const { findCurrencyByCode } = useCurrency();
  const currencySymbol = findCurrencyByCode(currencyCode)?.symbol || '';

  const {
    predictionSelected,
    pocPoint,
    showDetailHarvestModal
  } = useSelector((state: Store) => state.optimalHarvestPoint);

  const [currentTab, setCurrentTab] = useState<string>(commercialSizeTypes.GROW_OUT_WHOLE);

  useEffect(() => {
    const currentTab = getCurrentTab({ pocPoint, predictionSelected });
    setCurrentTab(currentTab);
  }, [pocPoint, predictionSelected]);

  const yieldPercent = getYieldPercent({ pocPoint, predictionSelected });
  const dataset = getDataset({ pocPoint, predictionSelected });

  const onCancel = () => {
    dispatch(optimalHarvestPointSlice.setShowDetailHarvestModal(false));
  };

  const renderDescription = () => {
    let percent = 0;

    if (predictionSelected?.presentValue.potentialGain && pocPoint?.presentValue.potentialGain) {
      percent = roundTwoDecimals(100 - (predictionSelected.presentValue.potentialGain * 100 / pocPoint.presentValue.potentialGain));
    }
    
    const point = predictionSelected ? predictionSelected : pocPoint;
    const day = predictionSelected?.x ?? (point?.x || '');
    const isPocPoint = !predictionSelected || (pocPoint?.x === predictionSelected.x && pocPoint?.packerId === predictionSelected.packerId);
    const stage = `${t('shadedplot.day')} ${day}`;
    const description = isPocPoint ? t('optimalHarvestPoint.barsChartDescriptions.itIsPocPoint') : t('optimalHarvestPoint.barsChartDescriptions.belowPocPoint', { percent });
    const text = `${point?.packerName || ''} - ${stage} - ${description}`;

    return (
      <div className={cx(styles.description, isPocPoint ? '' : styles.belowDescription)}>
        <LrvText
          theme={theme}
          text={text}
        />
      </div>
    );
  };

  const renderIncome = (props: {label: string; value: number}) => {
    const { label, value } = props;

    return (
      <LrvDescriptions
        column={1}
        theme={theme}
      >
        <Descriptions.Item label={label}>
          {currencySymbol} {value}
        </Descriptions.Item>
      </LrvDescriptions>
    );
  };

  const renderIncomes = () => {
    const point = predictionSelected ? predictionSelected : pocPoint;
    
    const incomes = point?.histogram.income;

    return (
      <div className={styles.incomes}>
        {!!incomes?.whole && renderIncome({ label: t('optimalHarvestPoint.incomes.whole'), value: roundTwoDecimals(point?.histogram.income.whole || 0) })}
        {!!incomes?.tail && renderIncome({ label: t('optimalHarvestPoint.incomes.tail'), value: roundTwoDecimals(point?.histogram.income.tail || 0) })}
        {!!incomes?.waste && renderIncome({ label: t('optimalHarvestPoint.incomes.waste'), value: roundTwoDecimals(point?.histogram.income.waste || 0) })}
      </div>
    );
  };

  return (
    <LrvModal
      theme='light'
      title={t('optimalHarvestPoint.detailHarvest')}
      open={showDetailHarvestModal}
      destroyOnClose={true}
      closeIcon={<Icon id='close_modal' name='close' />}
      onCancel={onCancel}
      className={styles.detailHarvestModal}
      width={720}
      footer={[
        <Button
          type='default'
          className={styles.button}
          onClick={onCancel}
        >
          {t('priceTable.cancel')}
        </Button>
      ]}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          {renderDescription()}
        </div>

        {renderIncomes()}

        <DistributionBar
          whole={Number(yieldPercent.whole)}
          tail={calcYieldPorcentageTail({ whole: Number(yieldPercent.whole), tail: Number(yieldPercent.tail) })}
          waste={calcYieldPorcentageWaste({ whole: Number(yieldPercent.whole), tail: Number(yieldPercent.tail) })}
        />

        <LrvTabs
          theme={theme}
          activeKey={currentTab}
          defaultActiveKey={currentTab}
          onChange={setCurrentTab}
        >
          {
            (dataset.whole && dataset.whole.length) &&
            <TabPane tab={t(`commercialSizes.commercialSizeTypes.${commercialSizeTypes.GROW_OUT_WHOLE}`)} key={commercialSizeTypes.GROW_OUT_WHOLE}>
              <GrowOutSizesChart
                height={400}
                width={640}
                theme={theme}
                currencySymbol={currencySymbol}
                dataset={dataset.whole}
                fillBars='#0055ff'
              />
            </TabPane>
          }

          {
            (dataset.tail && dataset.tail.length) &&
            <TabPane tab={t(`commercialSizes.commercialSizeTypes.${commercialSizeTypes.GROW_OUT_TAIL}`)} key={commercialSizeTypes.GROW_OUT_TAIL}>
              <GrowOutSizesChart
                height={400}
                width={640}
                theme={theme}
                currencySymbol={currencySymbol}
                dataset={dataset.tail}
                fillBars='#ff9000'
              />
            </TabPane>
          }
        </LrvTabs>
      </div>
    </LrvModal>
  );
};
