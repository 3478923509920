import { Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import ActionButton from '../../../common/components/buttons/ActionButton';
import Breadcrumbs from '../../../common/components/Breadcrumb/Breadcrumbs';

import * as packerSlice from './packerSlice';
import styles from './PackerSubHeader.module.scss';

export const PackerSubHeader = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { breadcrumb } = useSelector((state: Store) => state.header);
  const theme = getCurrentTheme();

  return (
    <Row className={styles.rowHeader} align='middle' justify='space-between'>
      <Breadcrumbs breadcrumb={breadcrumb} theme={theme} />

      <Row className={styles.rightRow} justify='end' align='middle'>
        <Space size={18}>
          <ActionButton
            id='add_button'
            type='primary'
            icon={<Icon name='add' />}
            onClick={() => {
              dispatch(packerSlice.setShowCreatePackerModal(true));
            }}
          >
            {t('stockingParameter.create')}
          </ActionButton>
        </Space>
      </Row>
    </Row>
  );
};
