import i18next from 'i18next';
import { cloneDeep } from 'lodash';

import { getMainRole, roles } from '../../../config/commons';

import { DefaultStockingParameterChart, LayoutCustomizable } from './interfaces';

export const parameterCardWidth = {
  DEFAULT: 4,
  MAX: 24,
};

export const defaultStockingDataChart = {
  DAYS: 'days',
  BIOMASS: 'biomass',
  AVERAGE_WEIGHT: 'averageWeight',
  SURVIVAL_RATE: 'survivalRate',
  UNIFORMITY_WEIGHT: 'uniformityWeight',
  FCR: 'fcr',
  TOTAL_FEED: 'totalFeed',
  CHART: 'chart',
  NEW: 'new',
};

export const defaultStockingParameterChart = {
  OXYGEN: 'Oxygen',
  TEMPERATURE: 'Temperature',
  TURBIDITY: 'Turbidity',
};

export const defaultLegendChart = {
  TOTAL_FEED: 'totalFeed',
  GROWTH_RATE: 'growthRate',
};

export const allFrequencies = 'ALL';

export const dateRangeOptions = {
  WHOLE_CROP: {
    value: 'Whole Crop',
    days: 0,
  },
  '30D': {
    value: '30D',
    days: 30,
  },
  '14D': {
    value: '14D',
    days: 14,
  },
  '7D': {
    value: '7D',
    days: 7,
  },
};

export const dateRanges = [dateRangeOptions.WHOLE_CROP.value, dateRangeOptions['30D'].value, dateRangeOptions['14D'].value, dateRangeOptions['7D'].value];

const higherRole = getMainRole();
export const hasEditSettingsPermission = higherRole === roles.SUPER_ADMIN || higherRole === roles.SALES || higherRole === roles.SALES_MANAGER || higherRole === roles.CLIENT_OWNER || higherRole === roles.PARAMETERIST;

export const getKeysStockingDataCustom = (props: { layoutCustomizable: LayoutCustomizable[]; fields: string[] }) => {
  const { layoutCustomizable, fields } = props;

  const selectedItemList = layoutCustomizable.map((item) => item.i);
  const keysSelected = selectedItemList.filter(element => fields.includes(element));
  const otherKeys = fields.filter(element => !selectedItemList.includes(element));

  return {
    keysSelected,
    otherKeys,
  };
};

export const updateLayout = (props: { layout: LayoutCustomizable[]; layoutCustomizable: LayoutCustomizable[] }) => {
  const { layout, layoutCustomizable } = props;

  const layoutCopy = cloneDeep(layout);

  for (let index = 0; index < layoutCustomizable.length; index++) {
    const item = layoutCustomizable[index];
    layoutCopy[index] = item;
  }

  return layoutCopy;
};

export const updateParameterLayout = (props: { layout: LayoutCustomizable[]; layoutCustomizable: LayoutCustomizable[] }) => {
  const { layout, layoutCustomizable } = props;

  const layoutCopy = cloneDeep(layout);

  for (let index = 0; index < layoutCopy.length; index++) {
    if (layoutCopy[index].i !== defaultStockingDataChart.CHART) {
      continue;
    }

    layoutCopy[index].parameters = layoutCustomizable.find((item) => item.i === layoutCopy[index].i)?.parameters;
  }

  return layoutCopy;
};

export const getWidthOfTheOtherElements = () => {
  const extraWidth = 40;

  if (window.innerWidth <= 950) {
    return extraWidth;
  }

  return extraWidth + 80;
};

export const calculateDateRange = (props: { defaultMinDate: string; defaultMaxDate: string; option: string; }) => {
  const { defaultMinDate, defaultMaxDate, option } = props;

  let minDate: Date;
  const maxDate = new Date(defaultMaxDate);

  switch (option) {
    case dateRangeOptions['30D'].value:
      minDate = new Date(maxDate);
      minDate.setDate(maxDate.getDate() - dateRangeOptions['30D'].days);
      break;

    case dateRangeOptions['14D'].value:
      minDate = new Date(maxDate);
      minDate.setDate(maxDate.getDate() - dateRangeOptions['14D'].days);
      break;

    case dateRangeOptions['7D'].value:
      minDate = new Date(maxDate);
      minDate.setDate(maxDate.getDate() - dateRangeOptions['7D'].days);
      break;

    case dateRangeOptions.WHOLE_CROP.value:
    default:
      minDate = new Date(defaultMinDate);
      minDate.setDate(minDate.getDate());
      break;
  }

  maxDate.setDate(maxDate.getDate() + 1);
  minDate.setHours(0, 0, 0, 0);

  return {
    minDate,
    maxDate,
  };
};

const getExtraLabel = (props: { key: string; }) => {
  const { key } = props;

  switch (key) {
    case defaultStockingDataChart.AVERAGE_WEIGHT:
      return i18next.t('production.parameter.extraData.averageWeight');

    case defaultStockingDataChart.UNIFORMITY_WEIGHT:
      return i18next.t('production.parameter.extraData.uniformity');

    default:
      return '';
  }
};

export const getExtraValue = (props: {
  key: string;
  extraData: {
    [key: string]: {
      value: number;
      unit?: string;
      dataKey: string;
    };
  }
}) => {
  const { key, extraData } = props;

  let extraDataInfo = { unit: '', value: 0, label: '' };

  Object.keys(extraData).forEach((extraDataKey) => {
    if (extraData[extraDataKey].dataKey === key) {
      extraDataInfo = {
        unit: extraData[extraDataKey].unit || '',
        value: extraData[extraDataKey].value,
        label: getExtraLabel({ key }),
      };
    }
  });

  return extraDataInfo;
};

export const hasNewItem = (props: { layoutCustomizable: LayoutCustomizable[]; }) => {
  const { layoutCustomizable } = props;

  return layoutCustomizable.filter((item) => item.i === defaultStockingDataChart.NEW).length > 0;
};

export const existsItemsAvailables = (props: { defaultData: DefaultStockingParameterChart; layoutCustomizable: LayoutCustomizable[]; }) => {
  const { defaultData, layoutCustomizable } = props;

  const fields = [];
  for (const key in defaultData) {
    fields.push(key);
  }

  const { otherKeys } = getKeysStockingDataCustom({ layoutCustomizable, fields });
  return otherKeys.length > 0;
};
