import i18next from 'i18next';
import { cloneDeep } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sortByName } from '../../utils/sort';
import { getUserSession } from '../../utils/userSession';
import { CampusPocParameter } from '../Units/interfaces';
import { Tank, TankPocParameter } from '../Tanks/interfaces';
import { Module, Unit } from '../Sowings/Analysis/interfaces';
import { PopulationData, Stocking } from '../Sowings/interfaces';
import { axiosClient as axios } from '../../utils/axios_instance';
import { convertKilogramsToPounds } from '../../helpers/stocking.helpers';
import { getUnitPhaseTypeFromAnalysis } from '../../helpers/units.helpers';
import { openSuccessNotification } from '../../common/notification/Notification';
import { CommercialSizePriceTable } from '../Company/Packers/TablePrices/interfaces';
import { getLastPopulation, sortAndBuildPopulationData } from '../../helpers/population.helpers';
import { DEFAULT_STAGE_MAX, stockingPhaseTypes, stockingStatuses, transferTypes, unitStatuses } from '../../config/commons';
import { CAMPUS_POC_PARAMETER_URL, CAMPUS_URL, COMMERCIAL_SIZE_PRICE_TABLE_URL, HARVEST_OPTIMAL_PLAN_URL, INTEREST_RATE_URL, MODULES_URL, OPTIMAL_HARVEST_POINT_URL, PACKERS_URL, STOCKINGS_URL, TANK_POC_PARAMETER_URL, TANKS_URL } from '../../config/config.api';

import { FeedingStrategy } from './Indicators/interfaces';
import { generateUniqueRandomNumbers, packerColors } from './helpers';
import { ChartParameter, CommercialSizeData, DataSource, DataSourceByStage, HarvestOptimalPlan, OptimalHarvestPointFilters, OptimalHarvestPointState, PackersWithCommercialSizes, PocByPacker, Point, PredictionPoint } from './interfaces';

const initialState: OptimalHarvestPointState = {
  dataSource: {
    allAnalysis: [],
    excludedAnalyses: [],
    predictions: [],
  },
  interestRate: 0,
  pocPoint: undefined,
  allPredictions: [],
  dataSourceByStage: [],
  allPoints: [],
  filters: {
    unitId: '',
    moduleId: '',
    tankId: '',
    chartParameter: ChartParameter.POC,
    miniChartParameter: undefined,
    selectedStocking: {
      _id: '',
      name: '',
    } as Stocking,
    commercialSizePriceTable: undefined,
    loadCapacity: 0,
    survival: 0,
    mortality: 0,
    weeklyFeeding: 0,
    foodPricePerKg: 0,
    costPerVolumeDay: 0,
    accumulatedCost: 0,
    feedingStrategy: FeedingStrategy.NORMAL,
    linearBiomassCoefficientKg: 22,
    linearBiomassCoefficientLb: 44,
    costPerHp: 0,
  },
  units: [],
  modules: [],
  tanks: [],
  firstStage: 1,
  lastStage: DEFAULT_STAGE_MAX,
  maxStage: 0,
  isFetchingData: false,
  isFetchingStocking: false,
  isExcluding: false,
  analysesToExclude: [],
  predictionSelected: undefined,
  isUpdatingPoints: false,
  lastPopulation: { } as PopulationData,
  harvestsAndTransfers: 0,
  showUpdatePriceTableModal: false,
  isUpdatingPriceTable: false,
  isFetchingPackersInfo: false,
  packersWithCommercialSizes: [],
  bestPackers: [],
  allPackers: [],
  colors: [],
  showDetailHarvestModal: false,
  showMiniChartModal: false,
  showHeatMapModal: false,
  harvestOptimalPlan: {
    harvestOptimalPlans: [],
    isCreating: false,
  },
};

const optimalHarvestPointSlice = createSlice({
  initialState,
  name: 'optimalHarvestPoint',
  reducers: {
    setDataSource: (state: OptimalHarvestPointState, action: PayloadAction<DataSource>) => {
      state.dataSource = action.payload;
    },
    setFirstStage: (state: OptimalHarvestPointState, action: PayloadAction<number>) => {
      state.firstStage = action.payload;
    },
    setLastStage: (state: OptimalHarvestPointState, action: PayloadAction<number>) => {
      state.lastStage = action.payload;
    },
    setMaxStage: (state: OptimalHarvestPointState, action: PayloadAction<number>) => {
      state.maxStage = action.payload;
    },
    setIsFetchingData: (state: OptimalHarvestPointState, action: PayloadAction<boolean>) => {
      state.isFetchingData = action.payload;
    },
    setIsFetchingStocking: (state: OptimalHarvestPointState, action: PayloadAction<boolean>) => {
      state.isFetchingStocking = action.payload;
    },
    setOptimarHarvestPointFilters: (state: OptimalHarvestPointState, action: PayloadAction<OptimalHarvestPointFilters>) => {
      state.filters = action.payload;
    },
    resetOptimarHarvestPointFilters: (state: OptimalHarvestPointState) => {
      state.filters = initialState.filters;
    },
    setSurvival: (state: OptimalHarvestPointState, action: PayloadAction<number>) => {
      state.filters.survival = action.payload;
    },
    setFeedingStrategy: (state: OptimalHarvestPointState, action: PayloadAction<FeedingStrategy>) => {
      state.filters.feedingStrategy = action.payload;
    },
    setAeration: (state: OptimalHarvestPointState, action: PayloadAction<{linearBiomassCoefficientKg: number; linearBiomassCoefficientLb: number; costPerHp: number;}>) => {
      state.filters.linearBiomassCoefficientKg = action.payload.linearBiomassCoefficientKg;
      state.filters.linearBiomassCoefficientLb = action.payload.linearBiomassCoefficientLb;
      state.filters.costPerHp = action.payload.costPerHp;
    },
    
    setSelectedUnit: (state: OptimalHarvestPointState, action: PayloadAction<string>) => {
      state.filters.unitId = action.payload;
    },
    setSelectedModule: (state: OptimalHarvestPointState, action: PayloadAction<string>) => {
      state.filters.moduleId = action.payload;
    },
    setSelectedTank: (state: OptimalHarvestPointState, action: PayloadAction<string>) => {
      state.filters.tankId = action.payload;
    },
    setSelectedStocking: (state: OptimalHarvestPointState, action: PayloadAction<Stocking>) => {
      state.filters.selectedStocking = action.payload;
    },
    setUnits: (state: OptimalHarvestPointState, action: PayloadAction<Unit[]>) => {
      state.units = action.payload;
    },
    setModules: (state: OptimalHarvestPointState, action: PayloadAction<Module[]>) => {
      state.modules = action.payload;
    },
    setTanks: (state: OptimalHarvestPointState, action: PayloadAction<Tank[]>) => {
      state.tanks = action.payload;
    },
    setCommercialSizePriceTable: (state: OptimalHarvestPointState, action: PayloadAction<CommercialSizePriceTable | undefined>) => {
      state.filters.commercialSizePriceTable = action.payload;
    },
    setIsExcluding: (state: OptimalHarvestPointState, action: PayloadAction<boolean>) => {
      state.isExcluding = action.payload;
    },
    setAnalysesToExclude: (state: OptimalHarvestPointState, action: PayloadAction<string[]>) => {
      state.analysesToExclude = action.payload;
    },
    toggleAnalysisExclusion: (state: OptimalHarvestPointState, action: PayloadAction<string>) => {
      const newAnalysisId = action.payload;
      const analysesToExclude = state.analysesToExclude.slice();
      const index = analysesToExclude.findIndex(analysisId => analysisId === newAnalysisId);
      
      if (index !== -1) { // remove new analysis id
        analysesToExclude.splice(index, 1);
        state.analysesToExclude = analysesToExclude;
        return;
      }
      // add new analysis id
      analysesToExclude.push(newAnalysisId);
      state.analysesToExclude = analysesToExclude;
    },
    setIsUpdatingPoints: (state: OptimalHarvestPointState, action: PayloadAction<boolean>) => {
      state.isUpdatingPoints = action.payload;
    },
    setPredictionSelected: (state: OptimalHarvestPointState, action: PayloadAction<CommercialSizeData | undefined>) => {
      state.predictionSelected = action.payload;
    },
    setLastPopulation: (state: OptimalHarvestPointState, action: PayloadAction<PopulationData | undefined>) => {
      state.lastPopulation = action.payload;
    },
    setChartParameter: (state: OptimalHarvestPointState, action: PayloadAction<ChartParameter>) => {
      state.filters.chartParameter = action.payload;
    },
    setMiniChartParameter: (state: OptimalHarvestPointState, action: PayloadAction<ChartParameter | undefined>) => {
      state.filters.miniChartParameter = action.payload;
    },
    setHarvestsAndTransfers: (state: OptimalHarvestPointState, action: PayloadAction<number>) => {
      state.harvestsAndTransfers = action.payload;
    },
    setAllPredictions: (state: OptimalHarvestPointState, action: PayloadAction<PredictionPoint[]>) => {
      state.allPredictions = action.payload;
    },
    setDataSourceByStage: (state: OptimalHarvestPointState, action: PayloadAction<DataSourceByStage[]>) => {
      state.dataSourceByStage = action.payload;
    },
    setAllPoints: (state: OptimalHarvestPointState, action: PayloadAction<(PredictionPoint | Point)[]>) => {
      state.allPoints = action.payload;
    },
    setPocPoint: (state: OptimalHarvestPointState, action: PayloadAction<CommercialSizeData | undefined>) => {
      state.pocPoint = action.payload;
    },
    setShowUpdatePriceTableModal: (state: OptimalHarvestPointState, action: PayloadAction<boolean>) => {
      state.showUpdatePriceTableModal = action.payload;
    },
    setIsUpdatingPriceTable: (state: OptimalHarvestPointState, action: PayloadAction<boolean>) => {
      state.isUpdatingPriceTable = action.payload;
    },
    setPackersWithCommercialSizes: (state: OptimalHarvestPointState, action: PayloadAction<PackersWithCommercialSizes[]>) => {
      state.packersWithCommercialSizes = action.payload;
    },
    
    setBestPackers: (state: OptimalHarvestPointState, action: PayloadAction<PocByPacker[]>) => {
      state.bestPackers = action.payload;
    },
    setAllPackers: (state: OptimalHarvestPointState, action: PayloadAction<PocByPacker[]>) => {
      state.allPackers = action.payload;
    },
    setColors: (state: OptimalHarvestPointState, action: PayloadAction<string[]>) => {
      state.colors = action.payload;
    },
    setIsFetchingPackersInfo: (state: OptimalHarvestPointState, action: PayloadAction<boolean>) => {
      state.isFetchingPackersInfo = action.payload;
    },
    setShowDetailHarvestModal: (state: OptimalHarvestPointState, action: PayloadAction<boolean>) => {
      state.showDetailHarvestModal = action.payload;
    },
    setShowMiniChartModal: (state: OptimalHarvestPointState, action: PayloadAction<boolean>) => {
      state.showMiniChartModal = action.payload;
    },
    setShowHeatMapModal: (state: OptimalHarvestPointState, action: PayloadAction<boolean>) => {
      state.showHeatMapModal = action.payload;
    },
    setInterestRate: (state: OptimalHarvestPointState, action: PayloadAction<number>) => {
      state.interestRate = action.payload;
    },

    setCampusPocParameter: (state: OptimalHarvestPointState, action: PayloadAction<CampusPocParameter>) => {
      state.campusPocParameter = action.payload;

      state.filters.foodPricePerKg = action.payload.foodPricePerKg;
      state.filters.costPerHp = action.payload.costPerHp;
      state.filters.linearBiomassCoefficientKg = action.payload.linearBiomassCoefficient;
      state.filters.linearBiomassCoefficientLb = convertKilogramsToPounds(action.payload.linearBiomassCoefficient);
    },
    resetCampusPocParameter: (state: OptimalHarvestPointState) => {
      state.campusPocParameter = initialState.campusPocParameter;

      state.filters.foodPricePerKg = initialState.filters.foodPricePerKg;
      state.filters.costPerHp = initialState.filters.costPerHp;
      state.filters.linearBiomassCoefficientKg = initialState.filters.linearBiomassCoefficientKg;
      state.filters.linearBiomassCoefficientLb = initialState.filters.linearBiomassCoefficientLb;
    },

    setTankPocParameter: (state: OptimalHarvestPointState, action: PayloadAction<TankPocParameter>) => {
      state.tankPocParameter = action.payload;

      state.filters.loadCapacity = action.payload.loadCapacity;
      state.filters.costPerVolumeDay = action.payload.costPerVolumeDay;
    },
    resetTankPocParameter: (state: OptimalHarvestPointState) => {
      state.tankPocParameter = initialState.tankPocParameter;

      state.filters.loadCapacity = initialState.filters.loadCapacity;
      state.filters.costPerVolumeDay = initialState.filters.costPerVolumeDay;
    },

    setHarvestOptimalPlan: (state: OptimalHarvestPointState, action: PayloadAction<HarvestOptimalPlan>) => {
      state.filters.foodPricePerKg = action.payload.foodPricePerKg;
      state.filters.costPerHp = action.payload.costPerHp;
      state.filters.linearBiomassCoefficientKg = action.payload.linearBiomassCoefficient;
      state.filters.linearBiomassCoefficientLb = convertKilogramsToPounds(action.payload.linearBiomassCoefficient);

      state.filters.loadCapacity = action.payload.loadCapacity;
      state.filters.costPerVolumeDay = action.payload.costPerVolumeDay;
      state.filters.feedingStrategy = action.payload.feedingStrategy;

      state.filters.accumulatedCost = action.payload.accumulatedCost;
      state.filters.mortality = action.payload.mortality;
      state.filters.survival = action.payload.survival;
      state.filters.weeklyFeeding = action.payload.weeklyFeeding;
    },
    resetHarvestOptimalPlan: (state: OptimalHarvestPointState) => {
      state.filters.loadCapacity = initialState.filters.loadCapacity;
      state.filters.costPerVolumeDay = initialState.filters.costPerVolumeDay;
      state.filters.feedingStrategy = initialState.filters.feedingStrategy;

      state.filters.accumulatedCost = initialState.filters.accumulatedCost;
      state.filters.mortality = initialState.filters.mortality;
      state.filters.survival = initialState.filters.survival;
      state.filters.weeklyFeeding = initialState.filters.weeklyFeeding;
    },

    setHarvestOptimalPlans: (state: OptimalHarvestPointState, action: PayloadAction<HarvestOptimalPlan[]>) => {
      state.harvestOptimalPlan.harvestOptimalPlans = action.payload;
    },
    setIsCreatingHarvestOptimalPlan: (state: OptimalHarvestPointState, action: PayloadAction<boolean>) => {
      state.harvestOptimalPlan.isCreating = action.payload;
    },
  }
});

export const {
  setUnits, setTanks, setModules, setIsFetchingStocking, setHarvestsAndTransfers, setPocPoint, setIsFetchingPackersInfo,
  setIsExcluding, setAnalysesToExclude, toggleAnalysisExclusion, setPredictionSelected, setLastPopulation,
  setAllPredictions, setDataSourceByStage, setAllPoints,
  setOptimarHarvestPointFilters, resetOptimarHarvestPointFilters,
  setSurvival, setFeedingStrategy, setAeration,
  setSelectedUnit, setSelectedModule, setSelectedStocking, setSelectedTank,
  setChartParameter, setMiniChartParameter,
  setDataSource,
  setFirstStage, setLastStage, setMaxStage, setIsFetchingData, setIsUpdatingPoints, setCommercialSizePriceTable,
  setShowUpdatePriceTableModal, setIsUpdatingPriceTable,
  setPackersWithCommercialSizes,
  setBestPackers, setAllPackers, setColors,
  setShowDetailHarvestModal, setShowMiniChartModal, setShowHeatMapModal,
  setInterestRate,
  setCampusPocParameter, resetCampusPocParameter,
  setTankPocParameter, resetTankPocParameter,
  setHarvestOptimalPlan,
  resetHarvestOptimalPlan,
  setHarvestOptimalPlans,
  setIsCreatingHarvestOptimalPlan,
} = optimalHarvestPointSlice.actions;

const optimalHarvestPointReducer = optimalHarvestPointSlice.reducer;
export default optimalHarvestPointReducer;

const updatePopulation = (props: {dataSource: DataSource;stocking: Stocking }) => (dispatch: Function) => {
  const { dataSource, stocking } = props;

  const lastPrediction = dataSource.predictions[dataSource.predictions.length - 1];
  const stockingCopy = cloneDeep(stocking);
  
  const transfers = stockingCopy.transfers.filter((transfer) => new Date(transfer.transferDate) < new Date(lastPrediction.createdAt));
  const harvests = stockingCopy.harvests.filter((transfer) => new Date(transfer.harvestDate) < new Date(lastPrediction.createdAt));
  const populations = stockingCopy.populations.filter((transfer) => new Date(transfer.populationDate) < new Date(lastPrediction.createdAt));

  stockingCopy.transfers = transfers;
  stockingCopy.harvests = harvests;
  stockingCopy.populations = populations;

  const populationData = sortAndBuildPopulationData(stockingCopy);
  const harvestsAndTransfers = populationData.reduce((sum, { animalsTransferred, harvestQuantity }) => (animalsTransferred || 0) + (harvestQuantity || 0) + sum, 0);
  dispatch(setHarvestsAndTransfers(harvestsAndTransfers));
  const lastPopulation = getLastPopulation(populationData);
  
  dispatch(setLastPopulation(lastPopulation));
  dispatch(setSurvival(Number(lastPopulation?.survivalRate || 100)));
};

export const fetchDataSource = (stocking: Stocking) => async (dispatch: Function) => {
  dispatch(setIsFetchingData(true));
  
  const params = {
    stockingId: stocking._id,
    companyId: stocking.companyId,
    tankId: stocking.tankId,
  };

  try {
    const response = await axios.get<DataSource | undefined>(OPTIMAL_HARVEST_POINT_URL, { params });
    const dataSource = response?.data;
    const excludedAnalysisIds = dataSource?.excludedAnalyses.map((item) => item._id);

    dispatch(setDataSource(dataSource || initialState.dataSource));
    dispatch(setAnalysesToExclude(excludedAnalysisIds || []));
    
    if (dataSource?.predictions?.length) {
      dispatch(updatePopulation({ dataSource, stocking }));
    }
  } catch (error) {
    console.log(error?.response);
    dispatch(setDataSource(initialState.dataSource));
    dispatch(setAnalysesToExclude([]));
  }
  dispatch(setIsFetchingData(false));
};

export const fetchUnits = (params: { companyId: string, unitPhaseType: string }) => async (dispatch: Function) => {
  const { companyId, unitPhaseType } = params;
  const userSession = getUserSession();

  const unitParams = {
    $limit: -1,
    companyId: companyId ? companyId : userSession.companyId,
    phaseType: unitPhaseType,
    active: true,
    status: unitStatuses.ACTIVE,
    '$sort[name]': 1,
    $select: ['name'],
  };

  try {
    const response = await axios.get(CAMPUS_URL, { params: unitParams });
    dispatch(setUnits(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchModules = (campusId: string, phaseType: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    campusId,
    active: true,
    phaseType,
    '$sort[name]': 1,
    $select: ['name'],
  };

  try {
    const response = await axios.get<Module[]>(MODULES_URL, { params });
    dispatch(setModules(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchTanks = (campusId: string, moduleId: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    active: true,
    campusId,
    moduleId,
    '$sort[name]': 1,
    $select: ['name'],
  };

  try {
    const response = await axios.get<Tank[]>(TANKS_URL, { params: params });
    response.data.sort(sortByName);
    dispatch(setTanks(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

const getHarvestOptimalPlan = (props: { harvestOptimalPlans: HarvestOptimalPlan[]; stockingId: string; }) => (dispatch: Function) => {
  const { harvestOptimalPlans, stockingId } = props;
  const harvestOptimalPlan = harvestOptimalPlans.find((harvestOptimalPlan) => harvestOptimalPlan.stockingId === stockingId);

  if (!harvestOptimalPlan?._id) {
    return;
  }

  dispatch(setHarvestOptimalPlan(harvestOptimalPlan));
};

export const fetchActiveStocking = (props: { campusId: string; moduleId: string; containerId: string; companyId: string; harvestOptimalPlans: HarvestOptimalPlan[]; }) => async (dispatch: Function) => {
  const { campusId, moduleId, containerId, harvestOptimalPlans } = props;
  const userSession = getUserSession();
  dispatch(setIsFetchingStocking(true));
  dispatch(setDataSource(initialState.dataSource));
  dispatch(setSelectedStocking(initialState.filters.selectedStocking));

  const companyId = props.companyId || userSession.companyId;
  const params = {
    $limit: -1,
    active: true,
    isArchived: false,
    'status[$in]': [stockingStatuses.ACTIVE, transferTypes.PARTIAL_TRANSFER],
    campusId,
    moduleId,
    tankId: containerId,
    companyId,
    $select: ['name', 'growOutNumber', 'startDateGrowOut', 'hectares', 'phaseType', 'cubicMeters', 'transfers', 'harvests', 'populations', 'companyId', 'tankId'],
  };

  dispatch(resetHarvestOptimalPlan());

  try {
    const response = await axios.get<Stocking[]>(STOCKINGS_URL, { params });
    const firstStocking = response.data[0];
    dispatch(setSelectedStocking(firstStocking));

    if (firstStocking?._id) {
      dispatch(fetchDataSource(firstStocking));
      dispatch(setIsFetchingStocking(false));
      dispatch(getHarvestOptimalPlan({ harvestOptimalPlans, stockingId: firstStocking._id }));
      return;
    }

    dispatch(setDataSource({ allAnalysis: [], excludedAnalyses: [], predictions: [] }));
  } catch (error) {
    dispatch(setSelectedStocking(initialState.filters.selectedStocking));
    dispatch(setHarvestsAndTransfers(0));
    dispatch(setLastPopulation(undefined));
    console.log(error?.response);
  }
  dispatch(setIsFetchingStocking(false));
};

export const resetChartFilters = (params: { phaseType: string; companyId: string }) => (dispatch: Function) => {
  const { phaseType, companyId } = params;

  dispatch(setUnits([]));
  dispatch(setTanks([]));
  dispatch(setModules([]));
  dispatch(resetOptimarHarvestPointFilters());

  dispatch(setIsExcluding(false));
  dispatch(setPredictionSelected(initialState.predictionSelected));
  dispatch(setDataSource(initialState.dataSource));
  dispatch(setCommercialSizePriceTable(undefined));
  dispatch(setPackersWithCommercialSizes([]));

  if (phaseType === stockingPhaseTypes.ADULT) {
    const unitPhaseType = getUnitPhaseTypeFromAnalysis(phaseType);
    dispatch(fetchUnits({ companyId, unitPhaseType }));
    dispatch(fetchPackersWithCommercialSizes({ companyId }));
    dispatch(fetchInterestRate({ companyId }));
  }
};

export const applyExcludeAnalyses = (params: { stocking: Stocking; analysesToExclude: string[]; analysesToInclude: string[] }) => async (dispatch: Function) => {
  const { stocking, analysesToExclude, analysesToInclude } = params;
  const body = { analysesToExclude, analysesToInclude };
  dispatch(setIsUpdatingPoints(true));

  try {
    const url = `${STOCKINGS_URL}/${stocking._id}/exclude-analyses-from-prediction`;
    await axios.patch(url, body);
    dispatch(fetchDataSource(stocking));
    openSuccessNotification(i18next.t('optimalHarvestPoint.successfullyExcluded'));
  } catch (error) {
    console.log(error?.response);
  }
  
  dispatch(setIsUpdatingPoints(false));
};

export const createCommercialSizePriceTable = (props: { body: CommercialSizePriceTable; onSuccess: () => void; }) => async (dispatch: Function) => {
  const { body, onSuccess } = props;

  try {
    await axios.post(COMMERCIAL_SIZE_PRICE_TABLE_URL, body);
    dispatch(fetchPackersWithCommercialSizes({ companyId: body.companyId }));

    onSuccess();
    openSuccessNotification(i18next.t('priceTable.created'));
  } catch (error) {
    console.log(error?.response);
  }
};

export const updateCommercialSizePriceTable = (props: { commercialSizePriceTableId: string; body: CommercialSizePriceTable; onSuccess: () => void; }) => async (dispatch: Function) => {
  const { commercialSizePriceTableId, body, onSuccess } = props;
  
  dispatch(setIsUpdatingPriceTable(true));
  const url = `${COMMERCIAL_SIZE_PRICE_TABLE_URL}/${commercialSizePriceTableId}`;

  try {
    await axios.patch(url, body);
    dispatch(fetchPackersWithCommercialSizes({ companyId: body.companyId }));

    onSuccess();
    openSuccessNotification(i18next.t('priceTable.updated'));
  } catch (error) {
    console.log(error?.response);
  }

  dispatch(setIsUpdatingPriceTable(false));
};

export const fetchPackersWithCommercialSizes = (params: { companyId: string }) => async (dispatch: Function) => {
  const url = `${PACKERS_URL}/list/packers-with-commercial-sizes`;
  dispatch(setIsFetchingPackersInfo(true));

  try {
    const response = await axios.get(url, { params });
    dispatch(setPackersWithCommercialSizes(response.data));
    dispatch(selectColors());
  } catch (error) {
    dispatch(setPackersWithCommercialSizes([]));
    console.log(error?.response);
  }

  dispatch(setIsFetchingPackersInfo(false));
};

export const fetchInterestRate = (params: { companyId: string }) => async (dispatch: Function) => {
  const { companyId } = params;
  const url = `${INTEREST_RATE_URL}/${companyId}`;
  
  try {
    const response = await axios.get<{interestRate: number}>(url);
    dispatch(setInterestRate(response.data.interestRate));
  } catch (error) {
    console.log(error?.response);
  }
};

const selectColors = () => (dispatch: Function) => {
  const colors = [];

  const storedNumbers = localStorage.getItem('randomNumbers');
  const prevNumbers = storedNumbers ? JSON.parse(storedNumbers) : [];
  const numbers = generateUniqueRandomNumbers(prevNumbers);

  for (let index = 0; index < numbers.length; index++) {
    const color = packerColors[numbers[index]];
    colors.push(color);
  }

  dispatch(setColors(colors));
};

export const fetchOptimalHarvestPointPing = async () => {
  const params = {
    'ping': 1,
  };
  
  try {
    await axios.get(OPTIMAL_HARVEST_POINT_URL, { params });
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchCampusPocParameter = (props: {companyId: string; campusId: string;}) => async (dispatch: Function) => {
  const { companyId, campusId } = props;

  const params = {
    $limit: -1,
    campusId,
    companyId,
  };

  dispatch(resetCampusPocParameter());

  try {
    const response = await axios.get<CampusPocParameter[]>(CAMPUS_POC_PARAMETER_URL, { params });
    if (response.data.length) {
      dispatch(setCampusPocParameter(response.data[0]));
    }
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchTankPocParameter = (props: {companyId: string; tankId: string;}) => async (dispatch: Function) => {
  const { companyId, tankId } = props;

  const params = {
    $limit: -1,
    tankId,
    companyId,
  };

  dispatch(resetTankPocParameter());
  
  try {
    const response = await axios.get<TankPocParameter[]>(TANK_POC_PARAMETER_URL, { params });
    if (response.data.length) {
      const tankPocParameter = response.data[0];
      
      dispatch(setTankPocParameter(tankPocParameter));
      dispatch(setFeedingStrategy(tankPocParameter.feedingStrategy));
    }
  } catch (error) {
    console.log(error?.response);
  }
};

export const createHarvestOptimalPlan = (props: {body: HarvestOptimalPlan;}) => async (dispatch: Function) => {
  const { body } = props;
  dispatch(setIsCreatingHarvestOptimalPlan(true));

  try {
    await axios.post<HarvestOptimalPlan>(HARVEST_OPTIMAL_PLAN_URL, body);
    dispatch(setIsCreatingHarvestOptimalPlan(false));
    dispatch(fetchHarvestOptimalPlans({ campusId: body.campusId, companyId: body.companyId }));
    openSuccessNotification(i18next.t('optimalHarvestPoint.planUpdateSuccess'));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchHarvestOptimalPlans = (props: {companyId: string; campusId: string; }) => async (dispatch: Function) => {
  const params = {
    ...props,
    $limit: -1,
    active: true,
    $populate: ['tankId'],
  };

  try {
    const response = await axios.get<HarvestOptimalPlan[]>(HARVEST_OPTIMAL_PLAN_URL, { params });
    dispatch(setHarvestOptimalPlans(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};
