import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TankPocParameter } from '../../Tanks/interfaces';
import { TANK_POC_PARAMETER_URL } from '../../../config/config.api';
import { axiosClient as axios } from '../../../utils/axios_instance';
import * as optimalHarvestPointSlice from '../optimalHarvestPointSlice';
import { openSuccessNotification } from '../../../common/notification/Notification';

import { POWER_BASE, POWER_EXPONENT } from './helpers.food';
import { DailyRation, FeedingDataset, FeedingStrategy, FeedingTableState, UpdateDatasetPayload } from './interfaces';

export const feedingDatasetDefault: FeedingDataset[] = [
  { averageWeight: 0.1, dailyRation: 12.6 },
  { averageWeight: 1, dailyRation: 6.8 },
  { averageWeight: 3, dailyRation: 3.6 },
  { averageWeight: 6, dailyRation: 2.6 },
  { averageWeight: 10, dailyRation: 2.3 },
  { averageWeight: 15, dailyRation: 2 },
  { averageWeight: 20, dailyRation: 1.8 },
  { averageWeight: 50, dailyRation: 1.8 },
];

const initialState: FeedingTableState = {
  feedingDataset: {
    points: feedingDatasetDefault,
    line: [],
  },
  tankPocParameter: {
    companyId: '',
    campusId: '',
    tankId: '',
    dataset: [],
    feedingStrategy: FeedingStrategy.NORMAL,
    loadCapacity: 0,
    costPerVolumeDay: 0,
  },
  showFoodChartModal: false,
  dailyRation: {
    scaleFactor: POWER_BASE,
    slope: POWER_EXPONENT,
    r2: 0,
  },
  isLoading: false,
};

const feedingTableSlice = createSlice({
  initialState,
  name: 'feedingTable',
  reducers: {
    addPointFeedingDataset: (state, action: PayloadAction<{averageWeight: number; dailyRation: number}>) => {
      const { averageWeight, dailyRation } = action.payload;
      state.feedingDataset.points.push({ averageWeight, dailyRation });
    },
    updatePointFeedingDataset: (state, action: PayloadAction<UpdateDatasetPayload>) => {
      const { oldX, oldY, newX, newY } = action.payload;
      const point = state.feedingDataset.points.find((point) => point.averageWeight === oldX && point.dailyRation === oldY);

      if (point) {
        point.averageWeight = newX;
        point.dailyRation = newY;
      }
    },
    removePointFeedingDataset: (state, action: PayloadAction<number>) => {
      state.feedingDataset.points = state.feedingDataset.points.filter((_, i) => i !== action.payload);
    },
    setShowFoodChartModal: (state: FeedingTableState, action: PayloadAction<boolean>) => {
      state.showFoodChartModal = action.payload;
    },
    setDailyRation: (state: FeedingTableState, action: PayloadAction<DailyRation>) => {
      state.dailyRation = action.payload;
    },
    setIsLoading: (state: FeedingTableState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTankPocParameter: (state: FeedingTableState, action: PayloadAction<TankPocParameter>) => {
      state.tankPocParameter = action.payload;

      if (action.payload.dataset && action.payload.dataset.length > 0) {
        state.feedingDataset.points = action.payload.dataset;
      }
    },
    resetTankPocParameter: (state: FeedingTableState) => {
      state.tankPocParameter = initialState.tankPocParameter;
    },
    resetFeedingDataset: (state: FeedingTableState) => {
      state.feedingDataset.points = initialState.feedingDataset.points;
    },
  }
});

export const {
  addPointFeedingDataset,
  updatePointFeedingDataset,
  removePointFeedingDataset,
  setShowFoodChartModal,
  setDailyRation,
  setIsLoading,
  setTankPocParameter,
  resetTankPocParameter,
  resetFeedingDataset,
} = feedingTableSlice.actions;

const feedingTableReducer = feedingTableSlice.reducer;
export default feedingTableReducer;

export const createFeedingTable = (props: { body: TankPocParameter; onSuccess?: () => void; }) => async (dispatch: Function) => {
  const { body, onSuccess } = props;
  dispatch(setIsLoading(true));

  try {
    const response = await axios.post<TankPocParameter>(TANK_POC_PARAMETER_URL, body);
    dispatch(setTankPocParameter(response.data));
    dispatch(optimalHarvestPointSlice.setTankPocParameter(response.data));
    dispatch(setShowFoodChartModal(false));
    openSuccessNotification(i18next.t('optimalHarvestPoint.feedingTable.created'));
    onSuccess && onSuccess();
  } catch (error) {
    console.log(error?.response);
  }

  dispatch(setIsLoading(false));
};

export const updateFeedingTable = (props: { body: TankPocParameter; onSuccess?: () => void; }) => async (dispatch: Function) => {
  const { body, onSuccess } = props;
  dispatch(setIsLoading(true));
  const url = `${TANK_POC_PARAMETER_URL}/${body._id}`;

  try {
    const response = await axios.patch<TankPocParameter>(url, body);
    dispatch(setTankPocParameter(response.data));
    dispatch(optimalHarvestPointSlice.setTankPocParameter(response.data));
    dispatch(setShowFoodChartModal(false));
    openSuccessNotification(i18next.t('optimalHarvestPoint.feedingTable.updated'));
    onSuccess && onSuccess();
  } catch (error) {
    console.log(error?.response);
  }

  dispatch(setIsLoading(false));
};
