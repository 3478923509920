import cx from 'classnames';
import { capitalize } from 'lodash';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Row, Select, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import { LegendNormal } from '../NormalChart/LegendNormal';
import { changeHeader } from '../../AppHeader/headerSlice';
import { filterOptionSelect } from '../../../utils/select';
import { getUserSession } from '../../../utils/userSession';
import { UserSession } from '../../../common/interfaces/auth';
import { MultiphaseChart } from '../Multiphase/MultiphaseChart';
import * as multiphaseSlice from '../Multiphase/multiphaseSlice';
import { LegendMultiphase } from '../Multiphase/LegendMultiphase';
import { OriginsMultiphase } from '../Multiphase/OriginsMultiphase';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { StockingComparison } from '../NormalChart/StockingComparison';
import IconButton from '../../../common/components/buttons/IconButton';
import { LrvEmpty } from '../../../common/components/LrvEmpty/LrvEmpty';
import CleanButton from '../../../common/components/buttons/CleanButton';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import ActionButton from '../../../common/components/buttons/ActionButton';
import { LrvTooltip } from '../../../common/components/LrvTooltip/LrvTooltip';
import { getUnitPhaseTypeFromAnalysis } from '../../../helpers/units.helpers';
import { DropdownProps, GenericParam } from '../../../common/interfaces/commons';
import { LrvCheckbox } from '../../../common/components/LrvCheckbox/LrvCheckbox';
import { stockingPhaseTypes, stockingStatuses, THEME } from '../../../config/commons';
import { LrvFilterPanel } from '../../../common/components/LrvSideFloatingPanel/LrvFilterPanel';
import { typeParam, typeScale, isReferenceCurveMetric, estimators } from '../../../common/components/charts/ShadedPlot/helpers';

import { Tanks } from './Tanks';
import './StockingAnalysis.scss';
import { OtherStockings } from './OtherStockings';
import styles from './StockingAnalysis.module.scss';
import { StockingsByContainer } from './StockingsByContainer';
import * as stockingAnalysisSlice from './stockingAnalysisSlice';
import StockingInformationModal from './StockingInformationModal';
import { getPhaseTypes, getStockingsIds, getStockingsName } from './helpers';

export const StockingAnalysis = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const refFilters = useRef<HTMLDivElement>(null);
  const refLegends = useRef<HTMLDivElement>(null);
  const refOriginsMultiphase = useRef<HTMLDivElement>(null);

  const {
    stockingSelected, reference,
    firstStage, lastStage,
    firstStageZoom, lastStageZoom,
    parameter, scale,
    movingAverage, showMovingAverage,
    showDerivative,
    unitSelected, moduleSelected, containerSelected,
    showStockingDetail,
    listStockingsIds,
    listStockingsName,
    maxStage,
    isLoadingFetchActiveStockings,
    enabledStockings,
    isDownloadingFile,
    browsingFromStocking,
    showMultiphaseChart,
    globalReferenceCurves,
    companyReferenceCurves,
    unitReferenceCurves,
  } = useSelector((state: Store) => state.stockingAnalysis);

  const {
    phaseTypesSelected,
    multiphase,
    juvenileStockingId,
    larvaeStockingId,
    isDownloadingFile: isDownloadingFileMultiphase,
  } = useSelector((state: Store) => state.stockingMultiphase);

  const { company, phaseType } = useSelector((state: Store) => state.header);
  const { analysis, stockings } = multiphase;

  const userSession: UserSession = getUserSession();

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  useEffect(() => {
    dispatch(changeHeader({ title: 'production.filters.title' }));

    return (() => {
      if (browsingFromStocking) {
        dispatch(stockingAnalysisSlice.setBrowsingFromStocking(false));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (!company?._id || browsingFromStocking) {
      return;
    }

    const unitPhaseType = getUnitPhaseTypeFromAnalysis(phaseType);
    dispatch(stockingAnalysisSlice.fetchUnits({ companyId: company._id, phaseType: unitPhaseType, modulePhaseType: phaseType, selectFirstItem: true }));
  }, [dispatch, company?._id, phaseType, browsingFromStocking]);

  useEffect(() => {
    if (!company._id) {
      return;
    }

    dispatch(stockingAnalysisSlice.fetchGlobalReferenceCurves({ type: parameter, phaseType }));

    if (!stockingSelected?._id) {
      return;
    }

    dispatch(stockingAnalysisSlice.fetchCompanyReferenceCurves({ type: parameter, phaseType, companyId: company._id }));
  }, [dispatch, company, phaseType, parameter]);

  useEffect(() => {
    if (!company._id || !unitSelected || !stockingSelected?._id) {
      return;
    }

    dispatch(stockingAnalysisSlice.fetchUnitsReferenceCurves({ companyId: company._id, phaseType, campusId: unitSelected, type: parameter }));
  }, [dispatch, company, stockingSelected, phaseType, parameter, unitSelected]);

  const renderDropdownParameter = (props: DropdownProps) => {
    const { className, theme } = props;
    const options = [
      { id: typeParam.AVG_WEIGHT, value: typeParam.AVG_WEIGHT, label: capitalize(t('shadedplot.type.avg_weight').toLowerCase()).toString() },
      { id: typeParam.AVG_LENGTH, value: typeParam.AVG_LENGTH, label: capitalize(t('shadedplot.type.avg_length').toLowerCase()).toString() },
      { id: typeParam.CV_LENGTH, value: typeParam.CV_LENGTH, label: capitalize(t('shadedplot.type.cv_length').toLowerCase()).toString() },
      { id: typeParam.UNIFORMITY, value: typeParam.UNIFORMITY, label: capitalize(t('shadedplot.type.uniformity').toLowerCase()).toString() },
      { id: typeParam.PIGMENTATION, value: typeParam.PIGMENTATION, label: capitalize(t('shadedplot.type.pigmentation').toLowerCase()).toString() },
    ];

    if (phaseType === stockingPhaseTypes.LARVAE) {
      options.push(
        { id: typeParam.PLG, value: typeParam.PLG, label: capitalize(t('shadedplot.type.plg').toLowerCase()) }
      );
    }

    if (!showMultiphaseChart) {
      options.push(
        { id: typeParam.GROWTH_DELTA, value: typeParam.GROWTH_DELTA, label: capitalize(t('shadedplot.type.growth_delta').toLowerCase()) }
      );
    }

    if (!showMultiphaseChart && phaseType === stockingPhaseTypes.ADULT) {
      options.push(
        { id: typeParam.FACTOR_K, value: typeParam.FACTOR_K, label: `${capitalize(t('shadedplot.type.factorK').toLowerCase())} (beta)` }
      );
    }

    return (
      <LrvSelect
        id='dropdown_metric'
        theme={theme}
        className={className}
        suffixIcon={<Icon name='arrow-down-s' />}
        value={parameter}
        showSearch
        onChange={(value) => {
          dispatch(stockingAnalysisSlice.setParameter(value));
          dispatch(stockingAnalysisSlice.setReference(undefined));
          dispatch(stockingAnalysisSlice.setReferenceCurves({ _id: '', name: '', campusId: '', companyId: '', phaseType: '', type: '', values: [] }));
          dispatch(stockingAnalysisSlice.setShowDerivative(false));
        }}
        title={t('production.filters.parameter')}
        dropdownMatchSelectWidth={false}
        options={options}
      />
    );
  };

  const renderGlobalRefOptions = () => {
    return globalReferenceCurves.map((item) => {
      return (
        <Select.Option
          key={item._id}
          value={item._id}
          label={item.name}
        >
          {capitalize(item.name)}&nbsp;
          <Icon name='global' />
        </Select.Option>
      );
    });
  };

  const renderCompanyRefOptions = () => {
    return companyReferenceCurves.map((item) => {
      return (
        <Select.Option
          key={item._id}
          value={item._id}
        >
          {item.name}
        </Select.Option>
      );
    });
  };

  const renderUnitRefOptions = () => {
    return unitReferenceCurves.map((item) => {
      return (
        <Select.Option
          key={item._id}
          value={item._id}
        >
          {item.name}
        </Select.Option>
      );
    });
  };

  const renderRadioReference = (props: DropdownProps) => {
    const { className, theme } = props;
    if (showMultiphaseChart) {
      return;
    }

    if (parameter === typeParam.FACTOR_K || parameter === typeParam.GROWTH_DELTA || parameter === typeParam.PIGMENTATION) {
      return;
    }

    return (
      <LrvSelect
        id='reference_radio_group'
        theme={theme}
        className={className}
        suffixIcon={<Icon name='arrow-down-s' />}
        value={reference}
        showSearch
        title={t('production.filters.reference')}
        placeholder={t('production.filters.reference')}
        dropdownMatchSelectWidth={false}
        filterOption={filterOptionSelect}
        onChange={(value) => {
          dispatch(stockingAnalysisSlice.setReference(value));
          if (!isReferenceCurveMetric(value)) {
            return;
          }

          const referenceCurves = [...globalReferenceCurves, ...unitReferenceCurves, ...companyReferenceCurves];
          const refCurve = referenceCurves.find(item => item._id === value);

          if (refCurve) {
            dispatch(stockingAnalysisSlice.setReferenceCurves(refCurve));
          }
        }}
      >
        {renderGlobalRefOptions()}
        {renderCompanyRefOptions()}
        {renderUnitRefOptions()}
      </LrvSelect>
    );
  };

  const renderRadioScale = (props: DropdownProps) => {
    const { className, theme } = props;
    return (
      <LrvSelect
        id='scale_radio_group'
        theme={theme}
        className={className}
        title={t('production.filters.scale')}
        suffixIcon={<Icon name='arrow-down-s' />}
        value={scale}
        showSearch
        onChange={(value) => {
          dispatch(stockingAnalysisSlice.setScale(value));
        }}
      >
        <Select.Option
          key={typeScale.LINEAR}
          value={typeScale.LINEAR}
        >
          {capitalize(t('shadedplot.scale.linear').toLowerCase())}
        </Select.Option>

        <Select.Option
          key={typeScale.LOGARITHMIC}
          value={typeScale.LOGARITHMIC}
        >
          {capitalize(t('shadedplot.scale.logarithmic').toLowerCase())}
        </Select.Option>
      </LrvSelect>
    );
  };

  const showMovingAverageDropdown = () => {
    return (parameter === typeParam.GROWTH_DELTA || ((parameter === typeParam.AVG_WEIGHT || parameter === typeParam.FACTOR_K) && phaseType === stockingPhaseTypes.ADULT)) && listStockingsName.length === 0;
  };

  const renderMovingAverage = (props: DropdownProps) => {
    const { className, theme } = props;
    if (!showMovingAverageDropdown()) {
      return null;
    }

    const value = showDerivative ? t('production.window.derivative') : showMovingAverage ? movingAverage : t('production.none');
    const title = parameter === typeParam.FACTOR_K ? t('production.estimator') : t('production.movingAverage');

    return (
      <LrvSelect
        id='moving_average'
        theme={theme}
        className={className}
        suffixIcon={<Icon name='arrow-down-s' />}
        value={value}
        showSearch
        title={title}
        dropdownMatchSelectWidth={false}
        onChange={(value) => {
          if (!value) {
            dispatch(stockingAnalysisSlice.setShowMovingAverage(false));
            dispatch(stockingAnalysisSlice.setShowDerivative(false));
            return;
          }

          if (value === estimators.DERIVATIVE) {
            dispatch(stockingAnalysisSlice.setShowDerivative(true));
            dispatch(stockingAnalysisSlice.setShowMovingAverage(false));
            return;
          }

          if (!showMovingAverage) {
            dispatch(stockingAnalysisSlice.setShowMovingAverage(true));
            dispatch(stockingAnalysisSlice.setShowDerivative(false));
          }
          dispatch(stockingAnalysisSlice.setMovingAverage(value));
        }}
      >
        {
          parameter == typeParam.FACTOR_K ?
            <>
              <Select.Option key={undefined} value={undefined}> {t('production.none')} </Select.Option>
              <Select.Option key={2} value={2}> {t('production.window.2')} </Select.Option>
              <Select.Option key={estimators.DERIVATIVE} value={estimators.DERIVATIVE}> {t('production.window.derivative')} </Select.Option>
            </> :
            <>
              <Select.Option key={undefined} value={undefined}> {t('production.none')} </Select.Option>
              <Select.Option key={2} value={2}> {t('production.window.2')} </Select.Option>
              <Select.Option key={3} value={3}> {t('production.window.3')} </Select.Option>
              <Select.Option key={4} value={4}> {t('production.window.4')} </Select.Option>
            </>
        }
      </LrvSelect>
    );
  };

  const renderChartCheckout = (props: DropdownProps) => {
    const { className, theme } = props;
    if (!stockingSelected?.bindingStockings.length || parameter === typeParam.FACTOR_K) {
      return null;
    }

    return (
      <LrvCheckbox
        className={className}
        theme={theme}
        onChange={event => {
          if (parameter === typeParam.GROWTH_DELTA) {
            dispatch(stockingAnalysisSlice.setParameter(typeParam.AVG_WEIGHT));
          }
          dispatch(stockingAnalysisSlice.setShowMultiphaseChart(event.target.checked));
          if (!event.target.checked) {
            dispatch(multiphaseSlice.setPhaseTypesLegend([]));
            dispatch(multiphaseSlice.setPhaseTypesSelected([]));
          }
        }}
        checked={listStockingsIds.length > 0 ? false : showMultiphaseChart}
        disabled={listStockingsIds.length > 0}
      >
        {t('production.filters.multiphaseCheckbox')}
      </LrvCheckbox>
    );

  };

  const renderCleanButton = () => {
    return (
      <CleanButton
        theme={theme}
        onClick={() => dispatch(stockingAnalysisSlice.resetChartFilters({ company, phaseType }))}
      />
    );
  };

  const getStockingName = () => {
    if (!stockingSelected?._id) {
      return t('production.filters.noActiveStocking');
    }

    if (stockingSelected.status === stockingStatuses.ACTIVE) {
      return `${stockingSelected.name} (${t('production.filters.current')})`;
    }

    return stockingSelected.name;
  };

  const renderStockingSelected = () => {
    if (!containerSelected) {
      return null;
    }

    const stockingName = getStockingName();

    return (
      <div className={styles.stocking}>
        <LrvTooltip
          title={t('stockings.stocking')}
        >
          <div
            className={cx(styles.name, isLightTheme ? styles.nameLight : styles.nameDark)}
            onClick={() => {
              dispatch(stockingAnalysisSlice.setShowStockingsByContainerModal(true));
              dispatch(stockingAnalysisSlice.fetchStockings({ unitId: unitSelected, moduleId: moduleSelected, containerId: containerSelected, phaseType, companyId: company._id }));
            }}
          >
            <LrvText theme={theme} text={stockingName} />
          </div>
        </LrvTooltip>
      </div>
    );
  };

  const generatePdf = () => {
    if (!stockingSelected?._id || !stockingSelected?.name) {
      return;
    }

    if (showMultiphaseChart && stockingSelected.bindingStockings.length > 0 && listStockingsIds.length === 0) {
      const params = {
        stockingId: stockingSelected._id,
        stockingName: stockingSelected.name,
        dataSource: reference, parameter, scale,
        firstStage: firstStage, lastStage: lastStage,
        companyId: stockingSelected.companyId,
        stockingPhaseType: stockingSelected.phaseType,
        phaseTypeSelected: getPhaseTypes(phaseTypesSelected),
        juvenileStockingId,
        larvaeStockingId,
      };

      dispatch(multiphaseSlice.fetchUrlMultiphasePdf(params));
      return;
    }

    const params = {
      stockingId: stockingSelected._id,
      stockingName: stockingSelected.name,
      dataSource: reference, parameter, scale,
      firstStage: firstStageZoom,
      lastStage: lastStageZoom,
      stockingsId: getStockingsIds(listStockingsIds, enabledStockings),
      stockingsName: getStockingsName(listStockingsName, enabledStockings),
      movingAverage: showMovingAverage && listStockingsName.length === 0 ? movingAverage : -1,
      estimator: showDerivative && listStockingsName.length === 0 ? estimators.DERIVATIVE : undefined,
    };

    dispatch(stockingAnalysisSlice.fetchUrlStockingComparisonPdf(params));
  };

  const renderGeneratePdf = () => {
    return (
      <IconButton
        loading={isDownloadingFile || isDownloadingFileMultiphase}
        placement='left'
        tooltipText={t('production.download')}
        disabled={!stockingSelected?._id}
        iconName='download'
        onClick={generatePdf}
      />
    );
  };

  const renderSidePanel = () => {
    return (
      <div className={styles.sidePanel}>
        <LrvFilterPanel
          showFilterIcon={true}
          title={<div className={styles.title}>{t('production.chartOptions')}</div>}
          cleanButtonProps={{
            onClick: () => {
              dispatch(stockingAnalysisSlice.resetChartFilters({ company, phaseType }));
            },
          }}
        >
          <Space
            direction='vertical'
            className={styles.bodyPanel}
          >
            {renderDropdownParameter({ theme: 'light' })}
            {renderRadioReference({ theme: 'light' })}
            {renderRadioScale({ theme: 'light' })}
            {renderMovingAverage({ theme: 'light' })}
            {renderChartCheckout({ theme: 'light' })}
          </Space>
        </LrvFilterPanel>
      </div>
    );
  };

  const renderShowDetailButton = () => {
    if (!showMultiphaseChart || !stockingSelected?.bindingStockings?.length || listStockingsIds.length > 0) {
      return null;
    }

    return (
      <ActionButton
        type='primary'
        onClick={() => {
          dispatch(stockingAnalysisSlice.setShowStockingDetail(!showStockingDetail));
        }}
      >
        {t('production.filters.showDetail')}
      </ActionButton>
    );
  };

  const renderOtherStockingsButton = () => {
    if (!containerSelected || !stockingSelected?._id) {
      return null;
    }

    return (
      <Badge count={listStockingsIds.length < 2 ? 0 : listStockingsIds.length - 1} color='#eeeeee'>
        <ActionButton
          id='others_stocking_button'
          type='primary'
          onClick={() => {
            dispatch(stockingAnalysisSlice.setShowOtherStockingsModal(true));
          }}
        >
          {t('shadedplot.compare')}
        </ActionButton>
      </Badge>
    );
  };

  const renderInfoIcon = () => {
    if (!stockingSelected) {
      return null;
    }

    const hasBindingStocking = stockingSelected?.bindingStockings?.length > 0 && listStockingsIds.length === 0;

    if (hasBindingStocking && showMultiphaseChart) {
      return null;
    }

    return (
      <IconButton
        id='info_icon'
        onClick={() => {
          dispatch(stockingAnalysisSlice.setShowStockingInformationModal(true));
          dispatch(stockingAnalysisSlice.fetchStockingInformation({ stockingId: stockingSelected._id, companyId: userSession.companyId }));
          dispatch(stockingAnalysisSlice.fetchMaturationsInfo({ stockingId: stockingSelected._id }));
        }}
        iconName='information'
        tooltipText={t('production.filters.stockingInfo')}
        placement='topLeft'
      />
    );
  };

  const renderFilters = () => {
    return (
      <div ref={refFilters}>
        <Row justify='space-between' className={styles.row}>
          <Space className={styles.filters} align='end'>
            {renderDropdownParameter({ className: styles.select, theme })}
            {renderRadioReference({ className: styles.select, theme })}
            {renderRadioScale({ className: styles.select, theme })}
            {renderMovingAverage({ className: styles.select, theme })}
            {renderChartCheckout({ className: styles.checkbox, theme })}
            {renderCleanButton()}
          </Space>
          {renderSidePanel()}

          <Space align='end' className={styles.options}>
            {renderStockingSelected()}
            {renderOtherStockingsButton()}
            {renderShowDetailButton()}
            {renderGeneratePdf()}
            {renderInfoIcon()}
          </Space>
        </Row>
      </div>
    );
  };

  const renderLegends = () => {
    if (showMultiphaseChart && stockingSelected?.bindingStockings && stockingSelected?.bindingStockings.length > 0 && listStockingsIds.length === 0) {
      return (
        <div ref={refLegends}>
          <LegendMultiphase stockingDefault={stockingSelected} theme={theme} />
        </div>
      );
    }

    return (
      <div ref={refLegends}>
        <LegendNormal
          stockingDefault={stockingSelected}
          maxStage={maxStage}
          theme={theme}
        />
      </div>
    );
  };

  const renderBody = () => {
    if (isLoadingFetchActiveStockings) {
      return (
        <div className={styles.spinnerContainer}>
          <div className={styles.spinner}>
            <DotSpinner />
          </div>
        </div>
      );
    }

    if (!containerSelected) {
      return (
        <div className={styles.rowBody}>
          <div className={styles.center}>
            <LrvEmpty description={t('shadedplot.emptyContainer')} theme={theme} />
          </div>
        </div>
      );
    }

    if (!stockingSelected) {
      return (
        <div className={styles.rowBody}>
          <div className={styles.center} >
            <LrvEmpty description={t('shadedplot.emptyStocking')} theme={theme} />
          </div>
        </div>
      );
    }

    return (
      <div className={styles.rowBody}>
        <div className={styles.center}>
          {
            showMultiphaseChart && stockingSelected.bindingStockings.length > 0 && listStockingsIds.length === 0 ?
              <MultiphaseChart stockingDefault={stockingSelected} refParentFilters={refFilters} refChildFilters={refLegends} refOriginsMultiphase={refOriginsMultiphase} /> :
              <StockingComparison stockingDefault={stockingSelected} refFilters={refFilters} refLegends={refLegends} />
          }
        </div>
      </div>
    );
  };

  const getBindingStockingsCount = () => {
    let totalCount = 0;

    totalCount += Object.keys(stockings).filter(key => !Array.isArray((stockings as GenericParam)[key])).length;
    totalCount += Object.keys(stockings).filter(key => Array.isArray((stockings as GenericParam)[key])).reduce((count, key) => count + (stockings as GenericParam)[key].length, 0);

    return totalCount;
  };

  const renderOriginsMultiphase = () => {
    if (!stockingSelected || !(stockingSelected.bindingStockings?.length > 0 && listStockingsIds.length === 0) || !analysis.length) {
      return null;
    }

    const baseLength = 52;
    const rowBaseLength = 30;
    const stylesOrigins = {
      height: showStockingDetail ? baseLength + ((getBindingStockingsCount() - 1) * rowBaseLength) : 0,
    };

    return (
      <div className={styles.originsMultiphase}>
        <div className={showStockingDetail ? styles.show : styles.hide} style={stylesOrigins} >
          <div ref={refOriginsMultiphase} >
            <OriginsMultiphase theme={theme} stockingDefault={stockingSelected} showStockingDropdown={true} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.stockingAnalysis}>
        {renderFilters()}
        {renderOriginsMultiphase()}
        {renderLegends()}

        <div className={styles.body}>
          {renderBody()}
          <Tanks refFilters={refFilters} refLegends={refLegends} refOriginsMultiphase={refOriginsMultiphase} theme={theme} />
        </div>
        <StockingInformationModal theme='light' />
      </div>

      {
        containerSelected &&
        <>
          <StockingsByContainer />
          <OtherStockings stockingDefault={stockingSelected} />
        </>
      }
    </>
  );
};