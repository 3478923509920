import { commercialSizeTypes } from '../../../config/commons';
import { CommercialSizeData } from '../interfaces';

const getPoint = (props: { predictionSelected: CommercialSizeData | undefined; pocPoint: CommercialSizeData | undefined; }) => {
  const { predictionSelected, pocPoint } = props;
  return predictionSelected ? predictionSelected : pocPoint;
};

export const getCurrentTab = (props: { predictionSelected: CommercialSizeData | undefined; pocPoint: CommercialSizeData | undefined; }) => {
  const point = getPoint(props);
  return point?.histogram?.yield?.whole ? commercialSizeTypes.GROW_OUT_WHOLE : commercialSizeTypes.GROW_OUT_TAIL;
};

export const getDataset = (props: {predictionSelected: CommercialSizeData | undefined; pocPoint: CommercialSizeData | undefined; }) => {
  const point = getPoint(props);

  if (!point?.histogram) {
    return {
      whole: [],
      tail: [],
    };
  }

  const { histogram } = point;
  const { whole, tail } = histogram;
    
  return {
    whole,
    tail,
  };
};

export const getYieldPercent = (props: {predictionSelected: CommercialSizeData | undefined; pocPoint: CommercialSizeData | undefined; }) => {
  const point = getPoint(props);

  if (!point?.histogram) {
    return {
      whole: 0,
      tail: 0,
    };
  }

  const { whole, tail } = point.histogram.yield;

  return {
    whole,
    tail,
  };
};
