import moment from 'moment';
import { Descriptions } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

import { Store } from '../../state/store.interfaces';
import { applyThousandsSeparator } from '../../utils/strings';
import IconButton from '../../common/components/buttons/IconButton';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvTable } from '../../common/components/LrvTable/LrvTable';
import ActionButton from '../../common/components/buttons/ActionButton';
import { convertPoundsToKilograms } from '../../helpers/stocking.helpers';
import { LrvTooltip } from '../../common/components/LrvTooltip/LrvTooltip';
import { lrvConfirm } from '../../common/components/LrvConfirm/LrvConfirm';
import { formatDayMonthYear, formatLongDateWithZone } from '../../utils/date';
import { LrvDescriptions } from '../../common/components/LrvDescriptions/LrvDescriptions';
import { roundTwoDecimals, stockingPhaseTypes, stockingStatuses, transferTypes, weightUnits } from '../../config/commons';

import { Transfer } from './interfaces';
import * as stockingsSlice from './sowingsSlice';
import styles from './TransferStockingInfo.module.scss';
import * as finishStockingSlice from './finishStockingSlice';
import * as transferStockingSlice from './transferStockingSlice';

interface Props {
  theme?: 'dark' | 'light';
}

export const TransferStockingInfoModal = (props: Props) => {
  const { theme = 'dark' } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();
  const pathname = history.location.pathname;

  const {
    showTransferStockingInfo,
    transfers,
    isLoading,
  } = useSelector((state: Store) => state.transferStocking);

  const { isLoadingFinish } = useSelector((state: Store) => state.finishStocking);

  const { company } = useSelector((state: Store) => state.header);

  const {
    currentPage,
    selectedCampus,
    selectedModuleId,
    selectedStocking,
    selectedTankId,
    lastAnalysis,
    filters,
  } = useSelector((state: Store) => state.stockings);

  const {
    stockingsToShow,
  } = filters;

  const onCancel = () => {
    dispatch(transferStockingSlice.setShowTransferStockingInfo(false));
    dispatch(stockingsSlice.setLastAnalysis(undefined));
  };

  const renderConfirmationUndoTransferStocking = (transfer: Transfer) => {
    const title = t('stockings.confirmUndoTransfer.title');
    const description = t('stockings.confirmUndoTransfer.description');
    const idOkButton = 'confirm_button';
    const idCancelButton = 'cancel_button';

    lrvConfirm({
      theme: 'light',
      title: title,
      content: description,
      icon: <ExclamationCircleOutlined />,
      okText: t('stockings.confirmUndoTransfer.yes').toUpperCase(),
      cancelText: t('stockings.confirmUndoTransfer.no').toUpperCase(),
      okButtonProps: { id: idOkButton },
      cancelButtonProps: { id: idCancelButton },
      onOk () {
        const paramsToFetchStocking = { companyId: company._id, campusId: selectedCampus?._id, moduleId: selectedModuleId, tankId: selectedTankId, page: currentPage, phaseType: selectedStocking.phaseType, stockingsToShow };
        const makeFetchStockings = pathname === '/production/stockings';
        const params = { originStockingId: selectedStocking._id, destinationStockingId: transfer.stockingId, paramsToFetchStocking, makeFetchStockings };

        dispatch(transferStockingSlice.undoTransferStocking(params));
      },
    });
  };

  const getTransferWeightUnit = () => {
    switch (selectedStocking.phaseType) {
      case stockingPhaseTypes.LARVAE:
        return weightUnits.MG;

      default:
        return weightUnits.G;
    }
  };

  const getColumnsType = () => {
    const columnsType: ColumnsType<Transfer> = [
      {
        key: 1,
        width: '18%',
        title: t('stockings.finishStockingLabels.destinationStocking'),
        responsive: ['lg'] as Breakpoint[],
        render: (record) => {
          return (
            <div
              className={styles.stockingName}
              onClick={() => {
                if (record.isFooter) {
                  return;
                }
                const url = `/production/stockings/${record.stockingId}`;
                window.open(url, '_blank');
              }}
            >
              {record.stockingName}
            </div>
          );
        }
      },
      {
        key: 2,
        width: '20%',
        title: t('stockings.finishStockingLabels.JUVENILE.animalsTransferred'),
        render: (record) => {
          if (record.isFooter) {
            return renderFooterCell({ value: record.animalsTransferred, label: t('stockings.total') });
          }
          return applyThousandsSeparator(record.animalsTransferred);
        },
      },
      {
        key: 3,
        width: '18%',
        title: `${t('stockings.finishStockingLabels.JUVENILE.transferWeight')} ${getTransferWeightUnit()}`,
        render: (record) => {
          if (record.isFooter) {
            return renderFooterCell({ value: record.averageHarvestedWeight, label: t('stockings.weighted') });
          }
          return applyThousandsSeparator(record.averageHarvestedWeight);
        },
      },
      {
        key: 4,
        width: '18%',
        title: t('stockings.finishStockingLabels.JUVENILE.poundsTransferred'),
        render: (record) => {
          const { poundsTransferred } = record;
          if (!poundsTransferred) {
            return '-';
          }

          const pounds = roundTwoDecimals(poundsTransferred);
          if (record.isFooter) {
            return renderFooterCell({ value: pounds, label: t('stockings.total') });
          }
          return applyThousandsSeparator(pounds);
        }
      },
      {
        key: 5,
        width: '18%',
        title: t('stockings.finishStockingLabels.JUVENILE.kilogramsTransferred'),
        render: (record) => {
          const { poundsTransferred } = record;
          if (!poundsTransferred) {
            return '-';
          }

          const kg = convertPoundsToKilograms(poundsTransferred);
          if (record.isFooter) {
            return renderFooterCell({ value: kg, label: t('stockings.total') });
          }
          return applyThousandsSeparator(roundTwoDecimals(kg));
        }
      },
      {
        key: 6,
        width: '18%',
        title: t('stockings.finishStockingLabels.JUVENILE.transferDate'),
        dataIndex: 'date',
      },
      {
        key: 7,
        width: '8%',
        render: (record) => {
          if (record.isFooter) {
            return;
          }

          const statusesToDisabled = [stockingStatuses.HARVESTED, stockingStatuses.DISCARDED, transferTypes.FULL_TRANSFER];
          const disabledButton = statusesToDisabled.some(status => status === selectedStocking.status);

          return (
            <div className={styles.options}>
              <LrvTooltip
                themeStyle='light'
                title={t('stockings.delete')}
              >
                <IconButton
                  theme='light'
                  iconName='delete-bin'
                  disabled={disabledButton}
                  onClick={() => renderConfirmationUndoTransferStocking(record)}
                  className={styles.actionButton}
                />
              </LrvTooltip>
            </div>
          );
        }
      },
    ];

    return columnsType;
  };

  const renderFooterCell = (props: { label: string; value: number }) => {
    const { label, value } = props;

    return (
      <div className={styles.footerCell}>
        <div className={styles.value}>
          {applyThousandsSeparator(value)}
        </div>
        <div className={styles.label}>
          {label}
        </div>
      </div>
    );
  };

  const buildDataSource = () => {
    const dataSource = transfers.map((transfer: Transfer) => {
      return {
        ...transfer,
        isFooter: false,
        date: formatLongDateWithZone({ date: transfer.transferDate }),
      };
    });

    const totalAnimalsTransferred = dataSource.reduce((sum, item) => (item.animalsTransferred + sum), 0);
    const showPoundsTransferred = !dataSource.some(item => !item?.poundsTransferred || item?.poundsTransferred === 0);

    let totalPoundsTransferred = 0;

    if (showPoundsTransferred) {
      totalPoundsTransferred = dataSource.reduce((sum, item) => (sum + (item?.poundsTransferred ?? 0)), 0);
    }

    const sumProductAverageWeight = dataSource.reduce((sum, item) => (sum + (item.animalsTransferred * item.averageHarvestedWeight)), 0);

    let weightedAverageWeight = 0;
    if (sumProductAverageWeight !== 0) {
      weightedAverageWeight = roundTwoDecimals(sumProductAverageWeight / totalAnimalsTransferred);
    }

    const footerDataSource = {
      animalsTransferred: totalAnimalsTransferred,
      averageHarvestedWeight: weightedAverageWeight,
      poundsTransferred: totalPoundsTransferred,
      comment: '',
      status: '',
      stockingId: '',
      stockingName: '',
      transferDate: '',
      date: '',
      isFooter: true,
    };

    dataSource.push(footerDataSource);

    return dataSource;
  };

  const dataSource = buildDataSource();


  const onFinishTransferredStocking = (params: { endDate: string }) => {
    const { endDate } = params;
    const paramsToFetchStocking = { companyId: company._id, campusId: selectedCampus?._id, moduleId: selectedModuleId, tankId: selectedTankId, page: currentPage, phaseType: selectedStocking.phaseType, stockingsToShow };
    const onSuccess = () => dispatch(stockingsSlice.fetchStocking({ id: selectedStocking._id }));
    dispatch(finishStockingSlice.finishTransferredStocking({ stockingId: selectedStocking._id, endDate, paramsToFetchStocking, onSuccess }));
  };

  const renderConfirmationFinishStocking = () => {
    const lastTransfer = transfers[transfers.length - 1];
    const lastTransferDate = formatDayMonthYear(lastTransfer.transferDate);

    const title = t('stockings.confirmFinishStocking.title');
    const description = t('stockings.confirmFinishStocking.transferDescription', { endDate: lastTransferDate });
    const idOkButton = 'confirm_button';
    const idCancelButton = 'cancel_button';

    lrvConfirm({
      theme: 'light',
      title: title,
      content: description,
      icon: <ExclamationCircleOutlined />,
      okText: t('confirm.yes').toUpperCase(),
      cancelText: t('confirm.no').toUpperCase(),
      okButtonProps: { id: idOkButton },
      cancelButtonProps: { id: idCancelButton },
      onOk () {
        const endDate = transfers[transfers.length - 1].transferDate;
        onFinishTransferredStocking({ endDate });
      },
    });
  };


  const renderFooterButtons = () => {

    if (selectedStocking.status !== transferTypes.PARTIAL_TRANSFER || transfers.length === 0) {
      return <div></div>;
    }

    if (lastAnalysis) {
      const lastAnalysisDate = moment(lastAnalysis.createdAt).startOf('day');
      const lastTransfer = transfers[transfers.length - 1];
      const lastTransferDate = moment(lastTransfer.transferDate).startOf('day');

      if (lastAnalysisDate.isAfter(lastTransferDate)) {
        return <div></div>;
      }
    }

    return (
      <div className={styles.footerModal}>
        <ActionButton
          theme='light'
          type='text'
          onClick={onCancel}
        >
          <span>{t('stockings.cancel')}</span>
        </ActionButton>
        <ActionButton
          theme='light'
          type='primary'
          loading={isLoadingFinish}
          className={styles.closeStockingBtn}
          onClick={renderConfirmationFinishStocking}
        >
          <span>{t('stockings.finish')}</span>
        </ActionButton>
      </div>
    );
  };

  return (
    <LrvModal
      cancelButtonProps={{ style: { display: 'none' } }}
      className={styles.transferStockingInfoModal}
      destroyOnClose={true}
      onCancel={onCancel}
      footer={renderFooterButtons()}
      open={showTransferStockingInfo}
      theme={theme}
      title={t('stockings.transferStockingInfo')}
      width={1000}
    >
      <LrvDescriptions
        column={1}
        theme={theme}
      >
        <Descriptions.Item label={t('stockings.stocking')}>
          {selectedStocking.name}
        </Descriptions.Item>
      </LrvDescriptions>

      <LrvTable
        id='transfers_stockings_table'
        rowClassName={(record) => (record.isFooter ? styles.footerRow : '')}
        columns={getColumnsType()}
        loading={isLoading}
        dataSource={dataSource}
        size='small'
        theme={theme}
        pagination={false}
      />
    </LrvModal>
  );
};