import { Tank, TankPocParameter } from '../Tanks/interfaces';
import { Analysis } from '../Analysis/interfaces';
import { CampusPocParameter } from '../Units/interfaces';
import { Module, Unit } from '../Sowings/Analysis/interfaces';
import { PopulationData, Stocking } from '../Sowings/interfaces';
import { CommercialSizeRange } from '../Company/Packers/interfaces';
import { CommercialSizePriceTable } from '../Company/Packers/TablePrices/interfaces';

import { DailyRation, FeedingStrategy } from './Indicators/interfaces';

export interface OptimalHarvestPointState {
  dataSource: DataSource;
  pocPoint?: CommercialSizeData;
  allPredictions: PredictionPoint[];
  dataSourceByStage: DataSourceByStage[];
  allPoints: (PredictionPoint | Point)[];
  firstStage: number;
  lastStage: number;
  maxStage: number;
  filters: OptimalHarvestPointFilters;
  isFetchingData: boolean;
  isFetchingStocking: boolean;
  isExcluding: boolean;
  units: Unit[];
  modules: Module[];
  tanks: Tank[];
  predictionSelected: CommercialSizeData | undefined;
  analysesToExclude: string[];
  isUpdatingPoints: boolean;
  lastPopulation?: PopulationData;
  harvestsAndTransfers: number;
  showUpdatePriceTableModal: boolean;
  isFetchingPackersInfo: boolean;
  isUpdatingPriceTable: boolean;
  packersWithCommercialSizes: PackersWithCommercialSizes[];
  allPackers: PocByPacker[];
  bestPackers: PocByPacker[];
  colors: string[];
  interestRate: number;
  showDetailHarvestModal: boolean;
  showMiniChartModal: boolean;
  showHeatMapModal: boolean;
  campusPocParameter?: CampusPocParameter;
  tankPocParameter?: TankPocParameter;
  harvestOptimalPlan: {
    harvestOptimalPlans: HarvestOptimalPlan[];
    isCreating: boolean;
  };
}

export interface OptimalHarvestPointFilters {
  unitId: string;
  moduleId: string;
  tankId: string;
  selectedStocking: Stocking;
  chartParameter: ChartParameter;
  miniChartParameter?: ChartParameter;
  commercialSizePriceTable?: CommercialSizePriceTable;
  loadCapacity?: number;
  survival: number;
  mortality: number;
  feedingStrategy: FeedingStrategy;
  weeklyFeeding: number;
  foodPricePerKg: number;
  costPerVolumeDay?: number;
  accumulatedCost: number;
  linearBiomassCoefficientKg: number;
  linearBiomassCoefficientLb: number;
  costPerHp: number;
}

export interface DataSource {
  allAnalysis: Analysis[];
  excludedAnalyses: Analysis[]
  predictions: Analysis[];
}

export interface Dataset {
  index: number;
  frequency: number;
  label: string;
  percent: number;
  biomass: number;
  biomassLb: number;
  sizePrice: number;
  pricePerWeightUnit: number;
  commercialSizeRanges: CommercialSizeRange[];
}

export type IPoint = Point | PredictionPoint | CommercialSizeData;

export interface Point {
  _id: string;
  code: string;
  createdAt: string;
  excludedFromPrediction?: boolean;
  uniformity: number;
  x: number;
  y: number;
}

export interface PredictionPoint {
  predictionDate: string;
  uniformity: number;
  survival: number;
  population: number;
  biomass: number;
  biomassLb: number;
  pondLoadCapacity: number;
  costPerDayKg: number;
  costPerDayLb: number;
  x: number;
  y: number;
  modelFoodQuantity: number;
  correctedFoodQuantity: number;
  accumulatedCost: number;
  balancedAccumulatedCost: number;
  totalAccumulatedCost: number;
  commercialSizeData: CommercialSizeData[];
  aeration: number;
  costAccumulatedAeration: number;
}

export interface PocByPacker {
  packerId: string;
  packerName: string;
  color: string;
  x: number;
  poc: number;
}

export interface CommercialSizeData {
  x: number;
  packerId: string;
  packerName: string;
  color: string;
  potentialIncome: number;
  predictionDate?: string;
  histogram: HistogramData;
  netValue: {
    potentialGain: number;
    potentialGainByDayHectarea: number;
  };
  presentValue: {
    potentialGain: number;
    potentialGainByDayHectarea: number;
  };
}

export interface HistogramData {
  whole: Dataset[];
  tail: Dataset[];
  income: {
    whole: number;
    tail: number;
    waste: number;
  }
  yield: {
    whole: number;
    tail: number;
  }
}

export interface DataSourceByStage {
  id?: string;
  x: number;
  y: number;
  points: Point[] | PredictionPoint[] | undefined;
}

export interface GrowOutSizesSettings {
  whole: {
    labels: string[];
    ranges: number[];
    numericLabels: number[];
  };
  tail: {
    labels: string[];
    ranges: number[];
    numericLabels: number[];
  };
}

export interface GrowOutSize {
  labels: string[];
  values: number[];
  prices: number[];
}

export interface BuildAndGetFirstPredictionParams {
  dataSourceByStage: DataSourceByStage;
  initialPopulation: number;
  survival: number;
  dailyFeeding?: number;
  volume: number;
  accumulatedCost: number;
  packersWithCommercialSizes: PackersWithCommercialSizes[];
  interestRate: number;
  profitType: ProfitType;
  dailyRation: DailyRation;
  linearBiomassCoefficient: number;
  costPerHp: number;
}

export interface CalcDataSourceParams {
  dataSource: DataSource;
  initialPopulation: number;
  survival: number;
  dailyFeeding?: number;
  costPerVolumeDay: number;
  volume: number;
  accumulatedCost: number;
  harvestsAndTransfers: number;
  mortality: number;
  foodPricePerKg: number;
  animalsSown: number;
  packersWithCommercialSizes: PackersWithCommercialSizes[];
  interestRate: number;
  profitType: ProfitType;
  dailyRation: DailyRation;
  linearBiomassCoefficient: number;
  costPerHp: number;
}

export interface CheckValidInputsProps {
  loadCapacity: number;
  survival: number;
  mortality: number;
  foodPricePerKg: number;
  costPerVolumeDay: number;
  accumulatedCost: number;
}

export interface PackersWithCommercialSizes {
  packerId: string;
  packerName: string;
  companyId: string;
  date: string;
  paymentDelayDays: number;
  commercialSizes: {
    whole: number[];
    tail: number[];
  };
  prices: {
    whole?: number[];
    tail?: number[];
  };
  waste: {
    price?: number;
  };
  yield: {
    whole?: number;
    tail?: number;
  };
  commercialSizeId: string;
  commercialSizePriceTableId: string;
  color?: string;
}

export interface BuildGrowthSizesParams {
  randomWeights: number[];
  biomass: number;
  packerInfo: PackersWithCommercialSizes;
  animals: number;
}

export enum ProfitType {
  PROFIT_PER_DAY = 'PROFIT_PER_DAY',
  PROFIT_TOTAL = 'PROFIT_TOTAL',
}

export enum ViewMode {
  CHART = 'CHART',
  TABLE = 'TABLE',
}

export enum ChartParameter {
  POC = 'POC',
  WEIGHT = 'WEIGHT',
  BIOMASS = 'BIOMASS',
  BIOMASS_KG = 'BIOMASS_KG',
  BIOMASS_LB = 'BIOMASS_LB',
  AERATION = 'AERATION',
  CORRECTED_FOOD = 'CORRECTED_FOOD',
  POTENTIAL_GAIN = 'POTENTIAL_GAIN',
  POTENTIAL_INCOME = 'POTENTIAL_INCOME',
  TOTAL_ACCUMULATED_COST = 'TOTAL_ACCUMULATED_COST',
  LOAD_CAPACITY = 'LOAD_CAPACITY',
  COST_PER_DAY_KG = 'COST_PER_DAY_KG',
  COST_PER_DAY_LB = 'COST_PER_DAY_LB',
}

export interface AerationPower {
  linearBiomassCoefficient: number;
  biomass: number;
}

export interface StockingPocParameter {
  _id?: string;
  companyId: string;
  campusId: string;
  tankId: string;
  stockingId: string;
  survival: number;
  mortality: number;
  weeklyFeeding: number;
  accumulatedCost: number;
}

export interface HarvestOptimalPlan {
  _id?: string;
  companyId: string;
  active?: boolean;
  harvestDate: string;
  biomassKg: number;
  packerId: string;

  campusId: string;
  foodPricePerKg: number;
  costPerHp: number;
  linearBiomassCoefficient: number;
  
  tankId: Tank | string;
  loadCapacity?: number;
  costPerVolumeDay?: number;
  feedingStrategy: number;

  stockingId: string;
  survival: number;
  mortality: number;
  weeklyFeeding: number;
  accumulatedCost: number;
}

export interface HeatMapValue {
  date: string;
  count: number;
}