import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { axiosClient as axios } from '../../utils/axios_instance';
import { TANK_POC_PARAMETER_URL } from '../../config/config.api';
import { openSuccessNotification } from '../../common/notification/Notification';

import { TankPocParameter, TankPocParameterState } from './interfaces';

const initialState: TankPocParameterState = {
  tankId: '',
  isLoading: false,
  showCreateModal: false,
};

export const tankPocParameterSlice = createSlice({
  name: 'tankPocParameter',
  initialState,
  reducers: {
    setTankId: (state: TankPocParameterState, action: PayloadAction<string>) => {
      state.tankId = action.payload;
    },
    setIsLoading: (state: TankPocParameterState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShowCreateModal: (state: TankPocParameterState, action: PayloadAction<boolean>) => {
      state.showCreateModal = action.payload;
    },
    setTankPocParameter: (state: TankPocParameterState, action: PayloadAction<TankPocParameter>) => {
      state.tankPocParameter = action.payload;
    },
    resetTankPocParameter: (state: TankPocParameterState) => {
      state.tankPocParameter = initialState.tankPocParameter;
    },
    
  }
});

export const {
  setTankId,
  setIsLoading,
  setShowCreateModal,
  setTankPocParameter,
  resetTankPocParameter,
} = tankPocParameterSlice.actions;

export const fetchTankPocParameter = (props: {tankId: string; companyId: string;}) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    tankId: props.tankId,
    companyId: props.companyId,
  };
  
  try {
    const response = await axios.get<TankPocParameter[]>(TANK_POC_PARAMETER_URL, { params });
    if (response.data.length) {
      dispatch(setTankPocParameter(response.data[0]));
    }
  } catch (error) {
    console.log(error?.response);
  }
};

export const createTankPocParameter = (props: {body: TankPocParameter; onSuccess: () => void;}) => async (dispatch: Function) => {
  const { body, onSuccess } = props;

  try {
    const response = await axios.post<TankPocParameter>(TANK_POC_PARAMETER_URL, body);
    dispatch(setTankPocParameter(response.data));

    onSuccess();
    openSuccessNotification(i18next.t('formPocParameters.created'));
  } catch (error) {
    console.log(error?.response);
  }
};

export const updateTankPocParameter = (props: {body: TankPocParameter; onSuccess: () => void;}) => async (dispatch: Function) => {
  const { body, onSuccess } = props;
  const url = `${TANK_POC_PARAMETER_URL}/${body._id}`;

  try {
    const response = await axios.patch<TankPocParameter>(url, body);
    dispatch(setTankPocParameter(response.data));

    onSuccess();
    openSuccessNotification(i18next.t('formPocParameters.updated'));
  } catch (error) {
    console.log(error?.response);
  }
};

export default tankPocParameterSlice.reducer;
