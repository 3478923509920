import 'chart.js/auto';
import { ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { fetchAppActivityDefault, fetchCompletedAnalysisCount } from '../adminDashboardSlice';

import styles from './AppActivity.module.scss';
import { getAppActivityData, getAppFullActivityData, getOptionsBar, getAppActivityMonthlyData } from './helpers';

interface Props {
  theme?: 'dark' | 'light';
}

export const AppActivity = (props: Props) => {
  const { theme } = props;
  const dispatch = useDispatch();

  const { appActivity, appMonthlyActivity, appFullActivity, appFullActivityByDay, isAppActivityLoading, isAppActivityByDayLoading, isAppActivityByMonthLoading, filters } = useSelector((state: Store) => state.adminDashboard);

  const { activityType, dateSelected, showActivityByPhaseType } = filters;
  const { data: appsActivity, days } = appActivity;
  const { data: monthlyData, months } = appMonthlyActivity;
  const { data: appsFullActivity, days: daysFull } = appFullActivity;

  useEffect(() => {
    dispatch(fetchAppActivityDefault());
    dispatch(fetchCompletedAnalysisCount());
  }, [dispatch]);

  const getData = () => {
    switch (activityType) {
      case 'DAILY':
        return getAppActivityData({ days, analysisUsed: appsActivity, showActivityByPhaseType });

      case 'MONTHLY':
        return getAppActivityMonthlyData({ months, analysisUsed: monthlyData, showActivityByPhaseType });

      case 'PER_HOUR':
        return getAppFullActivityData({ days: daysFull, analysisUsed: appsFullActivity, analysisUsedByDay: appFullActivityByDay, selectDate: dateSelected });
    }
  };

  const data = getData();
  const showLegends = activityType === 'DAILY' || activityType === 'MONTHLY';

  if (isAppActivityLoading || isAppActivityByDayLoading || isAppActivityByMonthLoading) {
    return (
      <div className={styles.spinnerContainer}>
        <div className={styles.spinner}>
          <DotSpinner />
        </div>
      </div>
    );
  }

  const renderGraph = (data: ChartData<'bar', (number | [number, number] | null)[], unknown>) => {
    if (!data?.labels?.length) {
      return null;
    }

    return (
      <Bar
        data={data}
        options={getOptionsBar({ showLegends: showLegends && showActivityByPhaseType, theme })}
      />
    );
  };

  return (
    <div className={styles.container}>
      {renderGraph(data)}
    </div>
  );
};
