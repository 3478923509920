import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Banner } from '../Settings/interfaces';
import { COMPANIES_URL } from '../../config/config.api';
import { axiosClient as axios, ApiClient } from '../../utils/axios_instance';
import { DEFAULT_DAYS_TO_INITIAL_STAGE, DEFAULT_DAY_MAX_GROW_OUT, DEFAULT_DAY_MAX_JUVENILE, DEFAULT_STAGE_MAX, stockingPhaseTypes } from '../../config/commons';
import { getUserSession } from '../../utils/userSession';

import { HeaderState, HeaderPayload, Breadcrumb, Company } from './interfaces';

const apiClient: ApiClient = new ApiClient(false);
const userSession = getUserSession();

const initialState: HeaderState = {
  title: '',
  subtitle: '',
  breadcrumb: [],
  showPagination: false,
  showFullScreenMenu: false,
  showTopBarAlert: false,
  showIconPdf: false,
  showIconSetting: {
    show: false,
    analysisId: '',
    analysisCode: '',
  },
  companies: [],
  company: {
    _id: '',
    name: '',
    maxStage: DEFAULT_STAGE_MAX,
    maxDayJuvenile: DEFAULT_DAY_MAX_JUVENILE,
    maxDayGrowOut: DEFAULT_DAY_MAX_GROW_OUT,
    allowXlsxAnalysisReport: false,
    isDistributor: false,
    isInternational: false,
    allowBeta: false,
    daysToInitialStage: DEFAULT_DAYS_TO_INITIAL_STAGE,
    assignedCompanies: [],
    weightUnit: '',
    currencyCode: '',
    showStockingParameterSection: false,
    showPOCSection: false,
  },
  phaseType: userSession.defaultPhase || stockingPhaseTypes.LARVAE,
  showPreviousAlert: false,
};

export const analysisSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setTitleHeader: (state: HeaderState, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setSubtitleHeader: (state: HeaderState, action: PayloadAction<string>) => {
      state.subtitle = action.payload;
    },
    setPagination: (state: HeaderState, action: PayloadAction<boolean>) => {
      state.showPagination = action.payload;
    },
    setShowIconPdf: (state: HeaderState, action: PayloadAction<boolean>) => {
      state.showIconPdf = action.payload;
    },
    setIconSetting: (state: HeaderState, action: PayloadAction<HeaderPayload>) => {
      state.showIconSetting.show = action.payload.showIconSetting.show;
      state.showIconSetting.analysisId = action.payload.showIconSetting.analysisId;
      state.showIconSetting.analysisCode = action.payload.showIconSetting.analysisCode;
    },
    setBreadcrumb: (state: HeaderState, action: PayloadAction<Breadcrumb[]>) => {
      state.breadcrumb = action.payload;
    },

    setShowTopBarAlert: (state: HeaderState, action: PayloadAction<boolean>) => {
      state.showTopBarAlert = action.payload;
    },
    setShowFullScreenMenu: (state: HeaderState, action: PayloadAction<boolean>) => {
      state.showFullScreenMenu = action.payload;
    },

    setCompanies: (state: HeaderState, action: PayloadAction<Company[]>) => {
      state.companies = action.payload;
    },
    setCompany: (state: HeaderState, action: PayloadAction<Company>) => {
      state.company = action.payload;
    },
    setPhaseType: (state: HeaderState, action: PayloadAction<string>) => {
      state.phaseType = action.payload;
    },
    setAlertMessage: (state: HeaderState, action: PayloadAction<Banner>) => {
      state.alertMessage = action.payload;
    },
    setShowPreviousAlert: (state: HeaderState, action: PayloadAction<boolean>) => {
      state.showPreviousAlert = action.payload;
    },
  },
});

export const {
  setTitleHeader,
  setSubtitleHeader,
  setBreadcrumb,
  setPagination,
  setShowIconPdf,
  setIconSetting,
  setShowTopBarAlert,
  setShowFullScreenMenu,
  setCompanies,
  setCompany,
  setPhaseType,
  setAlertMessage,
  setShowPreviousAlert,
} = analysisSlice.actions;

export const changeHeader = (params: { title: string; subtitle?: string }) => (dispatch: Function) => {
  const { title, subtitle } = params;
  dispatch(setTitleHeader(title));
  dispatch(setSubtitleHeader(subtitle ?? ''));
};

export const showPagination = (params: { show: boolean }) => (dispatch: Function) => {
  const { show } = params;
  dispatch(setPagination(show));
};

export const showIconPdf = (params: { show: boolean }) => (dispatch: Function) => {
  const { show } = params;
  dispatch(setShowIconPdf(show));
};

export const showIconSetting = (params: { show: boolean; analysisId: string; analysisCode: string }) => (dispatch: Function) => {
  const { show, analysisId, analysisCode } = params;
  const showSetting = { showIconSetting: { show: show, analysisId: analysisId, analysisCode: analysisCode } };
  dispatch(setIconSetting(showSetting));
};

export const resetHeader = () => (dispatch: Function) => {
  dispatch(setPagination(false));
  dispatch(setShowIconPdf(false));
  dispatch(setIconSetting(initialState));
};

export const changeBreadcrumb = (breadcrumb: Breadcrumb[]) => (dispatch: Function) => {
  dispatch(setBreadcrumb(breadcrumb));
};

export const fetchCompanies = () => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    active: true,
    '$sort[name]': 1,
  };

  try {
    const response = await axios.get<Company[]>(COMPANIES_URL, { params });
    dispatch(setCompanies(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchCompany = (companyId: string, accessToken?: string) => async (dispatch: Function) => {
  const url = `${COMPANIES_URL}/${companyId}`;

  try {
    if (accessToken) {
      const response = await apiClient.axios.get(url, {
        headers: { 'Authorization': accessToken },
      });

      dispatch(setCompany(response.data));
      return;
    }

    const response = await axios.get(url);
    dispatch(setCompany(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export default analysisSlice.reducer;
