import { useEffect } from 'react';
import { Dropdown, Menu } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import { commonOptions } from '../../../config/commons';
import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import { LrvTag } from '../../../common/components/LrvTag/LrvTag';
import IconButton from '../../../common/components/buttons/IconButton';
import { LrvTable } from '../../../common/components/LrvTable/LrvTable';
import { changeBreadcrumb, changeHeader } from '../../AppHeader/headerSlice';

import { Packer } from './interfaces';
import styles from './Packers.module.scss';
import * as packerSlice from './packerSlice';
import { NewPackerForm } from './NewPackerForm';
import { EditPackerForm } from './EditPackerForm';
import { PackerSubHeader } from './PackerSubHeader';

interface Props {
  theme?: 'dark' | 'light';
}

export const Packers = (props: Props) => {
  const { theme = getCurrentTheme() } = props;

  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    isLoading,
    packers,
    limit,
    total,
    currentPage,
  } = useSelector((state: Store) => state.packers);
  const { company } = useSelector((state: Store) => state.header);

  useEffect(() => {
    dispatch(changeHeader({ title: 'packers.title' }));
    dispatch(changeBreadcrumb([{ id: 'packers', name: t('packers.title'), url: '/company/packers' }]));
  }, [dispatch]);

  useEffect(() => {
    if (!company?._id) {
      return;
    }

    dispatch(packerSlice.fetchPackers({ companyId: company._id, page: 0 }));
  }, [dispatch, company._id]);

  const menuItemsOptions = (record: Packer) => {
    return (
      <Menu
        onClick={({ key, domEvent }) => {
          domEvent.stopPropagation();

          switch (key) {
            case commonOptions.EDIT:
              if (!record._id) {
                return;
              }

              dispatch(packerSlice.setShowUpdatePackerModal(true));
              dispatch(packerSlice.fetchPacker({ packerId: record._id }));
              return;
          }
        }}
      >
        <Menu.Item id='menu_option_edit' key={commonOptions.EDIT} className={styles.menuItemOptions}>
          <Icon name='edit' theme={theme} className={styles.icon} />
          <span>{t('stockings.edit')}</span>
        </Menu.Item>
      </Menu>
    );
  };

  const renderStatusTag = (record: Packer) => {
    if (record.active) {
      // if return null, table will display an "-" in the empty cell
      return <div></div>;
    }

    const id = `tag_${record._id}`;
    return (
      <LrvTag
        id={id}
        type='info'
        className={styles.statusTag}
      >
        {t('packers.inactive')}
      </LrvTag>
    );
  };

  const onChangePage = (page: number) => {
    dispatch(packerSlice.fetchPackers({ companyId: company._id, page }));
    dispatch(packerSlice.setCurrentPage(page));
  };

  const columns: ColumnsType<Packer> = [
    {
      key: 1,
      title: t('packers.name'),
      width: '55%',
      dataIndex: 'name',
    },
    {
      key: 2,
      dataIndex: 'active',
      width: '40%',
      render: (_, record: Packer) => {
        return renderStatusTag(record);
      },
    },
    {
      key: 3,
      width: '5%',
      render: (_, record: Packer) => {
        return (
          <Dropdown
            overlay={() => menuItemsOptions(record)}
            trigger={['click']}
            placement='bottomRight'
          >
            <IconButton
              onClick={(e) => e.stopPropagation()}
              iconName='more-2'
            />
          </Dropdown>
        );
      },
    }
  ];

  return (
    <div className={styles.container}>
      <PackerSubHeader />

      <LrvTable
        columns={columns}
        dataSource={packers}
        loading={isLoading}
        scroll={{ y: '' }}
        theme={theme}
        size='small'
        pagination={{
          size: 'default',
          showSizeChanger: false,
          defaultPageSize: limit,
          pageSize: limit,
          onChange: onChangePage,
          total: total,
          current: currentPage,
        }}
        onRow={(record: Packer) => {
          return {
            onClick: (e) => {
              e.stopPropagation(); // This avoids click confusion when extraAction buttons are clicked
              history.push('/company/packers/' + record._id);
            },
          };
        }}
      />
      
      <NewPackerForm theme='light' />
      <EditPackerForm theme='light' />
    </div>
  );
};
