import i18next from 'i18next';
import { groupBy } from 'lodash';
import seedrandom from 'seedrandom';

import { Analysis } from '../Analysis/interfaces';
import { CommercialSizeRange } from '../Company/Packers/interfaces';
import { convertKilogramsToPounds } from '../../helpers/stocking.helpers';
import { getCurrentElementHeight, getCurrentElementWidth } from '../../utils/dimensions';
import { calcYieldPorcentageTail, calcYieldPorcentageWaste } from '../../helpers/commercial-size-price-table';
import { commercialSizeTypes, DEFAULT_STAGE_MAX, powerUnits, roundFourDecimals, weightUnits, weightUnitsByCompany } from '../../config/commons';
import { EXTRA_VALUE_AVERAGE_WEGIHT, generateCommercialSizeRanges, getClickableMinValue, getMaxValueDisplay, MIN_VALUE_DISPLAY_GROW_OUT, processCommercialSizeRanges } from '../../helpers/commercial-size.helpers';

import { AerationPower, BuildAndGetFirstPredictionParams, BuildGrowthSizesParams, CalcDataSourceParams, ChartParameter, CheckValidInputsProps, CommercialSizeData, Dataset, DataSourceByStage, GrowOutSize, HistogramData, PackersWithCommercialSizes, PocByPacker, Point, PredictionPoint, ProfitType } from './interfaces';

export const DAYS_OF_THE_WEEK = 7;
export const BEST_PACKER_COUNT = 3;

export const packerColors: string[] = ['#48AD4D', '#3F9AE9', '#FB8C00'];

export const defaultGrayColor = '#BDBDBD';

interface HeightProps {
  refStockingContainer: React.RefObject<HTMLDivElement>;
  refIndicatorContainer: React.RefObject<HTMLDivElement>;
  refMiniChartsContainer: React.RefObject<HTMLDivElement>;
  refOptions: React.RefObject<HTMLDivElement>;
}

interface WidthProps {
  inputsContainer?: React.RefObject<HTMLDivElement>;
  refPackerContainer?: React.RefObject<HTMLDivElement>;
}

export const getHeightOfTheOtherElements = (props: HeightProps) => {
  const { refStockingContainer, refIndicatorContainer, refMiniChartsContainer, refOptions } = props;

  const headerHeight = 64;
  const extraHeight = 72;

  const value = headerHeight + extraHeight + getCurrentElementHeight(refStockingContainer) + getCurrentElementHeight(refIndicatorContainer) + getCurrentElementHeight(refMiniChartsContainer) + getCurrentElementHeight(refOptions);
  return value;
};

export const getWidthOfTheOtherElements = (props: WidthProps) => {
  const { inputsContainer, refPackerContainer } = props;
  const inputsContainerWidth = inputsContainer ? getCurrentElementWidth(inputsContainer) : 0;
  const packerContainerWidth = refPackerContainer ? getCurrentElementWidth(refPackerContainer) : 0;
  
  const extraWidth = 90;
  let sidebarWidth = 0;

  if (window.innerWidth > 950) {
    sidebarWidth = 80;
  }

  return sidebarWidth + extraWidth + inputsContainerWidth + packerContainerWidth;
};

export const getNumberTicks = (props: { firstStage: number; lastStage: number; isExcluding?: boolean; }) => {
  const { firstStage, lastStage, isExcluding } = props;

  if (!isExcluding) {
    const stagesDiff = (lastStage - firstStage) / 2;
    return stagesDiff < DEFAULT_STAGE_MAX ? stagesDiff : 16;
  }

  const stagesDiff = (lastStage - firstStage);
  return stagesDiff < DEFAULT_STAGE_MAX ? stagesDiff : 16;
};

function randomNormal (rng: seedrandom.PRNG, weightPredicted: number, sigma: number) {
  const u1 = rng();
  const u2 = rng();
  const z0 = Math.sqrt(-2.0 * Math.log(u1)) * Math.cos(2.0 * Math.PI * u2); // Transformed from Box-Muller
  return (z0 * sigma) + weightPredicted;
}

export const getRandomWeights = (params: { uniformity: number; averageWeight: number; size?: number }) => {
  const { size = 150, uniformity, averageWeight } = params;
  const rng = seedrandom('1');
  
  const cv = 100 - uniformity;
  const sigma = (cv / 100) * averageWeight;

  return Array.from({ length: size }, () => randomNormal(rng, averageWeight, sigma));
};

/* eslint-disable max-depth*/
export const classifyGrowOutSizes = (ranges: CommercialSizeRange[], individualWeights: number[]) => {
  const constantToConvert = 1000;
  const length = ranges.length + 1;
  const values: number[] = Array(length).fill(0);

  for (const weight of individualWeights) {
    const convertedWeight = weight / constantToConvert;
    let rangeIndex = -1;

    for (const item of ranges) {
      const { averageMinWeight, averageMaxWeight } = item;
      rangeIndex++;

      if (rangeIndex === 0 && convertedWeight >= averageMinWeight) {
        values[rangeIndex]++;
        break;
      }

      if (rangeIndex === ranges.length - 1 && convertedWeight < (averageMaxWeight + EXTRA_VALUE_AVERAGE_WEGIHT)) {
        values[rangeIndex]++;
        break;
      }

      if (convertedWeight >= averageMinWeight && convertedWeight < (averageMaxWeight + EXTRA_VALUE_AVERAGE_WEGIHT)) {
        values[rangeIndex]++;
        break;
      }
    }
  }

  return values;
};

const getDataSourceByStagePoint = (params: { analysesByStage: Analysis[]; }) => {
  let { analysesByStage } = params;
  const stage = analysesByStage[0].inputData.stage;

  if (analysesByStage.length > 1) {
    analysesByStage = analysesByStage.sort((a, b) => b.resultData.averageWeight - a.resultData.averageWeight);
  }

  const points: Point[] = [];
  let totalWeight = 0;
  let analysisCount = 0;

  for (const analysis of analysesByStage) {
    const { _id, code, createdAt, inputData, resultData, excludedFromPrediction } = analysis;
    const { uniformity, averageWeight } = resultData;
    const y = roundFourDecimals(averageWeight / 1000);
    const point: Point = { _id, code, createdAt, x: inputData.stage, y, uniformity, excludedFromPrediction } as Point;
    points.push(point);
    totalWeight += excludedFromPrediction ? 0 : averageWeight;
    analysisCount += excludedFromPrediction ? 0 : 1;
  }

  // because divide any number to 0, gives an error
  const meanWeight = (analysisCount === 0) ? 0 : roundFourDecimals((totalWeight / analysisCount) / 1000);

  const dataSourceByStagePoint: DataSourceByStage = { x: stage, y: meanWeight, points };
  return dataSourceByStagePoint;
};

const generateDataset = (props: {growOutSize: GrowOutSize; commercialSizeType: string; animals: number; totalBiomass: number; yieldPercentaje: number; commercialSizeRanges: CommercialSizeRange[] }) => {
  const { growOutSize, commercialSizeType, totalBiomass, animals, yieldPercentaje, commercialSizeRanges } = props;

  const dataset: Dataset[] = [];
  let incomeByYield = 0;

  for (let index = 0; index < growOutSize.values.length - 1; index++) {
    const frequency = growOutSize.values[index];
    const label = growOutSize.labels[index];
    const sizePrice = growOutSize.prices[index];
    
    const percent = roundFourDecimals((frequency * 100) / animals);
    const biomass = roundFourDecimals(totalBiomass * percent / 100);
    const biomassLb = roundFourDecimals(convertKilogramsToPounds(biomass, false));
  
    let pricePerWeightUnit = 0;
    if (commercialSizeType === commercialSizeTypes.GROW_OUT_WHOLE) {
      pricePerWeightUnit = (biomass * yieldPercentaje / 100) * sizePrice;
    }
  
    if (commercialSizeType === commercialSizeTypes.GROW_OUT_TAIL) {
      pricePerWeightUnit = (biomassLb * yieldPercentaje / 100) * sizePrice;
    }
  
    incomeByYield += pricePerWeightUnit;
  
    dataset.push({
      index,
      frequency,
      label,
      percent,
      biomass,
      biomassLb,
      sizePrice,
      pricePerWeightUnit,
      commercialSizeRanges,
    });
  }

  return {
    dataset,
    incomeByYield,
    commercialSizeType,
  };
};

const processGrowthSize = (props: {type: string; yieldPercent: number; params: BuildGrowthSizesParams}) => {
  const { type, yieldPercent, params } = props;

  if (yieldPercent === 0) {
    return null;
  }

  const { biomass: totalBiomass, randomWeights, animals, packerInfo } = params;
  
  const weightUnit =
    type === commercialSizeTypes.GROW_OUT_WHOLE
      ? weightUnitsByCompany.KILOGRAM
      : weightUnitsByCompany.POUND;

  const maxValueDisplay = getMaxValueDisplay({ commercialSizeType: type });
  const commercialSizes =
    type === commercialSizeTypes.GROW_OUT_WHOLE
      ? packerInfo.commercialSizes.whole
      : packerInfo.commercialSizes.tail;

  const commercialSizeRanges = generateCommercialSizeRanges({
    commercialSizes,
    minValueDisplay: MIN_VALUE_DISPLAY_GROW_OUT,
    maxValueDisplay,
    weightUnit,
  });

  const values = classifyGrowOutSizes(commercialSizeRanges, randomWeights);
  const processedDataSource = processCommercialSizeRanges({
    ranges: commercialSizeRanges,
    maxValueDisplay,
    commercialSizeType: type,
    clickableMinValue: getClickableMinValue({ commercialSizeType: type }),
  });

  const labels = processedDataSource.map((item) => item.commercialSizeLabel);
  const prices = type === commercialSizeTypes.GROW_OUT_WHOLE ? packerInfo.prices.whole : packerInfo.prices.tail;

  const dataset = generateDataset({
    animals,
    commercialSizeRanges,
    commercialSizeType: type,
    totalBiomass,
    yieldPercentaje: yieldPercent,
    growOutSize: { labels, values, prices: prices || [] },
  });

  return dataset;
};

const buildGrowthSizes = (params: BuildGrowthSizesParams) => {
  const { biomass: totalBiomass, randomWeights, packerInfo } = params;
  const datasets: Record<string, { dataset: Dataset[]; incomeByYield: number; }> = {};

  const wholeYieldPercent = packerInfo.yield?.whole || 0;
  const tailYieldPercentOriginal = packerInfo.yield?.tail || 0;
  let tailYieldPercent = tailYieldPercentOriginal;

  if (tailYieldPercent !== 0) {
    tailYieldPercent = calcYieldPorcentageTail({
      whole: Number(wholeYieldPercent),
      tail: Number(tailYieldPercent),
    });
  }

  const processedData = [
    processGrowthSize({ type: commercialSizeTypes.GROW_OUT_WHOLE, yieldPercent: wholeYieldPercent, params }),
    processGrowthSize({ type: commercialSizeTypes.GROW_OUT_TAIL, yieldPercent: tailYieldPercent, params }),
  ].filter(Boolean) as { dataset: Dataset[]; incomeByYield: number; commercialSizeType: string; }[];

  processedData.forEach((data) => {
    datasets[data.commercialSizeType] = data;
  });

  const wastePrice = packerInfo.waste?.price || 0;
  const wastePercent = calcYieldPorcentageWaste({ whole: Number(wholeYieldPercent), tail: Number(tailYieldPercentOriginal) });

  const incomeByWaste = (totalBiomass * wastePercent / 100) * wastePrice;
  const incomeByYield = processedData.reduce((acc, data) => acc + data.incomeByYield, 0) + incomeByWaste;
  const potentialIncome = roundFourDecimals(incomeByYield);

  const histogram: HistogramData = {
    whole: datasets[commercialSizeTypes.GROW_OUT_WHOLE]?.dataset,
    tail: datasets[commercialSizeTypes.GROW_OUT_TAIL]?.dataset,
    income: {
      whole: datasets[commercialSizeTypes.GROW_OUT_WHOLE]?.incomeByYield,
      tail: datasets[commercialSizeTypes.GROW_OUT_TAIL]?.incomeByYield,
      waste: incomeByWaste,
    },
    yield: {
      whole: wholeYieldPercent,
      tail: tailYieldPercentOriginal,
    },
  };

  return { histogram, potentialIncome, randomWeights };
};

const buildAndGetFirstPrediction = (params: BuildAndGetFirstPredictionParams) => {
  const {
    dataSourceByStage, initialPopulation, survival, dailyFeeding,
    volume, packersWithCommercialSizes, interestRate, profitType, dailyRation,
    costPerHp, linearBiomassCoefficient, accumulatedCost: balancedAccumulatedCost,
  } = params;

  const biomass = roundFourDecimals(initialPopulation * dataSourceByStage.y / 1000);
  const biomassLb = roundFourDecimals(convertKilogramsToPounds(biomass, false));
  const points: Point[] = dataSourceByStage?.points ? dataSourceByStage.points as Point[] : [];
  const noExcludedPoints = points.filter(item => 'excludedFromPrediction' in item && !item.excludedFromPrediction);
  const uniformitySum = noExcludedPoints.reduce((sum, point) => sum + point.uniformity, 0);
  const uniformity = roundFourDecimals(uniformitySum / noExcludedPoints.length);
  const firstPoint = dataSourceByStage?.points?.[0];
  const predictionDate = firstPoint && 'createdAt' in firstPoint ? firstPoint.createdAt : '';
  const modelFoodQuantity = roundFourDecimals(Math.pow(dailyRation.scaleFactor * dataSourceByStage.y, dailyRation.slope) * biomass);

  const aeration = calcAeration({ biomass, linearBiomassCoefficient });
  const totalAccumulatedCost = balancedAccumulatedCost;

  const animals = 150;
  const randomWeights = getRandomWeights({ uniformity, averageWeight: (dataSourceByStage.y * 1000), size: animals });

  const commercialSizes = generateCommercialSizeData({ packersWithCommercialSizes, totalAccumulatedCost, biomass, animals, pocByPackers: [], volume, stage: dataSourceByStage.x, randomWeights, interestRate, profitType });
  const pocByPackers: PocByPacker[] = commercialSizes.pocByPackers;
  const commercialSizeData: CommercialSizeData[] = commercialSizes.commercialSizeData;

  const firstPrediction: PredictionPoint = {
    x: dataSourceByStage.x,
    y: dataSourceByStage.y,
    predictionDate,
    uniformity,
    biomass,
    biomassLb,
    pondLoadCapacity: biomass / volume,
    costPerDayKg: totalAccumulatedCost / biomass,
    costPerDayLb: totalAccumulatedCost / biomassLb,
    population: initialPopulation,
    survival,
    correctedFoodQuantity: dailyFeeding ? roundFourDecimals(dailyFeeding) : modelFoodQuantity,
    modelFoodQuantity,
    accumulatedCost: 0,
    balancedAccumulatedCost,
    totalAccumulatedCost,
    commercialSizeData,
    aeration,
    costAccumulatedAeration: costPerHp * aeration,
  };
  return { firstPrediction, pocByPackers };
};

const generateCommercialSizeData = (props: {packersWithCommercialSizes: PackersWithCommercialSizes[]; stage: number; randomWeights: number[]; totalAccumulatedCost: number; volume: number; pocByPackers: PocByPacker[]; animals: number; biomass: number; interestRate: number; profitType: ProfitType; }) => {
  const { packersWithCommercialSizes, stage, randomWeights, totalAccumulatedCost, volume, biomass, animals, pocByPackers, interestRate, profitType } = props;

  const commercialSizeData: CommercialSizeData[] = [];
  const initializePocByPacker = pocByPackers.length === 0;

  for (let index = 0; index < packersWithCommercialSizes.length; index++) {
    const packerInfo = packersWithCommercialSizes[index];
    const pocByPacker = pocByPackers[index];
    const { packerId, packerName, paymentDelayDays } = packerInfo;
    const { potentialIncome, histogram } = buildGrowthSizes({ randomWeights, biomass, animals, packerInfo });
    const potentialGain = (potentialIncome - totalAccumulatedCost);
    const potentialGainByDayHectarea = (potentialIncome - totalAccumulatedCost) / volume / stage;
   
    const potentialGainPresentValue = calculatePresentValue({ futureValue: potentialGain, annualRate: interestRate / 100, days: paymentDelayDays });
    const potentialGainByDayHectareaPresentValue = calculatePresentValue({ futureValue: potentialGainByDayHectarea, annualRate: interestRate / 100, days: paymentDelayDays });

    commercialSizeData.push({
      x: stage,
      packerId,
      packerName,
      histogram,
      potentialIncome,
      netValue: {
        potentialGain,
        potentialGainByDayHectarea,
      },
      presentValue: {
        potentialGain: potentialGainPresentValue,
        potentialGainByDayHectarea: potentialGainByDayHectareaPresentValue,
      },
      color: defaultGrayColor,
    });

    const poc = profitType === ProfitType.PROFIT_PER_DAY ? potentialGainByDayHectareaPresentValue : potentialGainPresentValue;

    if (initializePocByPacker) {
      pocByPackers.push({
        packerId,
        packerName,
        x: stage,
        poc,
        color: defaultGrayColor,
      });

      continue;
    }

    const prevPoc = pocByPacker.poc;
    if (poc > prevPoc) {
      pocByPackers[index].poc = poc;
      pocByPackers[index].x = stage;
    }
  }

  return {
    commercialSizeData,
    pocByPackers
  };
};

const calcAeration = (props: AerationPower) => {
  const { linearBiomassCoefficient, biomass } = props;
  return (biomass / linearBiomassCoefficient);
};

export const calcDataSource = (params: CalcDataSourceParams) => {
  const {
    dataSource, packersWithCommercialSizes,
    dailyFeeding, costPerVolumeDay, initialPopulation, volume,
    harvestsAndTransfers, mortality, animalsSown, foodPricePerKg,
    interestRate, profitType, dailyRation,
    linearBiomassCoefficient, costPerHp,
  } = params;

  const dataSourceByStage: DataSourceByStage[] = [];
  const allPredictions: PredictionPoint[] = [];
  const allPoints: (Point | PredictionPoint)[] = [];

  if (dataSource.allAnalysis.length === 0 || dataSource.predictions.length === 0) {
    return { dataSourceByStage, allPredictions, allPoints, pocByPackers: [] };
  }

  if (dataSource.allAnalysis.length === 0) {
    return { dataSourceByStage, allPredictions, allPoints, pocByPackers: [] };
  }

  const analysesToGroup = dataSource.allAnalysis;
  const analysisGroupByStage = groupBy(analysesToGroup, 'inputData.stage');

  for (const key in analysisGroupByStage) {
    if (Object.prototype.hasOwnProperty.call(analysisGroupByStage, key)) {
      const dataSourceByStagePoint = getDataSourceByStagePoint({ analysesByStage: analysisGroupByStage[key] });
      dataSourceByStage.push(dataSourceByStagePoint);
    }
  }

  const animals = 150;
  const filteredDataSourceByStage = dataSourceByStage.filter((item) => (item.points as Point[]).some((point) => !point?.excludedFromPrediction));
  const lastItem = filteredDataSourceByStage[filteredDataSourceByStage.length - 1];

  const firstPredictionResult = buildAndGetFirstPrediction({
    packersWithCommercialSizes,
    accumulatedCost: params.accumulatedCost,
    dailyFeeding,
    dataSourceByStage: lastItem,
    initialPopulation,
    survival: params.survival,
    volume,
    interestRate,
    profitType,
    dailyRation,
    linearBiomassCoefficient,
    costPerHp,
  });
  
  const { firstPrediction } = firstPredictionResult;
  let pocByPackers: PocByPacker[] = firstPredictionResult.pocByPackers;
  const firstPredictionStage = firstPrediction.x;
  const firstPredictionWeight = firstPrediction.y;
  const firstPredictionUniformity = firstPrediction.uniformity;
  allPredictions.push(firstPrediction);

  const lastPrediction = dataSource.predictions[dataSource.predictions.length - 1];
  const lastPredictionWeight = lastPrediction.resultData.averageWeight / 1000;
  const lastPredictionUniformity = lastPrediction.resultData.uniformity;
  const lastPredictionStage = lastPrediction.inputData.stage;

  let dataSourceIndex = 0;
  let prevPrediction = firstPrediction;
  let stage = firstPredictionStage + 1;
  let prevPredictionAverageWeight = firstPredictionWeight;
  let nextPredictionAverageWeight = dataSource.predictions[dataSourceIndex].resultData.averageWeight / 1000;
  let index = 1;

  while (stage < lastPredictionStage) {
    const { y: prevAverageWeight, uniformity: prevUniformity, predictionDate: prevPredictionDate } = prevPrediction;
    const prevPopulation = prevPrediction.population as number;
    const prevCorrectedFoodQuantity = prevPrediction.correctedFoodQuantity as number;
    const prevModelFoodQuantity = prevPrediction.modelFoodQuantity as number;
    const prevBalancedAccumulatedCost = prevPrediction.balancedAccumulatedCost as number;
    const prevCostAccumulatedAeration = prevPrediction.costAccumulatedAeration as number;

    let averageWeight: number;
    if (index % DAYS_OF_THE_WEEK === 0) {
      dataSourceIndex += 1;
      averageWeight = nextPredictionAverageWeight;
      prevPredictionAverageWeight = nextPredictionAverageWeight;
      nextPredictionAverageWeight = dataSource.predictions[dataSourceIndex].resultData.averageWeight / 1000;
    } else {
      averageWeight = roundFourDecimals(prevAverageWeight + ((nextPredictionAverageWeight - prevPredictionAverageWeight) / DAYS_OF_THE_WEEK));
    }
  
    const uniformity = roundFourDecimals(prevUniformity + ((lastPredictionUniformity - firstPredictionUniformity) / (lastPredictionStage - firstPredictionStage)));
    const population = Math.round(prevPopulation - (prevPopulation * mortality / (DAYS_OF_THE_WEEK * 100)));
    const biomass = roundFourDecimals(population * averageWeight / 1000);
    const biomassLb = roundFourDecimals(convertKilogramsToPounds(biomass, false));
    const survival = roundFourDecimals((population + harvestsAndTransfers) / animalsSown * 100);
    const modelFoodQuantity = roundFourDecimals(Math.pow(dailyRation.scaleFactor * averageWeight, dailyRation.slope) * biomass);
    const correctedFoodQuantity = roundFourDecimals(prevCorrectedFoodQuantity * modelFoodQuantity / prevModelFoodQuantity);
    const accumulatedCost = costPerVolumeDay * (stage - firstPredictionStage) * volume;
    const balancedAccumulatedCost = prevBalancedAccumulatedCost + (correctedFoodQuantity * foodPricePerKg);
    
    const aeration = calcAeration({ biomass, linearBiomassCoefficient });
    const costAeration = aeration * costPerHp;
    const costAccumulatedAeration = costAeration + prevCostAccumulatedAeration;
    const totalAccumulatedCost = accumulatedCost + balancedAccumulatedCost + costAccumulatedAeration;
    
    const randomWeights = getRandomWeights({ uniformity, averageWeight: (averageWeight * 1000), size: animals });

    const commercialSizes = generateCommercialSizeData({ packersWithCommercialSizes, totalAccumulatedCost, biomass, animals, pocByPackers, volume, stage, randomWeights, interestRate, profitType });
    const commercialSizeData: CommercialSizeData[] = commercialSizes.commercialSizeData;
    pocByPackers = commercialSizes.pocByPackers;

    const predictionDate = new Date(prevPredictionDate);
    predictionDate.setDate(predictionDate.getDate() + 1);
  
    const newPrediction: PredictionPoint = {
      x: stage,
      y: averageWeight,
      predictionDate: predictionDate.toISOString(),
      uniformity,
      biomass,
      biomassLb,
      pondLoadCapacity: biomass / volume,
      costPerDayKg: totalAccumulatedCost / biomass,
      costPerDayLb: totalAccumulatedCost / biomassLb,
      population,
      survival,
      modelFoodQuantity,
      correctedFoodQuantity,
      accumulatedCost,
      balancedAccumulatedCost,
      totalAccumulatedCost,
      commercialSizeData,
      aeration,
      costAccumulatedAeration,
    };

    const dataSourceByStagePoint: DataSourceByStage = { x: stage, y: averageWeight, points: [newPrediction] };
    dataSourceByStage.push(dataSourceByStagePoint);
    allPredictions.push(newPrediction);
    prevPrediction = newPrediction;
    stage += 1;
    index += 1;
  }

  const prevPopulation = prevPrediction.population as number;
  const prevCorrectedFoodQuantity = prevPrediction.correctedFoodQuantity as number;
  const prevModelFoodQuantity = prevPrediction.modelFoodQuantity as number;
  const prevBalancedAccumulatedCost = prevPrediction.balancedAccumulatedCost as number;
  const prevPredictionDate = prevPrediction.predictionDate;
  const prevCostAccumulatedAeration = prevPrediction.costAccumulatedAeration as number;

  const population = Math.round(prevPopulation - (prevPopulation * mortality / (DAYS_OF_THE_WEEK * 100)));
  const biomass = roundFourDecimals(population * lastPredictionWeight / 1000);
  const biomassLb = roundFourDecimals(convertKilogramsToPounds(biomass, false));
  const survival = roundFourDecimals((population + harvestsAndTransfers) / animalsSown * 100);
  const modelFoodQuantity = roundFourDecimals(Math.pow(dailyRation.scaleFactor * lastPredictionWeight, dailyRation.slope) * biomass);
  const correctedFoodQuantity = prevCorrectedFoodQuantity * modelFoodQuantity / prevModelFoodQuantity;
  const accumulatedCost = costPerVolumeDay * (stage - firstPredictionStage) * volume;
  const balancedAccumulatedCost = prevBalancedAccumulatedCost + (correctedFoodQuantity * foodPricePerKg);

  const aeration = calcAeration({ biomass, linearBiomassCoefficient });
  const costAccumulatedAeration = (aeration * costPerHp) + prevCostAccumulatedAeration;
  const totalAccumulatedCost = accumulatedCost + balancedAccumulatedCost + costAccumulatedAeration;
  const predictionDate = new Date(prevPredictionDate);
  predictionDate.setDate(predictionDate.getDate() + 1);

  const randomWeights = getRandomWeights({ uniformity: lastPredictionUniformity, averageWeight: (lastPredictionWeight * 1000), size: animals });

  const commercialSizes = generateCommercialSizeData({ packersWithCommercialSizes, totalAccumulatedCost, biomass, animals, pocByPackers, volume, stage, randomWeights, interestRate, profitType });
  const commercialSizeData: CommercialSizeData[] = commercialSizes.commercialSizeData;
  pocByPackers = commercialSizes.pocByPackers;

  const newPrediction: PredictionPoint = {
    x: stage,
    y: lastPredictionWeight,
    predictionDate: predictionDate.toISOString(),
    uniformity: lastPredictionUniformity,
    biomass,
    biomassLb,
    pondLoadCapacity: biomass / volume,
    costPerDayKg: totalAccumulatedCost / biomass,
    costPerDayLb: totalAccumulatedCost / biomassLb,
    population,
    survival,
    modelFoodQuantity,
    correctedFoodQuantity,
    accumulatedCost,
    balancedAccumulatedCost,
    totalAccumulatedCost,
    commercialSizeData,
    aeration,
    costAccumulatedAeration,
  };
  
  const dataSourceByStagePoint: DataSourceByStage = { x: stage, y: lastPredictionWeight, points: [newPrediction] };
  dataSourceByStage.push(dataSourceByStagePoint);
  allPredictions.push(newPrediction);

  for (const item of dataSourceByStage) {
    const { points } = item;
    if (!points || points.length === 0) {
      continue;
    }
    
    for (const point of points) {
      allPoints.push(point);
    }
  }

  return { dataSourceByStage, allPredictions, allPoints, pocByPackers };
};

export const checkValidInputs = (props: CheckValidInputsProps) => {
  const { loadCapacity, survival, mortality, foodPricePerKg, costPerVolumeDay, accumulatedCost } = props;
  
  return (
    mortality > 0 && foodPricePerKg > 0 && costPerVolumeDay > 0 &&
    accumulatedCost > 0 && survival > 0 && loadCapacity > 0
  );
};

export const getLabelsAxisY = (props: { chartParameter?: string; weightUnit: string; currencySymbol: string; profitType?: ProfitType }) => {
  const { chartParameter, weightUnit, currencySymbol, profitType } = props;

  switch (chartParameter) {
    case ChartParameter.WEIGHT:
      return `${i18next.t('optimalHarvestPoint.averageWeight')} ${weightUnits.G}`;

    case ChartParameter.BIOMASS:
    case ChartParameter.BIOMASS_KG:
    case ChartParameter.BIOMASS_LB:
      if (weightUnit === weightUnitsByCompany.POUND) {
        return `${i18next.t('optimalHarvestPoint.biomass')} (${weightUnits.LB})`;
      }

      return `${i18next.t('optimalHarvestPoint.biomass')} (${weightUnits.KG})`;

    case ChartParameter.AERATION:
      return `${i18next.t('optimalHarvestPoint.aeration')} (${powerUnits.HP}/h)`;

    case ChartParameter.CORRECTED_FOOD:
      return `${i18next.t('optimalHarvestPoint.food')}`;
      
    case ChartParameter.POC: {
      if (profitType === ProfitType.PROFIT_TOTAL) {
        return `${i18next.t('optimalHarvestPoint.profit')} (${currencySymbol})`;
      }

      return `${i18next.t('optimalHarvestPoint.profitPerDay')} (${currencySymbol})`;
    }
      
    case ChartParameter.POTENTIAL_INCOME:
      return `${i18next.t('optimalHarvestPoint.potentialIncome')} (${currencySymbol})`;
      
    case ChartParameter.TOTAL_ACCUMULATED_COST:
      return `${i18next.t('optimalHarvestPoint.costs')} (${currencySymbol})`;

    default:
      return '';
  }
};

export const sortPocs = (pocByPackers: PocByPacker[]): PocByPacker[] => {
  return pocByPackers
    .sort((a, b) => b.poc - a.poc);
};

export const getTopNPocs = (pocByPackers: PocByPacker[], n: number): PocByPacker[] => {
  return pocByPackers
    .slice(0, n);
};

export const calculatePresentValue = (props: {futureValue: number, annualRate: number, days: number;}): number => {
  const { futureValue, annualRate, days } = props;
  
  if (annualRate < 0 || days < 0) {
    return 0;
  }

  const dailyRate = Math.pow(1 + annualRate, 1 / 365) - 1;
  const presentValue = futureValue / Math.pow(1 + dailyRate, days);
  return presentValue;
};

interface OptimalHarvestPoint {
  loadCapacity: number | string | null | undefined;
  survival: number | string | null | undefined;
  mortality: number | string | null | undefined;
  feedingStrategy: number;
  weeklyFeeding: number | string | null | undefined;
  foodPricePerKg: number | string | null | undefined;
  costPerVolumeDay: number | string | null | undefined;
  accumulatedCost: number | string | null | undefined;
}

export const getPOCFiltersInLocalStorage = () => {
  const value = localStorage.getItem('poc') || '';
  const optimalHarvestPoint: OptimalHarvestPoint = value ? JSON.parse(value) : {};

  return optimalHarvestPoint;
};

export const injectPackerColor = (props: {bestPackers: PocByPacker[]; colors: string[];}) => {
  const { bestPackers, colors } = props;

  for (let index = 0; index < bestPackers.length; index++) {
    const packer = bestPackers[index];
    packer.color = colors[index];
  }

  return bestPackers;
};

export const generateUniqueRandomNumbers = (prevNumbers: number[] = []): number[] => {
  const numbers = [0, 1, 2];
  let newNumbers;

  do {
    newNumbers = [...numbers].sort(() => Math.random() - 0.5);
  } while (!isValidNewSequence(newNumbers, prevNumbers));

  localStorage.setItem('randomNumbers', JSON.stringify(newNumbers));
  return newNumbers;
};

const isValidNewSequence = (newNumbers: number[], prevNumbers: number[]): boolean => {
  if (prevNumbers.length === 0) {
    return true;
  }

  return newNumbers.every((num, index) => num !== prevNumbers[index]);
};

export const getModalWidth = () => {
  const hasMaxSize = window.innerWidth > 1160;
  const hasMinSize = window.innerWidth > 860;
  
  const modalWidth = hasMaxSize ? 1120 : hasMinSize ? 820 : 520;
  const graphWidth = hasMaxSize ? 1072 : hasMinSize ? 772 : 472;
  const graphHeight = 500;
  
  return {
    modalWidth,
    hasMinSize,
    graphWidth,
    graphHeight,
  };
};
