import cx from 'classnames';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { useState, useEffect } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InboxOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Select, Tabs, Row, Col, Space } from 'antd';

import { Store } from '../../state/store.interfaces';
import { useDragger } from '../../hooks/useDragger';
import Icon from '../../common/components/Icon/Icon';
import { MaturationCode } from '../Clients/interfaces';
import { filterOptionSelect } from '../../utils/select';
import { getUserSession } from '../../utils/userSession';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { LrvTabs } from '../../common/components/LrvTabs/LrvTabs';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import TinyLabel from '../../common/components/TinyLabel/TinyLabel';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { getContainerVolumeLimits } from '../../helpers/tanks.helpers';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import ActionButton from '../../common/components/buttons/ActionButton';
import { LrvTooltip } from '../../common/components/LrvTooltip/LrvTooltip';
import { LrvDragger } from '../../common/components/LrvDragger/LrvDragger';
import { LrvCheckbox } from '../../common/components/LrvCheckbox/LrvCheckbox';
import { typeParam } from '../../common/components/charts/ShadedPlot/helpers';
import { openErrorNotification } from '../../common/notification/Notification';
import { isNumber, validateNumber, validateString } from '../../utils/validations';
import { LrvDatePicker } from '../../common/components/LrvDatePicker/LrvDatePicker';
import { LrvInputNumber } from '../../common/components/LrvInputNumber/LrvInputNumber';
import { applyParserThousandsSeparator, applyThousandsSeparator } from '../../utils/strings';
import { stockingPhaseTypes, containerTypes, unitStatuses, stockingTypes, getKeyValue, stockingCurrentTab, THEME, maximumNumberOfStockings, stockingStageOptions, MIN_DAYS_TO_INITIAL_STAGE, MAX_DAYS_TO_INITIAL_STAGE, DEFAULT_DAYS_TO_INITIAL_STAGE, weightUnits } from '../../config/commons';

import './Sowings.scss';
import styles from './Sowings.module.scss';
import { BindingStockingTable, ErrorLabelRow, NewBindingStockingButton } from './BindingStocking';
import { editStocking, setShowModalEdit, updateReferralGuide, setCurrentTabSetting, fetchMaturationCodes, fetchStockingByBindingCode, fetchReferenceCurves } from './sowingsSlice';
import { getBindingStockingByMaxAnimals, getBindingStockingData, getTotalAnimals, initialBindingStocking, isValidStockingBinding, MIN_STOCKING_WEIGHT, stockingBindingRowIsFull } from './sowings.helpers';
import { BindingStocking, CustomStage, FetchStockingProps, Stocking as ISowing, BindingStockingTable as IBindingStockingTable, UpdateLarvaeData, UpdateJuvenileData, UpdateGrowOutData } from './interfaces';

interface Props {
  theme?: 'dark' | 'light';
}

function EditStockingModal (props: Props) {
  const { theme = 'dark' } = props;

  const { Option } = Select;
  const { TabPane } = Tabs;
  const [formEditStocking] = Form.useForm();

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const history = useHistory();
  const pathname = history.location.pathname;

  const [editStockingName, setEditStockingName] = useState('');
  const [editStockingType, setEditStockingType] = useState<string>('');
  const [editStockingStartDate, setEditStockingStartDate] = useState('');
  const [editStockingStartDateJuvenile, setEditStockingStartDateJuvenile] = useState('');
  const [editStockingStartDateGrowOut, setEditStockingStartDateGrowOut] = useState('');
  const [editStockingMaturation, setEditStockingMaturation] = useState({ id: '', name: '' });
  const [editStockingMaturationCode, setEditStockingMaturationCode] = useState<string | undefined>('');
  const [editStockingNumberNauplii, setEditStockingNumberNauplii] = useState<number | string>(0);
  const [editStockingNumberJuvenile, setEditStockingNumberJuvenile] = useState<number | string>(0);
  const [editStockingNumberGrowOut, setEditStockingNumberGrowOut] = useState<number | string>(0);
  const [editStockingLiters, setEditStockingLiters] = useState<number | string>(0);
  const [editStockingCubicMeters, setEditStockingCubicMeters] = useState<number | string>(0);
  const [editStockingHectares, setEditStockingHectares] = useState<number | string>(0);
  const [editStockingLabName, setEditStockingLabName] = useState<string | undefined>('');
  const [editBindingStockings, setEditBindingStockings] = useState<BindingStocking[]>([initialBindingStocking]);
  const [editStockingParameter, setEditStockingParameter] = useState<string | undefined>('');
  const [editStockingReference, setEditStockingReference] = useState<string | undefined>('');
  const [hasBindingStocking, setHasBindingStocking] = useState<boolean>(false);
  const [editStockingStage, setEditStockingStage] = useState<CustomStage>({ key: 'NAUPLII', value: stockingStageOptions.NAUPLII });
  const [editDaysToInitialStage, setEditDaysToInitialStage] = useState<number>(DEFAULT_DAYS_TO_INITIAL_STAGE);
  const [editStockingWeight, setEditStockingWeight] = useState<number | string | undefined>(0);

  const [disabledButtonFormEditStocking, setDisabledButtonFormEditStocking] = useState(false);

  const {
    currentTabSetting,
    currentPage,
    filters,
    isLoadingEdit,
    isLoadingStocking,
    maturations,
    maturationCodes,
    referenceCurves,
    showModalEdit: showModal,
    stageOptions,
    stockingDateRanges,
    selectedStocking,
    selectedTankId: tankId,
    selectedModuleId: moduleId,
    selectedCampus,
  } = useSelector((state: Store) => state.stockings);
  const { company } = useSelector((state: Store) => state.header);
  const volumeRanges = useSelector((state: Store) => state.campus.volumeRanges);

  const { stockingsToShow } = filters;
  const userSession = getUserSession();

  const containerVolumeLimits = getContainerVolumeLimits({ volumeRanges, phaseType: selectedStocking?.phaseType });

  const isCampusInactive = selectedStocking.campusId.status === unitStatuses.INACTIVE;
  const stockingIsPublished = selectedStocking.isPublic;
  const selectContainerType = selectedStocking.tankId?.type || '';
  const isLightTheme = theme === THEME.LIGHT;

  const {
    canvasRef,
    handleCustomRequest,
    handleOnChange,
    imageUrl,
    loadingImage,
    reducedImage,
    voucherHeight,
    voucherWidth,
    cleanImage,
  } = useDragger({ applyBlackWhite: true });
  const image = imageUrl || selectedStocking.voucher?.presignedUrl;

  useEffect(() => {
    if (currentTabSetting !== stockingCurrentTab.INVOICE) {
      return;
    }
    setDisabledButtonFormEditStocking(!image);
  }, [currentTabSetting, image]);

  useEffect(() => {
    if (!selectedStocking._id || !selectedStocking.referenceCurveId?.type) {
      return;
    }

    dispatch(fetchReferenceCurves({ parameter: selectedStocking.referenceCurveId?.type, phaseType: selectedStocking.phaseType, companyId: company._id }));
  }, [selectedStocking]);

  useEffect(() => {
    const fillFormEditStocking = (stocking: ISowing) => {
      const startDate = stocking.startDate;
      const startDateJuvenile = stocking.startDateJuvenile;
      const startDateGrowOut = stocking.startDateGrowOut;
      const labNames = stocking?.labNames || [];
      const labName = labNames.length > 0 ? labNames[0] : '';

      setEditStockingName(stocking.name);
      setEditStockingType(stocking.type);
      setEditStockingLabName(labName);
      setEditStockingStartDate(startDate);
      setEditStockingStartDateJuvenile(startDateJuvenile);
      setEditStockingStartDateGrowOut(startDateGrowOut);
      setEditStockingNumberNauplii(stocking.naupliusNumber);
      setEditStockingNumberJuvenile(stocking.juvenilesNumber);
      setEditStockingNumberGrowOut(stocking.growOutNumber);
      setEditStockingWeight(stocking.weight);
      setEditStockingCubicMeters(stocking.cubicMeters);
      setEditStockingHectares(stocking.hectares);
      setEditStockingLiters(stocking.litersNumber);
      setEditStockingParameter(stocking.referenceCurveId?.type);
      setEditStockingReference(stocking.referenceCurveId?._id);
      setEditStockingMaturation({ id: stocking.maturationId._id, name: stocking.maturationId.name });
      setEditStockingMaturationCode(stocking.maturationCode);
      setEditDaysToInitialStage(stocking.daysToInitialStage);

      if (stocking.customStage) {
        setEditStockingStage(stocking.customStage);
      }

      if (stocking.bindingStockings?.length === undefined) {
        setHasBindingStocking(false);
        setEditBindingStockings([initialBindingStocking]);
      } else if (stocking.bindingStockings.length > 0) {
        setHasBindingStocking(true);
        setEditBindingStockings(stocking.bindingStockings);
      } else {
        setHasBindingStocking(false);
        setEditBindingStockings([initialBindingStocking]);
      }

      formEditStocking.setFieldsValue({
        name: stocking.name,
        type: stocking.type,
        labName,
        startDate: moment.parseZone(stocking.startDate),
        startDateJuvenile: moment.parseZone(stocking.startDateJuvenile),
        startDateGrowOut: moment.parseZone(stocking.startDateGrowOut),
        numberNauplii: stocking.naupliusNumber,
        numberJuvenile: stocking.juvenilesNumber,
        numberGrowOut: stocking.growOutNumber,
        weight: stocking.weight,
        liters: stocking.litersNumber,
        cubicMeters: stocking.cubicMeters,
        hectares: stocking.hectares,
        maturation: stocking.maturationId.name,
        maturationCode: stocking.maturationCode || undefined,
        customStage: stocking.customStage?.key,
        parameter: stocking.referenceCurveId?.type,
        referenceCurveId: stocking.referenceCurveId?._id,
        daysToInitialStage: stocking.daysToInitialStage,
      });
    };

    if (selectedStocking._id !== '' && showModal === true) {
      fillFormEditStocking(selectedStocking);
    }
  }, [dispatch, formEditStocking, selectedStocking, showModal]);

  useEffect(() => {
    if (isCampusInactive) {
      setDisabledButtonFormEditStocking(true);
    }
  }, [isCampusInactive]);

  function disabledStartDateLarvaeEdit (currentDate: moment.Moment) {
    const { max, min } = stockingDateRanges.larvae.startDateLarvae;
    return currentDate && (currentDate < moment(editStockingStartDate).subtract(min, 'd') || currentDate > moment(editStockingStartDate).add(max, 'd'));
  }

  function disabledStartDateJuvenileEdit (currentDate: moment.Moment) {
    const { max, min } = stockingDateRanges.juvenile.startDateJuvenile;
    return currentDate && (currentDate < moment(editStockingStartDateJuvenile).subtract(min, 'd') || currentDate > moment(editStockingStartDateJuvenile).add(max, 'd'));
  }

  function disabledStartDateGrowOutEdit (currentDate: moment.Moment) {
    const { max, min } = stockingDateRanges.growOut.startDateGrowOut;
    return currentDate && (currentDate < moment(editStockingStartDateGrowOut).subtract(min, 'd') || currentDate > moment(editStockingStartDateGrowOut).add(max, 'd'));
  }

  function onChangeTab (key: string) {
    dispatch(setCurrentTabSetting(key));
    disabledButtonForm(key);
  }

  function disabledButtonForm (key: string) {
    if (isCampusInactive) {
      setDisabledButtonFormEditStocking(true);
      return;
    }

    let value = false;

    switch (key) {
      case stockingCurrentTab.EDIT:
        value = formEditStocking.getFieldsError().filter(({ errors }) => errors.length).length > 0;
        break;

      case stockingCurrentTab.INVOICE:
        value = !image;
        break;
    }

    setDisabledButtonFormEditStocking(value);
  }

  function getTitle () {
    let text = '';
    switch (currentTabSetting) {
      case stockingCurrentTab.EDIT:
        text = t('stockings.editStocking');
        break;

      case stockingCurrentTab.INVOICE:
        text = t('stockings.viewInvoice');
        break;
    }

    return text;
  }

  function getTextButton () {
    let text = '';
    switch (currentTabSetting) {
      case stockingCurrentTab.EDIT:
        text = t('stockings.edit');
        break;

      case stockingCurrentTab.INVOICE:
        text = t('stockings.accept');
        break;
    }

    return text;
  }

  function getForm () {
    let text = '';
    switch (currentTabSetting) {
      case stockingCurrentTab.EDIT:
        text = 'formEditStocking';
        break;
    }

    return text;
  }

  function checkLoading () {
    let loading = false;
    switch (currentTabSetting) {
      case stockingCurrentTab.EDIT:
        loading = isLoadingEdit;
        break;
    }

    return loading;
  }

  function renderStockingName () {
    return (
      <Col span={12}>
        <Form.Item
          name='name'
          label={t('stockings.name')}
          required
          rules={[() => ({ validator (rule, value) { return validateString(value); } })]}
        >
          <LrvInput
            theme={theme}
            value={editStockingName}
            onChange={(e) => setEditStockingName(e.target.value)}
            disabled={isCampusInactive}
          />
        </Form.Item>
      </Col>
    );
  }

  function renderNaupliusNumber () {
    return (
      <Col span={12}>
        <Form.Item
          name='numberNauplii'
          label={t('stockings.numberNauplii')}
          required
          rules={[() => ({ validator (rule, value) { return validateNumber(value, true, 1); } })]}
        >
          <LrvInputNumber
            theme={theme}
            value={editStockingNumberNauplii}
            formatter={value => applyThousandsSeparator(value)}
            parser={value => applyParserThousandsSeparator(value)}
            onChange={(value) => {
              if (value) {
                setEditStockingNumberNauplii(value);
              }
            }}
            disabled={isCampusInactive || stockingIsPublished}
          />
        </Form.Item>
      </Col>
    );
  }

  const renderDaysToInitialStage = () => {
    if (editStockingStage.key !== 'NAUPLII') {
      return null;
    }

    return (
      <Col span={12}>
        <Form.Item
          name='daysToInitialStage'
          label={t('clients.daysToInitialStage')}
          required
          rules={[() => ({ validator (rule, value) { return validateNumber(value, true, MIN_DAYS_TO_INITIAL_STAGE); } })]}
        >
          <LrvInputNumber
            theme='light'
            min={MIN_DAYS_TO_INITIAL_STAGE}
            max={MAX_DAYS_TO_INITIAL_STAGE}
            value={editDaysToInitialStage}
            onChange={(value) => {
              if (!value) {
                return;
              }

              setEditDaysToInitialStage(parseInt(value.toString()));
            }}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderStockingStage = () => {
    return (
      <Col span={12}>
        <Form.Item
          name='customStage'
          label={t('stockings.customStage')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <LrvSelect
            theme={theme}
            showSearch
            value={editStockingStage.key}
            onChange={(key) => {
              const value = stageOptions[key];
              setEditStockingStage({ key, value });
            }}
            suffixIcon={<Icon name='arrow-down-s' />}
            filterOption={filterOptionSelect}
            dropdownMatchSelectWidth={false}
          >
            {Object.entries(stageOptions).map(([key,]) => (
              <Option key={key} value={key}>{key}</Option>
            ))}
          </LrvSelect>
        </Form.Item>
      </Col>
    );
  };

  function renderLitersNumberLarvae () {
    return (
      <Col span={12}>
        <Form.Item
          name='liters'
          label={t('stockings.liters')}
          required
          rules={
            [() => ({
              validator (rule, value) {
                return validateNumber(value, true, containerVolumeLimits[selectContainerType].min, containerVolumeLimits[selectContainerType].max, t('stockings.volumeError', { min: containerVolumeLimits[selectContainerType].min, max: containerVolumeLimits[selectContainerType].max }));
              }
            })]
          }
        >
          <LrvInputNumber
            theme={theme}
            value={editStockingLiters}
            formatter={value => applyThousandsSeparator(value)}
            parser={value => applyParserThousandsSeparator(value)}
            onChange={(value) => {
              if (value) {
                setEditStockingLiters(value);
              }
            }}
            disabled={isCampusInactive || stockingIsPublished}
          />
        </Form.Item>
      </Col>
    );
  }

  function renderStartDate () {
    return (
      <Col span={12}>
        <Form.Item
          name='startDate'
          label={t('stockings.startDate')}
          rules={[{ required: true, message: t('stockings.form.startDate') }]}
        >
          <LrvDatePicker
            theme={theme}
            disabledDate={disabledStartDateLarvaeEdit}
            value={moment(editStockingStartDate)}
            onChange={(date) => {
              setEditStockingStartDate(date?.toString() as string);
            }}
            disabled={isCampusInactive || stockingIsPublished}
          />
        </Form.Item>
      </Col>
    );
  }

  function renderStartDateJuvenile () {
    return (
      <Col span={12}>
        <Form.Item
          name='startDateJuvenile'
          label={t('stockings.startDateJuvenile')}
          rules={[{ required: true, message: t('stockings.form.startDateJuvenile') }]}
        >
          <LrvDatePicker
            theme={theme}
            disabledDate={disabledStartDateJuvenileEdit}
            value={moment(editStockingStartDateJuvenile)}
            onChange={(date) => {
              setEditStockingStartDateJuvenile(date?.toString() as string);
            }}
            disabled={isCampusInactive}
          />
        </Form.Item>
      </Col>
    );
  }

  function renderStartDateGrowOut () {
    return (
      <Col span={12}>
        <Form.Item
          name='startDateGrowOut'
          label={t('stockings.startDateGrowOut')}
          rules={[{ required: true, message: t('stockings.form.startDateGrowOut') }]}
        >
          <LrvDatePicker
            theme={theme}
            disabledDate={disabledStartDateGrowOutEdit}
            value={moment(editStockingStartDateGrowOut)}
            onChange={(date) => {
              setEditStockingStartDateGrowOut(date?.toString() as string);
            }}
            disabled={isCampusInactive}
          />
        </Form.Item>
      </Col>
    );
  }

  function renderJuvenilesNumber () {
    if (hasBindingStocking) {
      return null;
    }

    return (
      <Col span={12}>
        <Form.Item
          name='numberJuvenile'
          label={t('stockings.numberJuvenile')}
          required
          rules={[() => ({ validator (rule, value) { return validateNumber(value, true, 1); } })]}
        >
          <LrvInputNumber
            theme={theme}
            value={editStockingNumberJuvenile}
            formatter={value => applyThousandsSeparator(value)}
            parser={value => applyParserThousandsSeparator(value)}
            onChange={(value) => {
              if (value) {
                setEditStockingNumberJuvenile(value);
              }
            }}
            disabled={isCampusInactive}
          />
        </Form.Item>
      </Col>
    );
  }

  function renderNumberGrowOut () {
    if (hasBindingStocking) {
      return null;
    }

    return (
      <Col span={12}>
        <Form.Item
          name='numberGrowOut'
          label={t('stockings.numberGrowOut')}
          required
          rules={[() => ({ validator (rule, value) { return validateNumber(value, true, 1); } })]}
        >
          <LrvInputNumber
            theme={theme}
            value={editStockingNumberGrowOut}
            formatter={value => applyThousandsSeparator(value)}
            parser={value => applyParserThousandsSeparator(value)}
            onChange={(value) => {
              if (value) {
                setEditStockingNumberGrowOut(value);
              }
            }}
            disabled={isCampusInactive}
          />
        </Form.Item>
      </Col>
    );
  }

  function renderCubicMetersJuvenile () {
    if (selectContainerType !== containerTypes.RACEWAY) {
      return null;
    }

    return (
      <Col span={12}>
        <Form.Item
          name='cubicMeters'
          label={t('stockings.cubicMeters')}
          required
          rules={
            [() => ({
              validator (rule, value) {
                return validateNumber(value, false, containerVolumeLimits[selectContainerType].min, containerVolumeLimits[selectContainerType].max, t('stockings.volumeError', { min: containerVolumeLimits[selectContainerType].min, max: containerVolumeLimits[selectContainerType].max }));
              }
            })]
          }
        >
          <LrvInputNumber
            theme={theme}
            value={editStockingCubicMeters}
            onChange={(value) => {
              if (value) {
                setEditStockingCubicMeters(value);
              }
            }}
            disabled={isCampusInactive}
          />
        </Form.Item>
      </Col>
    );
  }

  function renderHectaresJuvenile () {
    if (selectedStocking.phaseType !== stockingPhaseTypes.JUVENILE || selectContainerType !== containerTypes.PRE_HATCHERY) {
      return null;
    }

    return (
      <Col span={12}>
        <Form.Item
          name='hectares'
          label={t('stockings.hectares')}
          required
          rules={
            [() => ({
              validator (rule, value) {
                return validateNumber(value, false, containerVolumeLimits[selectContainerType].min, containerVolumeLimits[selectContainerType].max, t('stockings.volumeError', { min: containerVolumeLimits[selectContainerType].min, max: containerVolumeLimits[selectContainerType].max }));
              }
            })]
          }
        >
          <LrvInputNumber
            theme={theme}
            value={editStockingHectares}
            onChange={(value) => {
              if (value) {
                setEditStockingHectares(value);
              }
            }}
            disabled={isCampusInactive}
          />
        </Form.Item>
      </Col>
    );
  }

  function renderCubicMetersGrowOut () {
    if (selectContainerType !== containerTypes.RACEWAY) {
      return null;
    }

    return (
      <Col span={12}>
        <Form.Item
          name='cubicMeters'
          label={t('stockings.cubicMeters')}
          required
          rules={
            [() => ({
              validator (rule, value) {
                return validateNumber(value, false, containerVolumeLimits[selectContainerType].min, containerVolumeLimits[selectContainerType].max, t('stockings.volumeError', { min: containerVolumeLimits[selectContainerType].min, max: containerVolumeLimits[selectContainerType].max }));
              }
            })]
          }
        >
          <LrvInputNumber
            theme={theme}
            value={editStockingCubicMeters}
            onChange={(value) => {
              if (value) {
                setEditStockingCubicMeters(value);
              }
            }}
            disabled={isCampusInactive || stockingIsPublished}
          />
        </Form.Item>
      </Col>
    );
  }

  function renderHectaresGrowOut () {
    if (selectContainerType !== containerTypes.POOL) {
      return null;
    }

    return (
      <Col span={12}>
        <Form.Item
          name='hectares'
          label={t('stockings.hectares')}
          required
          rules={
            [() => ({
              validator (rule, value) {
                return validateNumber(value, false, containerVolumeLimits[selectContainerType].min, containerVolumeLimits[selectContainerType].max, t('stockings.volumeError', { min: containerVolumeLimits[selectContainerType].min, max: containerVolumeLimits[selectContainerType].max }));
              }
            })]
          }
        >
          <LrvInputNumber
            theme={theme}
            value={editStockingHectares}
            onChange={(value) => {
              if (value) {
                setEditStockingHectares(value);
              }
            }}
            disabled={isCampusInactive}
          />
        </Form.Item>
      </Col>
    );
  }

  function renderMaturations () {
    if (hasBindingStocking) {
      return null;
    }

    return (
      <Col span={12}>
        <Form.Item
          name='maturation'
          label={t('stockings.maturation')}
          rules={[{ required: true, message: t('stockings.form.maturation') }]}
        >
          <LrvSelect
            theme={theme}
            showSearch
            value={editStockingMaturation.id}
            onChange={(value) => {
              formEditStocking.setFieldsValue({
                maturationCode: undefined,
              });
              setEditStockingMaturationCode('');
              dispatch(fetchMaturationCodes(value));
              setEditStockingMaturation({ id: value, name: '' });
            }}
            suffixIcon={<Icon name='arrow-down-s' />}
            filterOption={filterOptionSelect}
            dropdownMatchSelectWidth={false}
            disabled={isCampusInactive}
          >
            {maturations.map((maturation, index: number) =>
              <Option key={index} value={maturation._id}>{maturation.name}</Option>
            )}
          </LrvSelect>
        </Form.Item>
      </Col>
    );
  }

  function renderMaturationCodes () {
    if (hasBindingStocking) {
      return null;
    }

    return (
      <Col span={12}>
        <Form.Item
          name='maturationCode'
          label={`${t('stockings.maturationCode')} (${t('common.optional')})`}
        >
          <LrvSelect
            theme={theme}
            showSearch
            mode='tags'
            value={editStockingMaturationCode || undefined}
            onSelect={(value) => {
              const valueFound: MaturationCode | undefined = maturationCodes.find(item => item._id === value || item.code === value);
              const newValue: string = valueFound?._id ?? value;

              setEditStockingMaturationCode(newValue);
              formEditStocking.setFieldsValue({ maturationCode: newValue });
            }}
            onDeselect={() => {
              setEditStockingMaturationCode(undefined);
              formEditStocking.setFieldsValue({ maturationCode: undefined });
            }}
            suffixIcon={<Icon name='arrow-down-s' />}
            filterOption={filterOptionSelect}
            disabled={isCampusInactive}
            optionFilterProp='children'
          >
            {maturationCodes && maturationCodes.map((maturation: MaturationCode) => <Option key={maturation._id} value={maturation._id}>{maturation.code}</Option>)}
          </LrvSelect>
        </Form.Item>
      </Col>
    );
  }

  function renderStockingType () {
    return (
      <Col span={12}>
        <Form.Item
          name='type'
          label={t('stockings.type')}
          required
          rules={[() => ({ validator (rule, value) { return validateString(value); } })]}
        >
          <LrvSelect
            theme={theme}
            id='dropdown_types'
            showSearch
            className={styles.select}
            disabled={isCampusInactive}
            suffixIcon={<Icon name='arrow-down-s' />}
            placeholder={t('stockings.type')}
            optionFilterProp='children'
            value={editStockingType}
            onChange={(value) => setEditStockingType(value)}
            filterOption={filterOptionSelect}
            dropdownMatchSelectWidth={false}
          >
            {Object.keys(stockingTypes).map((key, index) => {
              const typedKey = key as 'TRANSFER' | 'DIRECT_STOCKING';

              return (
                <Option key={index} value={getKeyValue(stockingTypes, typedKey)}>{t(`stockings.stockingTypes.${key}`)}</Option>
              );
            })}
          </LrvSelect>
        </Form.Item>
      </Col>
    );
  }

  function renderStockingLabName () {
    if (hasBindingStocking || editStockingType === stockingTypes.TRANSFER) {
      return null;
    }

    return (
      <Col span={12}>
        <Form.Item
          name='labName'
          label={`${t('stockings.labName')} (${t('common.optional')})`}
        >
          <LrvInput
            theme={theme}
            value={editStockingLabName}
            onChange={(e) => setEditStockingLabName(e.target.value)}
            disabled={isCampusInactive}
          />
        </Form.Item>
      </Col>
    );
  }

  const renderStockingWeight = () => {
    if (hasBindingStocking || editStockingType === stockingTypes.DIRECT_STOCKING) {
      return null;
    }

    return (
      <Col span={12}>
        <Form.Item
          name='weight'
          label={`${t('stockings.weight')} (${weightUnits.G})`}
          required
          rules={[() => ({ validator (rule, value) { return validateNumber(value, false, MIN_STOCKING_WEIGHT); } })]}
        >
          <LrvInputNumber
            theme={theme}
            value={editStockingWeight}
            onChange={(value) => {
              if (value) {
                setEditStockingWeight(value);
              }
            }}
            disabled={isCampusInactive}
          />
        </Form.Item>
      </Col>
    );
  };

  function renderLabelCampusInactive () {
    if (isCampusInactive) {
      return (
        <Form.Item>
          <TinyLabel className={styles.warning} text={t('stockings.inactiveCampus')} />
        </Form.Item>
      );
    }

    return null;
  }

  function renderLabelStockingIsPublished () {
    if (stockingIsPublished) {
      return (
        <Form.Item>
          <TinyLabel className={styles.stockingIsPublished} text={t('stockings.errors.stockingIsPublished')} />
          <TinyLabel className={styles.stockingIsPublished} text={t('stockings.errors.fieldsCannotBeEdited')} />
        </Form.Item>
      );
    }

    return null;
  }

  const renderBindingStockingCheckBox = () => {
    return (
      <Form.Item>
        <LrvCheckbox
          theme='light'
          checked={hasBindingStocking}
          onChange={event => setHasBindingStocking(event.target.checked)}
        >
          {t('stockings.binding')}
        </LrvCheckbox>
      </Form.Item>
    );
  };

  const renderDraggerFile = () => {
    return (
      <div>
        <p>
          <InboxOutlined />
        </p>
        <p>{t('payments.drag')}</p>
      </div>
    );
  };

  const getLabelUploadFile = () => {
    let value = '';

    switch (selectedStocking.phaseType) {
      case stockingPhaseTypes.ADULT:
        value = t('stockings.juvenileReferralGuide');
        break;

      case stockingPhaseTypes.JUVENILE:
        value = t('stockings.larvaReferralGuide');
        break;

      case stockingPhaseTypes.LARVAE:
        value = t('stockings.naupliusReferralGuide');
        break;
    }

    return value;
  };

  const isValidStockingBindingRow = (record: IBindingStockingTable) => {
    return !!record.bindingCode && record.animals > 0;
  };

  const renderBindingStockingRows = () => {
    const columns: ColumnsType<BindingStocking> = [
      {
        title: t('stockings.bindingCode'),
        dataIndex: 'bindingCode',
        width: '45%',
        render: (_, record: BindingStocking) => {
          return (
            <>
              <LrvInput
                theme='light'
                value={record.bindingCode}
                className={styles.input}
                maxLength={5}
                onChange={(e) => {
                  if (e.target.value == null || e.target.value == undefined) {
                    return;
                  }

                  const bindingStockingCopy: BindingStocking[] = cloneDeep(editBindingStockings);
                  record.isValid = false;
                  record.validatedCode = false;
                  record.bindingCode = e.target.value;
                  bindingStockingCopy[record.key] = record;
                  setEditBindingStockings(bindingStockingCopy);
                }}
              />
              <ErrorLabelRow hasError={record.validatedCode && !record.isValid} />
            </>
          );
        },
      },
      {
        title: t('stockings.animals'),
        dataIndex: 'animals',
        width: '45%',
        render: (_, record: BindingStocking) => {
          return (
            <>
              <LrvInputNumber
                theme='light'
                value={record.animals}
                className={styles.input}
                min={0}
                formatter={value => applyThousandsSeparator(value)}
                parser={value => applyParserThousandsSeparator(value)}
                onChange={(value) => {
                  if (value == null || value == undefined || !isNumber(value)) {
                    return;
                  }

                  const bindingStockingCopy: BindingStocking[] = cloneDeep(editBindingStockings);
                  record.isValid = false;
                  record.validatedCode = false;
                  record.animals = parseInt(value.toString());
                  bindingStockingCopy[record.key] = record;
                  setEditBindingStockings(bindingStockingCopy);
                }}
              />
              <ErrorLabelRow />
            </>
          );
        },
      },
      {
        title: '',
        dataIndex: 'remove',
        width: '10%',
        render: (_, record: BindingStocking) => {
          return (
            <>
              <Space className={styles.bindingCode} size='small'>
                <LrvTooltip
                  themeStyle={isLightTheme ? 'dark' : 'light'}
                  title={t('stockings.delete')}
                >
                  <ActionButton
                    type='text'
                    icon={<Icon name='delete-bin' type='line' theme={theme} />}
                    onClick={() => {
                      const bindingStockingCopy: BindingStocking[] = cloneDeep(editBindingStockings);
                      const bindingStockings = bindingStockingCopy.filter((item, index) => index !== record.key);

                      if (bindingStockings.length === 0) {
                        bindingStockings.push(initialBindingStocking);
                      }

                      setEditBindingStockings(bindingStockings);
                    }}
                  />
                </LrvTooltip>
              </Space>
              <ErrorLabelRow />
            </>
          );
        },
      },
    ];

    return (
      <BindingStockingTable columns={columns} dataSource={getBindingStockingData(editBindingStockings)} />
    );
  };

  const addRowBindingStocking = () => {
    if (editBindingStockings.length >= maximumNumberOfStockings) {
      openErrorNotification(t('stockings.maximumNumberOfStockings'));
      return;
    }

    const data: BindingStocking[] = [...editBindingStockings];
    data.push(initialBindingStocking);
    setEditBindingStockings(data);
  };

  const onChangeStockingParameter = (value: string) => {
    formEditStocking.setFieldsValue({
      parameter: value,
      referenceCurveId: undefined,
    });

    setEditStockingParameter(value);
    setEditStockingReference(undefined);
    dispatch(fetchReferenceCurves({ parameter: value, phaseType: selectedStocking.phaseType, companyId: company._id }));
  };

  const onChangeStockingReference = (value: string) => {
    setEditStockingReference(value);
  };

  const renderReferenceCurve = () => {
    return (
      <Row gutter={16} className={styles.referenceCurve}>
        <Col span={24}>
          <Form.Item className={styles.formItem}>
            <LrvText
              className={styles.text}
              theme='light'
              text={t('stockings.referenceDescription')}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name='parameter'
            label={`${t('production.filters.parameter')} (${t('common.optional')})`}
          >
            <LrvSelect
              theme={theme}
              autoFocus
              showSearch
              value={editStockingParameter}
              onChange={onChangeStockingParameter}
              suffixIcon={<Icon name='arrow-down-s' />}
              filterOption={filterOptionSelect}
              dropdownMatchSelectWidth={false}
            >
              {
                selectedStocking.phaseType === stockingPhaseTypes.LARVAE &&
                <Option value={typeParam.PLG}>{t('analysis.resultData.larvaePerGram')}</Option>
              }
              <Option value={typeParam.AVG_WEIGHT}>{t('analysis.resultData.averageWeight')}</Option>
              <Option value={typeParam.AVG_LENGTH}>{t('analysis.resultData.averageLength')}</Option>
              <Option value={typeParam.UNIFORMITY}>{t('analysis.resultData.uniformity')}</Option>
            </LrvSelect>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name='referenceCurveId'
            label={`${t('stockings.reference')} (${t('common.optional')})`}
          >
            <LrvSelect
              theme={theme}
              autoFocus
              showSearch
              value={editStockingReference}
              onChange={onChangeStockingReference}
              suffixIcon={<Icon name='arrow-down-s' />}
              filterOption={filterOptionSelect}
              dropdownMatchSelectWidth={false}
              disabled={!editStockingParameter}
            >
              {referenceCurves.map((reference) => <Option key={reference._id} value={reference._id}>{reference.name}</Option>)}
            </LrvSelect>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const renderBindingStocking = () => {
    if (!hasBindingStocking) {
      return null;
    }
    
    return (
      <>
        {renderBindingStockingRows()}
        <NewBindingStockingButton
          theme={theme}
          bindingStockings={editBindingStockings}
          onClick={() => {
            if (stockingBindingRowIsFull(editBindingStockings)) {
              addRowBindingStocking();
            }
          }}
        />
      </>
    );
  };

  const renderBodyEditFormLarvae = () => {
    return (
      <div>
        <Row gutter={16}>
          {renderStockingName()}
          {renderLitersNumberLarvae()}
        
          {renderStartDate()}
          {renderStockingStage()}

          {renderMaturations()}
          {renderMaturationCodes()}

          {!hasBindingStocking && renderNaupliusNumber()}
          {renderDaysToInitialStage()}
        </Row>

        {renderBindingStocking()}
        {renderReferenceCurve()}
      </div>
    );
  };

  const renderBodyEditFormJuvenile = () => {
    return (
      <div>
        <Row gutter={16}>
          {renderStockingName()}
          {renderStartDateJuvenile()}

          {renderCubicMetersJuvenile()}
          {renderHectaresJuvenile()}

          {renderMaturations()}
          {renderMaturationCodes()}

          {renderJuvenilesNumber()}
          {renderStockingLabName()}
        </Row>

        {renderBindingStocking()}
        {renderReferenceCurve()}
      </div>
    );
  };

  const renderBodyEditFormGrowOut = () => {
    return (
      <div>
        <Row gutter={16}>
          {renderStockingName()}
          {renderStartDateGrowOut()}

          {renderCubicMetersGrowOut()}
          {renderHectaresGrowOut()}
          {renderStockingType()}

          {renderMaturations()}
          {renderMaturationCodes()}

          {renderNumberGrowOut()}
          {renderStockingLabName()}
          {renderStockingWeight()}
        </Row>

        {renderBindingStocking()}
        {renderReferenceCurve()}
      </div>
    );
  };

  const renderBodyEditForm = () => {
    switch (selectedStocking.phaseType) {
      case stockingPhaseTypes.LARVAE:
        return renderBodyEditFormLarvae();

      case stockingPhaseTypes.JUVENILE:
        return renderBodyEditFormJuvenile();

      case stockingPhaseTypes.ADULT:
        return renderBodyEditFormGrowOut();

      default:
        return null;
    }
  };

  const renderBindingCodeWarning = () => {
    if (!hasBindingStocking) {
      return null;
    }

    return (
      <Form.Item>
        <TinyLabel className={styles.warning} text={t('stockings.bindingCodeWarning')} />
      </Form.Item>
    );
  };

  const editLarvaeStocking = async (props: { paramsToFetchStocking: FetchStockingProps; makeFetchStockings: boolean }) => {
    const { paramsToFetchStocking, makeFetchStockings } = props;

    const stockingLarvaeData: UpdateLarvaeData = {
      name: editStockingName,
      labName: editStockingLabName,
      startDate: editStockingStartDate,
      maturationId: editStockingMaturation.id,
      naupliusNumber: Number(editStockingNumberNauplii),
      litersNumber: Number(editStockingLiters),
      phaseType: selectedStocking.phaseType,
      maturationCode: editStockingMaturationCode,
      bindingStockings: [],
      customStage: editStockingStage,
      referenceCurveId: editStockingReference,
      daysToInitialStage: editDaysToInitialStage,
    };

    if (hasBindingStocking) {
      const bindingStockings: BindingStocking[] = cloneDeep(editBindingStockings);
      for (let index = 0; index < bindingStockings.length; index++) {
        const newBindingStocking = bindingStockings[index];

        // eslint-disable-next-line
        if (isValidStockingBindingRow(newBindingStocking)) {
          const itemBindingStocking = await fetchStockingByBindingCode({ code: newBindingStocking.bindingCode, index, bindingStockings });
          bindingStockings[index] = itemBindingStocking;
        }
      }

      setEditBindingStockings(bindingStockings);
      const isValid = isValidStockingBinding(bindingStockings);
      if (!isValid) {
        openErrorNotification(t('stockings.invalidBindingStocking'));
        return;
      }

      const animales = getTotalAnimals(bindingStockings);
      const bindingStocking = getBindingStockingByMaxAnimals(bindingStockings);

      stockingLarvaeData.bindingStockings = bindingStockings;
      stockingLarvaeData.maturationId = bindingStocking.maturationId;
      stockingLarvaeData.maturationCode = bindingStocking.maturationCode;
      stockingLarvaeData.naupliusNumber = animales;
    }

    dispatch(editStocking({ id: selectedStocking._id, stockingData: stockingLarvaeData, makeFetchStockings, paramsToFetchStocking }));
  };

  const editJuvenileStocking = async (props: { paramsToFetchStocking: FetchStockingProps; makeFetchStockings: boolean }) => {
    const { paramsToFetchStocking, makeFetchStockings } = props;

    const stockingJuvenileData: UpdateJuvenileData = {
      name: editStockingName,
      labName: editStockingLabName,
      startDate: editStockingStartDate,
      startDateJuvenile: editStockingStartDateJuvenile,
      maturationId: editStockingMaturation.id,
      juvenilesNumber: Number(editStockingNumberJuvenile),
      phaseType: selectedStocking.phaseType,
      maturationCode: editStockingMaturationCode,
      bindingStockings: [],
      referenceCurveId: editStockingReference,
    };

    if (selectContainerType === containerTypes.RACEWAY) {
      stockingJuvenileData.cubicMeters = Number(editStockingCubicMeters);
    } else if (selectContainerType === containerTypes.PRE_HATCHERY) {
      stockingJuvenileData.hectares = Number(editStockingHectares);
    }

    if (hasBindingStocking) {
      const bindingStockings: BindingStocking[] = cloneDeep(editBindingStockings);
      for (let index = 0; index < bindingStockings.length; index++) {
        const newBindingStocking = bindingStockings[index];

        if (isValidStockingBindingRow(newBindingStocking)) {
          const itemBindingStocking = await fetchStockingByBindingCode({ code: newBindingStocking.bindingCode, index, bindingStockings });
          bindingStockings[index] = itemBindingStocking;
        }
      }

      setEditBindingStockings(bindingStockings);
      const isValid = isValidStockingBinding(bindingStockings);
      if (!isValid) {
        openErrorNotification(t('stockings.invalidBindingStocking'));
        return;
      }

      const animales = getTotalAnimals(bindingStockings);
      const bindingStocking = getBindingStockingByMaxAnimals(bindingStockings);

      stockingJuvenileData.bindingStockings = bindingStockings;
      stockingJuvenileData.maturationId = bindingStocking.maturationId;
      stockingJuvenileData.maturationCode = bindingStocking.maturationCode;
      stockingJuvenileData.juvenilesNumber = animales;
    }

    dispatch(editStocking({ id: selectedStocking._id, stockingData: stockingJuvenileData, makeFetchStockings, paramsToFetchStocking }));
  };

  const editGrowOutStocking = async (props: { paramsToFetchStocking: FetchStockingProps; makeFetchStockings: boolean }) => {
    const { paramsToFetchStocking, makeFetchStockings } = props;

    const stockingGrowOutData: UpdateGrowOutData = {
      name: editStockingName,
      labName: editStockingLabName,
      type: editStockingType,
      startDateGrowOut: editStockingStartDateGrowOut,
      maturationId: editStockingMaturation.id,
      growOutNumber: Number(editStockingNumberGrowOut),
      weight: Number(editStockingWeight),
      phaseType: selectedStocking.phaseType,
      maturationCode: editStockingMaturationCode,
      bindingStockings: [],
      referenceCurveId: editStockingReference,
    };

    if (selectContainerType === containerTypes.RACEWAY) {
      stockingGrowOutData.cubicMeters = Number(editStockingCubicMeters);
    } else if (selectContainerType === containerTypes.POOL) {
      stockingGrowOutData.hectares = Number(editStockingHectares);
    }

    if (hasBindingStocking) {
      const bindingStockings: BindingStocking[] = cloneDeep(editBindingStockings);
      for (let index = 0; index < bindingStockings.length; index++) {
        const newBindingStocking = bindingStockings[index];

        if (isValidStockingBindingRow(newBindingStocking)) {
          const itemBindingStocking = await fetchStockingByBindingCode({ code: newBindingStocking.bindingCode, index, bindingStockings });
          bindingStockings[index] = itemBindingStocking;
        }
      }

      setEditBindingStockings(bindingStockings);
      const isValid = isValidStockingBinding(bindingStockings);
      if (!isValid) {
        openErrorNotification(t('stockings.invalidBindingStocking'));
        return;
      }

      const animales = getTotalAnimals(bindingStockings);
      const bindingStocking = getBindingStockingByMaxAnimals(bindingStockings);

      stockingGrowOutData.bindingStockings = bindingStockings;
      stockingGrowOutData.maturationId = bindingStocking.maturationId;
      stockingGrowOutData.maturationCode = bindingStocking.maturationCode;
      stockingGrowOutData.growOutNumber = animales;
    }

    dispatch(editStocking({ id: selectedStocking._id, stockingData: stockingGrowOutData, makeFetchStockings, paramsToFetchStocking }));
  };

  return <LrvModal
    theme={theme}
    className={cx(styles.settingStockingModal, 'settingStockingModal')}
    title={getTitle()}
    open={showModal}
    destroyOnClose={true}
    isLoading={isLoadingStocking}
    okButtonProps={{ id: 'submit_edit_stocking', htmlType: 'submit', form: getForm(), loading: checkLoading(), disabled: disabledButtonFormEditStocking }}
    onOk={async () => {
      const paramsToFetchStocking: FetchStockingProps = { companyId: company._id, campusId: selectedCampus?._id, moduleId, tankId, page: currentPage, phaseType: selectedStocking.phaseType, stockingsToShow };
      const makeFetchStockings = pathname === '/production/stockings';

      switch (currentTabSetting) {
        case stockingCurrentTab.EDIT: {
          switch (selectedStocking.phaseType) {
            case stockingPhaseTypes.LARVAE: {
              await editLarvaeStocking({ paramsToFetchStocking, makeFetchStockings });
              break;
            }

            case stockingPhaseTypes.JUVENILE: {
              await editJuvenileStocking({ paramsToFetchStocking, makeFetchStockings });
              break;
            }

            case stockingPhaseTypes.ADULT: {
              await editGrowOutStocking({ paramsToFetchStocking, makeFetchStockings });
              break;
            }
          }
          break;
        }

        case stockingCurrentTab.INVOICE: {
          const stockingData = {
            tankId: selectedStocking.tankId?._id,
            code: selectedStocking.code,
            userId: userSession._id,
            invoiceImg: reducedImage,
          };

          const params = { id: selectedStocking?._id, stockingData, makeFetchStockings, paramsToFetchStocking };
          dispatch(updateReferralGuide(params));
          dispatch(setShowModalEdit(false));
          cleanImage();
        }
      }
    }}
    okText={getTextButton()}
    cancelText={t('stockings.cancel')}
    onCancel={() => {
      switch (currentTabSetting) {
        case stockingCurrentTab.EDIT:
          formEditStocking.resetFields();
          break;

        case stockingCurrentTab.INVOICE:
          cleanImage();
          break;
      }

      dispatch(setCurrentTabSetting(stockingCurrentTab.EDIT));
      dispatch(setShowModalEdit(false));
    }}
    width={580}
  >
    <LrvTabs
      theme={theme}
      defaultActiveKey={currentTabSetting}
      activeKey={currentTabSetting}
      onChange={onChangeTab}
    >
      <TabPane tab={t('stockings.edit')} key={stockingCurrentTab.EDIT}>
        <LrvForm
          theme={theme}
          form={formEditStocking}
          name='formEditStocking'
          id='formEditStocking'
          layout='vertical'
          onFieldsChange={() => {
            disabledButtonForm(stockingCurrentTab.EDIT);
          }}
        >
          {renderBindingStockingCheckBox()}
          {renderLabelCampusInactive()}
          {renderLabelStockingIsPublished()}
          {renderBodyEditForm()}
          {renderBindingCodeWarning()}
        </LrvForm>
      </TabPane>

      <TabPane tab={t('stockings.referralGuide')} key={stockingCurrentTab.INVOICE}>
        <LrvForm
          theme={theme}
          layout='vertical'
        >
          <Form.Item
            name='invoiceImg'
            label={getLabelUploadFile()}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <div id='referral_guide'>
              <LrvDragger
                theme={theme}
                name='file'
                accept='image/png, image/jpeg'
                maxCount={1}
                listType='picture-card'
                showUploadList={false}
                beforeUpload={() => true}
                onChange={handleOnChange}
                customRequest={handleCustomRequest}
              >
                {image && !loadingImage ?
                  <img src={image} alt='imageVoucher' style={{ width: '100%', height: 'inherit' }} /> :
                  renderDraggerFile()}
              </LrvDragger>

              <canvas className={styles.canvasInvoice} ref={canvasRef} width={voucherWidth} height={voucherHeight} id='canvas' />
            </div>
          </Form.Item>

          {image && <div className={styles.refferedGuide} >{t('stockings.descriptionRefferedGuide')}</div>}
        </LrvForm>
      </TabPane>
    </LrvTabs>
  </LrvModal>;
}

export default EditStockingModal;