import cx from 'classnames';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { isDesktop } from 'react-device-detect';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Dropdown, Form, Menu, Row, Select, Tabs } from 'antd';

import { Module } from '../Modules/interfaces';
import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../helpers/theme';
import { validateNumber } from '../../utils/validations';
import { LrvTag } from '../../common/components/LrvTag/LrvTag';
import { LrvMap } from '../../common/components/LrvMap/LrvMap';
import { LrvTabs } from '../../common/components/LrvTabs/LrvTabs';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../common/components/LrvText/LrvText';
import TinyLabel from '../../common/components/TinyLabel/TinyLabel';
import { LrvTable } from '../../common/components/LrvTable/LrvTable';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { getContainerVolumeLimits } from '../../helpers/tanks.helpers';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import ActionButton from '../../common/components/buttons/ActionButton';
import { fetchModule, setSelectedModule } from '../Modules/modulesSlice';
import Breadcrumbs from '../../common/components/Breadcrumb/Breadcrumbs';
import { changeBreadcrumb, changeHeader } from '../AppHeader/headerSlice';
import { lrvConfirm } from '../../common/components/LrvConfirm/LrvConfirm';
import { LrvInputNumber } from '../../common/components/LrvInputNumber/LrvInputNumber';
import { ExtraActionsButton } from '../../common/components/buttons/ExtraActionsButton';
import { fetchCampus, fetchVolumeRanges, setSelectedCampus } from '../Units/campusSlice';
import { goToOnboardingNextStep } from '../../common/components/Onboarding/OnboardingSlice';
import { applyParserThousandsSeparator, applyThousandsSeparator } from '../../utils/strings';
import { commonOptions, containerTypes, CONTAINER_LABEL, stockingPhaseTypes, tankStatuses, THEME, unitPhaseTypes, unitStatuses } from '../../config/commons';

import './Tanks.scss';
import styles from './Tanks.module.scss';
import { CreateTank, Location, Tank } from './interfaces';
import { TankPocParameterForm } from './TankPocParameterForm';
import * as tankPocParameterSlice from './tankPocParameterSlice';
import { createTank, createTanks, deleteTank, fetchTanks, setSelectedTank, setTanks, updateTank } from './tanksSlice';

type TParams = { unitId: string; moduleId: string };
const { Option } = Select;
const { TabPane } = Tabs;

const TANK_SINGLE = 'single';
const TANK_BATCH = 'batch';

const Tanks = ({ match }: RouteComponentProps<TParams>) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const unitId = match.params.unitId;
  const moduleId = match.params.moduleId;

  const [formNewTank] = Form.useForm();
  const [formEditTank] = Form.useForm();
  const [formNewTanks] = Form.useForm();
  const baseName = t('campus.batchCreation.baseNameExample');
  const baseCode = t('campus.batchCreation.baseCodeExample');

  const [showCreateTankModal, setShowCreateTankModal] = useState(false);
  const [showUpdateTankModal, setShowUpdateTankModal] = useState(false);
  const [newTankName, setNewTankName] = useState('');
  const [newTankCode, setNewTankCode] = useState('');
  const [newTankTypeEdit, setNewTankTypeEdit] = useState('');
  const [newTankBaseName, setNewTankBaseName] = useState(baseName);
  const [newTankBaseCode, setNewTankBaseCode] = useState(baseCode);
  const [disabledButtonTankForm, setDisabledButtonTankForm] = useState(true);
  const [errorLengthTankCode, setErrorLengthTankCode] = useState(false);

  const [location, setLocation] = useState<Location>();

  const [newTankFromNumber, setNewTankFromNumber] = useState(1);
  const [newTankToNumber, setNewTankToNumber] = useState(1);
  const [newTankVolume, setNewTankVolume] = useState<number | string>('');
  const [newTankType, setNewTankType] = useState(containerTypes.TANK);
  const [activeTab, setActiveTab] = useState(TANK_SINGLE);

  const isRunningOnboarding = useSelector((state: Store) => state.onboarding.run);

  const { selectedCampus, volumeRanges } = useSelector((state: Store) => state.campus);
  const { selectedModule, isModuleLoading } = useSelector((state: Store) => state.modules);
  const { tanks, selectedTank, isTanksLoading } = useSelector((state: Store) => state.tanks);
  const { breadcrumb } = useSelector((state: Store) => state.header);

  const isCampusInactive = selectedCampus.status === unitStatuses.INACTIVE;
  const unitNameRoute = t('campus.title');

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;
  const containerVolumeLimits = getContainerVolumeLimits({ volumeRanges, phaseType: selectedModule.phaseType });

  const MAX_CONTAINER_BASE_CODE_LENGTH = 4;
  const MAX_CONTAINER_CODE_LENGTH = 6;
  const MAX_CONTAINER_CODE_FROM = 1;
  const MAX_CONTAINER_CODE_TO = 9999;

  const columns = [
    {
      title: t('campus.name'),
      dataIndex: 'name',
    },
    {
      title: t('campus.code'),
      dataIndex: 'code',
    },
    {
      title: t('campus.capacity'),
      dataIndex: 'volume',
    },
  ];

  const data: { key: number; name: string; code: string; volume: string }[] | undefined = [];
  for (let i: number = newTankFromNumber; i <= newTankToNumber; ++i) {
    const prefix = t(`campus.prefixUnitOfMeasurement.${selectedCampus.phaseType}.${newTankType}`);

    data.push({
      key: i,
      name: `${newTankBaseName} ${i}`,
      code: `${newTankBaseCode}${i}`.toUpperCase(),
      volume: `${applyThousandsSeparator(newTankVolume)} ${prefix}`,
    });
  }

  useEffect(() => {
    if (selectedCampus.name && selectedModule.name) {
      const breadcrumb = [
        { id: 'units', name: unitNameRoute, url: '/units' },
        { id: 'modules', name: selectedCampus.name, url: `/units/${unitId}` },
        { id: 'tanks', name: selectedModule.name, url: `/units/${unitId}/modules/${moduleId}` },
      ];
      dispatch(changeBreadcrumb(breadcrumb));
    }
  }, [dispatch, unitNameRoute, selectedModule.name, selectedCampus.name]);

  useEffect(() => {
    dispatch(changeHeader({ title: 'campus.title' }));
    dispatch(fetchCampus(unitId));
    dispatch(fetchModule(moduleId));
    dispatch(fetchTanks(moduleId));
    dispatch(fetchVolumeRanges());
  }, [dispatch, unitId, moduleId]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedCampus({ _id: '', name: '', code: '', timezone: '', companyId: '', phaseType: '', country: '', province: '', status: '' }));
      dispatch(setSelectedModule({ _id: '' } as Module));
      dispatch(setTanks([]));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!selectedTank._id) {
      return;
    }

    formEditTank.setFieldsValue({
      tankName: selectedTank.name,
      tankCode: selectedTank.code,
      tankType: t(`campus.containerTypes.${selectedTank.type}`),
      tankVolume: selectedTank.volume ?? '0'
    });

    setNewTankName(selectedTank.name);
    setNewTankCode(selectedTank.code);
    setNewTankTypeEdit(t(`campus.containerTypes.${selectedTank.type}`));
    setNewTankVolume(selectedTank.volume?.toString() || '0');
    setNewTankType(selectedTank.type);
  }, [formEditTank, selectedTank._id, selectedTank.code, selectedTank.name, selectedTank.type, selectedTank.volume, t]);

  function showDeleteTankConfirm (tankId: string, tankName: string, tankType: string) {
    let content: JSX.Element | string = tankName;

    if (isCampusInactive) {
      content = (
        <div className={styles.confirmModal}>
          <div className={styles.description}>
            {content}
          </div>
          <br />
          <div>
            <TinyLabel className={styles.campusInactive} text={t('analysis.inactiveCampus')} />
          </div>
        </div>
      );
    }

    lrvConfirm({
      theme: 'light',
      title: t('campus.confirmDelete'),
      icon: <ExclamationCircleOutlined />,
      content: content,
      okText: t('campus.delete'),
      okButtonProps: { id: 'btnOkDeleteContainer', disabled: isCampusInactive },
      okType: 'primary',
      cancelText: t('campus.cancel'),
      onOk () {
        dispatch(deleteTank(tankId, tankType));
        dispatch(setSelectedTank({ _id: '' } as Tank));
      },
    });
  }

  function closeTankModal () {
    setShowCreateTankModal(false);
    setNewTankName('');
    setNewTankCode('');
    setNewTankVolume('0');
    setActiveTab(TANK_SINGLE);

    formNewTank.resetFields();
    formNewTanks.resetFields();

    setDisabledButtonTankForm(true);
  }

  const checkInteger = () => {
    return (selectedCampus.phaseType === unitPhaseTypes.LARVAE && newTankType === containerTypes.TANK);
  };

  const checkApplyFormatter = () => {
    return selectedCampus.phaseType === unitPhaseTypes.LARVAE;
  };

  const renderOptionDropdownTypeContainer = () => {
    if (selectedCampus.phaseType !== unitPhaseTypes.PRODUCTION) {
      return;
    }

    if (selectedModule.phaseType === stockingPhaseTypes.JUVENILE) {
      return (
        <>
          <Option key={containerTypes.RACEWAY} value={containerTypes.RACEWAY}>{t(`campus.containerTypes.${containerTypes.RACEWAY}`)}</Option>
          <Option key={containerTypes.PRE_HATCHERY} value={containerTypes.PRE_HATCHERY}>{t(`campus.containerTypes.${containerTypes.PRE_HATCHERY}`)}</Option>
        </>
      );
    }

    if (selectedModule.phaseType === stockingPhaseTypes.ADULT) {
      return (
        <>
          <Option key={containerTypes.RACEWAY} value={containerTypes.RACEWAY}>{t(`campus.containerTypes.${containerTypes.RACEWAY}`)}</Option>
          <Option key={containerTypes.POOL} value={containerTypes.POOL}>{t(`campus.containerTypes.${containerTypes.POOL}`)}</Option>
        </>
      );
    }

  };

  const renderDropdownTypeContainer = () => {
    if (selectedCampus.phaseType === unitPhaseTypes.LARVAE) {
      return null;
    }

    return (
      <Form.Item
        name='tankType'
        label={t('campus.type')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvSelect
          theme='light'
          className={styles.select}
          style={{ width: '100%' }}
          suffixIcon={<Icon name='arrow-down-s' />}
          placeholder={t('campus.phase')}
          optionFilterProp='children'
          value={newTankType}
          onChange={value => {
            setNewTankType(value);
            formNewTank.setFieldsValue({
              tankCapacity: containerVolumeLimits[value].min,
            });
            formNewTanks.setFieldsValue({
              containersCapacity: containerVolumeLimits[value].min,
            });
            setNewTankVolume(containerVolumeLimits[value].min.toString());
          }}
          dropdownMatchSelectWidth={false}
          disabled={isCampusInactive}
        >
          {renderOptionDropdownTypeContainer()}
        </LrvSelect >
      </Form.Item>
    );
  };

  const tanksColumns: ColumnsType<Tank> = [
    {
      key: 1,
      title: t('campus.code'),
      dataIndex: 'code',
      ellipsis: { showTitle: false },
      width: '14%',
      responsive: ['md'] as Breakpoint[],
    },
    {
      key: 2,
      title: t('campus.name'),
      dataIndex: 'name',
      ellipsis: { showTitle: false },
      width: '25%',
    },
    {
      key: 3,
      title: t('campus.volume'),
      dataIndex: 'volume',
      ellipsis: { showTitle: false },
      width: '20%',
      render: (_, record: Tank) => {
        if (!record.volume) {
          return '';
        }

        const prefix = t(`campus.prefixUnitOfMeasurement.${selectedCampus.phaseType}.${record.type}`);
        const volume = `${applyThousandsSeparator(record.volume)} ${prefix}`;
        return volume;
      }
    },
    {
      key: 4,
      ellipsis: { showTitle: false },
      width: isDesktop ? '25%' : '20%',
      render: (_, record: Tank) => {
        if (record.status === tankStatuses.SOWN) {
          return (
            <Row align='middle'>
              <LrvTag id={`status_tag_${record.status}`} theme={theme} type='info'>{t('campus.tankSown')}</LrvTag>
            </Row>
          );
        }
        return (
          <Row align='middle'>
            <LrvTag id={`status_tag_${record.status}`} theme={theme} type='active'>{t('campus.tankAvailable')}</LrvTag>
          </Row>
        );
      },
    },
    {
      key: 5,
      className: styles.optionsCell,
      width: '15%',
      render: (_, record: Tank) => (
        renderDropdownTankOptions(record)
      ),
    }
  ];

  const renderDropdownTankOptions = (record: Tank) => {
    return (
      <Dropdown
        overlay={() => tankMenuItems(record)}
        trigger={['click']}
        placement='bottomRight'
      >
        <ExtraActionsButton
          onClick={(e) => e.stopPropagation()}
          id={`button_${record._id}`}
        />
      </Dropdown>
    );
  };

  const tankMenuItems = (record: Tank) => {
    return (
      <Menu
        onClick={({ key, domEvent }) => {
          domEvent.stopPropagation();

          switch (key) {
            case commonOptions.EDIT:
              setShowUpdateTankModal(true);
              dispatch(setSelectedTank(record));
              return;
              
            case commonOptions.REMOVE:
              showDeleteTankConfirm(record._id, record.name, record.type);
              return;
                
            case commonOptions.POC:
              dispatch(tankPocParameterSlice.setShowCreateModal(true));
              dispatch(tankPocParameterSlice.setTankId(record._id));
              dispatch(tankPocParameterSlice.fetchTankPocParameter({ tankId: record._id, companyId: selectedCampus.companyId }));
              return;
          }
        }}
      >
        <Menu.Item id='unit_menu_option_config' key={commonOptions.EDIT} className={styles.menuItemOptions}>
          <Icon name='edit' type='line' theme={theme} className={styles.icon} />
          <span>{t('campus.edit')}</span>
        </Menu.Item>

        <Menu.Item id='unit_menu_option_remove' key={commonOptions.REMOVE} className={styles.menuItemOptions}>
          <Icon name='delete-bin' type='line' theme={theme} className={styles.icon} />
          <span>{t('campus.delete')}</span>
        </Menu.Item>

        {
          selectedModule.phaseType === stockingPhaseTypes.ADULT &&
          <Menu.Item id='unit_menu_option_poc' key={commonOptions.POC} className={styles.menuItemOptions}>
            <Icon name='settings-4' type='line' theme={theme} className={styles.icon} />
            <span>{t('formPocParameters.poc')}</span>
          </Menu.Item>
        }
      </Menu>
    );
  };

  return (
    <div className={styles.tanks}>
      <Breadcrumbs breadcrumb={breadcrumb} theme={theme} />
      <Row align='middle' justify='space-between' className={styles.rowHeader}>
        <div className={styles.title}>
          <LrvText text={t('campus.tanksIn', { module: selectedModule.name })} theme={theme} />
        </div>
        <ActionButton
          id='btn_new_tank'
          type='primary'
          icon={<Icon name='add' />}
          className={styles.btnAddTank}
          onClick={() => {
            setNewTankName('');
            setNewTankCode('');
            setNewTankVolume('0');

            setNewTankFromNumber(1);
            setNewTankToNumber(1);
            setNewTankBaseName(baseName);
            setNewTankBaseCode(baseCode);

            setShowCreateTankModal(true);

            switch (selectedCampus.phaseType) {
              case unitPhaseTypes.LARVAE:
                setNewTankType(containerTypes.TANK);
                formNewTank.setFieldsValue({
                  tankCapacity: containerVolumeLimits[containerTypes.TANK].min
                });

                formNewTanks.setFieldsValue({
                  containersCapacity: containerVolumeLimits[containerTypes.TANK].min
                });

                setNewTankVolume(containerVolumeLimits[containerTypes.TANK].min.toString());
                break;
              case unitPhaseTypes.PRODUCTION:
                switch (selectedModule.phaseType) {
                  case stockingPhaseTypes.JUVENILE:
                    setNewTankType(containerTypes.PRE_HATCHERY);
                    formNewTank.setFieldsValue({
                      tankType: containerTypes.PRE_HATCHERY,
                      tankCapacity: containerVolumeLimits[containerTypes.PRE_HATCHERY].min
                    });
                    formNewTanks.setFieldsValue({
                      tankType: containerTypes.PRE_HATCHERY,
                      containersCapacity: containerVolumeLimits[containerTypes.PRE_HATCHERY].min
                    });
                    setNewTankVolume(containerVolumeLimits[containerTypes.PRE_HATCHERY].min.toString());
                    break;

                  case stockingPhaseTypes.ADULT:
                    setNewTankType(containerTypes.POOL);
                    formNewTank.setFieldsValue({
                      tankType: containerTypes.POOL,
                      tankCapacity: containerVolumeLimits[containerTypes.POOL].min
                    });
                    formNewTanks.setFieldsValue({
                      tankType: containerTypes.POOL,
                      containersCapacity: containerVolumeLimits[containerTypes.POOL].min
                    });
                    setNewTankVolume(containerVolumeLimits[containerTypes.POOL].min.toString());
                    break;
                }

                break;
            }

            dispatch(goToOnboardingNextStep(500));
          }}
        >
          {t('campus.tank')}
        </ActionButton>
      </Row>
      <Row className={styles.rowFlex}>
        <LrvTable
          id='tableContainer'
          loading={isTanksLoading}
          rowClassName={(record) => cx(styles.tankRow, selectedTank._id === record._id ? isLightTheme ? styles.selectedRowLight : styles.selectedRowDark : '')}
          size='small'
          columns={tanksColumns}
          dataSource={tanks}
          scroll={{ y: '' }}
          theme={theme}
          pagination={false}
        />
      </Row>

      <LrvModal
        theme='light'
        title={t(`campus.newTank.${newTankType ?? containerTypes.TANK}`)}
        open={showCreateTankModal}
        destroyOnClose={true}
        okButtonProps={{ form: 'formNewTank', id: 'formNewContainer_btnSave', disabled: disabledButtonTankForm || isCampusInactive }}
        onOk={async () => {
          switch (activeTab) {
            case TANK_SINGLE:
              formNewTank.setFieldsValue({
                tankName: newTankName,
                tankCode: newTankCode,
                tankType: newTankType,
                tankCapacity: newTankVolume,
              });

              await formNewTank.validateFields();

              dispatch(createTank({
                name: newTankName,
                code: newTankCode,
                volume: parseFloat(newTankVolume.toString()),
                moduleId: selectedModule._id,
                campusId: selectedCampus._id,
                type: newTankType
              }));
              closeTankModal();
              break;
            case TANK_BATCH: {
              formNewTanks.setFieldsValue({
                baseName: newTankBaseName,
                baseCode: newTankBaseCode,
                tankType: newTankType,
                containersCapacity: newTankVolume,
              });

              await formNewTanks.validateFields();

              const tanks: CreateTank[] = data.map(tank => {
                return {
                  name: tank.name,
                  code: tank.code.toUpperCase(),
                  volume: newTankVolume,
                  moduleId: selectedModule._id,
                  campusId: selectedCampus._id,
                  type: newTankType
                };
              });
              dispatch(createTanks(tanks));
              closeTankModal();
              if (isRunningOnboarding) {
                dispatch(goToOnboardingNextStep(500));
              }
            }
          }
        }}
        okText={t('campus.create')}
        cancelText={t('campus.cancel')}
        onCancel={() => {
          closeTankModal();
        }}
        className={cx(styles.createTankModal, 'createTankModal')}
      >
        <LrvTabs
          theme='light'
          id='tabsContainer'
          onChange={(key) => {
            setActiveTab(key);

            if (key === TANK_SINGLE) {
              formNewTank.setFieldsValue({
                tankType: newTankType,
              });
              const disabled = (!formNewTank.isFieldTouched('tankName') || !formNewTank.isFieldTouched('tankCode')) || formNewTank.getFieldsError().filter(({ errors }) => errors.length).length > 0;
              setDisabledButtonTankForm(disabled);
            }

            if (key === TANK_BATCH) {
              formNewTanks.setFieldsValue({
                tankType: newTankType,
              });
              const disabled = formNewTanks.getFieldsError().filter(({ errors }) => errors.length).length > 0 || errorLengthTankCode;
              setDisabledButtonTankForm(disabled);
            }
          }}
        >
          <TabPane tab={t(`campus.batchCreation.singleTabTitle.${newTankType ?? containerTypes.TANK}`)} key={TANK_SINGLE}>
            <LrvForm
              theme='light'
              form={formNewTank}
              layout='vertical'
              name='formNewTank'
              id='formNewTank'
              className='formContainer'
              onFieldsChange={() => {
                const disabled = (!formNewTank.isFieldTouched('tankName') || !formNewTank.isFieldTouched('tankCode')) || formNewTank.getFieldsError().filter(({ errors }) => errors.length).length > 0;
                setDisabledButtonTankForm(disabled);
              }}
            >
              <Form.Item
                name='tankName'
                label={t('campus.name')}
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <LrvInput
                  theme='light'
                  autoFocus
                  value={newTankName}
                  onChange={(e) => setNewTankName(e.target.value)} disabled={isCampusInactive}
                />
              </Form.Item>

              <Form.Item
                name='tankCode'
                label={t('campus.code')}
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <LrvInput
                  theme='light'
                  value={newTankCode}
                  className={styles.inputUpperCase}
                  maxLength={MAX_CONTAINER_CODE_LENGTH}
                  onChange={(e) => setNewTankCode(e.target.value)}
                  disabled={isCampusInactive}
                />
              </Form.Item>

              {renderDropdownTypeContainer()}

              <Form.Item
                name='tankCapacity'
                label={t('campus.capacity') + ' (' + t(`campus.prefixUnitOfMeasurement.${selectedCampus.phaseType}.${newTankType}`) + ')'}
                required
                rules={
                  [() => ({ validator (rule, value) { return validateNumber(value, checkInteger(), containerVolumeLimits[newTankType].min, containerVolumeLimits[newTankType].max, t('campus.capacityError', { min: containerVolumeLimits[newTankType].min, max: containerVolumeLimits[newTankType].max })); } })]
                }
              >
                <LrvInputNumber
                  theme='light'
                  value={newTankVolume}
                  className={styles.volume}
                  formatter={checkApplyFormatter() ? value => applyThousandsSeparator(value) : undefined}
                  parser={checkApplyFormatter() ? value => applyParserThousandsSeparator(value) : undefined}
                  onChange={(value) => {
                    if (value) {
                      setNewTankVolume(value);
                    }
                  }}
                />
              </Form.Item>

            </LrvForm>
          </TabPane>

          <TabPane tab={t(`campus.batchCreation.batchTabTitle.${newTankType ?? containerTypes.TANK}`)} key={TANK_BATCH} id='tabContainerBatch'>
            <LrvForm
              theme='light'
              form={formNewTanks}
              name='formNewTanks'
              id='formNewTanks'
              layout='vertical'
              className='formBatchContainer'
              onFieldsChange={() => {
                const disabled = formNewTanks.getFieldsError().filter(({ errors }) => errors.length).length > 0 || errorLengthTankCode;
                setDisabledButtonTankForm(disabled);
              }}
            >
              <Row gutter={16}>
                <Col span={7}>
                  <Form.Item
                    name='baseName'
                    label={t('campus.batchCreation.baseName')}
                    rules={[{ required: true, message: t('common.requiredField') }]}
                    initialValue={baseName}
                  >
                    <LrvInput
                      theme='light'
                      value={newTankBaseName}
                      onChange={(e) => setNewTankBaseName(e.target.value)}
                      disabled={isCampusInactive}
                    />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item
                    name='baseCode'
                    label={t('campus.batchCreation.baseCode')}
                    rules={[{ required: true, message: t('common.requiredField') }]}
                    initialValue={baseCode}
                  >
                    <LrvInput
                      theme='light'
                      value={newTankBaseCode}
                      className={styles.inputUpperCase}
                      maxLength={MAX_CONTAINER_BASE_CODE_LENGTH}
                      disabled={isCampusInactive}
                      onChange={(e) => {
                        const maxNumber = newTankToNumber > newTankFromNumber ? newTankToNumber : newTankFromNumber;
                        const newCode = e.target.value + maxNumber;

                        setErrorLengthTankCode(newCode.length > MAX_CONTAINER_CODE_LENGTH);
                        setNewTankBaseCode(e.target.value);
                      }
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={5}
                  className='from'
                >
                  <Form.Item
                    name='from'
                    label={t('campus.batchCreation.from')}
                    rules={[{ required: true, message: t('common.requiredField') }]}
                    initialValue={1}
                  >
                    <LrvInputNumber
                      theme='light'
                      value={newTankFromNumber}
                      min={MAX_CONTAINER_CODE_FROM}
                      max={MAX_CONTAINER_CODE_TO}
                      disabled={isCampusInactive}
                      onChange={(value) => {
                        if (!value) {
                          return;
                        }

                        const maxNumber = newTankToNumber > Number(value) ? newTankToNumber : value;
                        const newCode = newTankBaseCode + maxNumber;

                        setErrorLengthTankCode(newCode.length > MAX_CONTAINER_CODE_LENGTH);
                        setNewTankFromNumber(value as number);
                      }} />
                  </Form.Item>
                </Col>
                <Col span={5}
                  className='to'
                >
                  <Form.Item
                    name='to'
                    label={t('campus.batchCreation.to')}
                    rules={[{ required: true, message: t('common.requiredField') }]}
                    initialValue={1}
                  >
                    <LrvInputNumber
                      theme='light'
                      value={newTankToNumber}
                      min={MAX_CONTAINER_CODE_FROM}
                      max={MAX_CONTAINER_CODE_TO}
                      disabled={isCampusInactive}
                      onChange={(value) => {
                        if (!value) {
                          return;
                        }

                        const maxNumber = Number(value) > newTankFromNumber ? value : newTankFromNumber;
                        const newCode = newTankBaseCode + maxNumber;

                        setErrorLengthTankCode(newCode.length > MAX_CONTAINER_CODE_LENGTH);
                        setNewTankToNumber(value as number);
                      }} />
                  </Form.Item>
                </Col>
              </Row>

              {renderDropdownTypeContainer()}

              <Form.Item
                name='containersCapacity'
                label={t('campus.capacity') + ' (' + t(`campus.prefixUnitOfMeasurement.${selectedCampus.phaseType}.${newTankType}`) + ')'}
                required
                rules={
                  [() => ({ validator (rule, value) { return validateNumber(value, checkInteger(), containerVolumeLimits[newTankType].min, containerVolumeLimits[newTankType].max, t('campus.capacityError', { min: containerVolumeLimits[newTankType].min, max: containerVolumeLimits[newTankType].max })); } })]
                }
              >
                <LrvInputNumber
                  theme='light'
                  value={newTankVolume}
                  className={styles.volume}
                  formatter={checkApplyFormatter() ? value => applyThousandsSeparator(value) : undefined}
                  parser={checkApplyFormatter() ? value => applyParserThousandsSeparator(value) : undefined}
                  onChange={(value) => {
                    if (value) {
                      setNewTankVolume(value);
                    }
                  }}
                />
              </Form.Item>
            </LrvForm>

            {
              errorLengthTankCode &&
              <div className={styles.alertError}>{t('campus.batchCreation.errorCodeLength', { value: MAX_CONTAINER_CODE_LENGTH })}</div>
            }

            <LrvText theme='light' text={t('campus.batchCreation.tanksToCreate')} className={styles.tableDescription} />

            <LrvTable
              theme='light'
              scroll={{ y: 150 }}
              columns={columns}
              dataSource={data}
              size='small'
              pagination={false}
            />
          </TabPane>
        </LrvTabs>
      </LrvModal>

      <LrvModal
        theme='light'
        className='editTankModal'
        title={t(`campus.editTank.${selectedTank.type ?? CONTAINER_LABEL[selectedCampus.phaseType]}`)}
        open={showUpdateTankModal}
        destroyOnClose={true}
        okButtonProps={{ disabled: isModuleLoading || isCampusInactive, form: 'formEditTank', id: 'formEditTank_btnSave' }}
        onOk={() => {
          formEditTank.setFieldsValue({
            tankName: newTankName,
            tankCode: newTankCode,
            tankVolume: newTankVolume,
          });

          formEditTank.validateFields()
            .then(() => {
              formEditTank.submit();
            });
        }}
        okText={t('campus.save')}
        cancelText={t('campus.cancel')}
        onCancel={() => {
          setShowUpdateTankModal(false);
          setNewTankName('');
          setNewTankCode('');
          setNewTankVolume('0');
          setNewTankTypeEdit('');
          dispatch(setSelectedTank({ _id: '' } as Tank));
          formEditTank.resetFields();
        }}
      >
        <LrvForm
          theme='light'
          form={formEditTank}
          layout='vertical'
          name='formEditTank'
          onFinish={() => {
            const tankData = {
              _id: selectedTank._id,
              name: newTankName,
              code: newTankCode,
              volume: parseFloat(newTankVolume.toString()),
              active: true,
              moduleId: selectedModule._id,
              latitude: location?.latitude || undefined,
              longitude: location?.longitude || undefined,
              type: selectedTank.type,
            };

            dispatch(updateTank(tankData));
            setShowUpdateTankModal(false);
            setNewTankName('');
            setNewTankCode('');
            setNewTankVolume('0');
            dispatch(setSelectedTank({ _id: '' } as Tank));
            formEditTank.resetFields();

            if (isRunningOnboarding) {
              dispatch(goToOnboardingNextStep(800));
            }
          }}
        >
          <Form.Item
            label={t('campus.name')}
            name='tankName'
            rules={[{ required: true, message: t('campus.nameRequired') }]}
            initialValue={newTankName}
          >
            <LrvInput
              theme='light'
              autoFocus
              value={newTankName}
              onChange={(e) => setNewTankName(e.target.value)}
              disabled={isCampusInactive}
            />
          </Form.Item>

          <Form.Item
            label={t('campus.code')}
            name='tankCode'
            initialValue={newTankCode}
            rules={[{ required: true, message: t('campus.codeRequired') }]}
          >
            <LrvInput
              theme='light'
              value={newTankCode}
              className={styles.inputUpperCase}
              maxLength={MAX_CONTAINER_CODE_LENGTH}
              onChange={(e) => setNewTankCode(e.target.value)}
              disabled={isCampusInactive}
            />
          </Form.Item>

          {selectedCampus.phaseType === unitPhaseTypes.PRODUCTION ?
            <Form.Item
              label={t('campus.type')}
              name='tankType'
              initialValue={newTankTypeEdit}
            >
              <LrvInput
                theme='light'
                readOnly
                style={{ color: 'grey' }}
                disabled={isCampusInactive}
              />
            </Form.Item> : null}

          <Form.Item
            name='tankVolume'
            label={t('campus.volume') + ' (' + t(`campus.prefixUnitOfMeasurement.${selectedCampus.phaseType}.${newTankType}`) + ')'}
            initialValue={newTankVolume}
            required
            rules={
              [() => ({
                validator (rule, value) {
                  return validateNumber(value, checkInteger(), containerVolumeLimits[selectedTank.type].min, containerVolumeLimits[selectedTank.type].max, t('campus.capacityError', { min: containerVolumeLimits[selectedTank.type].min, max: containerVolumeLimits[selectedTank.type].max }));
                }
              })]
            }
          >
            <LrvInputNumber
              theme='light'
              value={newTankVolume}
              className={styles.volume}
              formatter={checkApplyFormatter() ? value => applyThousandsSeparator(value) : undefined}
              parser={checkApplyFormatter() ? value => applyParserThousandsSeparator(value) : undefined}
              onChange={(value) => {
                if (value) {
                  setNewTankVolume(value);
                }
              }}
              disabled={isCampusInactive}
            />
          </Form.Item>

          <Form.Item
            label={t('campus.location')}
          >
            <LrvMap
              onLocationSelect={setLocation}
              containerLocation={{ latitude: selectedTank.latitude, longitude: selectedTank.longitude }}
            />
          </Form.Item>
        </LrvForm>
      </LrvModal>

      <TankPocParameterForm/>
    </div>
  );
};

export default Tanks;