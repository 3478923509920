import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getModalWidth } from '../helpers';
import { weightUnits } from '../../../config/commons';
import Icon from '../../../common/components/Icon/Icon';
import { Store } from '../../../state/store.interfaces';
import { TankPocParameter } from '../../Tanks/interfaces';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import { FoodChartD3 } from '../../../common/components/charts/OptimalHarvestPoint/FoodChartD3';

import styles from './FoodChart.module.scss';
import * as feedingTableSlice from './feedingTableSlice';
import { processFeedingRegression } from './helpers.food';

let chart: FoodChartD3 | null = null;

interface Props {
  theme?: 'dark' | 'light';
}

export const FoodChart = (props: Props) => {
  const { theme = 'light' } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const refChart = useRef<HTMLDivElement>(null);

  const { company } = useSelector((state: Store) => state.header);
  const { filters } = useSelector((state: Store) => state.optimalHarvestPoint);

  const {
    showFoodChartModal,
    feedingDataset,
    isLoading,
    tankPocParameter,
  } = useSelector((state: Store) => state.feedingTable);

  const { feedingStrategy, tankId, unitId, costPerVolumeDay, loadCapacity } = filters;
  const { points } = feedingDataset;

  const { graphWidth, modalWidth, graphHeight } = getModalWidth();

  useEffect(() => {
    return () => {
      chart = null;
    };
  }, [dispatch]);

  useEffect(() => {
    if (!points?.length || !refChart.current) {
      return;
    }

    const { smoothCurve, r2, scaleFactor, slope } = processFeedingRegression(points);
    dispatch(feedingTableSlice.setDailyRation({ scaleFactor: scaleFactor * feedingStrategy, slope, r2 }));

    if (!showFoodChartModal) {
      return;
    }

    if (chart) {
      chart.refreshChart({
        points,
        line: smoothCurve,
      });
      return;
    }
    
    chart = new FoodChartD3({
      container: refChart.current,
      points,
      line: smoothCurve,
      height: graphHeight,
      width: graphWidth,
      dispatch,
    });
  }, [dispatch, points, refChart.current, feedingStrategy, showFoodChartModal]);

  useEffect(() => {
    if (!chart) {
      return;
    }

    if (chart instanceof FoodChartD3) {
      chart.resize({ width: graphWidth, height: graphHeight });
    }
  }, [graphHeight, graphWidth]);

  const onCancel = () => {
    dispatch(feedingTableSlice.resetTankPocParameter());
    dispatch(feedingTableSlice.resetFeedingDataset());
    dispatch(feedingTableSlice.setShowFoodChartModal(false));
  };

  const onSave = () => {
    const body: TankPocParameter = {
      _id: tankPocParameter?._id,
      companyId: company._id,
      campusId: unitId,
      tankId,
      dataset: points,
      feedingStrategy,
      costPerVolumeDay,
      loadCapacity,
    };

    if (body._id) {
      dispatch(feedingTableSlice.updateFeedingTable({ body }));
      return;
    }

    dispatch(feedingTableSlice.createFeedingTable({ body }));
  };

  return (
    <LrvModal
      theme='light'
      title={t('optimalHarvestPoint.feedingTable.title')}
      open={showFoodChartModal}
      destroyOnClose={true}
      closeIcon={<Icon id='close_modal' name='close' />}
      onCancel={onCancel}
      onOk={onSave}
      className={styles.foodChartModal}
      width={modalWidth}
      forceRender
      okText={t('optimalHarvestPoint.buttons.save')}
      cancelText={t('optimalHarvestPoint.buttons.cancel')}
      okButtonProps={{ loading: isLoading }}
    >
      <div
        className={styles.container}
      >
        <div className={styles.row}>
          <div className={styles.description}>
            <LrvText text={`* ${t('optimalHarvestPoint.feedingTable.addPoint')}`} theme={theme}/>
            <LrvText text={`* ${t('optimalHarvestPoint.feedingTable.movePoint')}`} theme={theme}/>
            <LrvText text={`* ${t('optimalHarvestPoint.feedingTable.removePoint')}`} theme={theme}/>
          </div>
        </div>

        <div className={styles.labelAxisY}>
          <LrvText text={t('optimalHarvestPoint.feedingTable.dailyRation')} theme={theme}/>
        </div>

        <div
          className={styles.chart}
          ref={refChart}
        />

        <div className={styles.labelAxisX}>
          <LrvText text={`${t('optimalHarvestPoint.feedingTable.averageWeight')} ${weightUnits.G}`} theme={theme}/>
        </div>
      </div>
    </LrvModal>
  );
};
