import { getCurrentElementHeight } from '../../../utils/dimensions';
import { DEFAULT_STAGE_MAX, TICKS_DAYS_RANGE } from '../../../config/commons';
import { colorsPoints, colorsStroke } from '../../../common/components/charts/ShadedPlot/helpers';

import { ChartData, PrevStatsByTanks, PrevTankSelected } from './interfaces';

export function groupDataSource (dataSource: ChartData[], prevTanksSelected: PrevTankSelected[]) {
  const availableTanksIds = prevTanksSelected.map(item => item.tankId);
  const dataSourceFiltered = dataSource.filter(item => availableTanksIds.some(tankId => tankId === item.previousTankId));

  const groupedData = dataSourceFiltered.reduce((acc: PrevStatsByTanks[], curr: ChartData) => {
    const existingEntry = acc.find(item => item.tankId === curr.previousTankId);
    const { previousTankId, previousTankName, avg, stageRange, rangeId, previousPhaseType, destinationData, previousStockingId, previousStockingName } = curr;

    if (existingEntry) {
      existingEntry.prevStats.push({ tankId: curr.previousTankId, avg, stageRange, rangeId, phaseType: previousPhaseType, destination: destinationData, stockingId: previousStockingId, stockingName: previousStockingName });
    } else {
      acc.push({
        tankId: previousTankId,
        tankName: previousTankName,
        prevStats: [{ tankId: previousTankId, avg, stageRange, rangeId, phaseType: previousPhaseType, destination: destinationData, stockingId: previousStockingId, stockingName: previousStockingName }],
      });
    }

    return acc;
  }, []);

  return groupedData;
}

export function calcPrevStatsStages (dataSource: PrevStatsByTanks[], maxStage: number = DEFAULT_STAGE_MAX) {
  const stages = [];
  for (let index = 0; index < dataSource.length; index++) {
    const points = dataSource[index].prevStats;

    if (points && points.length !== 0) {

      // eslint-disable-next-line max-depth
      for (const point of points) {
        const stage = point.rangeId;
        stages.push(stage);
      }
    }
  }

  let firstStage = -1;
  let lastStage = 0;

  for (let index = 0; index < stages.length; index++) {
    const stage = stages[index];
    if ((firstStage === -1 || firstStage > stage)) {
      firstStage = stage;
    }

    if (lastStage < stage) {
      lastStage = stage;
    }
  }

  const shouldDrawWholeArea = (lastStage - firstStage + 1) < 2 || lastStage === firstStage;
  firstStage = shouldDrawWholeArea ? firstStage - 1 < 1 ? 1 : firstStage - 1 : firstStage;
  lastStage = shouldDrawWholeArea ? lastStage + 1 > maxStage ? maxStage : lastStage + 1 : lastStage;
  return [firstStage, lastStage];
}

interface HeightProps {
  filters: React.RefObject<HTMLDivElement>;
}

export const getHeightOfTheOtherElements = (props: HeightProps) => {
  const { filters } = props;

  const headerHeight = 64;
  const axisXHeight = 24;
  const labelTopHeight = 24;
  const extraHeight = 64;

  const value = getCurrentElementHeight(filters) + axisXHeight + headerHeight + extraHeight + labelTopHeight;
  return value;
};

const getTanksPanelWidth = (element: React.RefObject<HTMLDivElement>) => {
  return element.current?.offsetWidth || 0;
};

interface WidthProps {
  tanksPanel: React.RefObject<HTMLDivElement>;
}

export const getWidthOfTheOtherElements = (props: WidthProps) => {
  const { tanksPanel } = props;
  const extraWidth = 40;

  if (window.innerWidth <= 950) {
    return getTanksPanelWidth(tanksPanel) + extraWidth;
  }

  return getTanksPanelWidth(tanksPanel) + extraWidth + 80;
};

interface LeftPositionProps {
  marginLeft: number;
  tooltipDialogWidth: number;
  bubbleWidth: number;
  width: number;
}

export const getPrevStatChartLeftPosition = (props: LeftPositionProps) => {
  const { marginLeft, tooltipDialogWidth, bubbleWidth, width } = props;

  const tooltipTotalWidth = tooltipDialogWidth + bubbleWidth;

  let value = 0;
  if (marginLeft + tooltipTotalWidth < width) {
    value = marginLeft + (tooltipDialogWidth / 2) + bubbleWidth - 32;
  } else {
    value = marginLeft - (tooltipDialogWidth / 2) - bubbleWidth - 32;
  }

  return `${value}px`;
};

export const renderXTickFormat = (domainValue: d3.AxisDomain) => {
  const x = Number(domainValue);
  if (!Number.isInteger(x)) {
    return '';
  }

  if (x < 0) {
    return '';
  }

  const x2 = (x * TICKS_DAYS_RANGE) - 1;
  const x1 = (x * TICKS_DAYS_RANGE) - TICKS_DAYS_RANGE;
  return `${x1}-${x2}`;
};

export const getNewPrevTankColor = (prevTanksSelected: PrevTankSelected[]) => {
  const [color1, color2, color3, color4, color5] = colorsPoints;
  const [colorStroke1, colorStroke2, colorStroke3, colorStroke4, colorStroke5] = colorsStroke;
  const lineColors = [color1, color2, color3, color4, color5];
  const strokeColors = [colorStroke1, colorStroke2, colorStroke3, colorStroke4, colorStroke5];

  const availableLineColors = lineColors.filter(lineColor => !prevTanksSelected.some(item => item.lineColor === lineColor));
  const availableStrokeColors = strokeColors.filter(strokeColor => !prevTanksSelected.some(item => item.strokeColor === strokeColor));
  
  return { lineColor: availableLineColors[0], strokeColor: availableStrokeColors[0] };
};