import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { ChartParameter } from '../interfaces';
import { THEME } from '../../../config/commons';
import Icon from '../../../common/components/Icon/Icon';
import { Store } from '../../../state/store.interfaces';
import { getCurrentTheme } from '../../../helpers/theme';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import * as optimalHarvestPointSlice from '../optimalHarvestPointSlice';
import { MiniForecastMetricD3 } from '../../../common/components/charts/OptimalHarvestPoint/MiniForecastMetricD3';

import styles from './MiniChart.module.scss';

interface Props {
  chartParameter: ChartParameter;
  title: string;
  width: number;
}

export const MiniChart = (props: Props) => {
  const { chartParameter, title, width } = props;

  const dispatch = useDispatch();
  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  const refChart = useRef<HTMLDivElement>(null);
  const chart = useRef<MiniForecastMetricD3 | null>(null);

  const { company: selectedCompany } = useSelector((state: Store) => state.header);
  const {
    firstStage,
    lastStage,
    allPredictions,
    predictionSelected,
    pocPoint,
  } = useSelector((state: Store) => state.optimalHarvestPoint);

  const point = useMemo(() => predictionSelected || pocPoint, [predictionSelected, pocPoint]);
  const height = 102;

  useEffect(() => {
    return () => {
      chart.current = null;
    };
  }, [dispatch]);

  const refreshChart = useCallback(() => {
    if (allPredictions.length === 0 || !point?.packerId || !refChart.current) {
      return;
    }
  
    if (chart.current) {
      chart.current.refreshChart({
        allPredictions,
        companyData: selectedCompany,
        chartParameter,
        firstStage,
        lastStage,
        packerId: point?.packerId,
        height,
        width,
      });
      return;
    }
    
    chart.current = new MiniForecastMetricD3({
      container: refChart.current,
      allPredictions,
      height,
      width,
      firstStage,
      lastStage,
      companyData: selectedCompany,
      chartParameter,
      packerId: point?.packerId,
    });
  }, [allPredictions, selectedCompany, chartParameter, firstStage, lastStage, point, width]);
    
  useEffect(() => {
    refreshChart();
  }, [refreshChart]);
  
  return (
    <div
      className={cx(styles.container, isLightTheme ? styles.lightContainer : styles.darkContainer)}
      onClick={() => {
        dispatch(optimalHarvestPointSlice.setShowMiniChartModal(true));
        dispatch(optimalHarvestPointSlice.setMiniChartParameter(chartParameter));
      }}
    >
      <div className={styles.header}>
        <LrvText className={styles.title} text={title} theme={theme} />

        <div className={styles.iconContainer}>
          <Icon name='expand-diagonal' theme={theme} className={styles.icon} />
        </div>
      </div>

      <div
        ref={refChart}
        className={styles.chart}
      />
    </div>
  );
};
