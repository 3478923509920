import moment from 'moment';
import { Row, Col } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import { getCurrentTheme } from '../../helpers/theme';
import { changeHeader } from '../AppHeader/headerSlice';
import Content from '../../common/components/Content/Content';
import { LrvText } from '../../common/components/LrvText/LrvText';

import { AppActivity } from './widgets/AppActivity';
import styles from './AdminDashboard.module.scss';
import AdminDashboardFilters from './AdminDashboardFilters';

export const AdminDashboard = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const { filters } = useSelector((state: Store) => state.adminDashboard);
  const { daily, monthly, activityType } = filters;

  const getDates = () => {
    switch (activityType) {
      case 'DAILY':
      case 'PER_HOUR':
      default:
        return {
          fromDate: daily.fromDate,
          toDate: daily.toDate,
        };

      case 'MONTHLY':
        return {
          fromDate: monthly.fromDate,
          toDate: monthly.toDate,
        };
    }
  };

  const { fromDate, toDate } = getDates();
  const lastDays = moment(toDate).diff(moment(fromDate), 'day');
  const theme = getCurrentTheme();

  useEffect(() => {
    dispatch(changeHeader({ title: 'adminDashboard.title', subtitle: t('adminDashboard.subtitle', { days: lastDays }) }));
  }, [dispatch, lastDays, t]);

  const renderLastDaysLabels = () => {
    const subtitle = t('adminDashboard.subtitle', { days: lastDays });

    return (
      <Row className={styles.lastDays}>
        <LrvText text={subtitle} theme={theme} />
      </Row>
    );
  };

  return (
    <div className={styles.adminDashboard}>
      {renderLastDaysLabels()}
      <AdminDashboardFilters theme={theme} />

      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Content title={t('adminDashboard.appActivity')} noPadding theme={theme}>
            <AppActivity theme={theme} />
          </Content>
        </Col>
      </Row>
    </div>
  );
};
