import { Col, Form, Row, Tabs } from 'antd';
import { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../../state/store.interfaces';
import { CommercialSize, CommercialSizeRange } from '../interfaces';
import { LrvTabs } from '../../../../common/components/LrvTabs/LrvTabs';
import { LrvForm } from '../../../../common/components/LrvForm/LrvForm';
import { LrvModal } from '../../../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../../../common/components/LrvInput/LrvInput';
import { openSuccessNotification } from '../../../../common/notification/Notification';
import { commercialSizeTypes, weightUnitsByCompany } from '../../../../config/commons';
import { LrvMultiRangeSlider } from '../../../../common/components/LrvMultiRangeSlider/LrvMultiRangeSlider';
import { generateCommercialSizeRanges, getClickableMaxValue, getClickableMinValue, getDefaultCommercialSizes, getMaxValueDisplay, MIN_VALUE_DISPLAY_GROW_OUT } from '../../../../helpers/commercial-size.helpers';

import styles from './NewCommercialSizeForm.module.scss';
import { CommercialSizeTable } from './CommercialSizeTable';
import * as commercialSizeSlice from './commercialSizeSlice';

interface Props {
  theme?: 'dark' | 'light';
}

const { TabPane } = Tabs;

export const NewCommercialSizeForm = (props: Props) => {
  const { theme = 'dark' } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const {
    isUpdating,
    showCreateModal,
    packer,
    defaultCommercialSizes,
  } = useSelector((state: Store) => state.commercialSizes);

  const [name, setName] = useState<string>('');
  const [commercialSizesWhole, setCommercialSizesWhole] = useState<number[]>([]);
  const [commercialSizesTail, setCommercialSizesTail] = useState<number[]>([]);
  const [commercialSizeRangesWhole, setCommercialSizeRangesWhole] = useState<CommercialSizeRange[]>([]);
  const [commercialSizeRangesTail, setCommercialSizeRangesTail] = useState<CommercialSizeRange[]>([]);
  const [currentTab, setCurrentTab] = useState<string>(commercialSizeTypes.GROW_OUT_WHOLE);

  useEffect(() => {
    const commercialSizesWhole = getDefaultCommercialSizes({ commercialSizeType: commercialSizeTypes.GROW_OUT_WHOLE, defaultCommercialSizes });
    setCommercialSizesWhole(commercialSizesWhole);

    const commercialSizesTail = getDefaultCommercialSizes({ commercialSizeType: commercialSizeTypes.GROW_OUT_TAIL, defaultCommercialSizes });
    setCommercialSizesTail(commercialSizesTail);
  }, [showCreateModal, defaultCommercialSizes]);

  useEffect(() => {
    if (commercialSizesWhole.length === 0) {
      return;
    }

    const maxValueDisplay = getMaxValueDisplay({ commercialSizeType: commercialSizeTypes.GROW_OUT_WHOLE });
    const commercialSizeRanges = generateCommercialSizeRanges({ commercialSizes: commercialSizesWhole, minValueDisplay: MIN_VALUE_DISPLAY_GROW_OUT, maxValueDisplay, weightUnit: weightUnitsByCompany.KILOGRAM });
    setCommercialSizeRangesWhole(commercialSizeRanges);
  }, [commercialSizesWhole]);

  useEffect(() => {
    if (commercialSizesTail.length === 0) {
      return;
    }

    const maxValueDisplay = getMaxValueDisplay({ commercialSizeType: commercialSizeTypes.GROW_OUT_TAIL });
    const commercialSizeRanges = generateCommercialSizeRanges({ commercialSizes: commercialSizesTail, minValueDisplay: MIN_VALUE_DISPLAY_GROW_OUT, maxValueDisplay, weightUnit: weightUnitsByCompany.POUND });
    setCommercialSizeRangesTail(commercialSizeRanges);
  }, [commercialSizesTail]);

  const closeModal = () => {
    form.resetFields();
    setName('');
    setCommercialSizesTail([]);
    setCommercialSizesWhole([]);
    setCommercialSizeRangesTail([]);
    setCommercialSizeRangesWhole([]);
    
    setCurrentTab(commercialSizeTypes.GROW_OUT_WHOLE);
    dispatch(commercialSizeSlice.setShowCreateModal(false));
  };

  const disableSubmitButton = !name || commercialSizeRangesWhole.length === 0 || commercialSizeRangesTail.length === 0;

  const addCommercialSize = () => {
    if (disableSubmitButton || !packer?._id || !packer.commercialSizes) {
      return;
    }

    const newCommercialSizes: CommercialSize = {
      name,
      active: true,
      commercialSizes: {
        whole: commercialSizesWhole,
        tail: commercialSizesTail,
      },
    };

    const props = {
      packerId: packer._id,
      commercialSizes: [...packer.commercialSizes, newCommercialSizes],
      onSuccess: () => {
        openSuccessNotification(t('commercialSizes.created'));
        closeModal();
      },
    };

    dispatch(commercialSizeSlice.updateCommercialSize(props));
  };

  const renderCommercialSizeName = () => {
    return (
      <Form.Item
        name='name'
        label={t('commercialSizes.name')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvInput
          theme={theme}
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={t('commercialSizes.name')}
        />
      </Form.Item>
    );
  };

  const renderLabelSlider = (props: {weightUnit: string}) => {
    const { weightUnit } = props;

    return (
      <div className={styles.commercialSizesByAnimal}>
        <div className={styles.title}>{t('commercialSizes.byAnimal', { unit: t(`commercialSizes.weightUnits.${weightUnit}`) })}</div>
        <div className={styles.description}>{t('commercialSizes.slider.add')}</div>
        <div className={styles.description}>{t('commercialSizes.slider.remove')}</div>
      </div>
    );
  };

  const renderSlider = (props: {weightUnit: string; commercialSizes: number[]; commercialSizeType: string; setCommercialSizes: (value: SetStateAction<number[]>) => void }) => {
    const { weightUnit, commercialSizes, commercialSizeType, setCommercialSizes } = props;

    return (
      <Col span={24}>
        <Form.Item
          label={renderLabelSlider({ weightUnit })}
        >
          <LrvMultiRangeSlider
            initialValues={commercialSizes}
            minValueDisplay={MIN_VALUE_DISPLAY_GROW_OUT}
            maxValueDisplay={getMaxValueDisplay({ commercialSizeType })}
            clickableMinValue={getClickableMinValue({ commercialSizeType })}
            clickableMaxValue={getClickableMaxValue({ commercialSizeType })}
            onChange={(values) => setCommercialSizes(values)}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderCommercialSizeTable = (props: {commercialSizeRanges: CommercialSizeRange[]; commercialSizeType: string }) => {
    const { commercialSizeRanges, commercialSizeType } = props;
    
    return (
      <Col span={24}>
        <Form.Item>
          <CommercialSizeTable
            commercialSizeRanges={commercialSizeRanges}
            commercialSizeType={commercialSizeType}
          />
        </Form.Item>
      </Col>
    );
  };

  return (
    <LrvModal
      theme='light'
      title={t('commercialSizes.newCommercialSize')}
      open={showCreateModal}
      destroyOnClose={true}
      okButtonProps={{
        id: 'submit-packer',
        htmlType: 'submit',
        form: 'form',
        loading: isUpdating,
        disabled: disableSubmitButton,
      }}
      onOk={addCommercialSize}
      okText={t('commercialSizes.save')}
      cancelText={t('commercialSizes.cancel')}
      onCancel={closeModal}
      width={720}
      className={styles.commercialSizeModal}
    >
      <LrvForm
        theme={theme}
        form={form}
        name='form'
        id='form-analysis-observation'
        layout='vertical'
      >
        <Row gutter={16}>
          <Col span={24}>
            {renderCommercialSizeName()}
          </Col>

          <Col span={24}>
            <LrvTabs
              theme={theme}
              activeKey={currentTab}
              defaultActiveKey={currentTab}
              onChange={(activeKey) => {
                setCurrentTab(activeKey);
              }}
            >
              <TabPane tab={t(`commercialSizes.commercialSizeTypes.${commercialSizeTypes.GROW_OUT_WHOLE}`)} key={commercialSizeTypes.GROW_OUT_WHOLE}>
                {renderSlider({ weightUnit: weightUnitsByCompany.KILOGRAM, commercialSizes: commercialSizesWhole, commercialSizeType: commercialSizeTypes.GROW_OUT_WHOLE, setCommercialSizes: setCommercialSizesWhole }) }
                {renderCommercialSizeTable({ commercialSizeRanges: commercialSizeRangesWhole, commercialSizeType: commercialSizeTypes.GROW_OUT_WHOLE })}
              </TabPane>

              <TabPane tab={t(`commercialSizes.commercialSizeTypes.${commercialSizeTypes.GROW_OUT_TAIL}`)} key={commercialSizeTypes.GROW_OUT_TAIL}>
                {renderSlider({ weightUnit: weightUnitsByCompany.POUND, commercialSizes: commercialSizesTail, commercialSizeType: commercialSizeTypes.GROW_OUT_TAIL, setCommercialSizes: setCommercialSizesTail }) }
                {renderCommercialSizeTable({ commercialSizeRanges: commercialSizeRangesTail, commercialSizeType: commercialSizeTypes.GROW_OUT_TAIL })}
              </TabPane>
            </LrvTabs>
          </Col>

        </Row>
      </LrvForm>
    </LrvModal>
  );
};