import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TRANSFERS_URL } from '../../../config/config.api';
import { axiosClient as axios } from '../../../utils/axios_instance';

import { TransferSummaryPayload, TransferSummaryState } from './interfaces';

const initialState: TransferSummaryState = {
  stocking: {
    _id: '',
    name: '',
    code: '',
    campusName: '',
    moduleName: '',
    tankName: '',
    startDateJuvenile: '',
  },
  transfers: [],
  histogramWeight: {
    frequencies: [],
    limits: [],
  },
  transferSummary: {
    _id: '',
    name: '',
    transferDate: '',
    animalsSmallerThan: 0,
    animalsTransferred: 0,
    averageWeight: 0,
    uniformityWeight: 0,
    variationCoefficientWeight: 0,
    transferredWeight: 0,
    maxSmallAnimalsWeight: 0,
  },
  isDownloadingFile: false,
  isLoading: false,
};

export const transferSummaryGraphSlice = createSlice({
  name: 'transferSummaryGraph',
  initialState,
  reducers: {
    setTransferSummary: (state: TransferSummaryState, action: PayloadAction<TransferSummaryPayload>) => {
      state.histogramWeight = action.payload.histogramWeight;
      state.stocking = action.payload.stocking;
      state.transfers = action.payload.transfers;
      state.transferSummary = action.payload.transferSummary;
    },
    setIsLoading: (state: TransferSummaryState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsDownloadingFile: (state: TransferSummaryState, action: PayloadAction<boolean>) => {
      state.isDownloadingFile = action.payload;
    },
  },
});

export const {
  setTransferSummary,
  setIsLoading,
  setIsDownloadingFile,
} = transferSummaryGraphSlice.actions;

export const fetchTransferSummaryChartData = (props: { stockingId: string; accessToken: string; }) => async (dispatch: Function) => {
  const { accessToken, stockingId } = props;
  dispatch(setIsLoading(true));

  const url = `${TRANSFERS_URL}/summary/${stockingId}/chart-data`;

  try {
    const response = await axios.get<TransferSummaryPayload>(url, {
      headers: { 'Authorization': accessToken },
    });

    dispatch(setTransferSummary(response.data));
    dispatch(setIsLoading(false));
  } catch (error) {
    console.log(error?.response);
  }
};

export default transferSummaryGraphSlice.reducer;
