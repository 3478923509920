import { HarvestOptimalPlan, HeatMapValue } from '../interfaces';

export const panelColors = ['#D4EADC', '#C1E1CC', '#AED7BD', '#9BCEAD', '#88C59E', '#75BC8E', '#61B27F', '#4EA970', '#3BA060', '#289650', '#158D41'];

export const countByHarvestDateArray = (harvestOptimalPlans: HarvestOptimalPlan[]): HeatMapValue[] => {
  const counts = harvestOptimalPlans.reduce((acc, plan) => {
    const dateOnly = plan.harvestDate.split('T')[0].replace(/-/g, '/');
    acc[dateOnly] = (acc[dateOnly] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  return Object.entries(counts).map(([date, count]) => ({
    date,
    count
  }));
};

export const getHeatMapValues = (data: HeatMapValue[]) => {
  const counts = data.map(item => item.count);
  const min = Math.min(...counts);
  let max = Math.max(...counts);

  if (min === max || (min + 1) === max) {
    max += 2;
  }

  const values = Array.from({ length: max - min + 1 }, (_, i) => min + i);
  if (!values.length) {
    return [0, 4];
  }

  values.unshift(0);
  return values;
};
