import cx from 'classnames';
import { Space } from 'antd';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import { changeHeader } from '../../AppHeader/headerSlice';
import { handleResizeEvent } from '../../../utils/dimensions';
import { THEME, roundTwoDecimals } from '../../../config/commons';
import { ReactComponent as Logo } from '../../../assets/resize.svg';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvEmpty } from '../../../common/components/LrvEmpty/LrvEmpty';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { getUnitPhaseTypeFromStocking } from '../../../helpers/stocking.helpers';
import ParameterChartD3 from '../../../common/components/charts/ParameterChart/ParameterChartD3';
import { getColorParameterWithFrequencies, getSelectedParametersChart } from '../../../common/components/charts/ParameterChart/helpers';
import { getParameterLabelByKey } from '../../Company/StockingParameters/helpers';
import { LrvTooltip } from '../../../common/components/LrvTooltip/LrvTooltip';

import { SubHeader } from './SubHeader';
import { DataCustomizable } from './interfaces';
import styles from './StockingParameters.module.scss';
import * as parameterChartSlice from './parameterChartSlice';
import { StockingDataCustomizableModal } from './StockingDataCustomizableModal';
import { StockingParameterCustomizableModal } from './StockingParameterCustomizableModal';
import { calculateDateRange, defaultLegendChart, defaultStockingDataChart, allFrequencies, updateParameterLayout, getExtraValue, parameterCardWidth, dateRanges } from './helpers';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

let chart: ParameterChartD3 | null;

export const StockingParameters = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { phaseType, company } = useSelector((state: Store) => state.header);
  const {
    companyStockingParameters,
    parameterChart,
    enableEditing,
    stockingId,
    hoveredFrequency,
    hoveredParameter,

    parameterWithoutFrequencies,
    parameterWithFrequencies,
    selectedDateOption,
    selectedParameterName,
    isRequestInProgress,
  } = useSelector((state: Store) => state.parameterChart);

  const { dataCustomizable } = parameterChart;
  const {
    growthRates,
    totalFeed,
    dates,
  } = parameterChart.data;

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const subtractWidth = 44;
  const subtractHigh = 32;

  const refChartMain = useRef<HTMLDivElement>(null);
  const refOptions = useRef<HTMLDivElement>(null);
  const refLegends = useRef<HTMLDivElement>(null);

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  const emptyData = growthRates.values.length === 0 && totalFeed.values.length === 0 && (parameterWithoutFrequencies.values.length === 0 || Object.keys(parameterWithoutFrequencies).length === 0);

  const onSelectParameter = (props: { parameter: string }) => {
    const { parameter } = props;

    if (!parameterChart.data.parameters?.withoutFrequencies) {
      return;
    }

    const parameterWithoutFrequencies = parameterChart.data.parameters.withoutFrequencies[parameter];
    dispatch(parameterChartSlice.setSelectedParameterName(parameter));

    if (parameterWithoutFrequencies && parameterWithoutFrequencies.values.length > 0) {
      dispatch(parameterChartSlice.setParameterWithoutFrequencies(parameterWithoutFrequencies));
      dispatch(parameterChartSlice.setParameterWithFrequencies({}));
      return;
    }

    const parameterWithFrequencies = parameterChart.data.parameters.withFrequencies[parameter];

    if (parameterWithFrequencies) {
      dispatch(parameterChartSlice.setParameterWithoutFrequencies({ unit: '', values: [] }));
      dispatch(parameterChartSlice.setParameterWithFrequencies(parameterWithFrequencies));
      return;
    }

    dispatch(parameterChartSlice.setParameterWithoutFrequencies({ unit: '', values: [] }));
    dispatch(parameterChartSlice.setParameterWithFrequencies({}));
  };

  useEffect(() => {
    dispatch(changeHeader({ title: 'production.parameter.title' }));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      chart = null;
    };
  }, [stockingId]);

  useEffect(() => {
    if (!company._id) {
      return;
    }

    dispatch(parameterChartSlice.fetchParameterChart({ companyId: company._id }));
    dispatch(parameterChartSlice.fetchCompanyStockingParameter({ companyId: company._id }));
    dispatch(parameterChartSlice.fetchDefaultStockingParameterChart());

    const unitPhaseType = getUnitPhaseTypeFromStocking(phaseType);
    dispatch(parameterChartSlice.fetchUnits({ companyId: company._id, phaseType: unitPhaseType }));

    return (() => {
      dispatch(parameterChartSlice.resetFilters());
    });
  }, [dispatch, phaseType, company._id]);

  useEffect(() => {
    if (!stockingId || !dataCustomizable.data.length || !parameterChart.data.parameters?.withFrequencies) {
      return;
    }

    const parameters = getSelectedParametersChart({ layoutCustomizable: dataCustomizable.data });
    if (!parameters.length) {
      return;
    }

    const parameter = parameters[0];
    dispatch(parameterChartSlice.setSelectedParameterName(parameter));
    onSelectParameter({ parameter });
  }, [dataCustomizable.data, parameterChart.data.parameters, stockingId]);

  useEffect(() => {
    handleResizeEvent(() => {
      const optiopsHeight = refOptions.current?.offsetHeight || 0;
      const legendsHeight = refLegends.current?.offsetHeight || 0;

      const chartWidth = refChartMain.current?.parentElement?.parentElement?.offsetWidth;
      const chartHeight = refChartMain.current?.parentElement?.parentElement?.offsetHeight;

      if (!chartWidth || !chartHeight) {
        return;
      }

      setWidth(chartWidth - subtractWidth);
      setHeight(chartHeight - optiopsHeight - legendsHeight - subtractHigh);
    });
  }, []);

  useEffect(() => {
    if (isRequestInProgress || emptyData) {
      return;
    }

    const parameters = getSelectedParametersChart({ layoutCustomizable: dataCustomizable.data });

    if (!selectedParameterName && parameters.length > 0) {
      return;
    }

    const optiopsHeight = refOptions.current?.offsetHeight || 0;
    const legendsHeight = refLegends.current?.offsetHeight || 0;

    const chartWidth = refChartMain.current?.parentElement?.parentElement?.offsetWidth;
    const chartHeight = refChartMain.current?.parentElement?.parentElement?.offsetHeight;

    if (!chartWidth || !chartHeight) {
      return;
    }

    setWidth(chartWidth - subtractWidth);
    setHeight(chartHeight - optiopsHeight - legendsHeight - subtractHigh);
  }, [emptyData, selectedParameterName, isRequestInProgress, dataCustomizable.data, parameterChart.data.dates, parameterChart.data.data, parameterChart.data.growthRates, parameterChart.data.parameters, parameterChart.data.totalFeed, isRequestInProgress, enableEditing]);

  useEffect(() => {
    if (!height || !width || isRequestInProgress) {
      return;
    }

    const parameters = getSelectedParametersChart({ layoutCustomizable: dataCustomizable.data });

    if (!selectedParameterName && parameters.length > 0) {
      return;
    }

    const renderD3Chart = () => {
      const { maxDate, minDate } = calculateDateRange({ defaultMaxDate: dates.maxDate, defaultMinDate: dates.minDate, option: selectedDateOption });

      if (chart) {
        chart.refreshChart({
          minDate,
          maxDate,
          parameterWithoutFrequencies,
          parameterWithFrequencies,
          totalFeed,
          growthRates,
          selectedParameter: selectedParameterName,
          theme,
        });

        return;
      }

      chart = new ParameterChartD3({
        dispatch,
        height: height,
        width,
        parameterWithoutFrequencies,
        parameterWithFrequencies,
        container: refChartMain.current,
        minDate,
        maxDate,
        totalFeed,
        growthRates,
        selectedParameter: selectedParameterName,
        theme,
      });
    };

    renderD3Chart();
  }, [dispatch, phaseType, width, height, theme, totalFeed, growthRates, dates, parameterWithoutFrequencies, parameterWithFrequencies, selectedDateOption, selectedParameterName, refChartMain.current, isRequestInProgress, dataCustomizable.data]);

  useEffect(() => {
    chart && chart.resize({ width, height: height });
  }, [width, height]);

  useEffect(() => {
    if (!chart) {
      return;
    }

    chart.refreshBorders({
      hoveredParameter,
      hoveredFrequency,
      theme,
    });
  }, [hoveredParameter, hoveredFrequency, theme]);

  const removeItemData = (props: { index: number }) => {
    const { index } = props;

    const layoutCopy = cloneDeep(dataCustomizable);
    layoutCopy.data.splice(index, 1);

    const newData: DataCustomizable = {
      data: layoutCopy.data,
    };

    dispatch(parameterChartSlice.setDataCustomizable(newData));
  };

  const renderParameterCard = (props: { key: string; value?: number; unit?: string; index: number; }) => {
    const { key, value = 0, unit = '', index } = props;

    const extraData = getExtraValue({ key, extraData: parameterChart.data.extraData });

    return (
      <div
        key={key}
        className={cx(styles.box, enableEditing ? isLightTheme ? styles.borderLight : styles.borderDark : '')}
      >
        <div className={styles.containerText}>
          <LrvText text={t(`production.parameter.${key}`)} theme={theme} className={styles.label} />
          <LrvText text={enableEditing ? '' : `${roundTwoDecimals(value)} ${unit}`} theme={theme} className={styles.value} />
          <LrvText text={enableEditing || !extraData.value ? '' : `${roundTwoDecimals(extraData.value)} ${extraData.unit} ${extraData.label}`} theme={theme} className={cx(styles.label, extraData.value && extraData.value > 0 ? styles.green : styles.red)} />
        </div>

        {
          enableEditing &&
          <>
            <div className={styles.containerResizeIcon}>
              <Logo className={isLightTheme ? styles.fillLight : styles.fillDark} />
            </div>

            <div
              className={styles.containerCloseIcon}
              onClick={() => removeItemData({ index })}
            >
              <Icon className={styles.closeIcon} name='close' theme={theme} />
            </div>

            <div
              className={styles.containerSettingIcon}
              onClick={() => {
                dispatch(parameterChartSlice.setSelectedDataIndex(index));
                dispatch(parameterChartSlice.setShowDataCustomizableModal(true));
              }}
            >
              <Icon className={styles.settingIcon} name='settings-4' theme={theme} />
            </div>
          </>
        }
      </div>
    );
  };

  const renderLegendWithFrequency = () => {
    const items: JSX.Element[] = [];
    let index = 0;

    Object.keys(parameterWithFrequencies).forEach((key) => {
      const label = `${selectedParameterName} ${key}`;

      const item = (
        <div
          className={styles.legend}
          onMouseEnter={() => {
            dispatch(parameterChartSlice.setHoveredParameter(selectedParameterName));
            dispatch(parameterChartSlice.setHoveredFrequency(key));
          }}
          onMouseLeave={() => {
            dispatch(parameterChartSlice.setHoveredParameter(undefined));
            dispatch(parameterChartSlice.setHoveredFrequency(undefined));
          }}
        >
          <div className={styles.circle} style={{ backgroundColor: getColorParameterWithFrequencies(index) }} />
          <LrvText theme={theme} text={label} />
        </div>
      );

      items.push(item);
      index++;
    });

    return items;
  };

  const renderLegendWithoutFrequency = () => {
    if (parameterWithoutFrequencies.values.length === 0) {
      return;
    }

    return (
      <div
        className={styles.legend}
        onMouseEnter={() => dispatch(parameterChartSlice.setHoveredParameter(selectedParameterName))}
        onMouseLeave={() => dispatch(parameterChartSlice.setHoveredParameter(undefined))}
      >
        <div className={cx(styles.circle, styles.parameterWithoutFrequencies)} />
        <LrvText theme={theme} text={getParameterLabelByKey(selectedParameterName || '')} />
      </div>
    );
  };

  const renderLegends = () => {
    return (
      <Space className={styles.legends} size='middle'>
        <div
          className={styles.legend}
          onMouseEnter={() => dispatch(parameterChartSlice.setHoveredParameter(defaultLegendChart.TOTAL_FEED))}
          onMouseLeave={() => dispatch(parameterChartSlice.setHoveredParameter(undefined))}
        >
          <div className={cx(styles.square, isLightTheme ? styles.totalFeedLight : styles.totalFeedDark)} />
          <LrvText theme={theme} text={t('production.parameter.totalFeed')} />
        </div>

        <div
          className={styles.legend}
          onMouseEnter={() => dispatch(parameterChartSlice.setHoveredParameter(defaultLegendChart.GROWTH_RATE))}
          onMouseLeave={() => dispatch(parameterChartSlice.setHoveredParameter(undefined))}
        >
          <div className={cx(styles.plusSign, isLightTheme ? styles.growthRateLight : styles.growthRateDark)} > + </div>
          <LrvText theme={theme} text={t('production.parameter.growthRate')} />
        </div>

        {renderLegendWithoutFrequency()}
        {renderLegendWithFrequency()}
      </Space>
    );
  };

  const renderParameterOptions = (props: { parameters: string[] }) => {
    const { parameters } = props;

    if (parameters.length === 0) {
      return;
    }

    return (
      <>
        <div className={styles.containerParameters}>
          <Space className={styles.parameters}>
            {parameters.map((parameter) => {
              const parameterWithoutFrequencies = parameterChart.data.parameters.withoutFrequencies[parameter];
              const parameterWithFrequencies = parameterChart.data.parameters.withFrequencies[parameter];
              const disableParameter = !parameterWithFrequencies && !parameterWithoutFrequencies;
              
              if (disableParameter) {
                return (

                  <div
                    className={cx(styles.parameter, styles.parameterDisabled)}
                  >
                    <LrvTooltip
                      title={t('parameters.empty')}
                      themeStyle={theme}
                      placement='top'
                    >
                      <LrvText theme={theme} text={getParameterLabelByKey(parameter)} />
                    </LrvTooltip>
                  </div>
                );
              }

              return (
                <div
                  className={cx(styles.parameter, parameter === selectedParameterName ? isLightTheme ? styles.selectedParameterLight : styles.selectedParameterDark : '')}
                  onClick={() => onSelectParameter({ parameter })}
                >
                  <LrvText theme={theme} text={getParameterLabelByKey(parameter)} />
                </div>
              );
            })}
          </Space>
        </div>
      </>
    );
  };

  const renderDateRangeOptions = () => {
    return (
      <div className={styles.containerParameters}>
        <Space className={styles.parameters}>
          {
            dateRanges.map((item) => {
              return (
                <div
                  className={cx(styles.dateOption, item === selectedDateOption ? isLightTheme ? styles.selectedDateOptionLight : styles.selectedDateOptionDark : '')}
                  onClick={() => {
                    dispatch(parameterChartSlice.setSelectedDateOption(item));
                  }}
                >
                  <LrvText theme={theme} text={item} />
                </div>
              );
            })
          }
        </Space>
      </div>
    );
  };

  const renderOptions = (props: { parameters: string[] }) => {
    const { parameters } = props;

    return (
      <div
        className={styles.options}
        ref={refOptions}
      >
        {renderParameterOptions({ parameters })}
        <></>
        {renderDateRangeOptions()}
      </div>
    );
  };

  const renderLeftYAxisLabel = () => {
    return (
      <div className={styles.containerAxisYLabel}>
        <div
          className={styles.axisYLabel}
          onMouseEnter={() => dispatch(parameterChartSlice.setHoveredParameter(defaultLegendChart.TOTAL_FEED))}
          onMouseLeave={() => dispatch(parameterChartSlice.setHoveredParameter(undefined))}
        >
          <LrvText theme={theme} text={`${t('production.parameter.totalFeed')} (${totalFeed.unit})`} />
        </div>
      </div>
    );
  };

  const getY3Label = () => {
    let unit = parameterWithoutFrequencies.unit;

    if (Object.keys(parameterWithFrequencies).length > 0) {
      const units = Object.values(parameterWithFrequencies).map(({ unit }) => unit);
      unit = units[0];
    }

    const parameterLabel = getParameterLabelByKey(selectedParameterName || '');
    return unit ? `${parameterLabel} (${unit})` : parameterLabel;
  };

  const renderRightYAxisLabel = () => {
    const y3Label = getY3Label();

    return (
      <Space className={styles.containerAxisYLabel}>
        <div
          className={cx(styles.axisYLabel, styles.axisY2Label)}
          onMouseEnter={() => dispatch(parameterChartSlice.setHoveredParameter(defaultLegendChart.GROWTH_RATE))}
          onMouseLeave={() => dispatch(parameterChartSlice.setHoveredParameter(undefined))}
        >
          <LrvText theme={theme} text={`${t('production.parameter.growthRate')} (${t('production.parameter.gramPerWeek', { unit: growthRates.unit })})`} />
        </div>

        <div
          className={cx(styles.axisYLabel, styles.axisY3Label)}
          onMouseEnter={() => {
            dispatch(parameterChartSlice.setHoveredParameter(selectedParameterName));

            const hoveredFrequency = Object.keys(parameterWithFrequencies).length > 0 ? allFrequencies : undefined;
            dispatch(parameterChartSlice.setHoveredFrequency(hoveredFrequency));
          }}
          onMouseLeave={() => {
            dispatch(parameterChartSlice.setHoveredParameter(undefined));
            dispatch(parameterChartSlice.setHoveredFrequency(undefined));
          }}
        >
          <LrvText theme={theme} text={y3Label} />
        </div>
      </Space>
    );
  };

  const renderRowLegends = () => {
    return (
      <div
        className={styles.containerLegends}
        ref={refLegends}
      >
        {
          !enableEditing &&
          <div className={styles.rowLegends}>
            {renderLeftYAxisLabel()}
            {renderLegends()}
            {renderRightYAxisLabel()}
          </div>
        }
      </div>
    );
  };

  const renderParameterChart = () => {
    return (
      <div className={styles.containerChart}>
        <div
          id='chart'
          ref={refChartMain}
          className={cx(styles.parameterChart, enableEditing ? styles.hideChart : styles.showChart)}
        />

        <div className={cx(enableEditing ? styles.containerIcon : styles.hideContainerIcon)}>
          <Icon className={cx(styles.icon, enableEditing ? styles.showIcon : styles.hideIcon)} name='bar-chart' theme={theme} type='fill' />
        </div>
      </div>
    );
  };

  const renderChartCard = (props: { key: string; parameters?: string[] }) => {
    const { key, parameters = [] } = props;

    return (
      <div key={key} className={cx(styles.chart, enableEditing ? isLightTheme ? styles.borderLight : styles.borderDark : '')}>
        {renderOptions({ parameters })}
        {renderRowLegends()}
        {renderParameterChart()}

        {
          enableEditing &&
          <div className={styles.containerResizeIcon}>
            <Logo className={isLightTheme ? styles.fillLight : styles.fillDark} />
          </div>
        }

        {
          (parameters.length > 0 && enableEditing) &&
          <div
            className={styles.containerParameterSettingIcon}
            onClick={() => {
              dispatch(parameterChartSlice.setShowParameterCustomizableModal(true));
            }}
          >
            <Icon name='settings-4' theme={theme} />
          </div>
        }
      </div>
    );
  };

  const renderBody = () => {
    if (isRequestInProgress) {
      return (
        <div className={styles.spinnerContainer}>
          <div className={styles.spinner}>
            <DotSpinner />
          </div>
        </div>
      );
    }

    if (!stockingId) {
      return (
        <div className={styles.center}>
          <LrvEmpty description={t('production.parameter.selectStocking')} theme={theme} />
        </div>
      );
    }

    if (companyStockingParameters.length === 0) {
      return (
        <div className={styles.center}>
          <LrvEmpty description={t('production.parameter.empty')} theme={theme} />
        </div>
      );
    }

    if (emptyData) {
      return (
        <div className={styles.center}>
          <LrvEmpty description={t('production.parameter.emptyData')} theme={theme} />
        </div>
      );
    }

    return (
      <div className={styles.body}>
        <div
          className={styles.grid}
        >
          <ResponsiveReactGridLayout
            cols={{
              lg: parameterCardWidth.MAX,
              md: parameterCardWidth.MAX,
              sm: parameterCardWidth.MAX,
              xs: parameterCardWidth.MAX,
              xxs: parameterCardWidth.MAX,
            }}
            rowHeight={10}
            layouts={{ lg: dataCustomizable.data }}
            onLayoutChange={(currentLayout: Layout[]) => {
              const layoutCopy = updateParameterLayout({ layout: currentLayout, layoutCustomizable: dataCustomizable.data });
              dispatch(parameterChartSlice.setDataCustomizable({ data: layoutCopy }));
            }}
            useCSSTransforms={false}
            isDraggable={enableEditing}
            isDroppable={enableEditing}
            isResizable={enableEditing}
          >
            {dataCustomizable.data.map((item, index) => {
              if (item.i === defaultStockingDataChart.CHART) {
                return renderChartCard({ key: item.i, parameters: item.parameters });
              }

              return renderParameterCard({
                key: item.i,
                index,
                value: parameterChart.data.data[item.i]?.value,
                unit: parameterChart.data.data[item.i]?.unit,
              });
            })}
          </ResponsiveReactGridLayout>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.stockingParameters}>
        <SubHeader />
        {renderBody()}
      </div>

      <StockingDataCustomizableModal
        theme='light'
      />

      <StockingParameterCustomizableModal
        theme='light'
      />
    </>
  );
};
