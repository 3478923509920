import cx from 'classnames';
import { ConfigProvider, DatePicker } from 'antd';

import { LrvText } from '../LrvText/LrvText';
import { getLanguage } from '../../../utils/language';
import { DATE_FORMATS, LANGUAGES } from '../../../config/commons';
import { LrvDatePickerProps } from '../../interfaces/lrvDatePicker';
import { getLocale } from '../../../utils/locale';

import styles from './LrvDatePicker.module.scss';

export const LrvDatePicker = (props: LrvDatePickerProps) => {
  const { title, titleClassName, containerClassName, className, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  const lrvDatePickerProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkDatePicker : styles.lightDatePicker, className),
    popupClassName: cx(isDarkTheme ? styles.darkDropdown : styles.lightDropdown),
  };

  const getFormat = () => {
    const language = getLanguage().toUpperCase();

    if (lrvDatePickerProps.format) {
      return lrvDatePickerProps.format;
    }

    switch (language) {
      case LANGUAGES.ENGLISH:
      default:
        return DATE_FORMATS.MMM_DD_YYYY;

      case LANGUAGES.PORTUGUESE:
      case LANGUAGES.SPANISH:
        return DATE_FORMATS.DD_MMM_YYYY;
    }
  };

  return (
    <div className={cx(styles.container, isDarkTheme ? styles.darkContainer : styles.lightContainer, containerClassName)}>
      <LrvText className={cx(isDarkTheme ? styles.titleDark : styles.titleLight, titleClassName)} text={title} theme={theme} />
      <ConfigProvider locale={getLocale()}>
        <DatePicker {...lrvDatePickerProps} format={getFormat()}/>
      </ConfigProvider>
    </div>
  );
};
