import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { axiosClient as axios } from '../../utils/axios_instance';
import { CAMPUS_POC_PARAMETER_URL } from '../../config/config.api';
import { openSuccessNotification } from '../../common/notification/Notification';

import { CampusPocParameter, CampusPocParameterState } from './interfaces';

const initialState: CampusPocParameterState = {
  campusId: '',
  isLoading: false,
  showCreateModal: false,
};

export const unitPocParameterSlice = createSlice({
  name: 'unitPocParameter',
  initialState,
  reducers: {
    setCampusId: (state: CampusPocParameterState, action: PayloadAction<string>) => {
      state.campusId = action.payload;
    },
    setIsLoading: (state: CampusPocParameterState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShowCreateModal: (state: CampusPocParameterState, action: PayloadAction<boolean>) => {
      state.showCreateModal = action.payload;
    },
    setCampusPocParameter: (state: CampusPocParameterState, action: PayloadAction<CampusPocParameter>) => {
      state.campusPocParameter = action.payload;
    },
    resetCampusPocParameter: (state: CampusPocParameterState) => {
      state.campusPocParameter = initialState.campusPocParameter;
    },
    
  }
});

export const {
  setCampusId,
  setIsLoading,
  setShowCreateModal,
  setCampusPocParameter,
  resetCampusPocParameter,
} = unitPocParameterSlice.actions;

export const fetchCampusPocParameter = (props: {campusId: string;}) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    campusId: props.campusId,
  };
  
  try {
    const response = await axios.get<CampusPocParameter[]>(CAMPUS_POC_PARAMETER_URL, { params });
    if (response.data.length) {
      dispatch(setCampusPocParameter(response.data[0]));
    }
  } catch (error) {
    console.log(error?.response);
  }
};

export const createCampusPocParameter = (props: {body: CampusPocParameter; onSuccess: () => void;}) => async (dispatch: Function) => {
  const { body, onSuccess } = props;

  try {
    const response = await axios.post<CampusPocParameter>(CAMPUS_POC_PARAMETER_URL, body);
    dispatch(setCampusPocParameter(response.data));

    onSuccess();
    openSuccessNotification(i18next.t('formPocParameters.created'));
  } catch (error) {
    console.log(error?.response);
  }
};

export const updateCampusPocParameter = (props: {body: CampusPocParameter; onSuccess: () => void;}) => async (dispatch: Function) => {
  const { body, onSuccess } = props;
  const url = `${CAMPUS_POC_PARAMETER_URL}/${body._id}`;

  try {
    const response = await axios.patch<CampusPocParameter>(url, body);
    dispatch(setCampusPocParameter(response.data));

    onSuccess();
    openSuccessNotification(i18next.t('formPocParameters.updated'));
  } catch (error) {
    console.log(error?.response);
  }
};

export default unitPocParameterSlice.reducer;
