import cx from 'classnames';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Dataset } from '../interfaces';
import { THEME } from '../../../config/commons';
import { RootState } from '../../../state/store';
import { getCurrentTheme } from '../../../helpers/theme';
import VerticalHistogramD3 from '../../../common/components/charts/OptimalHarvestPoint/VerticalHistogramD3';

import styles from './GrowOutSizesChart.module.scss';

interface Props {
  containerClassName?: string;
  theme?: 'light' | 'dark';
  width: number;
  height: number;
  currencySymbol: string;
  dataset: Dataset[] | undefined;
  fillBars: string;
}

export const GrowOutSizesChart = (props: Props) => {
  const {
    containerClassName = styles.container,
    theme = getCurrentTheme(),
    width,
    height,
    currencySymbol,
    dataset,
    fillBars,
  } = props;

  const refChart = useRef<HTMLDivElement>(null);
  const chart = useRef<VerticalHistogramD3 | null>(null);

  const { company: selectedCompany } = useSelector((state: RootState) => state.header);
  const { showDetailHarvestModal } = useSelector((state: RootState) => state.optimalHarvestPoint);
  const isLightTheme = theme === THEME.LIGHT;

  useEffect(() => {
    return () => {
      chart.current = null;
    };
  }, []);

  useEffect(() => {
    if (!dataset || !dataset.length) {
      return;
    }

    if (chart.current) {
      chart.current.refreshHistogram({
        dataset,
        companyData: selectedCompany,
        currencySymbol,
        fillBars,
      });
      return;
    }

    chart.current = new VerticalHistogramD3({
      container: refChart.current,
      dataset,
      height,
      width,
      companyData: selectedCompany,
      currencySymbol,
      fillBars,
    });
  }, [refChart.current, width, height, theme, selectedCompany, dataset, showDetailHarvestModal, fillBars]);

  return (
    <div id='histogram_chart' className={cx(containerClassName, isLightTheme ? styles.containerLight : styles.containerDark)}>
      <div ref={refChart} />
    </div>
  );
};
