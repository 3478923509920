import { Space } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { calculatePresentValue } from '../helpers';
import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import { formatLongDateWithZone } from '../../../utils/date';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { weightUnits, weightUnitsByCompany } from '../../../config/commons';
import { LrvTooltip } from '../../../common/components/LrvTooltip/LrvTooltip';
import { LrvTextAnimated } from '../../../common/components/LrvText/LrvTextAnimated';

import styles from './Indicators.module.scss';

export const Indicators = () => {
  const [t] = useTranslation();
  const theme = getCurrentTheme();

  const {
    predictionSelected, allPredictions, pocPoint, interestRate, packersWithCommercialSizes
  } = useSelector((state: Store) => state.optimalHarvestPoint);
  const { company } = useSelector((state: Store) => state.header);

  const point = predictionSelected || pocPoint;
  const prediction = allPredictions.find((prediction) => prediction.x === point?.x);
  const packerWithCommercialSize = packersWithCommercialSizes.find((packer) => packer.packerId === point?.packerId);
  const days = packerWithCommercialSize?.paymentDelayDays || 0;

  const renderPounds = (props: {label: string; value?: number; unit: string}) => {
    const { label, value = 0, unit } = props;

    return (
      <div>
        <div className={styles.value}>
          <LrvTextAnimated value={value} /> &nbsp;
          <LrvText text={unit} theme={theme}/>
        </div>

        <div className={styles.label}>{label}</div>
      </div>
    );
  };

  const renderUtility = (props: {label: string; value?: number; unit: string; interestRate?: number; }) => {
    const { label, value = 0, unit, interestRate } = props;

    return (
      <div>
        <div className={styles.value}>
          <LrvText text={unit} theme={theme}/>&nbsp;
          <LrvTextAnimated value={value} /> &nbsp;
          {
            interestRate &&
            <LrvTooltip
              themeStyle={theme}
              title={() => (
                <div className={styles.info}>
                  <div><strong>{t('optimalHarvestPoint.interestRate')}</strong>: {interestRate}%</div>
                  <div><strong>{t('priceTable.paymentDelayDays')}</strong>: {days}</div>
                </div>
              )}
            >
              <div>
                <Icon
                  name='information'
                  type='fill'
                  className={styles.icon}
                  theme={theme}
                />
              </div>
            </LrvTooltip>
          }
        </div>
        
        <div className={styles.label}>{label}</div>
      </div>
    );
  };

  const renderDate = (props: {label: string; value?: string;}) => {
    const { label, value = '' } = props;

    return (
      <div>
        <div className={styles.value}>
          <LrvText text={formatLongDateWithZone({ date: value })} theme={theme}/>
        </div>
        
        <div className={styles.label}>{label}</div>
      </div>
    );
  };

  return (
    <Space className={styles.container} size={24}>
      {renderPounds({ label: t('optimalHarvestPoint.biomassToHarvest'), value: company.weightUnit === weightUnitsByCompany.KILOGRAM ? prediction?.biomass : prediction?.biomassLb, unit: company.weightUnit === weightUnitsByCompany.KILOGRAM ? weightUnits.KG : weightUnits.LB })}
      {renderUtility({ label: t('optimalHarvestPoint.presentValueProfit'), value: calculatePresentValue({ futureValue: (point?.netValue.potentialGain || 0), annualRate: interestRate / 100, days }), unit: '$', interestRate })}
      {renderUtility({ label: t('optimalHarvestPoint.profit'), value: point?.netValue.potentialGain, unit: '$' })}
      {renderDate({ label: t('optimalHarvestPoint.harvestDay'), value: prediction?.predictionDate })}
    </Space>
  );
};
