import React, { useRef } from 'react';

import styles from './LrvMultiRangeSlider.module.scss';

interface MultiRangeSliderProps {
  initialValues: number[];
  minValueDisplay: number;
  maxValueDisplay: number;
  clickableMinValue: number;
  clickableMaxValue: number;
  onChange: (values: number[]) => void;
}

export const LrvMultiRangeSlider: React.FC<MultiRangeSliderProps> = ({
  initialValues,
  minValueDisplay,
  maxValueDisplay,
  clickableMinValue,
  clickableMaxValue,
  onChange,
}) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const draggingIndexRef = useRef<number | null>(null); // We use useRef to persist the index while dragging

  const handleMouseMove = (e: MouseEvent) => {
    const draggingIndex = draggingIndexRef.current; // We use draggingIndexRef
    if (draggingIndex === null || !sliderRef.current) {
      return;
    }
  
    const { left, width } = sliderRef.current.getBoundingClientRect();
    const newValue = Math.round((((e.clientX - left) / width) * (maxValueDisplay - minValueDisplay)) + minValueDisplay);
  
    if (!(clickableMinValue <= newValue && newValue <= clickableMaxValue)) {
      return;
    }
  
    // Get minimum and maximum allowed values for the current marker
    const margin = 2;
    const minLimit = draggingIndex > 0 ? initialValues[draggingIndex - 1] + margin : minValueDisplay;
    const maxLimit = draggingIndex < initialValues.length - 1
      ? initialValues[draggingIndex + 1] - margin
      : maxValueDisplay;
  
    // Restrict the new value within the allowed range
    const restrictedValue = Math.max(minLimit, Math.min(newValue, maxLimit));
  
    // Updates the value of the dragged marker
    const newValues = initialValues.map((v, i) =>
      i === draggingIndex ? restrictedValue : v
    );
  
    onChange(newValues);
  };

  const handleMouseUp = () => {
    draggingIndexRef.current = null;
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  };

  const handleMouseDown = (index: number, e: React.MouseEvent) => {
    e.stopPropagation();
    draggingIndexRef.current = index;
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const handleAddMarker = (clientX: number) => {
    if (!sliderRef.current) {
      return;
    }

    const { left, width } = sliderRef.current.getBoundingClientRect();
    const newValue = Math.round((((clientX - left) / width) * (maxValueDisplay - minValueDisplay)) + minValueDisplay);

    if (!(clickableMinValue <= newValue && newValue <= clickableMaxValue)) {
      return;
    }

    if (!initialValues.includes(newValue)) {
      const newValues = [...initialValues, newValue].sort((a, b) => a - b);
      onChange(newValues);
    }
  };

  const handleRemoveMarker = (index: number) => {
    const newValues = initialValues.filter((_, i) => i !== index);
    onChange(newValues);
  };

  return (
    <div
      ref={sliderRef}
      className={styles.container}
    >
      {/* Slider base line */}
      <div
        className={styles.slider}
        onClick={(e) => handleAddMarker(e.clientX)}
      />

      {/* Markers */}
      {initialValues.map((value, index) => {
        const percentage = ((value - minValueDisplay) / (maxValueDisplay - minValueDisplay)) * 100;

        return (
          <div
            key={index}
            className={styles.containerSquare}
            style={{
              left: `calc(${percentage}% - 10px)`,
            }}
          >
            <div
              className={styles.square}
              onMouseDown={(e) => handleMouseDown(index, e)}
              onDoubleClick={() => handleRemoveMarker(index)}
            />
            <div className={styles.text}>
              {value}
            </div>
          </div>
        );
      })}
    </div>
  );
};
