import * as d3 from 'd3';

import { FeedingDataset, RegressionResult } from './interfaces';

export const POWER_BASE = 5.83;
export const POWER_EXPONENT = -0.407;

const calculateLogarithmicRegression = (dataset: FeedingDataset[]) => {
  const logX = dataset.map(d => Math.log(d.averageWeight));
  const logY = dataset.map(d => Math.log(d.dailyRation));

  const { slope, intercept } = linearRegression(logX, logY);
  const scaleFactor = Math.exp(intercept);

  return {
    scaleFactor,
    slope,
    predict: (x: number) => scaleFactor * Math.pow(x, slope),
  };
};

const linearRegression = (x: number[], y: number[]): { slope: number; intercept: number } => {
  const n = x.length;
  const sumX = x.reduce((acc, val) => acc + val, 0);
  const sumY = y.reduce((acc, val) => acc + val, 0);
  const sumXY = x.reduce((acc, val, i) => acc + (val * y[i]), 0);
  const sumXX = x.reduce((acc, val) => acc + (val * val), 0);

  const slope = ((n * sumXY) - (sumX * sumY)) / ((n * sumXX) - (sumX * sumX));
  const intercept = (sumY - (slope * sumX)) / n;

  return { slope, intercept };
};

export const computeR2 = (data: FeedingDataset[], regression: RegressionResult): number => {
  const yMean = data.reduce((sum, d) => sum + d.dailyRation, 0) / data.length;
  const ssTot = data.reduce((sum, d) => sum + Math.pow(d.dailyRation - yMean, 2), 0);
  const ssRes = data.reduce((sum, d) => sum + Math.pow(d.dailyRation - regression.predict(d.averageWeight), 2), 0);

  return 1 - (ssRes / ssTot);
};

const generateSmoothCurve = (regression: RegressionResult, xMin: number, xMax: number): FeedingDataset[] => {
  const points = d3.range(xMin, xMax, (xMax - xMin) / 200).map(averageWeight => ({
    averageWeight,
    dailyRation: regression.predict(averageWeight),
  }));
  
  return points;
};

export const processFeedingRegression = (feedingDataset: FeedingDataset[]) => {
  const regression = calculateLogarithmicRegression(feedingDataset);
  const r2 = computeR2(feedingDataset, regression);

  const xMin = Math.min(...feedingDataset.map(d => d.averageWeight));
  const xMax = Math.max(...feedingDataset.map(d => d.averageWeight));
  const smoothCurve = generateSmoothCurve(regression, xMin, xMax);

  return {
    smoothCurve,
    r2,
    scaleFactor: regression.scaleFactor,
    slope: regression.slope,
  };
};
