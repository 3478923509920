import * as d3 from 'd3';

import { DEFAULT_STAGE_MAX } from '../../../../config/commons';
import { defaultGrayColor } from '../../../../pages/OptimalHarvestPoint/helpers';
import { CommercialSizeData, DataSource, IPoint, PredictionPoint, Point, PocByPacker, DataSourceByStage, ProfitType, ChartParameter } from '../../../../pages/OptimalHarvestPoint/interfaces';

export function calcStages (params: { dataSource: DataSource; maxStage: number; isExcluding?: boolean }) {
  const { dataSource, maxStage = DEFAULT_STAGE_MAX, isExcluding } = params;
  const allPredictionStages = dataSource.predictions.map(item => item.inputData.stage);

  if (isExcluding) {
    const allAnalysisStages = dataSource.allAnalysis.map(item => item.inputData.stage);
    const allStages = [...allAnalysisStages, ...allPredictionStages];
  
    const firstStage = Math.min(...allStages);
    let lastStage = Math.max(...allStages);
    lastStage = lastStage > maxStage ? maxStage : lastStage;
  
    return [firstStage, lastStage];
  }
  
  const allAnalysisStages = dataSource.allAnalysis.filter((item) => !item.excludedFromPrediction).map(item => item.inputData.stage);
  const firstStage = Math.max(...allAnalysisStages);
  
  let lastStage = Math.max(...allPredictionStages);
  lastStage = lastStage > maxStage ? maxStage : lastStage;

  return [firstStage, lastStage];
}

export function calcYValues (params: { points: IPoint[]; chartParameter: string; profitType?: ProfitType }) {
  const { points, chartParameter, profitType } = params;
  let allValues: number[] = [];
  let allPoints: Point[] = [];
  let predictionPoints: PredictionPoint[] = [];
  let commercialSizeDataPoints: CommercialSizeData[] = [];
  
  switch (chartParameter) {
    case ChartParameter.WEIGHT:
    default:
      allPoints = points.filter(item => 'y' in item) as Point[];
      allValues = allPoints.map(item => item.y);
      break;

    case ChartParameter.BIOMASS_KG:
      predictionPoints = points.filter(item => 'biomass' in item) as PredictionPoint[];
      allValues = predictionPoints.map(item => item.biomass);
      break;

    case ChartParameter.BIOMASS_LB:
      predictionPoints = points.filter(item => 'biomassLb' in item) as PredictionPoint[];
      allValues = predictionPoints.map(item => item.biomassLb);
      break;

    case ChartParameter.LOAD_CAPACITY:
      predictionPoints = points.filter(item => 'pondLoadCapacity' in item) as PredictionPoint[];
      allValues = predictionPoints.map(item => item.pondLoadCapacity);
      break;

    case ChartParameter.COST_PER_DAY_KG:
      predictionPoints = points.filter(item => 'costPerDayKg' in item) as PredictionPoint[];
      allValues = predictionPoints.map(item => item.costPerDayKg);
      break;
      
    case ChartParameter.COST_PER_DAY_LB:
      predictionPoints = points.filter(item => 'costPerDayLb' in item) as PredictionPoint[];
      allValues = predictionPoints.map(item => item.costPerDayLb);
      break;

    case ChartParameter.AERATION:
      predictionPoints = points.filter(item => 'aeration' in item) as PredictionPoint[];
      allValues = predictionPoints.map(item => item.aeration);
      break;

    case ChartParameter.CORRECTED_FOOD:
      predictionPoints = points.filter(item => 'correctedFoodQuantity' in item) as PredictionPoint[];
      allValues = predictionPoints.map(item => item.correctedFoodQuantity);
      break;

    case ChartParameter.POC:
      if (profitType === ProfitType.PROFIT_TOTAL) {
        commercialSizeDataPoints = points.filter(item => 'presentValue' in item) as CommercialSizeData[];
        allValues = commercialSizeDataPoints.filter(item => item.presentValue.potentialGain).map(item => item.presentValue.potentialGain);
        break;
      }

      commercialSizeDataPoints = points.filter(item => 'presentValue' in item) as CommercialSizeData[];
      allValues = commercialSizeDataPoints.filter(item => item.presentValue.potentialGainByDayHectarea).map(item => item.presentValue.potentialGainByDayHectarea);
      break;

    case ChartParameter.POTENTIAL_INCOME:
      commercialSizeDataPoints = points.filter(item => 'potentialIncome' in item) as CommercialSizeData[];
      allValues = commercialSizeDataPoints.filter(item => item.potentialIncome).map(item => item.potentialIncome);
      break;

    case ChartParameter.TOTAL_ACCUMULATED_COST:
      predictionPoints = points.filter(item => 'totalAccumulatedCost' in item) as PredictionPoint[];
      allValues = predictionPoints.map(item => item.totalAccumulatedCost);
      break;
  }

  const minY = Math.min(...allValues);
  const maxY = Math.max(...allValues);

  return [minY, maxY];
}

export const getPresentValue = (props: { point: CommercialSizeData; profitType?: ProfitType }) => {
  const { point, profitType } = props;

  if (profitType === ProfitType.PROFIT_TOTAL) {
    return point.presentValue.potentialGain;
  }

  return point.presentValue.potentialGainByDayHectarea;
};

export const renderTickFormat = (domainValue: d3.AxisDomain) => {
  return Number.isInteger(domainValue) && Number(domainValue) >= 0 ? domainValue.toString() : '';
};

interface LeftPositionProps {
  marginLeft: number;
  tooltipDialogWidth: number;
  bubbleWidth: number;
  width: number;
  tooltipLeftAdjustmentRatio?: number;
  tooltipRightAdjustmentRatio?: number;
  bubbleOffsetMultiplier?: number;
}

export const getChartLeftPosition = (props: LeftPositionProps) => {
  const { marginLeft, tooltipDialogWidth, bubbleWidth, width, tooltipLeftAdjustmentRatio = 2.5, tooltipRightAdjustmentRatio = 2, bubbleOffsetMultiplier = 2 } = props;

  const tooltipTotalWidth = tooltipDialogWidth + bubbleWidth;

  let value = 0;
  if (marginLeft + tooltipTotalWidth < width) {
    value = marginLeft + (tooltipDialogWidth / tooltipLeftAdjustmentRatio) + (bubbleWidth / bubbleOffsetMultiplier);
  } else {
    value = marginLeft - (tooltipDialogWidth / tooltipRightAdjustmentRatio) - (bubbleWidth * bubbleOffsetMultiplier);
  }

  return `${value}px`;
};

export const getCommercialSizeDataPoints = (props: { allPredictions: PredictionPoint[]; bestPackers: PocByPacker[]; }) => {
  const { allPredictions, bestPackers } = props;

  if (allPredictions.length === 0) {
    return [];
  }

  const packerColorMap = new Map(bestPackers.map(packer => [packer.packerId, packer.color]));
  const packerOrderMap = new Map(bestPackers.map((packer, index) => [packer.packerId, index]));

  let commercialSizeDataPoints: CommercialSizeData[] = [];
  
  for (const prediction of allPredictions) {
    const { commercialSizeData } = prediction;
    
    const data = commercialSizeData
      .filter(item => packerOrderMap.has(item.packerId))
      .map(item => ({
        ...item,
        color: packerColorMap.get(item.packerId) || defaultGrayColor,
        predictionDate: prediction.predictionDate,
      }));

    commercialSizeDataPoints = [...commercialSizeDataPoints, ...data];
  }

  commercialSizeDataPoints.sort((a, b) => (packerOrderMap.get(a.packerId) ?? Infinity) - (packerOrderMap.get(b.packerId) ?? Infinity));
  return commercialSizeDataPoints;
};


export const getYValue = (props: {point: IPoint; chartParameter: string; profitType?: ProfitType }) => {
  const { chartParameter, point, profitType } = props;

  switch (chartParameter) {
    default:
    case ChartParameter.WEIGHT:
      return 'y' in point ? point.y : 0;
    case ChartParameter.CORRECTED_FOOD:
      return 'correctedFoodQuantity' in point ? point.correctedFoodQuantity : 0;
    case ChartParameter.BIOMASS_KG:
      return 'biomass' in point ? point.biomass : 0;
    case ChartParameter.BIOMASS_LB:
      return 'biomassLb' in point ? point.biomassLb : 0;
    case ChartParameter.COST_PER_DAY_KG:
      return 'costPerDayKg' in point ? point.costPerDayKg : 0;
    case ChartParameter.COST_PER_DAY_LB:
      return 'costPerDayLb' in point ? point.costPerDayLb : 0;
    case ChartParameter.AERATION:
      return 'aeration' in point ? point.aeration : 0;
    case ChartParameter.POTENTIAL_INCOME:
      return 'potentialIncome' in point ? point.potentialIncome : 0;
    case ChartParameter.POC:
      if (profitType === ProfitType.PROFIT_TOTAL) {
        return (point as CommercialSizeData).presentValue.potentialGain;
      }
      
      return (point as CommercialSizeData).presentValue.potentialGainByDayHectarea;
    case ChartParameter.TOTAL_ACCUMULATED_COST:
      return 'totalAccumulatedCost' in point ? point.totalAccumulatedCost : 0;
  }
};

export const getCommercialSize = (props: {point?: PredictionPoint; packerId?: string; }) => {
  const { packerId, point } = props;
  const currentCommercialSize = point ? point?.commercialSizeData.find((item) => item.packerId === packerId) : undefined;
  return currentCommercialSize;
};

export const getCommercialSizeData = (props: { allPredictions: PredictionPoint[]; packerId?: string; }) => {
  const { allPredictions, packerId } = props;
  
  const commercialSizeData: CommercialSizeData[] = [];

  for (let index = 0; index < allPredictions.length; index++) {
    const point = allPredictions[index];
    const commercialSize = getCommercialSize({ point, packerId });

    if (!commercialSize) {
      continue;
    }
    
    commercialSizeData.push(commercialSize);
  }

  return commercialSizeData;
};

export const getYDomainData = (props: { allPredictions: PredictionPoint[]; allPoints?: (PredictionPoint | Point)[]; chartParameter: string; packerId?: string; bestPackers?: PocByPacker[]; profitType?: ProfitType; isExcluding?: boolean }) => {
  const { allPredictions, chartParameter, packerId, bestPackers, profitType, allPoints, isExcluding } = props;
  const minMarginPercentage = 0.015;
  let maxMarginPercentage = 0.015;
  const minMargin = 0.5;

  let minY = 0;
  let maxY = 0;

  switch (chartParameter) {
    case ChartParameter.POTENTIAL_INCOME: {
      const points: CommercialSizeData[] = getCommercialSizeData({ allPredictions, packerId });
      const [minYValue, maxYValue] = calcYValues({ points, chartParameter });
      minY = minYValue;
      maxY = maxYValue;
      break;
    }

    case ChartParameter.POC: {
      if (!bestPackers || !bestPackers.length) {
        break;
      }
      const points = getCommercialSizeDataPoints({ allPredictions, bestPackers });
      const [minYValue, maxYValue] = calcYValues({ points, chartParameter, profitType });

      minY = minYValue;
      maxY = maxYValue;
      maxMarginPercentage = 0.05;
      break;
    }

    case ChartParameter.WEIGHT: {
      const points: IPoint[] = isExcluding && allPoints ? allPoints : allPredictions;
      const [minYValue, maxYValue] = calcYValues({ points, chartParameter });
      minY = minYValue;
      maxY = maxYValue;
      break;
    }
  
    default: {
      const [minYValue, maxYValue] = calcYValues({ points: allPredictions, chartParameter });
      minY = minYValue;
      maxY = maxYValue;
      break;
    }
  }

  if (maxY < 10) {
    const range = maxY - minY;
    minY = minY - (range * minMarginPercentage);
    maxY = maxY + (range * maxMarginPercentage);

    return {
      maxY,
      minY,
    };
  }

  const range = maxY - minY;
  const newMinMargin = Math.max(range * minMarginPercentage, minMargin);
  const newMaxMargin = Math.max(range * maxMarginPercentage, minMargin);

  minY -= newMinMargin;
  maxY += newMaxMargin;

  return {
    maxY,
    minY,
  };
};

const getYPosition = (props: {point: PredictionPoint | CommercialSizeData; chartParameter: string; scaleLinearLeftY: d3.ScaleLinear<number, number, never>; scaleLinearRightY?: d3.ScaleLinear<number, number, never>; packerId?: string; profitType?: ProfitType; }) => {
  const { chartParameter, point, scaleLinearLeftY, scaleLinearRightY, packerId, profitType } = props;

  if (chartParameter === ChartParameter.POC) {
    if (profitType === ProfitType.PROFIT_TOTAL) {
      const potentialGain = (point as CommercialSizeData).presentValue.potentialGain;
      return potentialGain === undefined ? 0 : scaleLinearLeftY(potentialGain);
    }
    
    const potentialGainByDayHectarea = (point as CommercialSizeData).presentValue.potentialGainByDayHectarea;
    return potentialGainByDayHectarea === undefined ? 0 : scaleLinearLeftY(potentialGainByDayHectarea);
  }

  const predictionPoint = (point as PredictionPoint);

  switch (chartParameter) {
    default:
    case ChartParameter.WEIGHT: {
      return scaleLinearLeftY(predictionPoint.y);
    }
    
    case ChartParameter.CORRECTED_FOOD: {
      return scaleLinearLeftY(predictionPoint.correctedFoodQuantity);
    }

    case ChartParameter.BIOMASS_KG: {
      return scaleLinearLeftY(predictionPoint.biomass);
    }
      
    case ChartParameter.BIOMASS_LB: {
      return scaleLinearLeftY(predictionPoint.biomassLb);
    }

    case ChartParameter.COST_PER_DAY_KG: {
      if (scaleLinearRightY) {
        return scaleLinearRightY(predictionPoint.costPerDayKg);
      }
      return 0;
    }
      
    case ChartParameter.COST_PER_DAY_LB: {
      if (scaleLinearRightY) {
        return scaleLinearRightY(predictionPoint.costPerDayLb);
      }
      return 0;
    }

    case ChartParameter.AERATION: {
      return scaleLinearLeftY(predictionPoint.aeration);
    }
      
    case ChartParameter.POTENTIAL_INCOME: {
      const commercialSize = getCommercialSize({ point: predictionPoint, packerId });
      return scaleLinearLeftY(commercialSize?.potentialIncome || 0);
    }

    case ChartParameter.TOTAL_ACCUMULATED_COST: {
      return scaleLinearLeftY(predictionPoint.totalAccumulatedCost);
    }
  }
};

export const generateLines = (props: {chartParameter: string; scaleLinearX: d3.ScaleLinear<number, number, never>; scaleLinearLeftY: d3.ScaleLinear<number, number, never>; scaleLinearRightY?: d3.ScaleLinear<number, number, never>; packerId?: string; profitType?: ProfitType; dataSourceByStage?: DataSourceByStage[]; }) => {
  const { chartParameter, scaleLinearX, scaleLinearLeftY, scaleLinearRightY, packerId, profitType, dataSourceByStage = [] } = props;
  
  const lineCurve = d3.line<PredictionPoint | CommercialSizeData>()
    .x((point) => scaleLinearX(point?.x))
    .y((point) => getYPosition({ point, chartParameter, scaleLinearLeftY, scaleLinearRightY, packerId, profitType }))
    .curve(d3.curveBasis);

  const linesData = dataSourceByStage.filter(item => item.y !== 0);

  return { lineCurve, linesData };
};

export const getHigherValue = (props: { chartParameter: string; pointsList: IPoint[]; packerId?: string; }) => {
  const { chartParameter, pointsList, packerId } = props;

  return pointsList.reduce(function (prev: IPoint, current: IPoint) {
    switch (chartParameter) {
      default:
      case ChartParameter.WEIGHT:
        return 'y' in prev && 'y' in current && (prev.y > current.y) ? prev : current;
      case ChartParameter.BIOMASS_KG:
        return 'biomass' in prev && 'biomass' in current && (prev.biomass > current.biomass) ? prev : current;
      case ChartParameter.BIOMASS_LB:
        return 'biomassLb' in prev && 'biomassLb' in current && (prev.biomassLb > current.biomassLb) ? prev : current;

      case ChartParameter.AERATION:
        return 'aeration' in prev && 'aeration' in current && (prev.aeration > current.aeration) ? prev : current;

      case ChartParameter.CORRECTED_FOOD:
        return 'correctedFoodQuantity' in prev && 'correctedFoodQuantity' in current && (prev.correctedFoodQuantity > current?.correctedFoodQuantity) ? prev : current;

      case ChartParameter.POTENTIAL_INCOME: {
        const previousCommercialSize = getCommercialSize({ point: prev as PredictionPoint, packerId });
        const currentCommercialSize = getCommercialSize({ point: current as PredictionPoint, packerId });
        return previousCommercialSize && currentCommercialSize && (previousCommercialSize.potentialIncome > currentCommercialSize.potentialIncome) ? prev : current;
      }
      case ChartParameter.TOTAL_ACCUMULATED_COST:
        return 'totalAccumulatedCost' in prev && 'totalAccumulatedCost' in current && (prev.totalAccumulatedCost > current.totalAccumulatedCost) ? prev : current;
    }
  });
};

export const getLowestValue = (props: { chartParameter: string; pointsList: PredictionPoint[]; packerId?: string; }) => {
  const { chartParameter, pointsList, packerId } = props;

  return pointsList.reduce(function (prev: PredictionPoint, current: PredictionPoint) {
    switch (chartParameter) {
      default:
      case ChartParameter.WEIGHT:
        return 'y' in prev && 'y' in current && (prev.y < current.y) ? prev : current;
      case ChartParameter.BIOMASS_KG:
        return 'biomass' in prev && 'biomass' in current && (prev.biomass < current.biomass) ? prev : current;
      case ChartParameter.BIOMASS_LB:
        return 'biomassLb' in prev && 'biomassLb' in current && (prev.biomassLb < current.biomassLb) ? prev : current;
      case ChartParameter.AERATION:
        return 'aeration' in prev && 'aeration' in current && (prev.aeration < current.aeration) ? prev : current;
      case ChartParameter.CORRECTED_FOOD:
        return 'correctedFoodQuantity' in prev && 'correctedFoodQuantity' in current && (prev.correctedFoodQuantity < current.correctedFoodQuantity) ? prev : current;
      case ChartParameter.POTENTIAL_INCOME: {
        const previousCommercialSize = getCommercialSize({ point: prev, packerId });
        const currentCommercialSize = getCommercialSize({ point: current, packerId });
        return previousCommercialSize && currentCommercialSize && (previousCommercialSize.potentialIncome < currentCommercialSize.potentialIncome) ? prev : current;
      }
      case ChartParameter.TOTAL_ACCUMULATED_COST:
        return 'totalAccumulatedCost' in prev && 'totalAccumulatedCost' in current && (prev.totalAccumulatedCost < current?.totalAccumulatedCost) ? prev : current;
    }
  });
};

export const getMarginBottom = (props: { chartParameter: string; scaleLinearY: d3.ScaleLinear<number, number, never>; packerId?: string; lowestValue: PredictionPoint }) => {
  const { chartParameter, scaleLinearY, packerId, lowestValue } = props;

  switch (chartParameter) {
    case ChartParameter.WEIGHT:
    default:
      return scaleLinearY(lowestValue.y);

    case ChartParameter.BIOMASS_KG:
      return scaleLinearY(lowestValue.biomass);
      
    case ChartParameter.BIOMASS_LB:
      return scaleLinearY(lowestValue.biomassLb);
      
    case ChartParameter.AERATION:
      return scaleLinearY(lowestValue.aeration);
      
    case ChartParameter.CORRECTED_FOOD:
      return scaleLinearY(lowestValue.correctedFoodQuantity);
      
    case ChartParameter.POTENTIAL_INCOME: {
      const commercialSize = getCommercialSize({ point: lowestValue, packerId });

      if (!commercialSize?.potentialIncome) {
        return 0;
      }

      return scaleLinearY(commercialSize.potentialIncome);
    }

    case ChartParameter.TOTAL_ACCUMULATED_COST:
      return scaleLinearY((lowestValue as PredictionPoint).totalAccumulatedCost);
  }
};

export const calculateTooltipExtraPaddingLeft = (props: {marginLeft: number; width: number; tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined> }) => {
  const { marginLeft, width, tooltip } = props;

  let value = 0;
  const tooltipTotalWidth = tooltip.node()?.offsetWidth || 0;

  if ((marginLeft + tooltipTotalWidth) < width) {
    value = -16; // has to be the same that width, but negative
  } else {
    value = tooltipTotalWidth;
  }

  return `${value}px`;
};

export const calculateTooltipBottom = (props: {marginBottom: number; height: number; tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined> }) => {
  const { marginBottom, height, tooltip } = props;

  let value = 0;
  const tooltipTotalHeight = tooltip.node()?.offsetHeight || 0;
  if (marginBottom + tooltipTotalHeight > height) {
    value = (height - marginBottom) + (tooltipTotalHeight / 4);
  } else {
    value = (height - marginBottom) - (tooltipTotalHeight / 4);
  }

  return `${value}px`;
};
