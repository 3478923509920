import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';

import { StockingParameter } from './interfaces';
import { getBodyStockingParameter } from './helpers';
import { StockingParameterForm } from './StockingParameterForm';
import { StockingParameterSelected } from './StockingParameterSelected';
import { CompanyStockingParameterForm } from './CompanyStockingParameterForm';
import { editStockingParameters } from './Detail/detailStockingParameterSlice';
import { createStockingParameters, setParameterDate, setShowStockingParameterModal } from './newStockingParameterSlice';

export const StockingParameterScreen = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const { company } = useSelector((state: Store) => state.header);
  const { selectedStocking } = useSelector((state: Store) => state.stockings);
  const { isLoadingStockingParameterSelected, stockingParameterSelected, companyStockingParameters } = useSelector((state: Store) => state.editStockingParameter);
  const { showStockingParameterModal, isLoadingNewStockingParameters, parameterDate, isLoadingFourDaysParameters } = useSelector((state: Store) => state.newStockingParameter);

  const [isEditing, setIsEditing] = useState(false);
  const [enterParameters, setEnterParameters] = useState(false);
  const [enteredParameters, setEnteredParameters] = useState(false);
  const [stockingParameters, setStockingParameters] = useState<StockingParameter[]>([]);
  const [auxiliarStockingParameters, setAuxiliarStockingParameters] = useState<StockingParameter[]>([]);

  useEffect(() => {
    if (!stockingParameterSelected?._id) {
      setEnteredParameters(false);
      setStockingParameters([]);
      return;
    }

    const parameters: StockingParameter[] = [];
    for (let index = 0; index < stockingParameterSelected.data.length; index++) {
      const currentCompanyStockingParameter = companyStockingParameters.find(companyStockingParameter => companyStockingParameter._id === stockingParameterSelected.data[index].key);

      if (!currentCompanyStockingParameter?.key) {
        return;
      }

      const item: StockingParameter = {
        _id: currentCompanyStockingParameter._id,
        key: currentCompanyStockingParameter.key,
        type: currentCompanyStockingParameter.type,
        value: stockingParameterSelected.data[index].value,
        frequency: stockingParameterSelected.data[index].frequency,
        options: currentCompanyStockingParameter.options,
        max: currentCompanyStockingParameter.max,
        min: currentCompanyStockingParameter.min,
      };

      parameters.push(item);
    }
    
    setStockingParameters(parameters);
    setEnteredParameters(true);
  }, [stockingParameterSelected, companyStockingParameters]);

  const resetForm = () => {
    dispatch(setShowStockingParameterModal(false));
    setEnterParameters(false);
    setEnteredParameters(false);
    setStockingParameters([]);
    setAuxiliarStockingParameters([]);
    dispatch(setParameterDate(moment().toISOString()));
  };

  const isValidForm = () => {
    return stockingParameters.length > 0 && !enterParameters;
  };

  const renderBody = () => {
    if (enterParameters) {
      return (
        <StockingParameterForm
          setStockingParameters={setStockingParameters}
          stockingParameters={stockingParameters}
          setEnteredParameters={setEnteredParameters}
          setEnterParameters={setEnterParameters}
          auxiliarStockingParameters={auxiliarStockingParameters}
          setAuxiliarStockingParameters={setAuxiliarStockingParameters}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      );
    }

    if (enteredParameters) {
      return (
        <StockingParameterSelected
          setStockingParameters={setStockingParameters}
          stockingParameters={stockingParameters}
          setEnterParameters={setEnterParameters}
          setAuxiliarStockingParameters={setAuxiliarStockingParameters}
          setIsEditing={setIsEditing}
        />
      );
    }

    return (
      <CompanyStockingParameterForm
        setEnterParameters={setEnterParameters}
        setAuxiliarStockingParameters={setAuxiliarStockingParameters}
      />
    );
  };

  return (
    <LrvModal
      theme='light'
      title={t('stockingParameter.formNew.title')}
      open={showStockingParameterModal}
      destroyOnClose={true}
      okButtonProps={{ id: 'stocking_parameter', htmlType: 'submit', form: 'formStockingParameter', loading: isLoadingNewStockingParameters, disabled: !isValidForm() }}
      onOk={() => {
        if (!stockingParameterSelected?._id) {
          const body = getBodyStockingParameter({ parameterDate, selectedStocking, stockingParameters, companyId: company._id });
          dispatch(createStockingParameters({ body, onCloseForm: resetForm }));
          return;
        }

        const body = getBodyStockingParameter({ _id: stockingParameterSelected._id, parameterDate: stockingParameterSelected.date, selectedStocking, stockingParameters, companyId: company._id, active: stockingParameters.length > 0 });
        dispatch(editStockingParameters({ body, onCloseForm: resetForm }));
      }}
      okText={t('stockingParameter.form.accept')}
      cancelText={t('stockingParameter.form.cancel')}
      isLoading={isLoadingFourDaysParameters || isLoadingStockingParameterSelected}
      onCancel={resetForm}
    >
      {renderBody()}
    </LrvModal>
  );
};
