import moment from 'moment';
import i18next from 'i18next';
import { AxiosError } from 'axios';

import { Stocking } from '../Sowings/interfaces';
import { getHoursOffset } from '../../utils/date';
import { isNumber } from '../../utils/validations';
import { openErrorNotification } from '../../common/notification/Notification';
import { getParameterLabelByKey } from '../Company/StockingParameters/helpers';
import { stockingParameterErrors, stockingParameterTypes } from '../../config/commons';
import { CompanyStockingParameter, DuplicateParameter } from '../Company/StockingParameters/interfaces';

import * as newStockingParameterSlice from './newStockingParameterSlice';
import { NewDataStockingParameter, StockingParameter } from './interfaces';

export const disabledStartButton = (companyStockingParameters: CompanyStockingParameter[]) => {
  const selectedItems = companyStockingParameters.filter((parameter) => !!parameter.selected);
  return selectedItems.length === 0;
};

export const subtractStockingParameters = (props: { companyStockingParameters: CompanyStockingParameter[]; stockingParameters: StockingParameter[] }) => {
  const {
    companyStockingParameters,
    stockingParameters,
  } = props;

  const results = companyStockingParameters.filter(companyStockingParameter => {
    const isNotInStockingParameters = !stockingParameters.some(stockingParameter => stockingParameter._id === companyStockingParameter._id);

    if (isNotInStockingParameters) {
      return true;
    }

    if (companyStockingParameter.type === stockingParameterTypes.NUMERIC && companyStockingParameter.options && companyStockingParameter.options.length > 0) {
      const usedOptionsCount = stockingParameters.filter(stockingParameter =>
        stockingParameter._id === companyStockingParameter._id &&
        companyStockingParameter.options.includes(stockingParameter.frequency)
      ).length;

      return usedOptionsCount < companyStockingParameter.options.length;
    }

    return false;
  });

  return results;
};

export const disabledParameterDate = (props: { date: moment.Moment }) => {
  const { date } = props;
  const currentDate = moment();
  const minDate = moment().subtract(4, 'd');
  return (date < minDate) || (date > currentDate);
};

export const onChangeParameterDate = (params: { date: moment.Moment | null }) => (dispatch: Function) => {
  const { date } = params;
  dispatch(newStockingParameterSlice.setParameterDate(date?.toISOString() || moment().toISOString()));
};

export const getBodyStockingParameter = (props: { _id?: string; stockingParameters: StockingParameter[]; selectedStocking: Stocking; companyId: string; active?: boolean; parameterDate: string; }) => {
  const { _id, parameterDate, selectedStocking, stockingParameters, companyId, active = true } = props;
  const newDataStockingParameters: NewDataStockingParameter[] = [];
  const hoursOffset = getHoursOffset();

  stockingParameters.forEach((stockingParameter) => {
    const item: NewDataStockingParameter = {
      key: stockingParameter._id,
      value: isNumber(stockingParameter.value) ? Number(stockingParameter.value) : stockingParameter.value,
      frequency: stockingParameter.frequency?.toString(),
    };
    newDataStockingParameters.push(item);
  });

  return {
    _id,
    stockingId: selectedStocking._id,
    companyId,
    active,
    data: newDataStockingParameters,
    date: parameterDate,
    hoursOffset,
  };
};

export const catchDuplicateKeys = (duplicateKeys: string[]) => {
  const key = getParameterLabelByKey(duplicateKeys[0]);
  let message = i18next.t('stockingParameter.form.errors.oneParameterByDate.singular', { key });

  if (duplicateKeys.length > 1) {
    const lastKey = getParameterLabelByKey(duplicateKeys[duplicateKeys.length - 1]);
    const firstKeys = duplicateKeys.slice(0, -1);
    const keys = firstKeys.map((key: string) => getParameterLabelByKey(key));
    message = i18next.t('stockingParameter.form.errors.oneParameterByDate.plural', { keys: keys.join(', '), key: lastKey });
  }

  return message;
};

export const showStockingParameterError = (props: { error: AxiosError }) => {
  const { error, duplicateKeys, duplicateParameters } = props.error?.response?.data?.data;

  let message = i18next.t('stockingParameter.form.errors.unknownError');

  switch (error) {
    case stockingParameterErrors.ONE_PARAMETER_BY_DATE:
      // When there are error with the front-end form
      if (duplicateKeys) {
        message = catchDuplicateKeys(duplicateKeys);
      }

      // When there are error with some database record
      if (duplicateParameters) {
        const duplicateKeys = duplicateParameters.map((item: DuplicateParameter) => item.keyName);
        message = catchDuplicateKeys(duplicateKeys);
      }

      openErrorNotification(message);
      break;
  }
};

export const isValidStockingParameter = (props: { auxiliarStockingParameters: StockingParameter[] }) => {
  const { auxiliarStockingParameters } = props;

  let isValid = true;

  for (let index = 0; index < auxiliarStockingParameters.length; index++) {
    const auxiliarStockingParameter = auxiliarStockingParameters[index];

    if (!isValid) {
      continue;
    }

    switch (auxiliarStockingParameter.type) {
      case stockingParameterTypes.NUMERIC:
      default: {
        const isValueValid = !!auxiliarStockingParameter.value && auxiliarStockingParameter.min <= Number(auxiliarStockingParameter.value) && Number(auxiliarStockingParameter.value) <= auxiliarStockingParameter.max;
        const isFrequencyValid = auxiliarStockingParameter.options.length > 0 ? !!auxiliarStockingParameter.frequency : true;
        isValid = isValueValid && isFrequencyValid;
        break;
      }

      case stockingParameterTypes.CATEGORICAL: {
        isValid = !!auxiliarStockingParameter.value;
        break;
      }
    }
  }

  return isValid;
};
