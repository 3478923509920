import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

import { weightUnits } from '../../../../config/commons';
import { LrvTable } from '../../../../common/components/LrvTable/LrvTable';
import { CommercialSizeRange, ProcessedCommercialSizeRange } from '../interfaces';
import { getMaxValueDisplay, processCommercialSizeRanges, getClickableMinValue } from '../../../../helpers/commercial-size.helpers';

import styles from './CommercialSizeTable.module.scss';

interface Props {
  commercialSizeRanges: CommercialSizeRange[];
  commercialSizeType: string;
}

export const CommercialSizeTable = (props: Props) => {
  const { commercialSizeRanges, commercialSizeType } = props;

  const maxValueDisplay = getMaxValueDisplay({ commercialSizeType });
  const clickableMinValue = getClickableMinValue({ commercialSizeType });

  const [t] = useTranslation();

  const processedDataSource = processCommercialSizeRanges({
    ranges: commercialSizeRanges,
    maxValueDisplay,
    commercialSizeType,
    clickableMinValue,
  });

  const columns: ColumnProps<ProcessedCommercialSizeRange>[] = [
    {
      key: 1,
      title: t('commercialSizes.labels'),
      width: '25%',
      dataIndex: 'commercialSizeLabel',
    },
    {
      key: 2,
      title: `${t('commercialSizes.weightRange')} (${weightUnits.G})`,
      width: '25%',
      dataIndex: 'weightRange',
    },
  ];

  return (
    <LrvTable
      theme='light'
      columns={columns}
      className={styles.table}
      dataSource={processedDataSource}
      size='small'
      pagination={false}
      scroll={{ y: 360 }}
    />
  );
};