import cx from 'classnames';
import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { HarvestOptimalPlan, PocByPacker } from '../interfaces';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { THEME, weightUnitsByCompany } from '../../../config/commons';
import * as optimalHarvestPointSlice from '../optimalHarvestPointSlice';
import { convertKilogramsToPounds } from '../../../helpers/stocking.helpers';
import { CommercialSizePriceTable } from '../../Company/Packers/TablePrices/interfaces';

import styles from './Packers.module.scss';
import { ProgressBar } from './ProgressBar';

interface Props {
  theme?: 'light' | 'dark';
  height: number;
}

export const Packers = (props: Props) => {
  const { theme = 'light', height } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { company } = useSelector((state: Store) => state.header);
  const { allPackers: packers, packersWithCommercialSizes, harvestOptimalPlan } = useSelector((state: Store) => state.optimalHarvestPoint);
  
  const isLightTheme = theme === THEME.LIGHT;

  const groupHarvestOptimalPlans = groupBy(harvestOptimalPlan.harvestOptimalPlans, 'packerId');

  const sumBiomass = (accumulator: number, value: HarvestOptimalPlan) => {
    if (company.weightUnit === weightUnitsByCompany.KILOGRAM) {
      return accumulator + value.biomassKg;
    }

    const biomassLb = convertKilogramsToPounds(value.biomassKg, false);
    return accumulator + biomassLb;
  };

  const allBiomass = Object.values(groupHarvestOptimalPlans).map((plans) =>
    plans.reduce(sumBiomass, 0)
  );

  const maxBiomass = Math.max(...allBiomass);
  const extraWeight = maxBiomass * 0.05;
  const maxWeight = maxBiomass + extraWeight;

  const getBiomassByPacker = (props: {packerId: string;}) => {
    const { packerId } = props;
    
    const harvestOptimalPlans = harvestOptimalPlan.harvestOptimalPlans.filter((harvestOptimalPlan) => harvestOptimalPlan.packerId === packerId);
    const biomass = harvestOptimalPlans.reduce(sumBiomass, 0);

    return biomass;
  };

  const onClickPacker = (packer: PocByPacker) => {
    const packerWithCommercialSize = packersWithCommercialSizes.find((item) => item.packerId === packer.packerId);
    if (!packerWithCommercialSize) {
      return;
    }

    const commercialSize: CommercialSizePriceTable = {
      _id: packerWithCommercialSize.commercialSizePriceTableId,
      date: packerWithCommercialSize.date,
      commercialSizes: packerWithCommercialSize.commercialSizes,
      prices: packerWithCommercialSize.prices,
      waste: packerWithCommercialSize.waste,
      yield: packerWithCommercialSize.yield,
      companyId: packerWithCommercialSize.companyId,
      packerId: packerWithCommercialSize.packerId,
      packerName: packerWithCommercialSize.packerName,
      commercialSizeId: packerWithCommercialSize.commercialSizeId,
      paymentDelayDays: packerWithCommercialSize.paymentDelayDays,
    };

    dispatch(optimalHarvestPointSlice.setCommercialSizePriceTable(commercialSize));
    dispatch(optimalHarvestPointSlice.setShowUpdatePriceTableModal(true));
  };

  const renderPacker = (packer: PocByPacker) => {
    const biomass = getBiomassByPacker({ packerId: packer.packerId });
    const percentage = biomass === 0 ? (8 / 100) * 100 : (biomass / maxWeight) * 100;

    return (
      <div key={packer.packerName} className={styles.packer}>
        <div
          className={styles.packerInfo}
          onClick={() => onClickPacker(packer)}
        >
          <LrvText
            className={styles.packerName}
            text={packer.packerName}
            theme={theme}
          />&nbsp;

          <div className={styles.iconContainer}>
            <Icon name='pencil' theme={theme} className={styles.icon} />
          </div>
        </div>

        <ProgressBar biomass={biomass} color={packer.color} percentage={percentage} theme={theme}/>
      </div>
    );
  };

  return (
    <div
      className={cx(styles.container, isLightTheme ? styles.lightContainer : styles.darkContainer)}
      style={{ height }}
    >
      <LrvText
        className={styles.title}
        text={t('packers.title')}
        theme={theme}
      />

      {packers.map(renderPacker)}
    </div>
  );
};
