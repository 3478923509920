import moment from 'moment';
import i18next from 'i18next';

import { roundTwoDecimals, weightUnits } from '../../../config/commons';
import { TransferMetrics, TransferMetricEntry, TransferSummary } from '../../Reports/TransferGraph/interfaces';

export const HISTOGRAM_HEIGHT = 700;
export const HISTOGRAM_WIDTH = 1600;

/* eslint-disable max-depth */
export const organizeDataByVariable = (transferParameters: TransferMetrics) => {
  const result: Record<string, TransferMetricEntry[]> = {};

  // Iterate over each key in the input object (e.g., Capsule-1, Capsule-2, etc.)
  for (const transferParameter in transferParameters) {
    const variables = transferParameters[transferParameter];

    // Iterate over the variables of each key (e.g., averageWeight, animalsInCapsule, etc.)
    for (const variable in variables) {
      const value = variables[variable];

      // If there is no list for this variable yet, initialize it
      if (!result[variable]) {
        result[variable] = [];
      }

      // Add the object { name, value } to the list corresponding to the variable
      result[variable].push({
        name: transferParameter,
        value,
      });
    }
  }

  return result;
};

export const getUnit = (props: {parameter: string}) => {
  const { parameter } = props;

  switch (parameter) {
    case 'animalsInCapsule':
      return 'juv';

    case 'animalsPerCubicMeter':
      return 'juv/m³';

    case 'animalsSmallerThan':
    case 'averageWeight':
      return weightUnits.G ;
    
    case 'transferredInCapsule':
      return weightUnits.KG;

    case 'uniformityWeight':
    case 'variationCoefficientWeight':
      return '%';
  
    default:
      return '';
  }
};

export const getTitleGraph = (props: {parameter: string; maxSmallAnimalsWeight: number}) => {
  const { parameter, maxSmallAnimalsWeight } = props;

  if (parameter === 'animalsSmallerThan') {
    return i18next.t(`transfers.parameters.${parameter}`, { value: maxSmallAnimalsWeight });
  }

  return i18next.t(`transfers.parameters.${parameter}`);
};

export const weightedAverage = (props: {values: number[], weights: number[]}): number => {
  const { values, weights } = props;

  if (values.length !== weights.length || values.length === 0) {
    return 0;
  }

  const weightedSum = values.reduce((sum, value, index) => sum + (value * weights[index]), 0);
  const totalWeight = weights.reduce((sum, weight) => sum + weight, 0);
  
  if (totalWeight === 0) {
    return 0;
  }

  return weightedSum / totalWeight;
};

export const getGlobalSmallAnimalsPercentage = (props: { animalsTransferred: number[]; totalAnimalsTransferred: number; animalsSmallerThan: number[]; }) => {
  const { animalsTransferred, totalAnimalsTransferred, animalsSmallerThan } = props;

  let weightedTotal = 0;

  for (let i = 0; i < animalsTransferred.length; i++) {
    weightedTotal += (Number(animalsTransferred[i]) * Number(animalsSmallerThan[i]));
  }

  if (weightedTotal === 0 || totalAnimalsTransferred === 0) {
    return 0;
  }

  return roundTwoDecimals(weightedTotal / totalAnimalsTransferred);
};

export const getDaysDifference = (props: {startDate: string, transferDate: string}): number => {
  const { startDate, transferDate } = props;

  const start = moment(startDate);
  const end = moment(transferDate);
  
  return end.diff(start, 'days');
};

export const getLatestTransferDate = (props: {transfers: TransferSummary[]}) => {
  const { transfers } = props;

  return transfers
    .map((transfer) => new Date(transfer.transferDate))
    .reduce((latest, current) => (current > latest ? current : latest))
    .toISOString();
};
