import { TankPocParameter } from '../../Tanks/interfaces';

export interface FeedingTableState {
  feedingDataset: {
    points: FeedingDataset[];
    line: FeedingDataset[];
  }
  tankPocParameter: TankPocParameter;
  showFoodChartModal: boolean;
  dailyRation: DailyRation;
  isLoading: boolean;
}

export interface FeedingDataset {
  averageWeight: number;
  dailyRation: number;
}

export interface RegressionResult {
  scaleFactor: number;
  slope: number;
  predict: (x: number) => number;
}

export interface UpdateDatasetPayload {
  oldX: number;
  oldY: number;
  newX: number;
  newY: number;
}

export enum FeedingStrategy {
  HIGH = 1.05,
  NORMAL = 1,
  LOW = 0.95,
}

export interface DailyRation {
  scaleFactor: number;
  slope: number;
  r2: number;
}
