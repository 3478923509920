import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TRANSFERS_URL } from '../../../config/config.api';
import { axiosClient as axios } from '../../../utils/axios_instance';

import { TransferState, TransferPayload } from './interfaces';

const initialState: TransferState = {
  transferParameters: {},
  stocking: {
    _id: '',
    name: '',
    code: '',
    campusName: '',
    moduleName: '',
    tankName: '',
    startDateJuvenile: '',
  },
  transfer: {
    _id: '',
    name: '',
    transferDate: '',
    maxSmallAnimalsWeight: 0,
    histogramWeight: {
      limits: [],
      frequencies: [],
    },
  },
  isDownloadingFile: false,
  isLoading: false,
};

export const transferGraphSlice = createSlice({
  name: 'transferGraph',
  initialState,
  reducers: {
    setCapsules: (state: TransferState, action: PayloadAction<TransferPayload>) => {
      state.transferParameters = action.payload.transferParameters;
      state.stocking = action.payload.stocking;
      state.transfer = action.payload.transfer;
    },
    setIsLoading: (state: TransferState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsDownloadingFile: (state: TransferState, action: PayloadAction<boolean>) => {
      state.isDownloadingFile = action.payload;
    },
  },
});

export const {
  setCapsules,
  setIsLoading,
  setIsDownloadingFile,
} = transferGraphSlice.actions;

export const fetchTransferChartData = (props: { transferId: string; accessToken: string; }) => async (dispatch: Function) => {
  const { accessToken, transferId } = props;
  dispatch(setIsLoading(true));

  const url = `${TRANSFERS_URL}/${transferId}/chart-data`;

  try {
    const response = await axios.get<TransferPayload>(url, {
      headers: { 'Authorization': accessToken },
    });

    dispatch(setCapsules(response.data));
    dispatch(setIsLoading(false));
  } catch (error) {
    console.log(error?.response);
  }
};

export default transferGraphSlice.reducer;
