import { useEffect } from 'react';
import { Dropdown, Menu } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../../state/store.interfaces';
import { commonOptions } from '../../../../config/commons';
import Icon from '../../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../../helpers/theme';
import { formatLongDateWithOffset } from '../../../../utils/date';
import { LrvTag } from '../../../../common/components/LrvTag/LrvTag';
import IconButton from '../../../../common/components/buttons/IconButton';
import { LrvTable } from '../../../../common/components/LrvTable/LrvTable';
import ActionButton from '../../../../common/components/buttons/ActionButton';
import Breadcrumbs from '../../../../common/components/Breadcrumb/Breadcrumbs';
import { changeBreadcrumb, changeHeader } from '../../../AppHeader/headerSlice';

import styles from './TablePrices.module.scss';
import * as tablePriceSlice from './tablePriceSlice';
import { NewTablePriceForm } from './NewTablePriceForm';
import { EditTablePriceForm } from './EditTablePriceForm';
import { CommercialSizePriceTable } from './interfaces';

type TParams = {
  packerId: string;
  commercialSizeId: string;
};

export const TablePrices = ({ match }: RouteComponentProps<TParams>) => {
  const packerId = match.params.packerId;
  const commercialSizeId = match.params.commercialSizeId;

  const theme = getCurrentTheme();

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const {
    packer,
    commercialSize,
    isLoading,
    commercialSizePriceTables,
  } = useSelector((state: Store) => state.tablePrices);
  const { breadcrumb } = useSelector((state: Store) => state.header);

  useEffect(() => {
    dispatch(changeHeader({ title: 'priceTable.title' }));
  }, [dispatch]);

  useEffect(() => {
    if (!packer.name || !commercialSize?.name) {
      return;
    }

    const breadcrumb = [
      { id: 'packers', name: t('packers.title'), url: '/company/packers' },
      { id: 'commercial-sizes', name: packer.name, url: `/company/packers/${packer._id}` },
      { id: 'commercial-size-price-table', name: commercialSize.name, url: `/company/packers/${packer._id}/commercial-size/${commercialSize._id}` },
    ];

    dispatch(changeBreadcrumb(breadcrumb));
  }, [dispatch, packer, commercialSize]);

  useEffect(() => {
    if (!packerId || !commercialSizeId) {
      return;
    }

    dispatch(tablePriceSlice.fetchPacker({ packerId, commercialSizeId }));
    dispatch(tablePriceSlice.fetchCommercialSizePriceTables({ packerId, commercialSizeId }));
  }, [dispatch, packerId, commercialSizeId]);

  const menuItemsOptions = (record: CommercialSizePriceTable) => {
    return (
      <Menu
        onClick={({ key, domEvent }) => {
          domEvent.stopPropagation();

          switch (key) {
            case commonOptions.EDIT:
              if (!record._id) {
                return;
              }

              dispatch(tablePriceSlice.setShowUpdateModal(true));
              dispatch(tablePriceSlice.fetchCommercialSizePriceTable({ commercialSizePriceTableId: record._id }));
              return;
          }
        }}
      >
        <Menu.Item id='menu_option_edit' key={commonOptions.EDIT} className={styles.menuItemOptions}>
          <Icon name='edit' theme={theme} className={styles.icon} />
          <span>{t('stockings.edit')}</span>
        </Menu.Item>
      </Menu>
    );
  };

  const renderStatusTag = (record: CommercialSizePriceTable) => {
    if (record.active) {
      // if return null, table will display an "-" in the empty cell
      return <div></div>;
    }

    const id = `tag_${record._id}`;
    return (
      <LrvTag
        id={id}
        type='info'
        className={styles.statusTag}
      >
        {t('commercialSizes.inactive')}
      </LrvTag>
    );
  };

  const columns: ColumnsType<CommercialSizePriceTable> = [
    {
      key: 1,
      title: t('priceTable.date'),
      width: '35%',
      dataIndex: 'name',
      render: (_, record: CommercialSizePriceTable) => {
        return formatLongDateWithOffset(record.date);
      },
    },
    {
      key: 2,
      dataIndex: 'active',
      width: '30%',
      render: (_, record: CommercialSizePriceTable) => {
        return renderStatusTag(record);
      },
    },
    {
      key: 4,
      width: '5%',
      render: (_, record: CommercialSizePriceTable) => {
        return (
          <Dropdown
            overlay={() => menuItemsOptions(record)}
            trigger={['click']}
            placement='bottomRight'
          >
            <IconButton
              onClick={(e) => e.stopPropagation()}
              iconName='more-2'
            />
          </Dropdown>
        );
      },
    }
  ];

  return (
    <div className={styles.container}>
      <Breadcrumbs breadcrumb={breadcrumb} theme={theme} />

      <div className={styles.alignButtonRight}>
        <ActionButton
          id='add_button'
          type='primary'
          icon={<Icon name='add' />}
          onClick={() => {
            dispatch(tablePriceSlice.setShowCreateModal(true));
          }}
        >
          {t('stockingParameter.create')}
        </ActionButton>
      </div>

      <LrvTable
        columns={columns}
        dataSource={commercialSizePriceTables}
        loading={isLoading}
        scroll={{ y: '' }}
        theme={theme}
        size='small'
        pagination={false}
      />
      
      <NewTablePriceForm theme='light' />
      <EditTablePriceForm theme='light' />
    </div>
  );
};
