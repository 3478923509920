import cx from 'classnames';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import { Col, Form, Row, Select, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { StockingParameter } from '../interfaces';
import { isNumber } from '../../../utils/validations';
import { isValidStockingParameter } from '../helpers';
import Icon from '../../../common/components/Icon/Icon';
import { Store } from '../../../state/store.interfaces';
import { filterOptionSelect } from '../../../utils/select';
import { stockingParameterTypes } from '../../../config/commons';
import { LrvForm } from '../../../common/components/LrvForm/LrvForm';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import ActionButton from '../../../common/components/buttons/ActionButton';
import { LrvPopover } from '../../../common/components/LrvPopover/LrvPopover';
import { getParameterLabelByKey } from '../../Company/StockingParameters/helpers';
import { LrvRadioGroup } from '../../../common/components/LrvRadioGroup/LrvRadioGroup';
import { CompanyStockingParameter } from '../../Company/StockingParameters/interfaces';
import { LrvRadioButton } from '../../../common/components/LrvRadioButton/LrvRadioButton';
import { LrvInputNumber } from '../../../common/components/LrvInputNumber/LrvInputNumber';

import styles from './StockingParameterForm.module.scss';
import * as detailStockingParameterSlice from './detailStockingParameterSlice';

interface Props {
  stockingParameters: StockingParameter[];
  setStockingParameters: Dispatch<SetStateAction<StockingParameter[]>>;
  auxiliarStockingParameters: StockingParameter[];
  setAuxiliarStockingParameters: Dispatch<SetStateAction<StockingParameter[]>>;
  setEnterParameters: Dispatch<SetStateAction<boolean>>;
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
}

const { Option } = Select;

export const StockingParameterForm = (props: Props) => {
  const {
    stockingParameters,
    setStockingParameters,
    auxiliarStockingParameters,
    setAuxiliarStockingParameters,
    setEnterParameters,
    isEditing,
    setIsEditing,
  } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { companyStockingParameters } = useSelector((state: Store) => state.editStockingParameter);

  const updateCategoricalStockingParameter = (props: { value: string; index: number }) => {
    const { index, value } = props;

    const stockingParametersCopy: StockingParameter[] = cloneDeep(auxiliarStockingParameters);
    stockingParametersCopy[index].value = value;
    setAuxiliarStockingParameters(stockingParametersCopy);
  };

  const updateFrequencyStockingParameter = (props: { value: string; index: number }) => {
    const { index, value } = props;

    const stockingParametersCopy: StockingParameter[] = cloneDeep(auxiliarStockingParameters);
    stockingParametersCopy[index].frequency = value;
    setAuxiliarStockingParameters(stockingParametersCopy);
  };

  const updateNumericalStockingParameter = (props: { value: null | string | number; index: number }) => {
    const { index, value } = props;

    if ((value == null || value === undefined) || !isNumber(value)) {
      return;
    }

    const stockingParametersCopy: StockingParameter[] = cloneDeep(auxiliarStockingParameters);
    stockingParametersCopy[index].value = value;
    setAuxiliarStockingParameters(stockingParametersCopy);
  };

  const disableNextButton = () => {
    return !isValidStockingParameter({ auxiliarStockingParameters });
  };

  const renderCategoricalForm = (auxiliarStockingParameter: StockingParameter, index: number) => {
    return (
      <Form.Item
        label={getParameterLabelByKey(auxiliarStockingParameter.key)}
        required
      >
        <LrvSelect
          id={`dropdown_option_${index}`}
          showSearch
          theme='light'
          value={auxiliarStockingParameter.value}
          suffixIcon={<Icon name='arrow-down-s' />}
          dropdownMatchSelectWidth={false}
          onChange={(value) => updateCategoricalStockingParameter({ value, index })}
          filterOption={filterOptionSelect}
        >
          {auxiliarStockingParameter.options.map((option) => {
            return (
              <Option key={option} value={option} label={option}>
                {option}
              </Option>
            );
          })}
        </LrvSelect>
      </Form.Item>
    );
  };

  const renderNumericalLabel = (auxiliarStockingParameter: StockingParameter) => {
    return (
      <>
        {getParameterLabelByKey(auxiliarStockingParameter.key)}
        &nbsp;&nbsp;
        <LrvPopover
          placement='right'
          content={
            <div>
              <div>{t('stockingParameter.formNew.validValue', { min: auxiliarStockingParameter.min, max: auxiliarStockingParameter.max })}</div>
            </div>
          }
        >
          <InfoCircleOutlined />
        </LrvPopover>
      </>
    );
  };

  const renderNumericalForm = (auxiliarStockingParameter: StockingParameter, index: number) => {
    if (auxiliarStockingParameter.options.length === 0) {
      return (
        <Form.Item
          label={renderNumericalLabel(auxiliarStockingParameter)}
          required
        >
          <LrvInputNumber
            autoFocus={index === 0}
            theme='light'
            value={auxiliarStockingParameter.value}
            min={auxiliarStockingParameter.min}
            max={auxiliarStockingParameter.max}
            onChange={(value) => updateNumericalStockingParameter({ value, index })}
          />
        </Form.Item>
      );
    }

    return (
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={renderNumericalLabel(auxiliarStockingParameter)}
            required
          >
            <LrvInputNumber
              autoFocus={index === 0}
              theme='light'
              value={auxiliarStockingParameter.value}
              min={auxiliarStockingParameter.min}
              max={auxiliarStockingParameter.max}
              onChange={(value) => updateNumericalStockingParameter({ value, index })}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label={t('stockingParameter.formNew.frequency', { key: getParameterLabelByKey(auxiliarStockingParameter.key) })}
            required
          >
            <LrvRadioGroup
              theme='light'
              value={auxiliarStockingParameter.frequency}
              className={styles.radioGroup}
              buttonStyle='outline'
              onChange={(event) => updateFrequencyStockingParameter({ value: event.target.value, index })}
            >
              {auxiliarStockingParameter.options.map((option, index) => {
                return (
                  <LrvRadioButton
                    theme='light'
                    className={cx(styles.radioButton, index === (auxiliarStockingParameter.options.length - 1) ? '' : styles.borderRight)}
                    value={option}
                  >
                    <div id={option}>{option}</div>
                  </LrvRadioButton>
                );
              })}
            </LrvRadioGroup>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const renderItem = (auxiliarStockingParameter: StockingParameter, index: number) => {
    switch (auxiliarStockingParameter.type) {
      case stockingParameterTypes.NUMERIC:
      default:
        return renderNumericalForm(auxiliarStockingParameter, index);

      case stockingParameterTypes.CATEGORICAL:
        return renderCategoricalForm(auxiliarStockingParameter, index);
    }
  };

  const clearSelectedCompanyStockingParameters = () => {
    const companyStockingParametersCopy: CompanyStockingParameter[] = cloneDeep(companyStockingParameters);

    for (let index = 0; index < companyStockingParametersCopy.length; index++) {
      companyStockingParametersCopy[index].selected = false;
    }

    dispatch(detailStockingParameterSlice.setCompanyStockingParameters(companyStockingParametersCopy));
  };

  const renderButtons = () => {
    return (
      <Space
        className={styles.containerButtons}
      >
        <ActionButton
          id='btn_return'
          theme='light'
          type='ghost'
          onClick={() => {
            setEnterParameters(false);
            setIsEditing(false);
          }}
        >
          {t('stockingParameter.formNew.return')}
        </ActionButton>

        <ActionButton
          id='btn_next'
          theme='light'
          type='primary'
          disabled={disableNextButton()}
          onClick={() => {
            setEnterParameters(false);
            setIsEditing(false);

            if (isEditing) {
              setStockingParameters(auxiliarStockingParameters);
              return;
            }

            const items = stockingParameters.concat(auxiliarStockingParameters);
            setStockingParameters(items);

            clearSelectedCompanyStockingParameters();
          }}
        >
          {t('stockingParameter.formNew.next')}
        </ActionButton>
      </Space>
    );
  };

  return (
    <LrvForm
      className={styles.stockingParameterForm}
      theme='light'
      layout='vertical'
    >
      {auxiliarStockingParameters.map(renderItem)}
      {renderButtons()}
    </LrvForm>
  );
};
