const getHeight = (element?: React.RefObject<HTMLDivElement>) => {
  return element?.current?.offsetHeight || 0;
};

interface HeightProps {
  origins: React.RefObject<HTMLDivElement>;
  parentFilters?: React.RefObject<HTMLDivElement>;
  childFilters?: React.RefObject<HTMLDivElement>;
  legends?: React.RefObject<HTMLDivElement>;
  showStockingDetail: boolean;
}

export const getHeightOfTheOtherElements = (props: HeightProps) => {
  const { parentFilters, childFilters, legends, origins, showStockingDetail } = props;

  const headerHeight = 64;
  const extraHeight = 40;

  const value = getHeight(parentFilters) + getHeight(childFilters) + getHeight(legends) + (showStockingDetail ? getHeight(origins) : 0) + headerHeight + extraHeight;
  return value;
};

export const getWidthOfTheOtherElements = () => {
  const sidebarWidth = 80;
  const tanksWidth = 200;
  const extraWidth = 60;
  const value = sidebarWidth + extraWidth + tanksWidth;
  return value;
};
