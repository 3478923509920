import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../../state/store.interfaces';
import * as headerSlice from '../../../AppHeader/headerSlice';
import DotSpinner from '../../../../common/components/DotSpinner/DotSpinner';
import * as transferSummaryGraphSlice from '../../../Reports/TransferGraph/transferSummaryGraphSlice';

import { TransferData } from './TransferData';
import styles from './TransferSummaryGraphPdf.module.scss';

type TParams = {
  companyId: string;
  stockingId: string;
  language: string;
  accessToken: string;
};

export const TransferSummaryGraphPdf = ({ match }: RouteComponentProps<TParams>) => {
  const {
    companyId,
    accessToken,
    language,
    stockingId,
  } = match.params;

  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const { isLoading } = useSelector((state: Store) => state.transferSummaryGraph);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n, i18n.language]);

  useEffect(() => {
    dispatch(headerSlice.fetchCompany(companyId, accessToken));
    dispatch(transferSummaryGraphSlice.fetchTransferSummaryChartData({ stockingId, accessToken }));
  }, [dispatch, companyId, accessToken, stockingId]);

  if (isLoading) {
    return (
      <div className={styles.spinnerContainer}>
        <div className={styles.spinner}>
          <DotSpinner />
        </div>
      </div>
    );
  }

  return (
    <div id='transfer-summary-graphs' className={styles.transferSummary}>
      <TransferData />
    </div>
  );
};
