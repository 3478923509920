import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';

import { ChartParameter } from '../interfaces';
import { Store } from '../../../state/store.interfaces';
import { weightUnitsByCompany } from '../../../config/commons';

import { Chart } from './Chart';
import { MiniChart } from './MiniChart';
import styles from './MiniCharts.module.scss';

interface Props {
  showChart: boolean;
}

export const MiniCharts = (props: Props) => {
  const { showChart } = props;
  const [t] = useTranslation();

  const containerRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState<number>(140);

  const { company } = useSelector((state: Store) => state.header);

  const charts = [
    {
      parameter: ChartParameter.WEIGHT,
      title: t('optimalHarvestPoint.weight'),
    },
    {
      parameter: company.weightUnit === weightUnitsByCompany.KILOGRAM ? ChartParameter.BIOMASS_KG : ChartParameter.BIOMASS_LB,
      title: t('optimalHarvestPoint.biomass'),
    },
    {
      parameter: ChartParameter.AERATION,
      title: t('optimalHarvestPoint.aeration'),
    },
    {
      parameter: ChartParameter.TOTAL_ACCUMULATED_COST,
      title: t('optimalHarvestPoint.costs'),
    },
    {
      parameter: ChartParameter.POTENTIAL_INCOME,
      title: t('optimalHarvestPoint.income'),
    },
    {
      parameter: ChartParameter.CORRECTED_FOOD,
      title: t('optimalHarvestPoint.food'),
    },
  ];

  useEffect(() => {
    if (!showChart || !containerRef?.current) {
      return;
    }

    const updateChartWidth = () => {
      if (containerRef.current) {
        const margin = 48;
        const padding = 10 * (charts.length - 1);
        const totalWidth = containerRef.current.clientWidth - padding - margin;
        const newWidth = totalWidth / charts.length;
        setChartWidth(Math.max(newWidth, 80));
      }
    };

    updateChartWidth();
    window.addEventListener('resize', updateChartWidth);
    return () => window.removeEventListener('resize', updateChartWidth);
  }, [showChart, containerRef?.current?.clientWidth]);

  return (
    <>
      <div
        ref={containerRef}
        className={styles.graphs}
      >
        {charts.map((chart) => (
          <MiniChart chartParameter={chart.parameter} title={chart.title} width={chartWidth}/>
        ))}
        
        <Chart />
      </div>
    </>
  );
};
