import { Form } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import { LrvForm } from '../../../common/components/LrvForm/LrvForm';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../../common/components/LrvInput/LrvInput';

import * as packerSlice from './packerSlice';
import { Packer } from './interfaces';

interface Props {
  theme?: 'dark' | 'light';
}

export const NewPackerForm = (props: Props) => {
  const { theme = 'dark' } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const { company } = useSelector((state: Store) => state.header);
  const {
    isCreating,
    showCreatePackerModal,
  } = useSelector((state: Store) => state.packers);

  const [name, setName] = useState<string>();

  const closeModal = () => {
    form.resetFields();
    setName(undefined);
    dispatch(packerSlice.setShowCreatePackerModal(false));
  };

  const savePacker = () => {
    if (!name) {
      return;
    }

    const body: Packer = {
      companyId: company._id,
      name,
    };

    const props = {
      body,
      onSuccess: closeModal,
    };

    dispatch(packerSlice.createPacker(props));
  };

  const renderPackerName = () => {
    return (
      <Form.Item
        name='name'
        label={t('packers.name')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvInput
          theme={theme}
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={t('packers.name')}
        />
      </Form.Item>
    );
  };

  return (
    <LrvModal
      theme='light'
      title={t('packers.newPacker')}
      open={showCreatePackerModal}
      destroyOnClose={true}
      okButtonProps={{
        id: 'submit-packer',
        htmlType: 'submit',
        form: 'form',
        loading: isCreating,
        disabled: !name,
      }}
      onOk={savePacker}
      okText={t('packers.save')}
      cancelText={t('packers.cancel')}
      onCancel={closeModal}
    >
      <LrvForm
        theme={theme}
        form={form}
        name='form'
        id='form-analysis-observation'
        layout='vertical'
      >
        {renderPackerName()}
      </LrvForm>
    </LrvModal>
  );
};