import * as d3 from 'd3';
import cx from 'classnames';
import i18next from 'i18next';
import { Dispatch } from 'react';

import { GenericParam } from '../../../interfaces/commons';
import { getCurrentTheme } from '../../../../helpers/theme';
import { roundTwoDecimals, THEME, weightUnits } from '../../../../config/commons';
import { FeedingDataset } from '../../../../pages/OptimalHarvestPoint/Indicators/interfaces';
import * as feedingTableSlice from '../../../../pages/OptimalHarvestPoint/Indicators/feedingTableSlice';

import { renderTickFormat } from './helpers';
import styles from './FoodChartD3.module.scss';

const TICKS_NUMBER_X = 5;
const TICKS_NUMBER_Y = 8;
const TICK_PADDING = 4;
const TIME_TRANSITION = 300;
const DEFAULT_POINT_SIZE = 10;

let dispatch: Dispatch<GenericParam>;

const minX = 0;
const maxX = 50;
const minY = 0;
const maxY = 14;

interface Props {
  container: HTMLDivElement;
  height: number;
  width: number;
  points: FeedingDataset[];
  line: FeedingDataset[];
  dispatch: Dispatch<GenericParam>;
}

interface RefreshProps {
  points: FeedingDataset[];
  line: FeedingDataset[];
}

export class FoodChartD3 {
  container: HTMLDivElement;
  svg: d3.Selection<SVGSVGElement, FeedingDataset, null, undefined>;
  groupMain: d3.Selection<SVGGElement, FeedingDataset, null, undefined>;

  scaleLinearX: d3.ScaleLinear<number, number, never> = d3.scaleLinear();
  scaleLinearY: d3.ScaleLinear<number, number, never> = d3.scaleLinear();

  margin = { top: 10, right: 10, bottom: 16, left: 60 };

  width: number;
  height: number;
  xAxis: d3.Selection<SVGGElement, FeedingDataset, null, undefined> = d3.select<SVGGElement, FeedingDataset>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));
  yAxis: d3.Selection<SVGGElement, FeedingDataset, null, undefined> = d3.select<SVGGElement, FeedingDataset>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  points: FeedingDataset[];
  line: FeedingDataset[];

  // eslint-disable-next-line
  constructor(props: Props) {
    const {
      container,
      height,
      width,
      points,
      line,
    } = props;

    this.container = container;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    this.points = points;
    this.line = line;
    dispatch = props.dispatch;

    d3.select(container).select('#tooltipFoodChart').remove();
    d3.select(container).select('#svgFoodChart').remove();

    this.svg = d3.select<HTMLDivElement | null, FeedingDataset>(container)
      .append('svg')
      .attr('id', 'svgFoodChart')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom);

    this.groupMain = this.svg
      .append('g')
      .attr('id', 'containerFoodChart')
      .attr('transform', `translate( ${this.margin.left}, ${this.margin.top} )`)
      .style('pointer-events', 'all');

    this.createDataPoints();
  }

  createDataPoints = () => {
    this.renderLeftAxisTriangle();
    this.buildAxisX();
    this.buildAxisY();
    
    this.renderLineSelected();

    this.renderLine();
    this.drawXAxis();
    this.drawYAxis();
    this.renderPoints();
  };

  updateAxis = () => {
    const { height } = this;
    
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;
    
    this.buildAxisX();
    this.buildAxisY();

    const axisBottom = d3.axisBottom(this.scaleLinearX)
      .tickFormat((x) => renderTickFormat(x))
      .tickSize(-height)
      .ticks(TICKS_NUMBER_X)
      .tickPadding(TICK_PADDING);

    this.xAxis
      .attr('class', cx(styles.axisX, isLightTheme ? styles.axisLight : styles.axisDark))
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisBottom);

    const axisLeft = d3.axisLeft(this.scaleLinearY)
      .tickFormat((d) => d.valueOf() as unknown as string)
      .ticks(TICKS_NUMBER_Y)
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    this.yAxis
      .attr('class', cx(styles.axisY, isLightTheme ? styles.axisLight : styles.axisDark))
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisLeft);
  };

  updateDataPoints = () => {
    this.renderLeftAxisTriangle();
    this.updateAxis();
    this.updateLine();
    this.renderPoints();
  };

  refreshChart = (props: RefreshProps) => {
    const { points, line } = props;
    const { container } = this;

    this.points = points;
    this.line = line;

    d3.select(container).select('#tooltipContent').remove();
    d3.select(container).select('#tooltipExtraPadding').remove();
  
    this.updateDataPoints();
    this.renderLineSelected();
  };

  getDailyRations = () => {
    const { line } = this;

    const dailyRations = line.map((item) => item.dailyRation);
    return dailyRations;
  }

  getAverageWeights = () => {
    const { points } = this;

    const averageWeights = points.map((item) => item.averageWeight);
    return averageWeights;
  }

  buildAxisX = () => {
    const { width } = this;

    this.scaleLinearX = d3.scaleLinear()
      .domain([minX, maxX])
      .range([0, width]);
  };

  buildAxisY = () => {
    const { height } = this;

    this.scaleLinearY = d3.scaleLinear()
      .domain([minY, maxY])
      .range([height, 0]);
  };

  generateLines = () => {
    const { scaleLinearX, scaleLinearY } = this;
    
    const lineCurve = d3.line<FeedingDataset>()
      .x((value) => scaleLinearX(value.averageWeight))
      .y((value) => scaleLinearY(value.dailyRation))
      .curve(d3.curveBundle.beta(1));

    return { lineCurve };
  };

  renderLine = () => {
    const { groupMain, line } = this;
    const { lineCurve } = this.generateLines();

    groupMain
      .append('path')
      .datum(line)
      .attr('class', cx(styles.line, 'pathLine'))
      .attr('d', lineCurve);
  };

  updateLine = () => {
    const { container, groupMain, line } = this;
    const { lineCurve } = this.generateLines();

    d3.select(container).selectAll('.pathLine').remove();

    groupMain
      .append('path')
      .datum(line)
      .attr('class', cx(styles.line, 'pathLine'))
      .attr('d', lineCurve);
  };

  drawXAxis = () => {
    const { height, groupMain } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    const axis = d3.axisBottom(this.scaleLinearX)
      .tickFormat((x) => renderTickFormat(x))
      .tickSize(-height)
      .ticks(TICKS_NUMBER_X)
      .tickPadding(TICK_PADDING);

    this.xAxis = groupMain.append('g')
      .attr('id', 'axisX')
      .attr('class', cx(styles.axisX, isLightTheme ? styles.axisLight : styles.axisDark))
      .attr('transform', `translate(0, ${this.height})`)
      .call(axis);
  };

  drawYAxis = () => {
    const { groupMain } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    const axis = d3.axisLeft(this.scaleLinearY)
      .tickFormat((d) => d.valueOf() as unknown as string)
      .ticks(TICKS_NUMBER_Y)
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    this.yAxis = groupMain.append('g')
      .attr('id', 'axisY')
      .attr('class', cx(styles.axisY, isLightTheme ? styles.axisLight : styles.axisDark))
      .call(axis);
  }

  dragHandler = () => {
    const { container, groupMain, scaleLinearX, scaleLinearY } = this;
    const svgElement = groupMain.node();

    let tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select(this.container).select('#tooltipFoodChart');

    if (tooltip.empty()) {
      tooltip = d3.select(this.container)
        .append('div')
        .attr('id', 'tooltipFoodChart')
        .style('position', 'absolute')
        .style('background', 'rgba(0, 0, 0, 0.7)')
        .style('color', 'white')
        .style('padding', '5px 10px')
        .style('border-radius', '5px')
        .style('font-size', '12px')
        .style('pointer-events', 'none')
        .style('display', 'none');
    }

    return d3.drag<SVGCircleElement, FeedingDataset>()
      .on('start', function () {
        d3.select(this)
          .raise()
          .style('cursor', 'grabbing');

        (this as GenericParam).moved = false;
      })
      .on('drag', function (event) {
        const [x, y] = d3.pointer(event, svgElement);

        const scaledX = scaleLinearX.invert(x);
        const scaledY = scaleLinearY.invert(y);

        if (scaledX >= minX && scaledX <= maxX && scaledY >= minY && scaledY <= maxY) {
          d3.select(this)
            .attr('cx', x)
            .attr('cy', y);

          const boundingRect = container?.getBoundingClientRect();
          const tooltipX = event.sourceEvent.pageX - boundingRect.left + 10;
          const tooltipY = event.sourceEvent.pageY - boundingRect.top - 20;

          tooltip
            .style('left', `${tooltipX}px`)
            .style('top', `${tooltipY - 4}px`)
            .style('display', 'block')
            .html(`
              ${i18next.t('optimalHarvestPoint.feedingTable.averageWeight')}: ${roundTwoDecimals(scaledX)} ${weightUnits.G}<br>
              ${i18next.t('optimalHarvestPoint.feedingTable.dailyRationLabelY')}: ${roundTwoDecimals(scaledY)}
            `);
        }

        (this as GenericParam).moved = true;
      })
      .on('end', function (event, d) {
        if (!(this as GenericParam).moved) {
          return;
        }

        const [x, y] = d3.pointer(event, svgElement);

        const scaledX = scaleLinearX.invert(x);
        const scaledY = scaleLinearY.invert(y);

        if (y < 0 || x < 0 || scaledX < 0 || scaledY < 0) {
          return;
        }
        
        dispatch(feedingTableSlice.updatePointFeedingDataset({ oldX: d.averageWeight, oldY: d.dailyRation, newX: roundTwoDecimals(scaledX), newY: roundTwoDecimals(scaledY) }));
        tooltip.style('display', 'none');

        d3.select(this)
          .attr('stroke', '#43A047')
          .style('cursor', 'pointer');
      });
  }

  renderPoints = () => {
    const { container, groupMain, scaleLinearX, scaleLinearY, points } = this;

    d3.select(container).selectAll('.points').remove();

    const gPoints = groupMain.append('g')
      .attr('class', 'points')
      .attr('cursor', 'default');

    const svgElement = groupMain.node();
    const dragHandler = this.dragHandler();

    gPoints
      .selectAll('circle')
      .data(points)
      .enter()
      .append('circle')
      .attr('class', styles.circle)
      .attr('r', DEFAULT_POINT_SIZE)
      .attr('cx', (value) => scaleLinearX(value.averageWeight))
      .attr('cy', (value) => scaleLinearY(value.dailyRation))
      .attr('fill', '#43A047')
      .attr('stroke', '#43A047')
      .attr('stroke-width', 1)
      .call(dragHandler)
      .on('dblclick', (event, d) => {
        event.stopPropagation();

        if ((this as GenericParam).moved) {
          return;
        }

        const index = points.findIndex(p => p.averageWeight === d.averageWeight && p.dailyRation === d.dailyRation);
        dispatch(feedingTableSlice.removePointFeedingDataset(index));
      });

    groupMain.on('dblclick', (event) => {
      const [x, y] = d3.pointer(event, svgElement);
      const scaledX = scaleLinearX.invert(x);
      const scaledY = scaleLinearY.invert(y);
  
      if (scaledX >= minX && scaledX <= maxX && scaledY >= minY && scaledY <= maxY) {
        dispatch(feedingTableSlice.addPointFeedingDataset({ averageWeight: roundTwoDecimals(scaledX), dailyRation: roundTwoDecimals(scaledY) }));
      }
    });
  };

  renderLeftAxisTriangle = () => {
    const { container, margin } = this;

    const triangleMargins = {
      left: margin.left - 5,
      top: 0,
    };

    d3.select(container).selectAll('.triangle').remove();

    d3.select(container)
      .attr('id', 'triangle')
      .append('div')
      .attr('class', cx('triangle', styles.triangle))
      .style('transform', `translate(${triangleMargins.left}px, ${triangleMargins.top}px)`);
  }

  renderLineSelected = () => {
    const { container, groupMain, height } = this;
    d3.select(container).select('#selectedTick').remove();

    groupMain.append('line')
      .attr('id', 'selectedTick')
      .attr('stroke', '#909090')
      .attr('stroke-width', 1)
      .attr('y1', 0)
      .attr('y2', height)
      .style('display', 'none');
  }

  resize = (props: {width: number, height: number}) => {
    const { width, height } = props;

    const { container } = this;

    d3.select(container).select('#tooltipContent').selectAll('*').remove();

    this.updateSize(width, height);
    this.updateDataPoints();
  };

  updateSize = (width: number, height: number) => {
    const { container } = this;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    const _width = this.width + this.margin.left + this.margin.right;
    const _height = this.height + this.margin.top + this.margin.bottom;

    d3.select(container)
      .select('svg')
      .attr('id', 'svgFoodChart')
      .attr('width', _width)
      .attr('height', _height);
  };
}
