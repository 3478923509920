import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { InputNumber } from 'antd';

import { LrvText } from '../LrvText/LrvText';
import { LrvInputNumberProps } from '../../interfaces/lrvInputNumber';

import styles from './LrvInputNumber.module.scss';

export const LrvInputNumberV2 = (props: LrvInputNumberProps) => {
  const { className, containerClassName, theme = 'dark', titleClassName, title, value, disabled } = props;
  const isDarkTheme = theme === 'dark';

  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const hasValue = value !== undefined && value !== null && value !== '';

  useEffect(() => {
    if (!hasValue) {
      setIsFocused(false);
    }
  }, [hasValue]);

  const handleClick = () => {
    if (disabled) {
      return;
    }

    inputRef.current?.focus();
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };
  
  const lrvProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkInputNumber : styles.lightInputNumber, className),
    ref: inputRef,
    value,
    onBlur: handleBlur,
    onFocus: handleFocus,
  };

  return (
    <div
      className={cx(
        styles.container, isDarkTheme ? styles.darkContainer : styles.lightContainer,
        containerClassName,
        isFocused ? styles.focus : '',
      )}
      onClick={handleClick}
    >
      <LrvText
        className={cx(
          isDarkTheme ? styles.titleDark : styles.titleLight,
          titleClassName,
          (hasValue || isFocused) ? '' : styles.titleUnfocused
        )}
        text={title}
        theme={theme}
      />
      <InputNumber {...lrvProps} />
    </div>
  );
};
