import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import { GenericParam } from '../common/interfaces/commons';

export interface XlsxExportData {
  headerLabels: GenericParam;
  columnKeys: string[];
  rowsData: GenericParam[];
}

export const exportToExcel = async (data: XlsxExportData, fileName: string) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet 1');

  // Add headers using columnKeys to map headerLabels
  const headers = data.columnKeys.map(key => data.headerLabels[key] || key);
  const headerRow = worksheet.addRow(headers);

  // Format the first row (header) in bold
  headerRow.eachCell(cell => {
    cell.font = { bold: true };
  });

  // add row data
  data.rowsData.forEach(row => {
    const rowData = data.columnKeys.map(key => row[key] === undefined ? '' : row[key]);
    worksheet.addRow(rowData);
  });

  // Adjust column widths based on longest content
  worksheet.columns = data.columnKeys.map((key, index) => ({
    key,
    width: Math.max(headers[index].length, ...data.rowsData.map(row => row[key]?.toString().length || 10)) + 2
  }));

  // Generate file
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  saveAs(blob, `${fileName}.xlsx`);
};