import * as d3 from 'd3';
import cx from 'classnames';
import i18next from 'i18next';
import { Dispatch } from 'react';

import { GenericParam } from '../../../interfaces/commons';
import { getCurrentTheme } from '../../../../helpers/theme';
import { formatLongDateWithZone } from '../../../../utils/date';
import { Company } from '../../../../pages/AppHeader/interfaces';
import { applyThousandsSeparator } from '../../../../utils/strings';
import { formatter } from '../../../../pages/Sowings/Multiphase/multiphase-helpers';
import { powerUnits, roundOneDecimal, roundTwoDecimals, stockingPhaseTypes, THEME, volumeUnits, weightUnits, weightUnitsByCompany } from '../../../../config/commons';
import * as optimalHarvestPointSlice from '../../../../pages/OptimalHarvestPoint/optimalHarvestPointSlice';
import { CommercialSizeData, DataSourceByStage, IPoint, PocByPacker, PredictionPoint, Point, ChartParameter } from '../../../../pages/OptimalHarvestPoint/interfaces';
import { convertKilogramsToPounds } from '../../../../helpers/stocking.helpers';

import styles from './ForecastMetricD3.module.scss';
import { calculateTooltipBottom, calculateTooltipExtraPaddingLeft, generateLines, getChartLeftPosition, getCommercialSize, getCommercialSizeDataPoints, getHigherValue, getLowestValue, getMarginBottom, getYDomainData, getYValue, renderTickFormat } from './helpers';

const TICKS_NUMBER_X = 5;
const TICKS_NUMBER_Y = 8;
const TICK_PADDING = 4;
const TIME_TRANSITION = 300;
const DEFAULT_POINT_SIZE = 3;
const POINT_ACTIVE_SIZE = DEFAULT_POINT_SIZE + 3;

let currentStageActive: string;
let dispatch: Dispatch<GenericParam>;
let analysesToExclude: string[] = [];

interface Props {
  companyData: Company;
  container: HTMLDivElement | null;
  allPredictions: PredictionPoint[];
  chartParameter: string;
  firstStage: number;
  lastStage: number;
  height: number;
  width: number;
  packerId?: string;
  bestPackers: PocByPacker[];
  currencySymbol: string;
  dataSourceByStage: DataSourceByStage[];
  allPoints: (PredictionPoint | Point)[];
  isExcluding: boolean;
  dispatch: Dispatch<GenericParam>;
  loadCapacity: number;
}

interface RefreshProps {
  lastStage: number;
  firstStage: number;
  chartParameter: string;
  allPredictions: PredictionPoint[];
  companyData: Company;
  packerId?: string;
  bestPackers: PocByPacker[];
  dataSourceByStage: DataSourceByStage[];
  allPoints: (PredictionPoint | Point)[];
  isExcluding: boolean;
  loadCapacity: number;
}

export class ForecastMetricD3 {
  container: HTMLDivElement | null;
  svg: d3.Selection<SVGSVGElement, IPoint, null, undefined>;
  groupMain: d3.Selection<SVGGElement, IPoint, null, undefined>;

  scaleLinearX: d3.ScaleLinear<number, number, never> = d3.scaleLinear();
  scaleLinearLeftY: d3.ScaleLinear<number, number, never> = d3.scaleLinear();
  scaleLinearRightY: d3.ScaleLinear<number, number, never> = d3.scaleLinear();
  scaleLinearLoadCapacity: d3.ScaleLinear<number, number, never> = d3.scaleLinear();

  margin = { top: 10, right: 50, bottom: 16, left: 60 };

  allPredictions: PredictionPoint[] = [];
  bestPackers: PocByPacker[] = [];
  currencySymbol: string;
  dataSourceByStage: DataSourceByStage[] = []
  allPoints: (PredictionPoint | Point)[] = [];
  isExcluding: boolean;
  loadCapacity: number;
  
  tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select<HTMLDivElement, unknown>(document.createElement('div'));
  width: number;
  height: number;

  bottomAxisX: d3.Selection<SVGGElement, IPoint, null, undefined> = d3.select<SVGGElement, IPoint>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));
  leftAxisY: d3.Selection<SVGGElement, IPoint, null, undefined> = d3.select<SVGGElement, IPoint>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));
  rightAxisY: d3.Selection<SVGGElement, IPoint, null, undefined> = d3.select<SVGGElement, IPoint>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  firstStage = 0;
  lastStage = 0;
  companyData: Company;
  chartParameter: string;
  packerId?: string;

  // eslint-disable-next-line
  constructor(props: Props) {
    const {
      allPredictions,
      bestPackers,
      chartParameter,
      companyData,
      container,
      firstStage,
      lastStage,
      height,
      width,
      packerId,
      currencySymbol,
      allPoints,
      dataSourceByStage,
      isExcluding,
      loadCapacity,
    } = props;

    this.container = container;
    this.chartParameter = chartParameter;
    this.allPredictions = allPredictions;
    this.bestPackers = bestPackers;
    this.companyData = companyData;
    this.packerId = packerId;
    this.currencySymbol = currencySymbol;

    this.allPoints = allPoints;
    this.dataSourceByStage = dataSourceByStage;
    this.isExcluding = isExcluding;
    dispatch = props.dispatch;
    this.loadCapacity = loadCapacity;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    d3.select(container).select('#tooltipForecastMetric').remove();
    d3.select(container).select('#svgForecastMetric').remove();

    this.svg = d3.select<HTMLDivElement | null, IPoint>(container)
      .append('svg')
      .attr('id', 'svgForecastMetric')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom);

    this.groupMain = this.svg
      .append('g')
      .attr('id', 'contentForecastMetric')
      .attr('transform', `translate( ${this.margin.left}, ${this.margin.top} )`)
      .style('pointer-events', 'all');

    this.firstStage = firstStage;
    this.lastStage = lastStage;

    this.createDataPoints();
    this.renderTooltips();
  }

  createDataPoints = () => {
    this.renderLeftAxisTriangle();
    this.buildAxisBottomX();
    this.buildAxisLeftY();
    this.buildAxisRightY();
    this.buildAxisPondLoadCapacity();
    
    this.createTooltip();
    this.renderLineSelected();

    this.renderLoadCapacityRects();
    this.renderLine();
    this.renderCostPerDayLine();
    this.drawXAxis();
    this.drawAxisLeft();
    this.drawAxisRight();
    this.renderPoints();
    this.renderCostPeyDayPoints();
  };

  isLightTheme = () => getCurrentTheme() === THEME.LIGHT;

  updateAxis = () => {
    const { height } = this;
    this.drawAxisRight();

    this.buildAxisBottomX();
    this.buildAxisLeftY();
    this.buildAxisRightY();
    this.buildAxisPondLoadCapacity();

    const axisBottom = d3.axisBottom(this.scaleLinearX)
      .tickFormat((x) => renderTickFormat(x))
      .tickSize(-height)
      .ticks(TICKS_NUMBER_X)
      .tickPadding(TICK_PADDING);

    this.bottomAxisX
      .attr('class', cx(styles.axisX, this.isLightTheme() ? styles.axisLight : styles.axisDark))
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisBottom);

    const axisLeft = d3.axisLeft(this.scaleLinearLeftY)
      .tickFormat((d) => d.valueOf() as unknown as string)
      .ticks(TICKS_NUMBER_Y)
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    this.leftAxisY
      .attr('class', cx(styles.axisY, this.isLightTheme() ? styles.axisLight : styles.axisDark))
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisLeft);

    if (this.chartParameter === ChartParameter.TOTAL_ACCUMULATED_COST) {
      const axisRight = d3.axisRight(this.scaleLinearRightY)
        .tickFormat((d) => d.valueOf() as unknown as string)
        .ticks(TICKS_NUMBER_Y)
        .tickSize(0)
        .tickPadding(TICK_PADDING);
  
      this.rightAxisY
        .attr('class', cx(styles.axisY, this.isLightTheme() ? styles.axisLight : styles.axisDark))
        .transition()
        .duration(TIME_TRANSITION)
        .call(axisRight);
    }
  };

  updateDataPoints = () => {
    this.renderLeftAxisTriangle();
    this.updateAxis();
    this.renderLoadCapacityRects();
    this.updateLine();
    this.updateCostPerDayLine();

    this.renderPoints();
    this.renderCostPeyDayPoints();
  };

  refreshChart = (props: RefreshProps) => {
    const { allPredictions, companyData, chartParameter, firstStage, lastStage, packerId, allPoints, dataSourceByStage, isExcluding, loadCapacity } = props;
    const { tooltip, container } = this;

    this.allPredictions = allPredictions;
    this.firstStage = firstStage;
    this.lastStage = lastStage;
    this.chartParameter = chartParameter;
    this.companyData = companyData;
    this.packerId = packerId;

    this.allPoints = allPoints;
    this.dataSourceByStage = dataSourceByStage;
    this.isExcluding = isExcluding;
    this.loadCapacity = loadCapacity;

    d3.select(container).select('#tooltipContent').remove();
    d3.select(container).select('#tooltipExtraPadding').remove();
    
    tooltip.style('display', 'none');

    this.updateDataPoints();
    this.renderTooltips();
    this.renderLineSelected();
  };

  buildAxisBottomX = () => {
    const { width, firstStage, lastStage } = this;

    const minX = firstStage;
    const maxX = lastStage;

    this.scaleLinearX = d3.scaleLinear()
      .domain([minX, maxX])
      .range([0, width]);
  };

  buildAxisLeftY = () => {
    const { height, allPredictions, packerId, chartParameter, allPoints, isExcluding } = this;
    const { maxY, minY } = getYDomainData({ allPredictions, allPoints, packerId, chartParameter, isExcluding });

    this.scaleLinearLeftY = d3.scaleLinear()
      .domain([minY, maxY])
      .range([height, 0]);
  };

  buildAxisRightY = () => {
    const { height, allPredictions, packerId, allPoints, companyData } = this;
  
    const chartParameter = companyData.weightUnit === weightUnitsByCompany.KILOGRAM ? ChartParameter.COST_PER_DAY_KG : ChartParameter.COST_PER_DAY_LB;
    const { maxY, minY } = getYDomainData({ allPredictions, allPoints, packerId, chartParameter });

    this.scaleLinearRightY = d3.scaleLinear()
      .domain([minY, maxY])
      .range([height, 0]);
  };

  buildAxisPondLoadCapacity = () => {
    const { width, allPredictions, packerId, allPoints } = this;
    const { maxY, minY } = getYDomainData({ allPredictions, allPoints, packerId, chartParameter: ChartParameter.LOAD_CAPACITY });

    this.scaleLinearLoadCapacity = d3.scaleLinear()
      .domain([minY, maxY])
      .range([0, width]);
  };

  getDataLine = () => {
    const { allPredictions } = this;

    const dataLine = [allPredictions[0], allPredictions[7], allPredictions[15], allPredictions[allPredictions.length - 1]];
    return dataLine;
  };

  renderLine = () => {
    const { groupMain, allPredictions, chartParameter, packerId, scaleLinearX, scaleLinearLeftY, scaleLinearRightY, dataSourceByStage } = this;
    const { lineCurve } = generateLines({ scaleLinearX, scaleLinearLeftY, scaleLinearRightY, chartParameter, packerId, dataSourceByStage });
    const dataLine = this.getDataLine();

    groupMain
      .append('path')
      .datum(dataLine)
      .datum(allPredictions)
      .attr('class', cx(styles.line, 'pathLine'))
      .attr('d', lineCurve);
  };

  renderCostPerDayLine = () => {
    const { container, companyData, groupMain, allPredictions, packerId, scaleLinearX, scaleLinearLeftY, scaleLinearRightY, dataSourceByStage } = this;
    
    d3.select(container).selectAll('.costPerDayLine').remove();

    if (this.chartParameter !== ChartParameter.TOTAL_ACCUMULATED_COST) {
      return;
    }

    const chartParameter = companyData.weightUnit === weightUnitsByCompany.KILOGRAM ? ChartParameter.COST_PER_DAY_KG : ChartParameter.COST_PER_DAY_LB;

    const { lineCurve } = generateLines({ scaleLinearX, scaleLinearLeftY, scaleLinearRightY, chartParameter, packerId, dataSourceByStage });

    groupMain
      .append('path')
      .datum(allPredictions)
      .attr('class', cx(styles.costPerDayLine, 'costPerDayLine'))
      .attr('d', lineCurve);
  };

  updateLine = () => {
    const { container, groupMain, allPredictions, chartParameter, packerId, scaleLinearX, scaleLinearLeftY, scaleLinearRightY, dataSourceByStage, isExcluding } = this;
    const { lineCurve, linesData } = generateLines({ scaleLinearX, scaleLinearLeftY, scaleLinearRightY, chartParameter, packerId, dataSourceByStage });
    const dataLine = this.getDataLine();

    d3.select(container).selectAll('.pathLine').remove();

    if (isExcluding) {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      groupMain
        .append('path')
        .datum(linesData as any)
        .attr('class', cx(styles.line, 'pathLine'))
        .attr('d', lineCurve);
    }

    groupMain
      .append('path')
      .datum(dataLine)
      .datum(allPredictions)
      .attr('class', cx(styles.line, 'pathLine'))
      .attr('d', lineCurve);
  };

  updateCostPerDayLine = () => {
    const { companyData, container, groupMain, allPredictions, packerId, scaleLinearX, scaleLinearLeftY, scaleLinearRightY, dataSourceByStage } = this;

    d3.select(container).selectAll('.costPerDayLine').remove();

    if (this.chartParameter !== ChartParameter.TOTAL_ACCUMULATED_COST) {
      return;
    }

    const chartParameter = companyData.weightUnit === weightUnitsByCompany.KILOGRAM ? ChartParameter.COST_PER_DAY_KG : ChartParameter.COST_PER_DAY_LB;
    const { lineCurve } = generateLines({ scaleLinearX, scaleLinearLeftY, scaleLinearRightY, chartParameter, packerId, dataSourceByStage });
    
    groupMain
      .append('path')
      .datum(allPredictions)
      .attr('class', cx(styles.costPerDayLine, 'costPerDayLine'))
      .attr('d', lineCurve);
  };

  drawXAxis = () => {
    const { height, groupMain } = this;

    const axis = d3.axisBottom(this.scaleLinearX)
      .tickFormat((x) => renderTickFormat(x))
      .tickSize(-height)
      .ticks(TICKS_NUMBER_X)
      .tickPadding(TICK_PADDING);

    this.bottomAxisX = groupMain.append('g')
      .attr('id', 'axisX')
      .attr('class', cx(styles.axisX, this.isLightTheme() ? styles.axisLight : styles.axisDark))
      .attr('transform', `translate(0, ${this.height})`)
      .call(axis);
  };

  drawAxisLeft = () => {
    const { groupMain } = this;

    const axis = d3.axisLeft(this.scaleLinearLeftY)
      .tickFormat((d) => d.valueOf() as unknown as string)
      .ticks(TICKS_NUMBER_Y)
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    this.leftAxisY = groupMain.append('g')
      .attr('id', 'leftAxisY')
      .attr('class', cx(styles.axisY, this.isLightTheme() ? styles.axisLight : styles.axisDark))
      .call(axis);
  }

  drawAxisRight = () => {
    const { container, chartParameter, groupMain, width } = this;

    d3.select(container).select('#rightAxisY').remove();

    if (chartParameter !== ChartParameter.TOTAL_ACCUMULATED_COST) {
      return;
    }

    const axis = d3.axisRight(this.scaleLinearRightY)
      .tickFormat((d) => d.valueOf() as unknown as string)
      .ticks(TICKS_NUMBER_Y)
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    this.rightAxisY = groupMain.append('g')
      .attr('id', 'rightAxisY')
      .attr('class', cx(styles.axisY, this.isLightTheme() ? styles.axisLight : styles.axisDark))
      .attr('transform', `translate(${width}, 0)`)
      .call(axis);
  }

  fillPoints (point: IPoint) {
    const { _id } = point as Point;

    const excludePoint = analysesToExclude.some(value => value === _id);

    if (excludePoint) {
      return '#ffffff';
    }

    if (_id) {
      return '#d9d9d9';
    }

    return '#43A047';
  }

  fillStroke (point: IPoint) {
    const { _id } = point as Point;

    const excludePoint = analysesToExclude.some(value => value === _id);

    if (excludePoint) {
      return '#000000';
    }

    if (_id) {
      return '#a8b7ec';
    }

    return '#43A047';
  }

  getPointsToShow = () => {
    const { allPredictions, chartParameter, bestPackers, isExcluding, allPoints } = this;
    
    if (isExcluding) {
      return allPoints;
    }

    if (chartParameter === ChartParameter.POTENTIAL_INCOME) {
      const commercialSizeDataPoints = getCommercialSizeDataPoints({ allPredictions, bestPackers });
      return commercialSizeDataPoints.filter((item) => item.packerId == this.packerId);
    }

    return allPredictions;
  }

  renderPoints = () => {
    const { container, groupMain, scaleLinearX, scaleLinearLeftY, chartParameter } = this;

    const points: IPoint[] = this.getPointsToShow();

    d3.select(container).selectAll('.points').remove();

    const gPoints = groupMain.append('g')
      .attr('class', 'points')
      .attr('cursor', 'default');

    gPoints
      .selectAll('circle')
      .data(points)
      .enter()
      .append('circle')
      .attr('class', (point) => `stage${point.x}`)
      .attr('r', DEFAULT_POINT_SIZE)
      .attr('cx', (point) => scaleLinearX(point.x))
      .attr('cy', (point) => scaleLinearLeftY(getYValue({ point, chartParameter })))
      .attr('fill', (point) => this.fillPoints(point))
      .attr('stroke', (point) => this.fillStroke(point))
      .attr('stroke-dasharray', (point) => analysesToExclude.some(value => value === (point as Point)._id) ? 2 : null)
      .attr('stroke-width', 1);
  };

  renderCostPeyDayPoints = () => {
    const { companyData, container, groupMain, scaleLinearX, scaleLinearRightY } = this;

    const points: IPoint[] = this.getPointsToShow();
    const chartParameter = companyData.weightUnit === weightUnitsByCompany.KILOGRAM ? ChartParameter.COST_PER_DAY_KG : ChartParameter.COST_PER_DAY_LB;

    d3.select(container).selectAll('.costPeyDayPoints').remove();

    if (this.chartParameter !== ChartParameter.TOTAL_ACCUMULATED_COST) {
      return;
    }

    const gPoints = groupMain.append('g')
      .attr('class', 'costPeyDayPoints')
      .attr('cursor', 'default');

    gPoints
      .selectAll('circle')
      .data(points)
      .enter()
      .append('circle')
      .attr('class', (point) => cx(styles.costPeyDayPoints, `stage${point.x}`))
      .attr('r', DEFAULT_POINT_SIZE)
      .attr('cx', (point) => scaleLinearX(point.x))
      .attr('cy', (point) => scaleLinearRightY(getYValue({ point, chartParameter })));
  };

  renderLeftAxisTriangle = () => {
    const { container, margin } = this;

    const triangleMargins = {
      left: margin.left - 5,
      top: 0,
    };

    d3.select(container).selectAll('.triangle').remove();

    d3.select(container)
      .attr('id', 'triangle')
      .append('div')
      .attr('class', cx('triangle', styles.triangle, styles.predictionTriangle))
      .style('transform', `translate(${triangleMargins.left}px, ${triangleMargins.top}px)`);
  }

  renderLineSelected = () => {
    const { container, groupMain, height } = this;
    d3.select(container).select('#selectedTick').remove();

    groupMain.append('line')
      .attr('id', 'selectedTick')
      .attr('stroke', '#909090')
      .attr('stroke-width', 1)
      .attr('y1', 0)
      .attr('y2', height)
      .style('display', 'none');
  }

  createTooltip = () => {
    this.tooltip = d3.select(this.container)
      .append('div')
      .attr('id', 'tooltipForecastMetric')
      .attr('class', styles.tooltip)
      .style('display', 'none')
      .on('mouseover', () => {
        this.tooltip.style('display', 'block');
        d3.select(this.container).select('#selectedTick').style('display', 'block');
        d3.select(this.container).selectAll(currentStageActive).attr('r', POINT_ACTIVE_SIZE);
      });
  }

  renderTooltips = () => {
    const {
      container,
      scaleLinearX,
      scaleLinearLeftY,
      tooltip,
      firstStage,
      lastStage,
      width,
      height,
      groupMain,
      renderTooltipsContent,
      allPredictions,
      chartParameter,
      packerId,
      isExcluding,
      allPoints,
    } = this;

    const bisect = d3.bisector(function (point: (Point | PredictionPoint)) {
      return point.x;
    }).left;

    const tooltipContent = tooltip.append('div')
      .attr('id', 'tooltipContent')
      .attr('class', styles.content);

    const tooltipExtraPadding = tooltip.append('div')
      .attr('id', 'tooltipExtraPadding')
      .attr('class', styles.extraPadding);

    groupMain
      .on('mouseleave', function () {
        tooltip.style('display', 'none');
        d3.select(container).select('#selectedTick').style('display', 'none');
        d3.select(container).selectAll(currentStageActive).attr('r', DEFAULT_POINT_SIZE);
      })
      .on('mousemove', function (event) {
        let x0 = scaleLinearX.invert((d3).pointer(event)[0]);
        x0 = Math.round(x0);

        const isXVisible = (x0 >= scaleLinearX.domain()[0]) && (x0 <= scaleLinearX.domain()[1]);
        if (!isXVisible) {
          return;
        }

        const points = isExcluding ? allPoints : allPredictions;
        const index = bisect(points, x0, 1);

        const previousPoint = points[index - 1];
        const currentPoint = points[index];
        let selectedPoint: (Point | PredictionPoint);

        if (currentPoint) {
          selectedPoint = x0 - previousPoint.x > currentPoint.x - x0 ? currentPoint : previousPoint;
        } else {
          selectedPoint = previousPoint;
        }

        if (!selectedPoint) {
          return;
        }

        if (!isExcluding && (selectedPoint as Point)._id) {
          return;
        }

        if (!(selectedPoint.x < firstStage || selectedPoint.x > lastStage)) {
          tooltip.style('display', 'block');
          d3.select(container).select('#selectedTick').style('display', 'block');
        }

        const dataByStage = points.find(item => item.x === selectedPoint?.x) as PredictionPoint;
        if (!dataByStage) {
          return;
        }

        const pointsList: IPoint[] = points.filter(item => item.x === dataByStage.x) ;
        d3.select(container).selectAll('.points circle').attr('r', DEFAULT_POINT_SIZE);
        d3.select(container).selectAll('.costPeyDayPoints circle').attr('r', DEFAULT_POINT_SIZE);

        if (pointsList?.length === 0) {
          tooltip.style('display', 'none');
          d3.select(container).select('#selectedTick').style('display', 'none');
          return;
        }

        const higherValue = getHigherValue({ chartParameter, packerId, pointsList: pointsList as PredictionPoint[] });
        const lowestValue = getLowestValue({ chartParameter, packerId, pointsList: pointsList as PredictionPoint[] });

        const marginLeft = scaleLinearX(higherValue.x);
        const marginBottom = getMarginBottom({ chartParameter, packerId, lowestValue, scaleLinearY: scaleLinearLeftY });
        
        currentStageActive = `.stage${higherValue.x}`;
        d3.select(container).selectAll(currentStageActive).attr('r', POINT_ACTIVE_SIZE);

        const tooltipDialogWidth = 160;
        const bubbleWidth = 17; // this needs to be the same as defined in the css
        const tooltipTotalWidthDefault = tooltipDialogWidth + bubbleWidth;

        if (marginLeft + tooltipTotalWidthDefault < width) {
          tooltip.classed(styles.rightAlignedTooltip, false);
          tooltip.classed(styles.leftAlignedTooltip, true);
        } else {
          tooltip.classed(styles.rightAlignedTooltip, true);
          tooltip.classed(styles.leftAlignedTooltip, false);
        }
        
        const leftPositionProps = { marginLeft, tooltipDialogWidth, bubbleWidth, width, tooltipRightAdjustmentRatio: 1.5 };
        tooltipExtraPadding
          .style('width', '16px') // has to be the same that value of left
          .style('left', calculateTooltipExtraPaddingLeft({ marginLeft, width, tooltip }));

        tooltip
          .style('left', getChartLeftPosition(leftPositionProps))
          .style('bottom', calculateTooltipBottom({ marginBottom, height, tooltip }));

        d3.select(container).select('#selectedTick')
          .attr('x1', marginLeft)
          .attr('x2', marginLeft);

        tooltipContent.selectAll('*').remove();
        renderTooltipsContent({ pointsList: pointsList as PredictionPoint[], tooltipContent, packerId, getCommercialSize, dispatch });
      });
  }

  /* eslint-disable max-depth*/
  renderTooltipsContent = (props: {
    pointsList: PredictionPoint[] | Point[];
    tooltipContent: d3.Selection<HTMLDivElement, unknown, null, undefined>;
    packerId?: string;
    dispatch: Dispatch<GenericParam>;
    getCommercialSize: (props: { point?: PredictionPoint; packerId?: string; }) => CommercialSizeData | undefined;
  }) => {
    const { chartParameter, companyData, currencySymbol } = this;
    const { pointsList, tooltipContent, packerId, getCommercialSize, dispatch } = props;
    
    for (let index = 0; index < pointsList.length; index++) {
      const currentPoint = pointsList[index];

      const predictionPoint = currentPoint as PredictionPoint;
      const point = currentPoint as Point;

      const entry = tooltipContent
        .append('div')
        .attr('class', styles.entry);

      const entryContent = entry.append('div')
        .attr('class', styles.entryContent);

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${i18next.t('shadedplot.day')}: <strong>${formatter(stockingPhaseTypes.ADULT, predictionPoint.x)}</strong>`);

      if (chartParameter === ChartParameter.WEIGHT) {
        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('analysis.resultData.averageWeight')}: <strong>${roundTwoDecimals(predictionPoint.y)} g</strong>`);

        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('analysis.resultData.uniformity')}: <strong>${roundTwoDecimals(predictionPoint.uniformity)} %</strong>`);

        predictionPoint.survival && entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('stockings.populations.survival')}: <strong>${roundTwoDecimals(predictionPoint.survival)} %</strong>`);

        predictionPoint.population && entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('stockings.populations.population')}: <strong>${applyThousandsSeparator(predictionPoint.population)} anim</strong>`);
      }

      if (chartParameter === ChartParameter.BIOMASS_KG || chartParameter === ChartParameter.BIOMASS_LB) {
        const biomassKg = Math.round(predictionPoint.biomass || 0);
        const biomassLb = Math.round(predictionPoint.biomassLb || 0);
        const pondLoadCapacityKg = Math.round(predictionPoint.pondLoadCapacity || 0);
        const pondLoadCapacityLb = Math.round(convertKilogramsToPounds(predictionPoint.pondLoadCapacity || 0));

        const formattedBiomassKg = applyThousandsSeparator(biomassKg);
        const formattedBiomassLb = applyThousandsSeparator(biomassLb);
        const formattedPondLoadCapacityKg = applyThousandsSeparator(pondLoadCapacityKg);
        const formattedPondLoadCapacityLb = applyThousandsSeparator(pondLoadCapacityLb);

        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('stockings.populations.biomass')} (${weightUnits.KG}): <strong>${formattedBiomassKg}</strong>`);

        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('optimalHarvestPoint.pondLoadCapacity')} (${weightUnits.KG}/${volumeUnits.HA}): <strong>${formattedPondLoadCapacityKg}</strong>`);
        
        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('stockings.populations.biomass')} (${weightUnits.LB}): <strong>${formattedBiomassLb}</strong>`);

        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('optimalHarvestPoint.pondLoadCapacity')} (${weightUnits.LB}/${volumeUnits.HA}): <strong>${formattedPondLoadCapacityLb}</strong>`);
        
      }

      if (chartParameter === ChartParameter.AERATION) {
        const biomass = applyThousandsSeparator(Math.round(predictionPoint.biomass || 0));
        const aeration = applyThousandsSeparator(Math.round(predictionPoint.aeration || 0));
        const costAccumulatedAeration = applyThousandsSeparator(Math.round(predictionPoint.costAccumulatedAeration || 0));

        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('stockings.populations.biomass')} (${weightUnits.KG}): <strong>${biomass}</strong>`);

        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('optimalHarvestPoint.aeration')} (${powerUnits.HP}): <strong>${aeration}</strong>`);

        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('unitPocParameters.costPerHp')}: <strong>${currencySymbol}${costAccumulatedAeration}</strong>`);
      }

      if (chartParameter === ChartParameter.POTENTIAL_INCOME || chartParameter === ChartParameter.TOTAL_ACCUMULATED_COST) {
        const commercialSize = getCommercialSize({ point: predictionPoint, packerId });
        
        if (!commercialSize?.potentialIncome) {
          break;
        }

        const potentialIncome = applyThousandsSeparator(roundOneDecimal(commercialSize?.potentialIncome || 0));
        const potentialGain = applyThousandsSeparator(roundOneDecimal(commercialSize?.presentValue.potentialGain || 0));
        const potentialGainByDayHectarea = applyThousandsSeparator(roundOneDecimal(commercialSize?.presentValue.potentialGainByDayHectarea || 0));
        const totalAccumulatedCost = applyThousandsSeparator(roundOneDecimal(predictionPoint.totalAccumulatedCost || 0));
        const costPerDayKg = roundTwoDecimals(predictionPoint.costPerDayKg || 0);
        const costPerDayLb = roundTwoDecimals(predictionPoint.costPerDayLb || 0);

        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('optimalHarvestPoint.potentialIncome')}: <strong>${currencySymbol}${potentialIncome}</strong>`);

        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('optimalHarvestPoint.potentialGain')}: <strong>${currencySymbol}${potentialGain}</strong>`);

        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('optimalHarvestPoint.potentialGainByDayHectarea')}: <strong>${currencySymbol}${potentialGainByDayHectarea}</strong>`);

        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('optimalHarvestPoint.totalAccumulatedCost')}: <strong>${currencySymbol}${totalAccumulatedCost}</strong>`);

        const costPerDayUnit = companyData.weightUnit === weightUnitsByCompany.KILOGRAM ? weightUnits.KG : weightUnits.LB;
        const costPerDay = companyData.weightUnit === weightUnitsByCompany.KILOGRAM ? costPerDayKg : costPerDayLb;

        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('optimalHarvestPoint.costPerDay')} ${costPerDayUnit}: <strong>${costPerDay}</strong>`);
      }

      if (chartParameter === ChartParameter.CORRECTED_FOOD) {
        const correctedFoodQuantity = applyThousandsSeparator(roundOneDecimal(predictionPoint.correctedFoodQuantity || 0));
        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('optimalHarvestPoint.foodQuantity')}: <strong>${correctedFoodQuantity}</strong>`);
      }

      const dateLabel = point._id ? i18next.t('analysis.createdAt') : i18next.t('analysis.predictionDate');
      const date = point._id ? point.createdAt : predictionPoint.predictionDate;

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${dateLabel}: <strong>${formatLongDateWithZone({ date })}</strong>`);

      if (point._id) {
        const checkbox = entryContent.append('div')
          .attr('class', styles.checkboxContainer)
          .style('display', 'flex')
          .style('align-items', 'center');
        
        checkbox.append('input')
          .attr('class', styles.checkbox)
          .attr('type', 'checkbox')
          .property('checked', analysesToExclude.some(analysisId => analysisId === point._id))
          .on('change', (event) => {
            event.stopPropagation();
            dispatch(optimalHarvestPointSlice.toggleAnalysisExclusion(point._id));
          });
        
        checkbox.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('analysis.exclude')}`);
      }

      if (index !== pointsList.length - 1) {
        tooltipContent.append('hr');
      }
    }
  }

  refreshPoints (props: { isExcluding: boolean; analysesToExclude: string[]; }) {
    const { isExcluding } = props;

    this.isExcluding = isExcluding;
    analysesToExclude = props.analysesToExclude;

    this.renderPoints();
    this.renderCostPeyDayPoints();
  }

  renderRects = (props: { x: number; width: number; id: string; className: string; }) => {
    const { x, width, id, className } = props;
    const { groupMain, height } = this;

    groupMain.append('rect')
      .attr('id', id)
      .attr('class', className)
      .attr('x', x)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', height);
  };

  renderLoadCapacityRects = () => {
    const { container, loadCapacity, scaleLinearLoadCapacity, chartParameter } = this;
    
    d3.select(container).select('#errorLoadCapacity').remove();
    d3.select(container).select('#warningLoadCapacity').remove();

    if (chartParameter !== ChartParameter.BIOMASS_KG && chartParameter !== ChartParameter.BIOMASS_LB) {
      return;
    }

    let pointX = scaleLinearLoadCapacity(loadCapacity);
    pointX = pointX < 0 ? 0 : pointX;
    const width = this.width - pointX;
    this.renderRects({ x: pointX, width, id: 'errorLoadCapacity', className: styles.errorLoadCapacity });

    if (pointX === 0) {
      return;
    }

    const newLoadCapacity = loadCapacity - (loadCapacity * 0.1);
    let newPointX = scaleLinearLoadCapacity(newLoadCapacity);
    newPointX = newPointX < 0 ? 0 : newPointX;
    this.renderRects({ x: newPointX, width: pointX - newPointX, id: 'warningLoadCapacity', className: styles.warningLoadCapacity });
  };

  resize = (props: {width: number, height: number}) => {
    const { width, height } = props;

    const { container } = this;

    d3.select(container).select('#tooltipContent').selectAll('*').remove();

    this.updateSize(width, height);
    this.updateDataPoints();
    this.renderTooltips();
  };

  updateSize = (width: number, height: number) => {
    const { container } = this;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    const _width = this.width + this.margin.left + this.margin.right;
    const _height = this.height + this.margin.top + this.margin.bottom;

    d3.select<HTMLDivElement | null, IPoint>(container)
      .select('svg')
      .attr('id', 'svgForecastMetric')
      .attr('width', _width)
      .attr('height', _height);
  };
}
