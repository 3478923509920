import { Form, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Store } from '../../../../state/store.interfaces';
import { getCurrentTheme } from '../../../../helpers/theme';
import { weightUnitsByCompany } from '../../../../config/commons';
import { LrvText } from '../../../../common/components/LrvText/LrvText';
import { LrvForm } from '../../../../common/components/LrvForm/LrvForm';
import { LrvRadio } from '../../../../common/components/LrvRadio/LrvRadio';
import ActionButton from '../../../../common/components/buttons/ActionButton';

import styles from './WeightUnit.module.scss';
import * as companySettingsSlice from './../companySettingsSlice';

export const WeightUnit = () => {
  const theme = getCurrentTheme();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [weightUnit, setWeightUnit] = useState(weightUnitsByCompany.KILOGRAM);

  const { company } = useSelector((state: Store) => state.companySettings);

  useEffect(() => {
    if (!company?.weightUnit) {
      return;
    }

    setWeightUnit(company.weightUnit);
  }, [company]);

  return (
    <div className={styles.container}>
      <LrvForm
        id='formCompanySetting'
        layout='vertical'
        theme={theme}
        onFinish={() => {
          dispatch(companySettingsSlice.updateCompanySetting({ weightUnit }));
        }}
      >
        <Form.Item>
          <div>
            <LrvText theme={theme} text={t('weightUnit.description')} />
          </div>
        </Form.Item>

        <Form.Item
          required
          label={t('weightUnit.unit')}
        >
          <Radio.Group
            className={styles.weightUnit}
            onChange={(event) => setWeightUnit(event.target.value)}
            value={weightUnit}
          >
            <LrvRadio
              theme={theme}
              className={styles.radio}
              value={weightUnitsByCompany.KILOGRAM}
            >
              {t('weightUnit.kilogram')}
            </LrvRadio>
            
            <LrvRadio
              theme={theme}
              className={styles.radio}
              value={weightUnitsByCompany.POUND}
            >
              {t('weightUnit.pound')}
            </LrvRadio>
            
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <div>
            <ActionButton
              id='submit_unit_button'
              type='primary'
              htmlType='submit'
            >
              {t('weightUnit.save')}
            </ActionButton>
          </div>
        </Form.Item>
      </LrvForm>
    </div>
  );
};
