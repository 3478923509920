import moment from 'moment';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Row, Col, Badge, Form, Button, Radio, Tooltip, Space } from 'antd';

import { Store } from '../../state/store.interfaces';
import { Breadcrumb } from '../AppHeader/interfaces';
import Icon from '../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../helpers/theme';
import * as headerSlice from '../AppHeader/headerSlice';
import PublishStocking from '../Sowings/PublishStocking';
import { fetchVolumeRanges } from '../Units/campusSlice';
import * as stockingsSlice from '../Sowings/sowingsSlice';
import EditStockingModal from '../Sowings/EditStockingModal';
import MoveStockingModal from '../Sowings/MoveStockingModal';
import { LrvTag } from '../../common/components/LrvTag/LrvTag';
import FinishStockingModal from '../Sowings/FinishStockingModal';
import EnableStockingModal from '../Sowings/EnableStockingModal';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../common/components/LrvText/LrvText';
import TinyLabel from '../../common/components/TinyLabel/TinyLabel';
import IconButton from '../../common/components/buttons/IconButton';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import * as finishStockingSlice from '../Sowings/finishStockingSlice';
import { canBePublishedStocking } from '../../helpers/analysis.helpers';
import { validateString, validateNumber } from '../../utils/validations';
import Breadcrumbs from '../../common/components/Breadcrumb/Breadcrumbs';
import { changeBreadcrumb, changeHeader } from '../AppHeader/headerSlice';
import { DropdownStockingOptions } from '../Sowings/DropdownStockingOptions';
import { FinishStockingInfoModal } from '../Sowings/FinishStockingInfoModal';
import { getTitleByStockingPhaseType } from '../../helpers/stocking.helpers';
import NotPermission from '../../common/components/NotPermission/NotPermission';
import { HarvestsStockingInfoModal } from '../Sowings/HarvestsStockingInfoModal';
import { TransferStockingInfoModal } from '../Sowings/TransferStockingInfoModal';
import { StockingParameterScreen } from '../StockingParameter/StockingParameterScreen';
import * as newStockingParameterSlice from '../StockingParameter/newStockingParameterSlice';
import * as detailStockingParameterSlice from '../StockingParameter/Detail/detailStockingParameterSlice';
import { StockingParameterDetailScreen } from '../StockingParameter/Detail/StockingParameterDetailScreen';
import { species, stockingPhaseTypes, unitStatuses, isTradingRole, THEME, isParameteristRole, isOperatorRole } from '../../config/commons';

import './Sowing.scss';
import styles from './Sowing.module.scss';
import MoveAnalysis from './MoveAnalysis';
import { EditAnalysis } from './interfaces';
import * as analysisSlice from './sowingSlice';
import { AnalysisTable } from './AnalysisTable';
import { ParameterTable } from './ParameterTable';
import * as stockingParameterSlice from './stockingParameterSlice';
import { AnalysisFromConsolidated } from './AnalysisFromConsolidated';
import { getMaxSampleWeight, getMaxStage, getMinSampleWeight, getMinStage } from './helpers';

type TParams = { id: string };

const listOptions = {
  ANALYSIS: 'ANALYSIS',
  PARAMETERS: 'PARAMETERS',
};

function Analysis ({ match }: RouteComponentProps<TParams>) {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const stockingId = match.params.id;
  const [formEditAnalysis] = Form.useForm();

  const [disabledButton, setDisabledButton] = useState(false);
  const [optionSelected, setOptionSelected] = useState(listOptions.ANALYSIS);

  const {
    analysis,
    total,
    hasPermission,
    sampleLimits: sampleWeightLimits,
    isDownloadingFile,
    currentPage,
    editAnalysis,
    analysisCards,
  } = useSelector((state: Store) => state.analysis);

  const { selectedStocking: stocking, publishLarviaTrade } = useSelector((state: Store) => state.stockings);

  const { breadcrumb, company: selectCompany, phaseType: phaseTypeGlobal } = useSelector((state: Store) => state.header);
  const { lastFourDaysParameters, parameterDate } = useSelector((state: Store) => state.newStockingParameter);

  const { isLoadingToPublish } = publishLarviaTrade;
  const isCampusInactive = stocking.campusId.status === unitStatuses.INACTIVE;
  const maxLengthCode = 30;
  const canPublishStocking = canBePublishedStocking(analysis);
  const stockingIsArchived = stocking.isArchived;

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  useEffect(() => {
    dispatch(changeHeader({ title: 'analysis.analysis' }));
    dispatch(fetchVolumeRanges());
    dispatch(stockingsSlice.fetchStockingDateRanges());
    dispatch(finishStockingSlice.fetchStockingFinishedData());
  }, [dispatch]);

  useEffect(() => {
    if (!stocking.name) {
      return;
    }

    const breadcrumb: Breadcrumb[] = [{
      id: 'sowings',
      name: getTitleByStockingPhaseType(phaseTypeGlobal),
      url: '/production/stockings',
    },
    {
      id: stocking.name,
      name: `${t('stockings.stocking')} ${stocking.name}`,
      url: `/production/stockings/${stockingId}`,
    }];
    dispatch(changeBreadcrumb(breadcrumb));
  }, [dispatch, stockingId, stocking.name, phaseTypeGlobal]);

  useEffect(() => {
    dispatch(stockingsSlice.fetchStocking({ id: stockingId }));
  }, [dispatch, stockingId]);

  useEffect(() => {
    if (!stocking.companyId || stocking.companyId === selectCompany._id) {
      return;
    }

    dispatch(headerSlice.fetchCompany(stocking.companyId));
  }, [dispatch, stocking.companyId, selectCompany._id]);

  useEffect(() => {
    if (stocking.phaseType === phaseTypeGlobal) {
      return;
    }

    dispatch(headerSlice.setPhaseType(stocking.phaseType));
  }, [dispatch, phaseTypeGlobal, stocking.phaseType]);

  useEffect(() => {
    if (!stocking.companyId) {
      return;
    }

    if (!isParameteristRole()) {
      dispatch(analysisSlice.fetchAnalysis({ page: 0, stockingId, companyId: stocking.companyId }));
    }

    dispatch(stockingParameterSlice.fetchStockingParameters({ page: 0, stockingId, companyId: stocking.companyId }));
    dispatch(stockingParameterSlice.fetchStockingParameterCustom({ companyId: stocking.companyId }));
    dispatch(stockingParameterSlice.fetchCompanyStockingParameter({ companyId: stocking.companyId }));
  }, [dispatch, stockingId, stocking.companyId, stocking.phaseType]);

  useEffect(() => {
    if (isParameteristRole() || !stocking.companyId || analysisCards.length > 0) {
      return;
    }

    dispatch(analysisSlice.fetchCustomAnalysisCards({ companyId: stocking.companyId }));
  }, [dispatch, stockingId, stocking.companyId, analysisCards.length]);

  useEffect(() => {
    if (isCampusInactive) {
      setDisabledButton(true);
    }
  }, [isCampusInactive]);

  useEffect(() => {
    if (!editAnalysis.data.code) {
      return;
    }

    formEditAnalysis.setFieldsValue({
      code: editAnalysis.data.code,
      sampleWeight: editAnalysis.data.sampleWeight,
      stage: editAnalysis.data.stage,
    });
  }, [editAnalysis.data.code, editAnalysis.data.sampleWeight, editAnalysis.data.stage, editAnalysis.showModal]);

  if (!hasPermission) {
    return <NotPermission />;
  }

  useEffect(() => {
    if (!lastFourDaysParameters.length || !parameterDate) {
      return;
    }

    const paramDate = moment(parameterDate).startOf('day');
    const parameterFound = lastFourDaysParameters.find(item => moment(item.date).startOf('day').isSame(paramDate));

    if (!parameterFound) {
      dispatch(detailStockingParameterSlice.setStockingParameterSelected(undefined));
      return;
    }
    dispatch(detailStockingParameterSlice.fetchCompanyStockingParameters({ companyId: selectCompany._id }));
    dispatch(detailStockingParameterSlice.fetchStockingParameter({ _id: parameterFound._id }));
  }, [parameterDate, lastFourDaysParameters, selectCompany]);

  const onClickAddParameterButton = () => {
    dispatch(newStockingParameterSlice.fetchLastFourDaysParameters({ companyId: selectCompany._id, stockingId }));
    dispatch(newStockingParameterSlice.showStockingParameterModal({ companyId: selectCompany._id }));
  };

  function renderLabelCampusInactive () {
    if (isCampusInactive) {
      return (
        <Form.Item>
          <TinyLabel className={styles.campusInactive} text={t('analysis.inactiveCampus')} />
        </Form.Item>
      );
    }

    return null;
  }

  const renderBindingCode = () => {
    if (isParameteristRole()) {
      return null;
    }
    
    if (!stocking.stockingBindingCode) {
      return null;
    }

    return (
      <div>
        <LrvText text={`${t('stockings.pdf.stockingBindingCode')}: `} theme={theme} />
        <LrvText text={stocking.stockingBindingCode} theme={theme} />
      </div>
    );
  };

  const renderParameterButton = () => {
    if (!selectCompany.showStockingParameterSection) {
      return;
    }

    return (
      <Button
        id='publish_stocking_button'
        type='primary'
        className={styles.button}
        icon={<Icon name='add' />}
        onClick={onClickAddParameterButton}
      >
        &nbsp;{t('stockingParameter.parameter')}
      </Button>
    );
  };

  const renderPublishButton = () => {
    if (!selectCompany.isInternational && isTradingRole() && stocking.phaseType === stockingPhaseTypes.LARVAE) {
      const titleTooltip = canPublishStocking ? undefined : t('stockings.trade.cannotPublish');
      const labelButton = stocking.isPublic ? t('stockings.trade.remove') : t('stockings.trade.publish');

      return (
        <Tooltip id='tooltip_publish_stocking' title={titleTooltip}>
          <Button
            id='publish_stocking_button'
            type='primary'
            className={styles.button}
            icon={<Icon name='share-box' />}
            disabled={!canPublishStocking}
            loading={stocking.isPublic ? isLoadingToPublish : undefined}
            onClick={() => {
              if (stocking.isPublic) {
                dispatch(stockingsSlice.publishStocking({ stockingId, isPublic: !stocking.isPublic }));
              } else {
                dispatch(stockingsSlice.setShowPublishStockingModal(true));
              }
            }}
          >&nbsp;{labelButton}</Button>
        </Tooltip>
      );
    }
    return null;
  };

  const generatePdf = () => {
    if (!stocking?._id || !stocking?.name) {
      return;
    }

    dispatch(analysisSlice.getUrlStockingChartsPdf(stocking._id));
  };

  const renderGeneratePdf = () => {
    if (isParameteristRole()) {
      return null;
    }

    return (
      <IconButton
        loading={isDownloadingFile}
        disabled={!stocking?._id}
        iconName='download'
        onClick={generatePdf}
        tooltipText={t('production.download')}
      />
    );
  };

  const renderSubHeader = () => {
    if (!stocking._id) {
      return null;
    }

    if (stockingIsArchived) {
      return (
        <Row className={styles.rowHeader} align='middle' justify='space-between'>
          <Space className={styles.archivedTag} align='end' size='middle'>
            <div>
              <LrvText text={stocking.name} theme={theme} />
            </div>
            <LrvTag color='#f50'>{t('detail.archived')}</LrvTag>
          </Space>
        </Row>
      );
    }

    return (
      <Row className={styles.rowHeader} align='middle' justify='space-between'>
        <Breadcrumbs breadcrumb={breadcrumb} noCapitalize theme={theme} />
        <Space className={styles.row} size='middle'>
          {renderBindingCode()}
          {renderGeneratePdf()}
          {renderPublishButton()}
          {renderParameterButton()}
          <DropdownStockingOptions stocking={stocking} theme={theme} page='sowing' />
        </Space>
      </Row>
    );
  };

  const renderNotShowingAnalysisLabel = () => {
    if (isParameteristRole()) {
      return null;
    }

    if (total >= stocking.analysisCount) {
      return null;
    }

    return (
      <Row align='middle' className={styles.analysisCount}>
        <Space>
          <Badge color='#2db7f5' />
          <LrvText
            theme={theme}
            text={t('analysis.notShowing', { number: stocking.analysisCount - total })}
          />
        </Space>
      </Row>
    );
  };

  const renderOptions = () => {
    if (isParameteristRole()) {
      return null;
    }

    if (!selectCompany.showStockingParameterSection) {
      return;
    }

    return (
      <div className={styles.actions}>
        <Radio.Group
          className={styles.typeHistogram}
          value={optionSelected}
          onChange={(event) => setOptionSelected(event.target.value)}
          size='small'
        >
          <Radio.Button
            className={cx(styles.analysisOption, styles.radioButton, isLightTheme ? styles.radioButtonLight : styles.radioButtonDark, optionSelected === listOptions.ANALYSIS ? styles.radioSelected : '')}
            value={listOptions.ANALYSIS}
          >
            <div id='radio_button_analysis'>{t('analysis.analysis')}</div>
          </Radio.Button>

          <Radio.Button
            className={cx(styles.parameterOption, styles.radioButton, isLightTheme ? styles.radioButtonLight : styles.radioButtonDark, optionSelected === listOptions.PARAMETERS ? styles.radioSelected : '')}
            value={listOptions.PARAMETERS}
          >
            <div id='radio_button_parameter'>{t('stockingParameter.formNew.title')}</div>
          </Radio.Button>
        </Radio.Group>
      </div>
    );
  };

  const renderTable = () => {
    if (isParameteristRole()) {
      return (
        <ParameterTable stockingId={stockingId} />
      );
    }

    switch (optionSelected) {
      case listOptions.ANALYSIS:
      default:
        return (
          <AnalysisTable stockingId={stockingId} />
        );

      case listOptions.PARAMETERS:
        return (
          <ParameterTable stockingId={stockingId} />
        );
    }
  };

  return (
    <div className={styles.analysis}>
      <Row className={cx('analysisSection', total >= stocking.analysisCount ? '' : 'analysisSectionWithLabel', 'analysisSectionWithParameterOptions')}>
        <Col className={styles.columnFlex} >
          {renderSubHeader()}
          {renderOptions()}
          {renderNotShowingAnalysisLabel()}
          {renderTable()}
        </Col>
      </Row>

      <LrvModal
        theme='light'
        className='editAnalysisModal'
        title={t('analysis.editAnalysis')}
        open={editAnalysis.showModal}
        destroyOnClose={true}
        okButtonProps={{ id: 'submit_edit_analysis', htmlType: 'submit', form: 'formEditAnalysis', disabled: disabledButton }}
        cancelButtonProps={{ id: 'cancel_edit_analysis' }}
        onOk={() => {
          const analysisData = {
            code: editAnalysis.data.code,
            fix: true,
            inputData: {
              sampleWeight: parseFloat(editAnalysis.data.sampleWeight),
              stage: parseInt(editAnalysis.data.stage),
              species: species.LITOPENAEUS_VANNAMEI,
              maturationId: editAnalysis.data.maturationId,
            },
          };
          dispatch(analysisSlice.updateAnalysis({ companyId: stocking.companyId, analysisId: editAnalysis.data.analysisId, stockingId, analysisData, currentPage }));
          dispatch(analysisSlice.setShowModalEdit(false));
        }}
        okText={t('analysis.edit').toUpperCase()}
        cancelText={t('analysis.cancel').toUpperCase()}
        onCancel={() => {
          formEditAnalysis.resetFields();
          dispatch(analysisSlice.setShowModalEdit(false));
        }}
      >
        <LrvForm
          theme='light'
          form={formEditAnalysis}
          name='formEditAnalysis'
          id='formEditAnalysis'
          layout='vertical'
          onFieldsChange={() => {
            const disabled = formEditAnalysis.getFieldsError().filter(({ errors }) => errors.length).length > 0 || isCampusInactive;
            setDisabledButton(disabled);
          }}
        >
          {renderLabelCampusInactive()}

          <Form.Item
            name='code'
            label={t('analysis.code')}
            required
            rules={[() => ({ validator (rule, value) { return validateString(value); } })]}
          >
            <LrvInput
              theme='light'
              value={editAnalysis.data.code}
              maxLength={maxLengthCode}
              onChange={(e) => {
                const editDataAnalysis: EditAnalysis = {
                  ...editAnalysis.data,
                  code: e.target.value,
                };

                dispatch(analysisSlice.setEditDataAnalysis(editDataAnalysis));
              }}
              disabled={isCampusInactive}
            />
          </Form.Item>

          <Form.Item
            name='sampleWeight'
            label={t('analysis.inputData.sampleWeight')}
            required
            rules={
              [() => ({
                validator (rule, value) {
                  return validateNumber(value, false, getMinSampleWeight(sampleWeightLimits, stocking.phaseType), getMaxSampleWeight(sampleWeightLimits, stocking.phaseType), t('analysis.sampleWeightError', { min: getMinSampleWeight(sampleWeightLimits, stocking.phaseType), max: getMaxSampleWeight(sampleWeightLimits, stocking.phaseType) }));
                }
              })]
            }
          >
            <LrvInput
              theme='light'
              value={editAnalysis.data.sampleWeight}
              readOnly={isOperatorRole()}
              onChange={(e) => {
                const editDataAnalysis: EditAnalysis = {
                  ...editAnalysis.data,
                  sampleWeight: e.target.value,
                };

                dispatch(analysisSlice.setEditDataAnalysis(editDataAnalysis));
              }}
              disabled={isCampusInactive}
            />
          </Form.Item>

          <Form.Item
            name='stage'
            label={stocking.phaseType === stockingPhaseTypes.LARVAE ? t('analysis.inputData.stage') : t('analysis.inputData.juvenileStage')}
            required
            rules={
              [() => ({
                validator (rule, value) {
                  return validateNumber(value, true, getMinStage(stocking.phaseType), getMaxStage(selectCompany, stocking.phaseType), t('analysis.stageError', { min: getMinStage(stocking.phaseType), max: getMaxStage(selectCompany, stocking.phaseType) }));
                }
              })]
            }
          >
            <LrvInput
              theme='light'
              value={editAnalysis.data.stage}
              onChange={(e) => {
                const editDataAnalysis: EditAnalysis = {
                  ...editAnalysis.data,
                  stage: e.target.value,
                };

                dispatch(analysisSlice.setEditDataAnalysis(editDataAnalysis));
              }}
              disabled={isCampusInactive}
            />
          </Form.Item>
        </LrvForm>
      </LrvModal>

      <FinishStockingInfoModal theme='light' />
      <TransferStockingInfoModal theme='light' />
      <HarvestsStockingInfoModal theme='light' />
      <MoveAnalysis theme='light' />
      <EditStockingModal theme='light' />
      <MoveStockingModal theme='light' />
      <FinishStockingModal theme='light' />
      <EnableStockingModal theme='light' />

      <PublishStocking stocking={stocking} company={selectCompany} />
      <AnalysisFromConsolidated currentPage={currentPage} />
      <StockingParameterScreen />
      <StockingParameterDetailScreen />
    </div>
  );
}

export default Analysis;
