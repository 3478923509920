import cx from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isDesktop } from 'react-device-detect';
import { Button, Layout, Menu, MenuProps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';

import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../helpers/theme';
import { canRenderItem } from '../../utils/checkRoutes';
import { getUserSession } from '../../utils/userSession';
import Avatar from '../../common/components/Avatar/Avatar';
import DnaIcon from '../../common/components/Icon/DnaIcon';
import larviaLogoDark from '../../assets/larvia-logo-dark.png';
import larviaLogoLight from '../../assets/larvia-logo-light.png';
import logoMiniSidebar from '../../assets/larvia-logo-mini-sidebar.webp';
import { JuvenileIcon } from '../../common/components/Icon/JuvenileIcon';
import { goToOnboardingNextStep } from '../../common/components/Onboarding/OnboardingSlice';
import { AutoEllipsisTooltip } from '../../common/components/AutoEllipsisTooltip/AutoEllipsisTooltip';
import { getMainRole, isBusinessManagerRole, isSalesManagerRole, isSalesRole, THEME } from '../../config/commons';

import styles from './Sidebar.module.scss';
import PopoverMenu from './PopoverMenu/PopoverMenu';
import { getMenuItem, getKeysByPathname } from './helpers';
import { MenuItem, menuItemsAbove, SubMenuItem } from './sidebar.config';

const { Sider } = Layout;

type MenuItemAntd = Required<MenuProps>['items'][number];

const Sidebar = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { companies, company: selectedCompany } = useSelector((state: Store) => state.header);
  const isRunningOnboarding = useSelector((state: Store) => state.onboarding.run);

  const higherRole = getMainRole();
  const userSession = getUserSession();
  const { firstName, lastName, companyId } = userSession;
  const company = companies.length > 0 ? companies.find((company) => company._id === companyId) : selectedCompany;

  const [isOpen, setIsOpen] = useState(false);
  const showFullSidebar = false;
  const [openKeys, setOpenKeys] = useState<string[]>(showFullSidebar ? getKeysByPathname(pathname) : []);

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  const shouldRenderSubItem = (subMenuItem: SubMenuItem, menuItemPath: string) => {
    const itemPathname = menuItemPath + subMenuItem.path;
    const hasAccess = canRenderItem(subMenuItem.rolesRequired);
  
    if (!hasAccess) {
      return false;
    }
  
    if ((isSalesManagerRole() || isSalesRole()) && itemPathname === '/genetics/insights' && company?._id !== userSession.companyId) {
      return false;
    }
  
    const restrictedPaths = [
      { path: '/production/parameters', condition: !selectedCompany.showStockingParameterSection },
      { path: '/company/stocking-parameters', condition: !selectedCompany.showStockingParameterSection },
      { path: '/production/optimal-harvest-point', condition: !selectedCompany.showPOCSection },
      { path: '/company/packers', condition: !selectedCompany.showPOCSection },
    ];
  
    if (restrictedPaths.some(({ path, condition }) => itemPathname === path && condition)) {
      return false;
    }
  
    return true;
  };

  const getSubMenuItems = (menuItem: MenuItem) => {
    const { title, subMenu = [], path: menuItemPath } = menuItem;

    const baseMenuItem: MenuItemAntd = getMenuItem({
      id: title,
      key: title,
      label: t(title).toUpperCase(),
      className: styles.menuTitle,
      disabled: true,
    });

    const filteredSubItems = subMenu.filter((subMenuItem) => shouldRenderSubItem(subMenuItem, menuItemPath));

    const subMenuItems = filteredSubItems.map((subMenuItem, index) => {
      const itemPathname = menuItemPath + subMenuItem.path;
      const isSubItemSelected = pathname.includes(itemPathname) || subMenuItem.subPaths?.some(subpath => pathname.includes(subpath));

      return getMenuItem({
        id: itemPathname,
        key: itemPathname,
        label: t(subMenuItem.title),
        className: filteredSubItems.length - 1 === index ? '' : isSubItemSelected ? cx(styles.subItem, styles.subItemSelected) : styles.subItem,
        onClick: () => history.push(itemPathname),
      });
    });

    if (subMenuItems.length === 0) {
      return;
    }

    const restrictedMainMenu =
    (isSalesManagerRole() || isSalesRole() || isBusinessManagerRole()) &&
    ['/production', '/reports'].includes(menuItemPath) &&
    company?._id !== userSession.companyId;

    if (restrictedMainMenu) {
      return;
    }

    return [baseMenuItem, ...subMenuItems];
  };

  const renderIcon = (props: { menuItem: MenuItem }) => {
    const { menuItem } = props;

    switch (menuItem.icon) {
      case 'production':
        return (
          <div className={styles.juvenileIcon}><JuvenileIcon /></div>
        );

      case 'dna':
        return (
          <div className={styles.dnaIcon}><DnaIcon /></div>
        );
      default:
        return (
          <Icon
            className={styles.subMenuItemIcon} name={menuItem.icon}
            type={menuItem.path === '/units' ? 'none' : 'line'}
          />
        );
    }
  };

  const handleOpenChange = (keys: string[]) => {
    if (keys.length > 0) {
      const lastKey = keys[keys.length - 1];
      setOpenKeys([lastKey]);
      return;
    }
    setOpenKeys([]);
  };

  const renderMenu = () => {
    const items: MenuItemAntd[] = [];

    menuItemsAbove.forEach((menuItem) => {
      const hasAccess = menuItem.rolesRequired && canRenderItem(menuItem.rolesRequired);
      if (!hasAccess) {
        return;
      }

      const subMenuItems = menuItem.subMenu ? getSubMenuItems(menuItem) : undefined;
      
      items.push(
        getMenuItem({
          id: menuItem.path,
          key: menuItem.path,
          label: t(menuItem.title).toUpperCase(),
          children: subMenuItems,
          icon: renderIcon({ menuItem }),
          onClick: () => {
            if (menuItem.path && !menuItem.subMenu) {
              history.push(menuItem.path);
              setOpenKeys([]);
            }
          },
        })
      );
    });

    return (
      <Menu
        id='sidebar-menu'
        className={styles.sidebarMenu}
        theme='dark'
        mode='inline'
        items={items}
        selectedKeys={[pathname]}
        openKeys={openKeys}
        onOpenChange={handleOpenChange}
      />
    );
  };

  const renderAvatarButton = () => {
    return (
      <Button
        id='menu-avatar'
        className={cx(styles.profileBottomContainer, showFullSidebar ? styles.paddingLeft : '', isLightTheme ? styles.profileBottomContainerLight : styles.profileBottomContainerDark)}
        type='text'
        onClick={() => {
          if (isRunningOnboarding) {
            dispatch(goToOnboardingNextStep(150));
          }
          setIsOpen(!isOpen);
        }}
      >
        <Avatar
          className={styles.avatar}
          labelClassName={styles.avatarLabel}
        />

        {
          showFullSidebar &&
          <div className={styles.labelsContent}>
            <AutoEllipsisTooltip classNameText={styles.nameText} text={`${firstName} ${lastName}`} />
            <AutoEllipsisTooltip classNameText={styles.emailText} text={`${company?.name}`} />
          </div>
        }
      </Button>
    );
  };

  if (!higherRole || !isDesktop) {
    return null;
  }

  return (
    <Sider
      className={cx(styles.sider, isLightTheme ? styles.siderLight : styles.siderDark)}
      collapsed={!showFullSidebar}
    >
      <div>
        <div
          className={styles.logo}
          onClick={() => history.push('/production/stockings')}
        >
          {
            showFullSidebar ?
              <img src={isLightTheme ? larviaLogoLight : larviaLogoDark} alt='lrv-logo' width={130} /> :
              <img src={logoMiniSidebar} alt='lrv-logo' width={32} />
          }
        </div>
        {renderMenu()}
      </div>

      <PopoverMenu
        isOpen={isOpen}
        onClickItem={() => setIsOpen(false)}
        onOpenChange={(visible) => setIsOpen(visible)}
        pathname={pathname}
        placement={showFullSidebar ? 'top' : 'rightTop'}
      >
        {renderAvatarButton()}
      </PopoverMenu>
    </Sider>
  );
};

export default withRouter(Sidebar);
