import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Select, Slider, Space } from 'antd';

import Icon from '../../../common/components/Icon/Icon';
import { Store } from '../../../state/store.interfaces';
import { FeedingStrategy } from '../Indicators/interfaces';
import { getLabelsAxisY, getModalWidth } from '../helpers';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvForm } from '../../../common/components/LrvForm/LrvForm';
import { useCurrency } from '../../../hooks/currency-codes/useCurrency';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import * as optimalHarvestPointSlice from '../optimalHarvestPointSlice';
import { ChartParameter, OptimalHarvestPointFilters } from '../interfaces';
import ActionButton from '../../../common/components/buttons/ActionButton';
import { LrvSelectV2 } from '../../../common/components/LrvSelectV2/LrvSelectV2';
import { calcStages } from '../../../common/components/charts/OptimalHarvestPoint/helpers';
import { LrvInputNumberV2 } from '../../../common/components/LrvInputNumberV2/LrvInputNumberV2';
import { DEFAULT_CURRENCY_CODE, weightUnits, weightUnitsByCompany } from '../../../config/commons';
import { ForecastMetricD3 } from '../../../common/components/charts/OptimalHarvestPoint/ForecastMetricD3';
import { convertKilogramsToPounds, convertPoundsToKilograms, getLabelAxisX } from '../../../helpers/stocking.helpers';
import { linearBiomassCoefficientValuesKg, linearBiomassCoefficientValuesLb } from '../../../helpers/unit-poc-parameters.helpers';

import styles from './Chart.module.scss';

const { Option } = Select;

interface Props {
  theme?: 'dark' | 'light';
}

export const Chart = (props: Props) => {
  const { theme = 'light' } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();
  const currencyCode = localStorage.getItem('currencyCode') || DEFAULT_CURRENCY_CODE;
  
  const { findCurrencyByCode } = useCurrency();
  const currencySymbol = findCurrencyByCode(currencyCode)?.symbol || '';

  const refChart = useRef<HTMLDivElement>(null);
  const [chart, setChart] = useState<ForecastMetricD3 | null>(null);
  const { graphWidth, modalWidth, graphHeight } = getModalWidth();

  const { company: selectedCompany, phaseType } = useSelector((state: Store) => state.header);
  const { isLoading } = useSelector((state: Store) => state.feedingTable);
  const {
    allPredictions,
    predictionSelected,
    pocPoint,
    showMiniChartModal,
    filters,
    bestPackers,
    isExcluding,
    dataSourceByStage,
    allPoints,
    dataSource,
    maxStage,
    analysesToExclude,
    isUpdatingPoints,
  } = useSelector((state: Store) => state.optimalHarvestPoint);

  const { miniChartParameter, selectedStocking, weeklyFeeding, feedingStrategy, linearBiomassCoefficientKg, linearBiomassCoefficientLb, costPerHp, loadCapacity } = filters;
  const point = predictionSelected || pocPoint;

  const labelLeftAxis = getLabelsAxisY({ chartParameter: miniChartParameter, weightUnit: selectedCompany.weightUnit, currencySymbol });
  
  useEffect(() => {
    if (allPredictions.length === 0 || !point?.packerId || !miniChartParameter || !showMiniChartModal || !refChart.current) {
      return;
    }

    const stages = calcStages({ dataSource, maxStage, isExcluding });
    const firstStage = stages[0];
    const lastStage = stages[1];

    if (chart) {
      chart.refreshChart({
        allPredictions,
        companyData: selectedCompany,
        chartParameter: miniChartParameter,
        firstStage,
        lastStage,
        packerId: point?.packerId,
        bestPackers,
        dataSourceByStage,
        allPoints,
        isExcluding,
        loadCapacity: Number(loadCapacity),
      });
      return;
    }

    const newChart = new ForecastMetricD3({
      container: refChart.current,
      allPredictions,
      height: 500,
      width: graphWidth,
      firstStage,
      lastStage,
      companyData: selectedCompany,
      chartParameter: miniChartParameter,
      packerId: point?.packerId,
      bestPackers,
      currencySymbol,
      dataSourceByStage,
      allPoints,
      isExcluding,
      dispatch,
      loadCapacity: Number(loadCapacity),
    });
    setChart(newChart);
  }, [dispatch, allPredictions, selectedCompany, miniChartParameter, point, bestPackers, showMiniChartModal, refChart, dataSourceByStage, allPoints, isExcluding, loadCapacity]);

  useEffect(() => {
    if (!chart) {
      return;
    }

    if (chart instanceof ForecastMetricD3) {
      chart.refreshPoints({ isExcluding, analysesToExclude });
    }
  }, [analysesToExclude, isExcluding]);

  useEffect(() => {
    if (!chart) {
      return;
    }

    if (chart instanceof ForecastMetricD3) {
      chart.resize({ width: graphWidth, height: graphHeight });
    }
  }, [graphHeight, graphWidth]);

  const onCancel = () => {
    dispatch(optimalHarvestPointSlice.setMiniChartParameter(undefined));
    dispatch(optimalHarvestPointSlice.setShowMiniChartModal(false));
    dispatch(optimalHarvestPointSlice.setIsExcluding(false));
  };

  const cancelExcludingAnalysis = () => {
    const excludedAnalysisIds = dataSource.excludedAnalyses.map(item => item._id);

    dispatch(optimalHarvestPointSlice.setIsExcluding(false));
    dispatch(optimalHarvestPointSlice.setAnalysesToExclude(excludedAnalysisIds));
  };

  const onApplyExcludeAnalyses = () => {
    if (isUpdatingPoints) {
      return;
    }

    const analysesToInclude = dataSource.allAnalysis
      .filter(analysis => !analysesToExclude.includes(analysis._id))
      .map(analysis => analysis._id);

    dispatch(optimalHarvestPointSlice.applyExcludeAnalyses({ stocking: selectedStocking, analysesToExclude, analysesToInclude }));
  };

  const renderExcludeButton = () => {
    if (!selectedStocking?._id || isExcluding || miniChartParameter !== ChartParameter.WEIGHT) {
      return;
    }

    return (
      <ActionButton
        type='primary'
        onClick={() => {
          dispatch(optimalHarvestPointSlice.setIsExcluding(true));
        }}
      >
        {t('optimalHarvestPoint.excludeAnalysis')}
      </ActionButton>
    );
  };

  const renderExcludeActions = () => {
    if (!isExcluding) {
      return;
    }

    return (
      <div className={styles.excludeActions}>
        <ActionButton
          className={cx(styles.actionButton, styles.applyButton)}
          id='apply_exclude_points_button'
          type='primary'
          loading={isUpdatingPoints}
          onClick={onApplyExcludeAnalyses}
          theme={theme}
        >
          {t('optimalHarvestPoint.buttons.apply')}
        </ActionButton>

        <ActionButton
          className={cx(styles.actionButton, styles.cancelButton)}
          id='cancel_exclude_points_button'
          type='default'
          onClick={cancelExcludingAnalysis}
          theme={theme}
        >
          {t('optimalHarvestPoint.buttons.cancel')}
        </ActionButton>
      </div>
    );
  };

  const renderCostPerHp = () => {
    return (
      <Form.Item>
        <LrvInputNumberV2
          min={0}
          theme={theme}
          value={costPerHp}
          size='small'
          addonAfter={currencySymbol}
          title={t('unitPocParameters.costPerHp')}
          onChange={(value) => {
            dispatch(optimalHarvestPointSlice.setAeration({ linearBiomassCoefficientKg, linearBiomassCoefficientLb, costPerHp: Number(value) }));
          }}
          parser={(input) => `${input}`.replace(',', '.')}
        />
      </Form.Item>
    );
  };

  const renderLinearBiomassCoefficient = () => {
    const linearBiomassCoefficient = selectedCompany.weightUnit === weightUnitsByCompany.KILOGRAM ? linearBiomassCoefficientKg : linearBiomassCoefficientLb;
    const unit = selectedCompany.weightUnit === weightUnitsByCompany.KILOGRAM ? 'kg/hp/h' : 'lb/hp/h';
    const label = `${t('unitPocParameters.linearBiomassCoefficient')} ${linearBiomassCoefficient} ${unit}`;
    const linearBiomassCoefficientValues = selectedCompany.weightUnit === weightUnitsByCompany.KILOGRAM ? linearBiomassCoefficientValuesKg : linearBiomassCoefficientValuesLb;

    return (
      <Form.Item
        label={label}
      >
        <Slider
          min={linearBiomassCoefficientValues.min}
          max={linearBiomassCoefficientValues.max}
          step={1}
          value={linearBiomassCoefficient}
          onChange={(value) => {
            if (Number.isNaN(value)) {
              return;
            }

            switch (selectedCompany.weightUnit) {
              case weightUnitsByCompany.KILOGRAM: {
                const linearBiomassCoefficientLb = convertKilogramsToPounds(Number(value));
                dispatch(optimalHarvestPointSlice.setAeration({ costPerHp, linearBiomassCoefficientKg: Number(value), linearBiomassCoefficientLb }));
                break;
              }
            
              case weightUnitsByCompany.POUND: {
                const linearBiomassCoefficientKg = convertPoundsToKilograms(Number(value));
                dispatch(optimalHarvestPointSlice.setAeration({ costPerHp, linearBiomassCoefficientKg, linearBiomassCoefficientLb: Number(value) }));
                break;
              }
            }
          }}
        />
      </Form.Item>
    );
  };

  const renderAerationOptions = () => {
    if (miniChartParameter !== ChartParameter.AERATION) {
      return;
    }

    return (
      <LrvForm
        theme={theme}
        layout='vertical'
        className={styles.form}
      >
        <Space>
          {renderCostPerHp()}
          {renderLinearBiomassCoefficient()}
        </Space>
      </LrvForm>
    );
  };

  const renderWeeklyFeeding = () => {
    if (miniChartParameter !== ChartParameter.CORRECTED_FOOD) {
      return;
    }

    return (
      <LrvInputNumberV2
        theme={theme}
        value={weeklyFeeding}
        size='small'
        addonAfter={weightUnits.KG}
        containerClassName={styles.inputNumber}
        title={`${t('optimalHarvestPoint.weeklyFeeding')}`}
        onChange={(value) => {
          const filterParams: OptimalHarvestPointFilters = {
            ...filters,
            weeklyFeeding: Number(value),
          };
        
          dispatch(optimalHarvestPointSlice.setOptimarHarvestPointFilters(filterParams));
        }}
        parser={(input) => `${input}`.replace(',', '.')}
      />
    );
  };

  const renderFeedingStrategy = () => {
    if (miniChartParameter !== ChartParameter.CORRECTED_FOOD) {
      return;
    }

    return (
      <LrvSelectV2
        id='dropdown_feeding'
        theme={theme}
        value={feedingStrategy}
        size='small'
        className={styles.select}
        onChange={(value) => {
          const filterParams: OptimalHarvestPointFilters = {
            ...filters,
            feedingStrategy: value,
          };

          dispatch(optimalHarvestPointSlice.setOptimarHarvestPointFilters(filterParams));
        }}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('optimalHarvestPoint.feedingStrategy.title')}
      >
        <Option key={FeedingStrategy.HIGH} value={FeedingStrategy.HIGH}>{t('optimalHarvestPoint.feedingStrategy.high')}</Option>
        <Option key={FeedingStrategy.NORMAL} value={FeedingStrategy.NORMAL}>{t('optimalHarvestPoint.feedingStrategy.normal')}</Option>
        <Option key={FeedingStrategy.LOW} value={FeedingStrategy.LOW}>{t('optimalHarvestPoint.feedingStrategy.low')}</Option>
      </LrvSelectV2>
    );
  };

  const renderFeedingOptions = () => {
    if (miniChartParameter !== ChartParameter.CORRECTED_FOOD) {
      return;
    }

    return (
      <Space>
        {renderWeeklyFeeding()}
        {renderFeedingStrategy()}
      </Space>
    );
  };

  const renderLegends = () => {
    if (miniChartParameter !== ChartParameter.BIOMASS_KG && miniChartParameter !== ChartParameter.BIOMASS_LB) {
      return;
    }

    return (
      <>
        <Space size={36}>
          <Space className={styles.legend} size='small'>
            <div className={cx(styles.square, styles.warning)}></div>
            <LrvText text={t('optimalHarvestPoint.loadCapacityLegends.warning')} theme={theme} />
          </Space>

          <Space className={styles.legend} size='small'>
            <div className={cx(styles.square, styles.error)}></div>
            <LrvText text={t('optimalHarvestPoint.loadCapacityLegends.error')} theme={theme} />
          </Space>
        </Space>
        <div></div>
      </>
    );
  };

  const renderFooter = () => {
    const cancelButton = (
      <Button
        type='default'
        className={styles.button}
        onClick={onCancel}
      >
        {t('priceTable.cancel')}
      </Button>
    );

    if (miniChartParameter === ChartParameter.CORRECTED_FOOD || miniChartParameter === ChartParameter.AERATION) {
      return (
        <>
          {cancelButton}

          <Button
            type='primary'
            className={styles.submitButton}
            onClick={() => {
              switch (miniChartParameter) {
                case ChartParameter.CORRECTED_FOOD:
                  onCancel();
                  break;
              
                case ChartParameter.AERATION:
                  onCancel();
                  break;
              }
            }}
            loading={isLoading}
          >
            {t('priceTable.save')}
          </Button>
        </>
      );
    }
    
    return cancelButton;
  };

  const renderLeftAxisLabel = () => {
    if (miniChartParameter === ChartParameter.TOTAL_ACCUMULATED_COST) {
      return (
        <Space className={styles.legend} size='small'>
          <div className={cx(styles.circle, styles.cost)}></div>

          <div className={styles.labelAxisY}>
            <LrvText text={labelLeftAxis} theme={theme}/>
          </div>
        </Space>
      );
    }

    return (
      <div className={styles.labelAxisY}>
        <LrvText text={labelLeftAxis} theme={theme}/>
      </div>
    );
  };

  const renderRightAxisLabel = () => {
    if (miniChartParameter === ChartParameter.TOTAL_ACCUMULATED_COST) {
      return (
        <Space className={styles.legend} size='small'>
          <div className={cx(styles.circle, styles.costPerDay)}></div>

          <div className={styles.labelAxisY}>
            <LrvText text={`${t('optimalHarvestPoint.costPerDay')} ${selectedCompany.weightUnit === weightUnitsByCompany.KILOGRAM ? weightUnits.KG : weightUnits.LB}`} theme={theme}/>
          </div>
        </Space>
      );
    }

    return null;
  };


  return (
    <LrvModal
      theme={theme}
      title={t('optimalHarvestPoint.prediction')}
      open={showMiniChartModal}
      forceRender={true}
      destroyOnClose={true}
      closeIcon={<Icon id='close_modal' name='close' />}
      onCancel={onCancel}
      className={styles.chartModal}
      width={modalWidth}
      footer={[renderFooter()]}
    >
      <div className={styles.container}>
        <div className={styles.row}>
          {renderLeftAxisLabel()}

          {renderExcludeButton()}
          {renderExcludeActions()}
          {renderAerationOptions()}
          {renderFeedingOptions()}
          {renderLegends()}

          {renderRightAxisLabel()}
        </div>

        <div
          ref={refChart}
          className={styles.chart}
        />

        <div className={styles.labelAxisX}>
          <LrvText text={getLabelAxisX(phaseType)} theme={theme}/>
        </div>
      </div>
    </LrvModal>
  );
};
