import { Form, Slider } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import { weightUnitsByCompany } from '../../config/commons';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvInputNumber } from '../../common/components/LrvInputNumber/LrvInputNumber';
import { convertKilogramsToPounds, convertPoundsToKilograms } from '../../helpers/stocking.helpers';
import { linearBiomassCoefficientValuesKg, linearBiomassCoefficientValuesLb } from '../../helpers/unit-poc-parameters.helpers';

import { CampusPocParameter } from './interfaces';
import * as unitPocParameterSlice from './unitPocParameterSlice';

interface Props {
  theme?: 'dark' | 'light';
}

export const UnitPocParameterForm = (props: Props) => {
  const { theme = 'light' } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [foodPricePerKg, setFoodPricePerKg] = useState<number | string | null>(0);
  const [costPerHp, setCostPerHp] = useState<number | string | null>(0);
  const [linearBiomassCoefficientKg, setLinearBiomassCoefficientKg] = useState<number>(linearBiomassCoefficientValuesKg.min);
  const [linearBiomassCoefficientLb, setLinearBiomassCoefficientLb] = useState<number>(linearBiomassCoefficientValuesLb.min);

  const {
    showCreateModal,
    campusId,
    campusPocParameter,
  } = useSelector((state: Store) => state.unitPocParameter);

  const { company } = useSelector((state: Store) => state.header);

  const linearBiomassCoefficientValues = company.weightUnit === weightUnitsByCompany.KILOGRAM ? linearBiomassCoefficientValuesKg : linearBiomassCoefficientValuesLb;
  const linearBiomassCoefficient = company.weightUnit === weightUnitsByCompany.KILOGRAM ? linearBiomassCoefficientKg : linearBiomassCoefficientLb;
  const unit = company.weightUnit === weightUnitsByCompany.KILOGRAM ? 'kg/hp/h' : 'lb/hp/h';
  const label = `${t('unitPocParameters.linearBiomassCoefficient')} ${linearBiomassCoefficient} ${unit}`;

  useEffect(() => {
    if (!campusPocParameter?._id || !showCreateModal) {
      return;
    }

    setFoodPricePerKg(campusPocParameter.foodPricePerKg);
    setCostPerHp(campusPocParameter.costPerHp);
    setLinearBiomassCoefficientKg(campusPocParameter.linearBiomassCoefficient);

    const linearBiomassCoefficientLb = convertKilogramsToPounds(campusPocParameter.linearBiomassCoefficient);
    setLinearBiomassCoefficientLb(linearBiomassCoefficientLb);

    const linearBiomassCoefficient = company.weightUnit === weightUnitsByCompany.KILOGRAM ? campusPocParameter.linearBiomassCoefficient : linearBiomassCoefficientLb;

    form.setFieldsValue({
      foodPricePerKg: campusPocParameter.foodPricePerKg,
      costPerHp: campusPocParameter.costPerHp,
      linearBiomassCoefficient: linearBiomassCoefficient,
    });
  }, [campusPocParameter, showCreateModal]);

  const closeModal = () => {
    dispatch(unitPocParameterSlice.setShowCreateModal(false));
    dispatch(unitPocParameterSlice.resetCampusPocParameter());

    form.resetFields();

    setFoodPricePerKg(0);
    setCostPerHp(0);
    setLinearBiomassCoefficientKg(linearBiomassCoefficientValuesKg.min);
    setLinearBiomassCoefficientLb(linearBiomassCoefficientValuesLb.min);
  };

  const getModalTitle = () => {
    return campusPocParameter?._id ? t('formPocParameters.edit') : t('formPocParameters.new');
  };

  const getSubmitButtonLabel = () => {
    return campusPocParameter?._id ? t('actions.edit') : t('actions.save');
  };

  return (
    <LrvModal
      theme={theme}
      title={getModalTitle()}
      open={showCreateModal}
      destroyOnClose={true}
      okButtonProps={{ htmlType: 'submit', form: 'form' }}
      onOk={() => {
        if (!foodPricePerKg || !costPerHp || !linearBiomassCoefficientKg) {
          return;
        }

        const body: CampusPocParameter = {
          _id: campusPocParameter?._id,
          companyId: company._id,
          campusId,
          foodPricePerKg: Number(foodPricePerKg),
          costPerHp: Number(costPerHp),
          linearBiomassCoefficient: Number(linearBiomassCoefficientKg),
        };

        if (campusPocParameter?._id) {
          dispatch(unitPocParameterSlice.updateCampusPocParameter({ body, onSuccess: closeModal }));
          return;
        }

        dispatch(unitPocParameterSlice.createCampusPocParameter({ body, onSuccess: closeModal }));
      }}
      okText={getSubmitButtonLabel()}
      cancelText={t('actions.cancel')}
      onCancel={closeModal}
    >
      <LrvForm
        theme={theme}
        form={form}
        layout='vertical'
        name='form'
        id='form'
      >
        <Form.Item
          label={t('unitPocParameters.foodPricePerKg')}
          name='foodPricePerKg'
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <LrvInputNumber
            theme={theme}
            autoFocus
            value={foodPricePerKg}
            onChange={setFoodPricePerKg}
            parser={(input) => `${input}`.replace(',', '.')}
            min={0}
          />
        </Form.Item>

        <Form.Item
          label={t('unitPocParameters.costPerHp')}
          name='costPerHp'
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <LrvInputNumber
            theme={theme}
            value={costPerHp}
            onChange={setCostPerHp}
            parser={(input) => `${input}`.replace(',', '.')}
            min={0}
          />
        </Form.Item>

        <Form.Item
          label={label}
          name='linearBiomassCoefficient'
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Slider
            min={linearBiomassCoefficientValues.min}
            max={linearBiomassCoefficientValues.max}
            step={1}
            value={linearBiomassCoefficient}
            onChange={(value) => {
              if (Number.isNaN(value)) {
                return;
              }
  
              switch (company.weightUnit) {
                case weightUnitsByCompany.KILOGRAM: {
                  const linearBiomassCoefficientLb = convertKilogramsToPounds(Number(value));
                  setLinearBiomassCoefficientKg(Number(value));
                  setLinearBiomassCoefficientLb(linearBiomassCoefficientLb);
                  break;
                }
              
                case weightUnitsByCompany.POUND: {
                  const linearBiomassCoefficientKg = convertPoundsToKilograms(Number(value));
                  setLinearBiomassCoefficientLb(Number(value));
                  setLinearBiomassCoefficientKg(linearBiomassCoefficientKg);
                  break;
                }
              }
            }}
          />
        </Form.Item>
      </LrvForm>
    </LrvModal>
  );
};
