import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, MenuProps, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Icon from '../Icon/Icon';
import { Store } from '../../../state/store.interfaces';
import { handleResizeEvent } from '../../../utils/dimensions';
import { LrvAvatarPhase } from '../LrvAvatarPhase/LrvAvatarPhase';
import { setPhaseType } from '../../../pages/AppHeader/headerSlice';
import { getTitlePhaseType } from '../../../helpers/header.helpers';
import * as sowingsSlice from '../../../pages/Sowings/sowingsSlice';
import { stockingPhaseTypes, stockingsShow, THEME } from '../../../config/commons';
import * as stockingAnalysisSlice from '../../../pages/Sowings/Analysis/stockingAnalysisSlice';

import styles from './SwitchPhase.module.scss';

declare const placements: ['topLeft', 'topCenter', 'topRight', 'bottomLeft', 'bottomCenter', 'bottomRight', 'top', 'bottom'];
export type PhaseTypePlacement = typeof placements[number];

export interface SwitchPhaseProps {
  phaseType: string;
  theme?: 'dark' | 'light';
  placement?: PhaseTypePlacement,
}

export const SwitchPhase = (props: SwitchPhaseProps) => {
  const {
    phaseType,
    theme = 'dark',
    placement = 'bottomRight',
  } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [width, setWidth] = useState(window.innerWidth);
  const isLightTheme = theme === THEME.LIGHT;

  const { company } = useSelector((state: Store) => state.header);

  useEffect(() => {
    handleResizeEvent(() => {
      setWidth(window.innerWidth);
    });
  }, []);

  const larvaeItem = {
    key: '1',
    label: (
      <Space
        className={styles.row}
        onClick={() => {
          dispatch(stockingAnalysisSlice.setBrowsingFromStocking(false));
          dispatch(setPhaseType(stockingPhaseTypes.LARVAE));
          dispatch(sowingsSlice.resetStockingFilters({ page: 1, phaseType: stockingPhaseTypes.LARVAE, stockingsToShow: stockingsShow.ACTIVE, companyId: company._id }));
        }}
      >
        {
          stockingPhaseTypes.LARVAE === phaseType ?
            <Icon name='checkbox-circle' className={styles.icon} /> :
            <div />
        }

        <div className={styles.text}>
          {t('header.phaseTypes.larvae')}
        </div>

        <LrvAvatarPhase
          phaseType={stockingPhaseTypes.LARVAE}
          avatarSize={28}
          iconSize={22}
        />
      </Space>
    ),
  };
  const juvenileItem = {
    key: '2',
    label: (
      <Space
        className={styles.row}
        onClick={() => {
          dispatch(stockingAnalysisSlice.setBrowsingFromStocking(false));
          dispatch(setPhaseType(stockingPhaseTypes.JUVENILE));
          dispatch(sowingsSlice.resetStockingFilters({ page: 1, phaseType: stockingPhaseTypes.JUVENILE, stockingsToShow: stockingsShow.ACTIVE, companyId: company._id }));
        }}
      >
        {
          stockingPhaseTypes.JUVENILE === phaseType ?
            <Icon name='checkbox-circle' className={styles.icon} /> :
            <div />
        }

        <div className={styles.text}>
          {t('header.phaseTypes.juveniles')}
        </div>

        <LrvAvatarPhase
          phaseType={stockingPhaseTypes.JUVENILE}
          avatarSize={28}
          iconSize={22}
        />
      </Space>
    ),
  };
  const adultItem = {
    key: '3',
    label: (
      <Space
        className={styles.row}
        onClick={() => {
          dispatch(stockingAnalysisSlice.setBrowsingFromStocking(false));
          dispatch(setPhaseType(stockingPhaseTypes.ADULT));
          dispatch(sowingsSlice.resetStockingFilters({ page: 1, phaseType: stockingPhaseTypes.ADULT, stockingsToShow: stockingsShow.ACTIVE, companyId: company._id }));
        }}
      >
        {
          stockingPhaseTypes.ADULT === phaseType ?
            <Icon name='checkbox-circle' className={styles.icon} /> :
            <div />
        }

        <div className={styles.text}>
          {t('header.phaseTypes.growOut')}
        </div>

        <LrvAvatarPhase
          phaseType={stockingPhaseTypes.ADULT}
          avatarSize={28}
          iconSize={22}
        />
      </Space>
    ),
  };

  const items: MenuProps['items'] = [larvaeItem, juvenileItem, adultItem];

  const phaseTypeBorderStyle = {
    [stockingPhaseTypes.LARVAE]: styles.larvaeBorder,
    [stockingPhaseTypes.JUVENILE]: styles.juvenileBorder,
    [stockingPhaseTypes.ADULT]: styles.growOutBorder,
  };

  const renderPhaseType = () => {
    const avatarSize = width > 640 ? 32 : 28;
    return (
      <div className={cx(styles.container, isLightTheme ? styles.containerLight : styles.containerDark, phaseTypeBorderStyle[phaseType])}>
        <span className={styles.phase}>
          {getTitlePhaseType(phaseType)}
        </span>

        <div className={styles.avatar}>
          <LrvAvatarPhase avatarSize={avatarSize} phaseType={phaseType} />
        </div>
      </div>
    );
  };

  return (
    <Dropdown
      overlayClassName={styles.dropdown}
      menu={{ items }}
      trigger={['click']}
      arrow
      placement={placement}
    >
      {renderPhaseType()}
    </Dropdown>
  );
};
