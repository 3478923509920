/* eslint-disable @typescript-eslint/no-explicit-any */
import i18next from 'i18next';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import { isNumber } from '../utils/validations';
import { formatYearMonthDay } from '../utils/date';
import { GenericParam } from '../common/interfaces/commons';
import { BiometricData, WeightGroupData } from '../pages/Sowings/interfaces';
import { roundTwoDecimals, stockingPhaseTypes, typeBiometricsReport, volumeUnits, weightUnitsByCompany } from '../config/commons';

const getPhaseTypeLabel = (props: { phaseType: string }) => {
  const { phaseType } = props;

  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
      return i18next.t('header.phaseTypes.larvae');

    case stockingPhaseTypes.JUVENILE:
      return i18next.t('header.phaseTypes.juveniles');

    case stockingPhaseTypes.ADULT:
      return i18next.t('header.phaseTypes.growOut');

    default:
      return '-';
  }
};

const getTitle = (props: {biometricType: string; unitName: string; phaseType: string; }) => {
  const { biometricType, unitName, phaseType } = props;
  
  switch (biometricType) {
    case typeBiometricsReport.WEIGHT_GROUP:
      return `${i18next.t('biometrics.title')} (${i18next.t('stockings.xlsxReport.weightGroup')}), ${unitName} (${getPhaseTypeLabel({ phaseType })})`;

    case typeBiometricsReport.COMMERCIAL_SIZES:
      return `${i18next.t('biometrics.title')} (${i18next.t('stockings.xlsxReport.commercialSizes')}), ${unitName} (${getPhaseTypeLabel({ phaseType })})`;

    default:
      return '';
  }
};

const getWeightIncrease = (props: {weightIncrease: GenericParam}) => {
  const { weightIncrease } = props;

  if (!weightIncrease?.value) {
    return '';
  }

  const daysLabel = weightIncrease.days === 1 ? i18next.t('biometrics.increase.day') : i18next.t('biometrics.increase.days');
  return `${weightIncrease?.value} ${weightIncrease?.unit} (${weightIncrease?.days} ${daysLabel})`;
};

const getVolumeLabel = (props: { unit: string }) => {
  const { unit } = props;

  switch (unit) {
    case volumeUnits.L:
      return i18next.t('stockings.liters');

    case volumeUnits.HA:
      return i18next.t('stockings.hectares');

    case volumeUnits.M3:
      return i18next.t('stockings.cubicMeters');
  
    default:
      return '';
  }
};

interface Observation {
  key: string;
  value: string | number;
}

const injectObservations = (props: {headers: (string | number)[][]; observations: Observation[]; }) => {
  const { headers, observations } = props;

  if (!observations || observations.length === 0) {
    return headers;
  }

  let currentRow: (string | number)[] = [];

  for (const observation of observations) {
    const observationKey = observation.key;
    const observationValue = observation.value;

    const observationLabel = i18next.exists(`analysisObservations.types.${observationKey}`) ? i18next.t(`analysisObservations.types.${observationKey}`) : observationKey;

    currentRow.push(observationLabel);
    currentRow.push(observationValue);

    // If the row reaches 6 columns (3 pairs), add it to the matrix and create a new row.
    if (currentRow.length === 6) {
      headers.push(currentRow);
      currentRow = [];
    }
  }

  // If there is any remaining row (with less than 6 elements), add it as well.
  if (currentRow.length > 0) {
    while (currentRow.length < 6) {
      currentRow.push('');
    }
    headers.push(currentRow);
  }
  return headers;
};

const getBiomass = (props: {weightUnit: string; lastAnalysis: BiometricData}) => {
  const { weightUnit, lastAnalysis } = props;

  if (weightUnit === weightUnitsByCompany.KILOGRAM) {
    return `${lastAnalysis.biomassKg.value} ${lastAnalysis.biomassKg.unit}`;
  }

  return `${lastAnalysis.biomassLb.value} ${lastAnalysis.biomassLb.unit}`;
};

const getBiomassUnit = (props: {weightUnit: string; lastAnalysis: BiometricData}) => {
  const { weightUnit, lastAnalysis } = props;

  if (weightUnit === weightUnitsByCompany.KILOGRAM) {
    return lastAnalysis.biomassKg.unit;
  }

  return lastAnalysis.biomassLb.unit;
};

const getBiomassPercent = (props: {weightUnit: string; group: WeightGroupData}) => {
  const { weightUnit, group } = props;

  if (weightUnit === weightUnitsByCompany.KILOGRAM) {
    return group.biomassKg.percent;
  }

  return group.biomassLb.percent;
};

const getBiomassWeight = (props: {weightUnit: string; group: WeightGroupData}) => {
  const { weightUnit, group } = props;

  if (weightUnit === weightUnitsByCompany.KILOGRAM) {
    return group.biomassKg.weight;
  }

  return group.biomassLb.weight;
};

export const generateExcel = async (props: { biometricData: BiometricData[]; biometricType: string; phaseType: string; weightUnit: string; }) => {
  const { biometricType, biometricData, phaseType, weightUnit } = props;

  const workbook = new ExcelJS.Workbook();

  const campus = biometricData[0]?.campusName;
  const worksheet = workbook.addWorksheet(campus);

  const title = getTitle({ biometricType, unitName: campus, phaseType });
  const titleRow = worksheet.addRow([title]);
  titleRow.font = { bold: true, size: 13 };
  titleRow.alignment = { horizontal: 'center', vertical: 'middle' };

  worksheet.mergeCells(titleRow.number, 1, titleRow.number, 6);
  
  biometricData.forEach((lastAnalysis) => {
    const headerStyle: Partial<ExcelJS.Style> = {
      font: { bold: true, color: { argb: '000000' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D9D9D9' } },
      alignment: { horizontal: 'center', vertical: 'middle' },
      border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } }
    };

    const borderStyle: Partial<ExcelJS.Style> = {
      border: {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' }
      }
    };

    const mainData = [
      [i18next.t('biometrics.stocking'), lastAnalysis.stockingName, i18next.t('biometrics.container'), lastAnalysis.tankName, i18next.t('biometrics.analysisDate'), formatYearMonthDay(lastAnalysis.analysisDate)],
      [i18next.t('biometrics.currentWeight'), `${roundTwoDecimals(lastAnalysis.averageWeight.value)} ${lastAnalysis.averageWeight.unit}`, i18next.t('biometrics.increase.label'), getWeightIncrease({ weightIncrease: lastAnalysis.weightIncrease }), i18next.t('biometrics.productionDays'), lastAnalysis.productionDays],
      [i18next.t('biometrics.population'), (lastAnalysis.population), i18next.t('biometrics.survival'), `${lastAnalysis.survivalRate}%`, i18next.t('biometrics.uniformityWeight'), `${lastAnalysis.uniformityWeight}%`],
      [i18next.t('biometrics.biomass'), getBiomass({ lastAnalysis, weightUnit }), `${getVolumeLabel({ unit: lastAnalysis.size.unit })}`, lastAnalysis.size.value, i18next.t('biometrics.density'), `${lastAnalysis.density.value} ${lastAnalysis.density.unit}`]
    ];

    const headers = injectObservations({ headers: mainData, observations: lastAnalysis.observations });

    headers.forEach((row) => {
      const excelRow = worksheet.addRow(row);
      excelRow.eachCell((cell, colNumber) => {
        cell.style = {
          ...borderStyle,
          font: { ...cell.font, bold: colNumber % 2 === 1 },
        };
      });
    });

    // Add tables according to biometrics type
    switch (biometricType) {
      case typeBiometricsReport.WEIGHT_GROUP: {
        const headers = [i18next.t('biometrics.weightGroup.range'), `# ${i18next.t('biometrics.weightGroup.animals')}`, `% ${i18next.t('biometrics.weightGroup.animals')}`, `${i18next.t('biometrics.biomass')} (${getBiomassUnit({ weightUnit, lastAnalysis })})`, `${i18next.t('biometrics.biomass')} (%)`, `${i18next.t('biometrics.weightGroup.averageWeight')} (${lastAnalysis.averageWeight.unit})`];
        const headerRow = worksheet.addRow(headers);
        headerRow.eachCell((cell) => (cell.style = headerStyle));

        lastAnalysis.weightGroupData.forEach((group) => {
          const row = worksheet.addRow([
            group.range,
            group.animals.count,
            group.animals.percent,
            getBiomassWeight({ group, weightUnit }),
            getBiomassPercent({ group, weightUnit }),
            group.averageWeight
          ]);
          row.eachCell((cell) => (cell.style = borderStyle));
        });

        const totalAnimals = lastAnalysis.weightGroupData.reduce((acc, group) => acc + group.animals.count, 0);
        const totalBiomass = lastAnalysis.weightGroupData.reduce((acc, group) => acc + getBiomassWeight({ group, weightUnit }), 0);
        const totalWeight = roundTwoDecimals(totalBiomass / totalAnimals);

        const totalRow = worksheet.addRow([i18next.t('biometrics.weightGroup.total'), totalAnimals, '', totalBiomass, '', totalWeight]);
        totalRow.eachCell((cell) => {
          cell.style = { ...borderStyle, font: { bold: true } };
        });

        break;
      }

      case typeBiometricsReport.COMMERCIAL_SIZES: {
        const headers = [i18next.t('biometrics.commercialSizes.whole'), `# ${i18next.t('biometrics.commercialSizes.animals')}`, `% ${i18next.t('biometrics.biomass')}`, i18next.t('biometrics.commercialSizes.tail'), `# ${i18next.t('biometrics.commercialSizes.animals')}`, `% ${i18next.t('biometrics.biomass')}`];
        const headerRow = worksheet.addRow(headers);
        headerRow.eachCell((cell) => (cell.style = headerStyle));

        for (let index = 0; index < lastAnalysis.wholeData.length; index++) {
          const whole = lastAnalysis.wholeData[index];
          const tail = lastAnalysis.tailData[index];

          const row = worksheet.addRow([
            whole.range,
            whole.animals,
            whole.biomass,
            tail.range,
            tail.animals,
            tail.biomass
          ]);
          row.eachCell((cell) => (cell.style = borderStyle));
        }

        const totalAnimals = lastAnalysis.wholeData.reduce((acc, analysis) => acc + (isNumber(analysis.animals.toString()) ? analysis.animals : 0), 0);
        const totalRow = worksheet.addRow([i18next.t('biometrics.weightGroup.total'), totalAnimals, '', '', totalAnimals, '']);
        totalRow.eachCell((cell) => {
          cell.style = { ...borderStyle, font: { bold: true } };
        });

        break;
      }
    }

    // Automatic adjustment of column widths
    worksheet.columns.forEach((column) => {
      let maxLength = 10;
    
      if (column.eachCell) {
        column.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
          if (rowNumber === 1) {
            return;
          }

          const cellValue = cell.value as string;
          if (cellValue) {
            maxLength = Math.max(maxLength, cellValue.toString().length);
          }
        });
      }
    
      column.width = maxLength + 2;
    });

    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
  });

  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${i18next.t('biometrics.file')}.xlsx`);
};
