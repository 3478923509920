import moment from 'moment';
import { Col, Form, Row, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { isNumber } from '../../../../utils/validations';
import { Store } from '../../../../state/store.interfaces';
import { LrvForm } from '../../../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../../../common/components/LrvText/LrvText';
import { LrvTabs } from '../../../../common/components/LrvTabs/LrvTabs';
import { LrvModal } from '../../../../common/components/LrvModal/LrvModal';
import { LrvSwitch } from '../../../../common/components/LrvSwitch/LrvSwitch';
import { CommercialSizeRange, ProcessedCommercialSizeRange } from '../interfaces';
import { LrvDatePicker } from '../../../../common/components/LrvDatePicker/LrvDatePicker';
import { LrvInputNumber } from '../../../../common/components/LrvInputNumber/LrvInputNumber';
import { applyParserThousandsSeparator, applyThousandsSeparator } from '../../../../utils/strings';
import { commercialSizeTypes, weightUnits, weightUnitsByCompany } from '../../../../config/commons';
import { generateCommercialSizeRanges, getMaxValueDisplay, MIN_VALUE_DISPLAY_GROW_OUT } from '../../../../helpers/commercial-size.helpers';
import { getPrices, disablePriceTableForm, calcYieldPorcentageTail, calcYieldPorcentageWaste } from '../../../../helpers/commercial-size-price-table';

import { DistributionBar } from './DistributionBar';
import * as tablePriceSlice from './tablePriceSlice';
import styles from './EditTablePriceForm.module.scss';
import { CommercialSizePriceTable } from './interfaces';
import { CommercialSizeTable } from './CommercialSizeTable';

interface Props {
  theme?: 'dark' | 'light';
}

const { TabPane } = Tabs;

export const EditTablePriceForm = (props: Props) => {
  const { theme = 'dark' } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const {
    showUpdateModal,
    isUpdating,
    packer,
    commercialSize: selectedCommercialSize,
    commercialSizePriceTable,
  } = useSelector((state: Store) => state.tablePrices);

  const [active, setActive] = useState<boolean | undefined>(false);
  const [date, setDate] = useState('');
  const [paymentDelayDays, setPaymentDelayDays] = useState<number | string | null | undefined>();
  const [yieldPercentageWhole, setYieldPercentageWhole] = useState<number | string | null | undefined>();
  const [yieldPercentageTail, setYieldPercentageTail] = useState<number | string | null | undefined>();
  const [wastePrice, setWastePrice] = useState<number | string | null | undefined>();
  const [commercialSizesWhole, setCommercialSizesWhole] = useState<number[]>([]);
  const [commercialSizesTail, setCommercialSizesTail] = useState<number[]>([]);
  const [commercialSizeRangesWhole, setCommercialSizeRangesWhole] = useState<CommercialSizeRange[]>([]);
  const [commercialSizeRangesTail, setCommercialSizeRangesTail] = useState<CommercialSizeRange[]>([]);
  const [processedDataSourceWhole, setProcessedDataSourceWhole] = useState<ProcessedCommercialSizeRange[]>([]);
  const [processedDataSourceTail, setProcessedDataSourceTail] = useState<ProcessedCommercialSizeRange[]>([]);
  const [currentTab, setCurrentTab] = useState<string>(commercialSizeTypes.GROW_OUT_WHOLE);

  const yieldPercentageWaste = calcYieldPorcentageWaste({ whole: Number(yieldPercentageWhole), tail: Number(yieldPercentageTail) });

  useEffect(() => {
    if (!selectedCommercialSize?._id || !commercialSizePriceTable?._id || !showUpdateModal) {
      return;
    }

    setActive(commercialSizePriceTable.active);
    setDate(commercialSizePriceTable.date);
    setCommercialSizesTail(commercialSizePriceTable.commercialSizes.tail);
    setCommercialSizesWhole(commercialSizePriceTable.commercialSizes.whole);
    setPaymentDelayDays(commercialSizePriceTable.paymentDelayDays);
    setYieldPercentageWhole(commercialSizePriceTable.yield?.whole);
    setYieldPercentageTail(commercialSizePriceTable.yield?.tail);
    setWastePrice(commercialSizePriceTable.waste?.price);

    form.setFieldsValue({
      date: moment.parseZone(commercialSizePriceTable.date),
      active: commercialSizePriceTable.active,
      paymentDelayDays: commercialSizePriceTable.paymentDelayDays,
      yieldPercentageWhole: commercialSizePriceTable.yield?.whole,
      yieldPercentageTail: commercialSizePriceTable.yield?.tail,
      wastePrice: commercialSizePriceTable.waste?.price,
    });

  }, [selectedCommercialSize, commercialSizePriceTable, showUpdateModal]);

  useEffect(() => {
    if (commercialSizesWhole.length === 0) {
      return;
    }

    const maxValueDisplay = getMaxValueDisplay({ commercialSizeType: commercialSizeTypes.GROW_OUT_WHOLE });
    const commercialSizeRanges = generateCommercialSizeRanges({ commercialSizes: commercialSizesWhole, minValueDisplay: MIN_VALUE_DISPLAY_GROW_OUT, maxValueDisplay, weightUnit: weightUnitsByCompany.KILOGRAM });
    setCommercialSizeRangesWhole(commercialSizeRanges);
  }, [commercialSizesWhole]);

  useEffect(() => {
    if (commercialSizesTail.length === 0) {
      return;
    }

    const maxValueDisplay = getMaxValueDisplay({ commercialSizeType: commercialSizeTypes.GROW_OUT_TAIL });
    const commercialSizeRanges = generateCommercialSizeRanges({ commercialSizes: commercialSizesTail, minValueDisplay: MIN_VALUE_DISPLAY_GROW_OUT, maxValueDisplay, weightUnit: weightUnitsByCompany.POUND });
    setCommercialSizeRangesTail(commercialSizeRanges);
  }, [commercialSizesTail]);

  const closeModal = () => {
    form.resetFields();
    setActive(false);
    setDate('');
    setCommercialSizesTail([]);
    setCommercialSizesWhole([]);
    setCommercialSizeRangesTail([]);
    setCommercialSizeRangesWhole([]);
    setProcessedDataSourceTail([]);
    setProcessedDataSourceWhole([]);
    setPaymentDelayDays('');
    setYieldPercentageWhole('');
    setYieldPercentageTail('');
    setWastePrice('');
    
    setCurrentTab(commercialSizeTypes.GROW_OUT_WHOLE);
    dispatch(tablePriceSlice.setShowUpdateModal(false));
  };

  const updateCommercialSize = () => {
    if (!packer?._id || !selectedCommercialSize?._id || !commercialSizePriceTable?._id) {
      return;
    }
    
    const pricesWhole = getPrices({ processedDataSource: processedDataSourceWhole });
    const pricesTail = getPrices({ processedDataSource: processedDataSourceTail });

    const body: CommercialSizePriceTable = {
      date,
      active,
      paymentDelayDays: parseInt(`${paymentDelayDays}`),
      commercialSizes: {
        whole: commercialSizesWhole,
        tail: commercialSizesTail,
      },
      prices: {
        whole: pricesWhole.length === 0 ? commercialSizePriceTable.prices.whole : pricesWhole,
        tail: pricesTail.length === 0 ? commercialSizePriceTable.prices.tail : pricesTail,
      },
      yield: {
        whole: isNumber(yieldPercentageWhole) ? parseFloat(`${yieldPercentageWhole}`) : undefined,
        tail: isNumber(yieldPercentageTail) ? parseFloat(`${yieldPercentageTail}`) : undefined,
      },
      waste: {
        price: isNumber(wastePrice) ? parseFloat(`${wastePrice}`) : undefined,
      },
      packerId: packer._id,
      companyId: packer.companyId,
      commercialSizeId: selectedCommercialSize._id,
    };

    const props = {
      body,
      commercialSizePriceTableId: commercialSizePriceTable._id,
      onSuccess: closeModal,
    };

    dispatch(tablePriceSlice.updateCommercialSizePriceTable(props));
  };

  const renderSwitch = () => {
    return (
      <Form.Item>
        <div className={styles.switch}>
          <div className={styles.status}>
            <LrvText text={t('priceTable.active')} theme={theme} />
          </div>

          <LrvSwitch
            theme={theme}
            checked={active}
            onChange={(value) => setActive(value)}
          />
        </div>
      </Form.Item>
    );
  };

  const renderCommercialSizeTable = (props: {commercialSizeRanges: CommercialSizeRange[]; prices?: number[]; commercialSizeType: string; processedDataSource: ProcessedCommercialSizeRange[], setProcessedDataSource: Dispatch<SetStateAction<ProcessedCommercialSizeRange[]>>; weightUnit: string; }) => {
    const { commercialSizeRanges, prices, commercialSizeType, processedDataSource, setProcessedDataSource, weightUnit } = props;
    
    return (
      <CommercialSizeTable
        commercialSizeRanges={commercialSizeRanges}
        commercialSizeType={commercialSizeType}
        processedDataSource={processedDataSource}
        setProcessedDataSource={setProcessedDataSource}
        prices={prices}
        weightUnit={weightUnit}
      />
    );
  };

  return (
    <LrvModal
      theme={theme}
      title={t('priceTable.editPriceTable')}
      open={showUpdateModal}
      destroyOnClose={true}
      okButtonProps={{
        id: 'submit-commercial-size-price-table',
        htmlType: 'submit',
        form: 'form',
        loading: isUpdating,
        disabled: disablePriceTableForm({ date, paymentDelayDays, processedDataSourceTail, processedDataSourceWhole, yieldPercentageTail, yieldPercentageWhole, wastePrice, yieldPercentageWaste }),
      }}
      onOk={updateCommercialSize}
      okText={t('priceTable.edit')}
      cancelText={t('priceTable.cancel')}
      onCancel={closeModal}
      className={styles.priceTableModal}
    >
      <LrvForm
        theme={theme}
        form={form}
        name='form'
        id='form-edit-commercial-size-price-table'
        layout='vertical'
      >
        {renderSwitch()}

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='date'
              label={t('priceTable.date')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <LrvDatePicker
                theme={theme}
                placeholder=''
                onChange={(date) => {
                  const dateSelected = date?.toString() as string;
                  setDate(dateSelected);
                }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name='paymentDelayDays'
              label={t('priceTable.paymentDelayDays')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <LrvInputNumber
                theme={theme}
                value={paymentDelayDays}
                formatter={value => applyThousandsSeparator(value)}
                parser={value => applyParserThousandsSeparator(value)}
                onChange={setPaymentDelayDays}
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <DistributionBar whole={Number(yieldPercentageWhole)} tail={calcYieldPorcentageTail({ whole: Number(yieldPercentageWhole), tail: Number(yieldPercentageTail) })} waste={calcYieldPorcentageWaste({ whole: Number(yieldPercentageWhole), tail: Number(yieldPercentageTail) })}/>
        </Form.Item>

        <LrvTabs
          theme={theme}
          activeKey={currentTab}
          defaultActiveKey={currentTab}
          onChange={setCurrentTab}
        >
          <TabPane tab={t(`commercialSizes.commercialSizeTypes.${commercialSizeTypes.GROW_OUT_WHOLE}`)} key={commercialSizeTypes.GROW_OUT_WHOLE}>
            <Form.Item
              name='yieldPercentageWhole'
              label={t('priceTable.yieldPercentage')}
              required={false}
            >
              <LrvInputNumber
                theme={theme}
                value={yieldPercentageWhole}
                min={1}
                max={100}
                onChange={setYieldPercentageWhole}
              />
            </Form.Item>

            <Form.Item>
              {renderCommercialSizeTable({ commercialSizeRanges: commercialSizeRangesWhole, prices: commercialSizePriceTable?.prices.whole, commercialSizeType: commercialSizeTypes.GROW_OUT_WHOLE, processedDataSource: processedDataSourceWhole, setProcessedDataSource: setProcessedDataSourceWhole, weightUnit: weightUnitsByCompany.KILOGRAM, })}
            </Form.Item>
          </TabPane>

          <TabPane tab={t(`commercialSizes.commercialSizeTypes.${commercialSizeTypes.GROW_OUT_TAIL}`)} key={commercialSizeTypes.GROW_OUT_TAIL}>
            <Form.Item
              name='yieldPercentageTail'
              label={t('priceTable.yieldPercentage')}
              required={false}
            >
              <LrvInputNumber
                theme={theme}
                value={yieldPercentageTail}
                min={1}
                max={100}
                onChange={setYieldPercentageTail}
              />
            </Form.Item>
            
            <Form.Item>
              {renderCommercialSizeTable({ commercialSizeRanges: commercialSizeRangesTail, prices: commercialSizePriceTable?.prices.tail, commercialSizeType: commercialSizeTypes.GROW_OUT_TAIL, processedDataSource: processedDataSourceTail, setProcessedDataSource: setProcessedDataSourceTail, weightUnit: weightUnitsByCompany.POUND, })}
            </Form.Item>
          </TabPane>

          <TabPane tab={t('priceTable.waste')} key='WASTE'>
            <Form.Item
              name='wastePrice'
              label={t('priceTable.wastePrice', { unit: weightUnits.KG })}
              required={false}
            >
              <LrvInputNumber
                theme={theme}
                value={wastePrice}
                onChange={setWastePrice}
              />
            </Form.Item>
          </TabPane>
        </LrvTabs>
      </LrvForm>
    </LrvModal>
  );
};