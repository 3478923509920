import cx from 'classnames';
import momentTimezone from 'moment-timezone';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isDesktop } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { Key, MutableRefObject, useEffect, useRef, useState } from 'react';
import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { FilterConfirmProps, FilterDropdownProps } from 'antd/lib/table/interface';
import { Button, Col, Dropdown, Form, InputRef, Menu, Row, Select, Space } from 'antd';

import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../helpers/theme';
import { fetchUrlTanksQR } from '../Tanks/tanksSlice';
import { filterOptionSelect } from '../../utils/select';
import { LrvTag } from '../../common/components/LrvTag/LrvTag';
import { GenericParam } from '../../common/interfaces/commons';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { LrvTable } from '../../common/components/LrvTable/LrvTable';
import { LrvSwitch } from '../../common/components/LrvSwitch/LrvSwitch';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import { changeBreadcrumb, changeHeader } from '../AppHeader/headerSlice';
import { lrvConfirm } from '../../common/components/LrvConfirm/LrvConfirm';
import { getUnitPhaseTypeFromAnalysis } from '../../helpers/units.helpers';
import * as onboardingSlice from '../../common/components/Onboarding/OnboardingSlice';
import { ExtraActionsButton } from '../../common/components/buttons/ExtraActionsButton';
import { commonOptions, getKeyValue, getMainRole, onboardingTypes, PROVINCES_ECUADOR, roles, stockingPhaseTypes, THEME, unitPhaseTypes, unitStatuses } from '../../config/commons';

import './Units.scss';
import { Campus } from './interfaces';
import styles from './Units.module.scss';
import { UnitPocParameterForm } from './UnitPocParameterForm';
import * as unitPocParameterSlice from './unitPocParameterSlice';
import { createCampus, fetchCampus, fetchCampuses, updateCampus, setSelectedCampus, updateCampusStatus, setShowCreateCampusModal } from './campusSlice';


const Units = () => {
  const [t] = useTranslation();
  const { Option } = Select;
  const history = useHistory();
  const dispatch = useDispatch();
  const timezones = momentTimezone.tz.names();

  const [form] = Form.useForm();

  const [showUpdateCampusModal, setShowUpdateCampusModal] = useState(false);
  const [newCampusStatus, setNewCampusStatus] = useState(unitStatuses.ACTIVE);
  const [newCampusName, setNewCampusName] = useState('');
  const [newCampusCode, setNewCampusCode] = useState('');
  const [newCampusType, setNewCampusType] = useState('');
  const [newCampusTimezone, setNewCampusTimezone] = useState(momentTimezone.tz.guess());
  const [newCampusProvince, setNewCampusProvince] = useState('');

  const {
    campuses,
    selectedCampus,
    isCampusesLoading,
    isCampusLoading,
    showCreateCampusModal,
  } = useSelector((state: Store) => state.campus);

  const { company, phaseType } = useSelector((state: Store) => state.header);
  const unitPhaseType = getUnitPhaseTypeFromAnalysis(phaseType);

  const {
    existsUserData,
    user: onboardingUserData,
    run: isRunningOnboarding,
  } = useSelector((state: Store) => state.onboarding);

  const isCampusInactive = selectedCampus.status === unitStatuses.INACTIVE;
  const isNewCampusInactive = newCampusStatus === unitStatuses.INACTIVE;

  const higherRole = getMainRole();

  const [newCampusPhase, setNewCampusPhase] = useState(unitPhaseTypes.LARVAE);
  const unitNameRoute = t('campus.title');

  const MAX_CODES_LENGTH = 4;

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  const refSearchName = useRef<InputRef | null>(null);

  useEffect(() => {
    if (existsUserData && !onboardingUserData.onboarding?.unitSection) {
      setTimeout(() => {
        dispatch(onboardingSlice.setStepIndex(0));
        dispatch(onboardingSlice.setOnboardingType(onboardingTypes.UNIT_SECTION));
        dispatch(onboardingSlice.setShouldRun(true));
        dispatch(onboardingSlice.setRun(true));
      }, 500);
    }
  }, [dispatch, onboardingUserData, existsUserData]);

  useEffect(() => {
    dispatch(changeHeader({ title: 'campus.title' }));
    dispatch(changeBreadcrumb([{ id: 'units', name: unitNameRoute, url: '/units' }]));
  }, [unitNameRoute, dispatch]);

  useEffect(() => {
    if (!company._id) {
      return;
    }

    dispatch(fetchCampuses({ companyId: company._id, phaseType: unitPhaseType }));
  }, [dispatch, company._id, unitPhaseType]);

  useEffect(() => {
    if (!selectedCampus?._id) {
      return;
    }

    setNewCampusStatus(selectedCampus.status);
    setNewCampusName(selectedCampus.name);
    setNewCampusCode(selectedCampus.code);
    setNewCampusType(selectedCampus.phaseType);
    setNewCampusTimezone(selectedCampus.timezone);
    setNewCampusProvince(selectedCampus.province);
  }, [selectedCampus._id, selectedCampus.code, selectedCampus.name, selectedCampus.phaseType, selectedCampus.timezone, selectedCampus.province]);

  function closeCampusModal () {
    dispatch(setShowCreateCampusModal(false));
    setNewCampusStatus('');
    setNewCampusName('');
    setNewCampusCode('');
    setNewCampusType('');
    setNewCampusTimezone(momentTimezone.tz.guess());
    setNewCampusProvince('');
    setNewCampusPhase(unitPhaseTypes.LARVAE);
    dispatch(setSelectedCampus({ _id: '', name: '', code: '', timezone: '', companyId: '', country: '', province: '', phaseType: '', status: '' }));
    form.resetFields();
  }

  function showDeleteCampusConfirm (campusId: string, campusName: string) {
    lrvConfirm({
      theme: 'light',
      title: t('campus.confirmDelete'),
      icon: <ExclamationCircleOutlined />,
      okButtonProps: { id: 'btnOkDeleteCampus' },
      content: campusName,
      okText: t('campus.delete'),
      okType: 'danger',
      cancelText: t('campus.cancel'),
      onOk () {
        dispatch(updateCampusStatus({ campusId, companyId: company._id, status: unitStatuses.REMOVED, phaseType: unitPhaseType }));

        if (selectedCampus._id === campusId) {
          dispatch(setSelectedCampus({ _id: '', name: '', code: '', timezone: '', companyId: '', country: '', province: '', phaseType: '', status: '' }));
        }
      },
    });
  }

  const columns: ColumnsType<Campus> = [
    {
      key: 1,
      title: t('campus.code').toUpperCase(),
      dataIndex: 'code',
      ellipsis: { showTitle: false },
      responsive: isDesktop ? undefined : ['md'] as Breakpoint[],
      width: '20%',
    },
    {
      key: 2,
      title: t('campus.name').toUpperCase(),
      dataIndex: 'name',
      ellipsis: { showTitle: false },
      width: '25%',
      ...getColumnSearchProps('name', refSearchName),
    },
    {
      key: 3,
      title: t('campus.timezone').toUpperCase(),
      dataIndex: 'timezone',
      ellipsis: { showTitle: false },
      width: '25%',
      responsive: ['lg'] as Breakpoint[],
    },
    {
      key: 3,
      title: t('campus.phase').toUpperCase(),
      dataIndex: 'phaseType',
      ellipsis: { showTitle: false },
      width: '25%',
      responsive: ['md'] as Breakpoint[],
      render: (_, record: Campus) => {
        return t(`stockings.phaseTypes.${record.phaseType}`);
      },
    },
    {
      key: 5,
      dataIndex: 'status',
      className: styles.statusCell,
      width: '20%',
      render: (_, record: Campus) => {
        if (record.status === unitStatuses.INACTIVE) {
          return (
            <Row align='middle'>
              <LrvTag id={`status_tag_${record.status}`} theme={theme} type='info'>{t('campus.inactive')}</LrvTag>
            </Row>
          );
        }
        // if return null, table will display an "-" in the empty cell
        return <div></div>;
      },
    },
    {
      key: 5,
      width: '10%',
      className: styles.optionsCell,
      render: (_, record: Campus) => renderDropdownUnitOptions(record),
    }
  ];

  function getColumnSearchProps (dataIndex: string, ref: MutableRefObject<InputRef | null>) {
    const label = `campus.paramsSearch.${dataIndex}`;
    const placeholder = `${t('campus.search')} ${t(label)}`;

    return {
      filterDropdown: (props: FilterDropdownProps) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props;
        return (
          <div className={styles.menuSearch}>
            <LrvInput
              theme='light'
              id={`txt_${dataIndex}_search`}
              ref={ref}
              placeholder={placeholder}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => {
                if (selectedKeys[0]) {
                  clearFilters && clearFilters();
                  searchCampuses({ dataIndex, confirm, value: selectedKeys });
                }
              }}
              className={styles.inputSearch}
            />
            <Space>
              <Button
                id={`btn_${dataIndex}_search`}
                type='primary'
                onClick={() => {
                  clearFilters && clearFilters();
                  searchCampuses({ dataIndex, confirm, value: selectedKeys });
                }}
                icon={<SearchOutlined />}
                size='small'
                className={styles.buttonSearch}
                disabled={!selectedKeys[0]}
              >
                {t('campus.search')}
              </Button>
              <Button
                id={`btn_${dataIndex}_reset`}
                onClick={() => {
                  clearFilters && clearFilters();
                  dispatch(fetchCampuses({ companyId: company._id, phaseType: unitPhaseType }));
                }}
                size='small'
                className={styles.buttonReset}
              >
                {t('campus.reset')}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => <SearchOutlined className={isLightTheme ? styles.searchOutlinedIconLight : styles.searchOutlinedIconDark} style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value: boolean | Key, record: Campus) => {
        const item: GenericParam = record;
        const field: string = item[dataIndex].toString().toLowerCase();
        return field.includes(`${value}`.toLowerCase());
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          setTimeout(() => ref.current?.select(), 100);
        }
      },
    };
  }

  const searchCampuses = (props: { dataIndex: string; confirm: (param?: FilterConfirmProps | undefined) => void; value: Key[] }) => {
    const { dataIndex, confirm, value } = props;
    confirm();

    switch (dataIndex) {
      case 'name':
        dispatch(fetchCampuses({ companyId: company._id, name: value[0], phaseType: unitPhaseType }));
        break;
    }
  };

  const renderDropdownUnitOptions = (record: Campus) => {
    return (
      <Dropdown
        overlay={() => unitMenuItems(record)}
        trigger={['click']}
        placement='bottomRight'
      >
        <ExtraActionsButton
          onClick={(e) => e.stopPropagation()}
          id={`button_${record._id}`}
        />
      </Dropdown>
    );
  };

  const unitMenuItems = (record: Campus) => {
    return (
      <Menu
        onClick={({ key, domEvent }) => {
          domEvent.stopPropagation();

          switch (key) {
            case commonOptions.EDIT:
              dispatch(fetchCampus(record._id));
              setShowUpdateCampusModal(true);
              break;

            case commonOptions.REMOVE:
              showDeleteCampusConfirm(record._id, record.name);
              break;

            case commonOptions.POC:
              dispatch(unitPocParameterSlice.setShowCreateModal(true));
              dispatch(unitPocParameterSlice.setCampusId(record._id));
              dispatch(unitPocParameterSlice.fetchCampusPocParameter({ campusId: record._id }));
              break;

                
            case commonOptions.REPORT:
              fetchUrlTanksQR({ campusId: record._id, campusName: record.name });
              break;
          }
        }}
      >
        <Menu.Item id='unit_menu_option_config' key={commonOptions.EDIT} className={styles.menuItemOptions}>
          <Icon name='edit' type='line' theme={theme} className={styles.icon} />
          <span>{t('campus.edit')}</span>
        </Menu.Item>

        <Menu.Item id='unit_menu_option_remove' key={commonOptions.REMOVE} className={styles.menuItemOptions}>
          <Icon name='delete-bin' type='line' theme={theme} className={styles.icon} />
          <span>{t('campus.delete')}</span>
        </Menu.Item>

        {
          phaseType === stockingPhaseTypes.ADULT &&
          <Menu.Item id='unit_menu_option_poc' key={commonOptions.POC} className={styles.menuItemOptions}>
            <Icon name='settings-4' type='line' theme={theme} className={styles.icon} />
            <span>{t('formPocParameters.poc')}</span>
          </Menu.Item>
        }

        <Menu.Item id='unit_menu_option_pdf' key={commonOptions.REPORT} className={styles.menuItemOptions}>
          <Icon name='file-list' type='line' theme={theme} className={styles.icon} />
          <span>{t('campus.downloadQRTanks')}</span>
        </Menu.Item>
      </Menu>
    );
  };

  const childrenTimezone: JSX.Element[] = [];
  for (let i = 0; i < timezones.length; i++) {
    const label = ' (GMT' + momentTimezone.tz(timezones[i]).format('Z') + ') ' + timezones[i];
    childrenTimezone.push(<Option key={timezones[i]} value={timezones[i]}>{label}</Option>);
  }

  const childrenProvinces: JSX.Element[] = [];
  for (let i = 0; i < PROVINCES_ECUADOR.length; i++) {
    const province = PROVINCES_ECUADOR[i];
    childrenProvinces.push(<Option key={province} value={province}>{province}</Option>);
  }

  function checkDisabledButton () {
    return isNewCampusInactive && (isCampusLoading || isCampusInactive);
  }

  return (
    <div className={styles.campus}>
      <Row className={styles.rowFlex}>
        <LrvTable
          id='tableCampus'
          className={styles.table}
          loading={isCampusesLoading}
          rowClassName={(record) => cx(styles.campusRow, selectedCampus._id === record._id ? isLightTheme ? styles.selectedRowLight : styles.selectedRowDark : '')}
          size='small'
          columns={columns}
          dataSource={campuses}
          pagination={false}
          scroll={{ y: '' }}
          theme={theme}
          onRow={(record) => {
            return {
              onClick: (e) => {
                e.stopPropagation(); // This avoids click confusion when extraAction buttons are clicked
                if (selectedCampus._id === record._id) {
                  return;
                }
                history.push('/units/' + record._id);

                if (isRunningOnboarding) {
                  dispatch(onboardingSlice.goToOnboardingNextStep(800));
                }
              },
            };
          }}
        />

        <LrvModal
          theme='light'
          title={t('campus.newCampus')}
          className='newCampusModal'
          open={showCreateCampusModal}
          destroyOnClose={true}
          okButtonProps={{ htmlType: 'submit', form: 'formNewCampus', id: 'formNewCampus_btnSave' }}
          onOk={() => {
            form.submit();
          }}
          okText={t('campus.create')}
          cancelText={t('campus.cancel')}
          onCancel={() => {
            closeCampusModal();
          }}
        >
          <LrvForm
            theme='light'
            form={form}
            layout='vertical'
            name='formNewCampus'
            id='formNewCampus'
            onFinish={() => {
              const campusData = { name: newCampusName, code: newCampusCode, timezone: newCampusTimezone, companyId: company._id, phaseType: newCampusPhase, province: newCampusProvince };
              dispatch(createCampus({ campusData, phaseType: unitPhaseType }));
              closeCampusModal();

              if (isRunningOnboarding) {
                dispatch(onboardingSlice.goToOnboardingNextStep(1000));
              }
            }}
          >
            <Form.Item
              label={t('campus.name')}
              required
              name='newCampusName'
              rules={[{ required: true, message: t('campus.nameRequired') }]}
            >
              <LrvInput
                theme='light'
                autoFocus
                value={newCampusName}
                onChange={(e) => setNewCampusName(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              required
              label={t('campus.code')}
              name='newCampusCode'
              rules={[{ required: true, whitespace: true, message: t('campus.codeRequired') }]}
            >
              <LrvInput
                theme='light'
                value={newCampusCode}
                className={styles.inputUpperCase}
                maxLength={MAX_CODES_LENGTH}
                onChange={(e) => setNewCampusCode(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              required
              name='newCampusTimezone'
              label={t('campus.timezone')}
              rules={[{ required: true }]}
              valuePropName='defaultValue'
              initialValue={momentTimezone.tz.guess()}
            >
              <LrvSelect
                theme='light'
                showSearch
                value={newCampusTimezone}
                defaultValue={momentTimezone.tz.guess()}
                onChange={e => setNewCampusTimezone(e)}
                suffixIcon={<Icon name='arrow-down-s' />}
                dropdownMatchSelectWidth={false}
              >
                {childrenTimezone}
              </LrvSelect>
            </Form.Item>

            {!company.isInternational &&
              <Form.Item
                required
                name='newCampusProvince'
                label={t('campus.province')}
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <LrvSelect
                  theme='light'
                  showSearch
                  value={newCampusProvince}
                  onChange={e => setNewCampusProvince(e)}
                  suffixIcon={<Icon name='arrow-down-s' />}
                  dropdownMatchSelectWidth={false}
                >
                  {childrenProvinces}
                </LrvSelect>
              </Form.Item>
            }

            <Form.Item
              required
              name='newCampusPhase'
              label={t('campus.phase')}
              rules={[{ required: true }]}
              valuePropName='defaultValue'
              initialValue={unitPhaseTypes.LARVAE}
            >
              <LrvSelect
                theme='light'
                showSearch
                className={styles.select}
                suffixIcon={<Icon name='arrow-down-s' />}
                placeholder={t('campus.phase')}
                optionFilterProp='children'
                value={newCampusPhase}
                onChange={value => setNewCampusPhase(value)}
                filterOption={filterOptionSelect}
                dropdownMatchSelectWidth={false}
              >
                {
                  Object.keys(unitPhaseTypes).map((key, index) => {
                    const typedKey = key as 'LARVAE' | 'PRODUCTION';

                    return (
                      <Option key={index} value={getKeyValue(unitPhaseTypes, typedKey)}>{t(`stockings.phaseTypes.${typedKey}`)}</Option>
                    );
                  })
                }
              </LrvSelect>
            </Form.Item>
          </LrvForm>
        </LrvModal>

        <LrvModal
          theme='light'
          className='editCampusModal'
          title={t('campus.editCampus')}
          isLoading={isCampusLoading}
          open={showUpdateCampusModal}
          destroyOnClose={true}
          okButtonProps={{
            disabled: checkDisabledButton(),
            form: 'formEditCampus',
            id: 'formEditCampus_btnSave',
          }}
          onOk={() => {
            form.setFieldsValue({
              campusStatus: newCampusStatus,
              campusName: newCampusName,
              campusCode: newCampusCode,
              campusTimezone: newCampusTimezone,
              campusProvince: newCampusProvince,
            });

            form.validateFields()
              .then(() => {
                form.submit();
              });
          }}
          onCancel={() => {
            setShowUpdateCampusModal(false);
          }}
          okText={t('campus.save')}
          cancelText={t('campus.cancel')}
        >
          <LrvForm
            theme='light'
            form={form}
            layout='vertical'
            name='formEditCampus'
            id='formEditCampus'
            onFinish={() => {
              const campusData = { _id: selectedCampus._id, status: newCampusStatus, name: newCampusName, code: newCampusCode, timezone: newCampusTimezone, province: newCampusProvince, companyId: selectedCampus.companyId, phaseType: unitPhaseType };
              dispatch(updateCampus(campusData));
              setNewCampusStatus('');
              setNewCampusName('');
              setNewCampusCode('');
              setNewCampusTimezone('');
              setNewCampusProvince('');
              form.resetFields();
              dispatch(setSelectedCampus({ _id: '', name: '', code: '', timezone: '', companyId: '', country: '', province: '', phaseType: '', status: '' }));
              setShowUpdateCampusModal(false);
            }}
          >
            <Form.Item
              required
              name='campusStatus'
              valuePropName='defaultValue'
              initialValue={newCampusStatus}
            >
              <Row align='middle' justify='space-between' wrap>
                <Row align='middle' justify='start' wrap>
                  <LrvText
                    theme='light'
                    text={selectedCampus.name}
                    className={styles.campusTitle}
                  />
                </Row>
                <Row>
                  <Col>
                    <LrvText
                      theme='light'
                      text={t('campus.active')}
                    />

                  </Col>
                  {(higherRole === roles.CLIENT_MANAGER || higherRole === roles.CLIENT_OPERATOR) ? null :
                    <Col>
                      <LrvSwitch
                        theme='light'
                        className={styles.switch}
                        id='campus_status'
                        checked={newCampusStatus === unitStatuses.ACTIVE}
                        onChange={(value) => {
                          if (!value) {
                            setNewCampusStatus(unitStatuses.ACTIVE);
                          }
                          setNewCampusStatus(value ? unitStatuses.ACTIVE : unitStatuses.INACTIVE);
                        }}
                      />
                    </Col>
                  }
                </Row>
              </Row>
            </Form.Item>

            <Form.Item
              required
              label={t('campus.name')}
              name='campusName'
              rules={[{ required: true, message: t('campus.nameRequired') }]}
              valuePropName='defaultValue'
              initialValue={newCampusName}
            >
              <LrvInput
                theme='light'
                autoFocus
                value={newCampusName}
                onChange={(e) => setNewCampusName(e.target.value)}
                disabled={isCampusInactive && isNewCampusInactive}
              />
            </Form.Item>

            <Form.Item
              required
              label={t('campus.code')}
              name='campusCode'
              rules={[{ required: true, message: t('campus.codeRequired') }]}
              valuePropName='defaultValue'
              initialValue={newCampusCode}
            >
              <LrvInput
                theme='light'
                value={newCampusCode}
                className={styles.inputUpperCase}
                maxLength={MAX_CODES_LENGTH}
                onChange={(e) => setNewCampusCode(e.target.value)}
                disabled={isCampusInactive && isNewCampusInactive}
              />
            </Form.Item>

            <Form.Item
              name='campusTimezone'
              label={t('campus.timezone')}
              rules={[{ required: true }]}
              valuePropName='defaultValue'
              initialValue={newCampusTimezone}
            >
              <LrvSelect
                theme='light'
                showSearch
                value={newCampusTimezone}
                defaultValue={newCampusTimezone}
                onChange={e => setNewCampusTimezone(e)}
                suffixIcon={<Icon name='arrow-down-s' />}
                dropdownMatchSelectWidth={false}
                disabled={isCampusInactive && isNewCampusInactive}
              >
                {childrenTimezone}
              </LrvSelect>
            </Form.Item>

            {!company.isInternational &&
              <Form.Item
                required
                name='campusProvince'
                label={t('campus.province')}
                rules={[{ required: true, message: t('common.requiredField') }]}
                valuePropName='defaultValue'
                initialValue={newCampusProvince}
              >
                <LrvSelect
                  theme='light'
                  showSearch
                  value={newCampusProvince}
                  defaultValue={newCampusProvince}
                  onChange={e => setNewCampusProvince(e)}
                  suffixIcon={<Icon name='arrow-down-s' />}
                  dropdownMatchSelectWidth={false}
                >
                  {childrenProvinces}
                </LrvSelect>
              </Form.Item>}

            <Form.Item
              label={t('campus.phase')}
              name='campusType'
              valuePropName='defaultValue'
              initialValue={t(`stockings.phaseTypes.${newCampusType}`)}
            >
              <LrvInput
                theme='light'
                value={t(`stockings.phaseTypes.${newCampusType}`) + ''}
                readOnly
                style={{ color: 'grey' }}
                disabled={isCampusInactive && isNewCampusInactive}
              />
            </Form.Item>

          </LrvForm>
        </LrvModal>
      </Row>

      <UnitPocParameterForm />
    </div>
  );
};

export default Units;
