import { useEffect, useState } from 'react';

import { getCurrentTheme } from '../../../helpers/theme';
import { roundTwoDecimals } from '../../../config/commons';

import { LrvText } from './LrvText';

interface Props {
  value: number;
  className?: string;
}

export const LrvTextAnimated = (props: Props) => {
  const { className, value } = props;
  const theme = getCurrentTheme();

  const [displayValue, setDisplayValue] = useState(0);

  useEffect(() => {
    const duration = 250;
    const startTime = performance.now();

    const animate = (currentTime: number) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      setDisplayValue(roundTwoDecimals(progress * value));

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  }, [value]);

  return (
    <LrvText text={displayValue} theme={theme} className={className}/>
  );
};
