import { useSelector } from 'react-redux';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

import Header from '../../Header';
import Footer from '../../Footer';
import { Histogram } from '../Histogram';
import { Store } from '../../../../state/store.interfaces';
import { Dataset } from '../../../Analysis/Detail/interfaces';
import { formatLongDateWithZone } from '../../../../utils/date';
import { getDaysDifference, getLatestTransferDate } from '../helpers';
import { getDataset } from '../../../Analysis/Detail/histogram.helpers';
import { roundTwoDecimals, weightUnits } from '../../../../config/commons';

import styles from './TransferData.module.scss';

export const TransferData = () => {
  const [t] = useTranslation();

  const { company } = useSelector((state: Store) => state.header);
  const { histogramWeight, stocking, transfers, transferSummary } = useSelector((state: Store) => state.transferSummaryGraph);

  if ((!histogramWeight || !histogramWeight.limits?.length || !transfers?.length || !transferSummary)) {
    return null;
  }

  const frequency = histogramWeight.frequencies;
  const total = frequency.reduce((accumulator, value) => accumulator + value, 0);
  const dataset: Dataset[] = getDataset(histogramWeight, total);

  const renderTitle = () => {
    const campusName = stocking.campusName?.toLowerCase();
    if (!campusName) {
      return company.name;
    }

    return company.name + ' - ' + t('analysis.campus') + ' ' + campusName.charAt(0).toUpperCase() + campusName.slice(1);
  };

  const renderItem = (props: {value: string | number; label: string; unit?: string}) => {
    const { value, label, unit = '' } = props;

    return (
      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {value} {unit}
        </Text>
        <Text className={styles.field}>
          {label}
        </Text>
      </div>
    );
  };
  
  const renderTransferSummaryData = () => {
    const latestTransferDate = getLatestTransferDate({ transfers });

    return (
      <div className={styles.transferData}>
        <div className={styles.row}>
          {renderItem({ value: stocking.campusName, label: t('transfers.unitName') })}
          {renderItem({ value: stocking.moduleName, label: t('transfers.moduleName') })}
          {renderItem({ value: stocking.tankName, label: t('transfers.tankName') })}
          {renderItem({ value: stocking.name, label: t('transfers.stockingName') })}
          {renderItem({ value: formatLongDateWithZone({ date: stocking.startDateJuvenile }), label: t('transfers.stockingDate') })}
          {renderItem({ value: formatLongDateWithZone({ date: latestTransferDate }), label: t('transfers.latesTransferDate') })}
        </div>

        <div className={styles.row}>
          {renderItem({ value: getDaysDifference({ startDate: stocking.startDateJuvenile, transferDate: latestTransferDate }), label: t('transfers.productionDays') })}
          {renderItem({ value: transferSummary.animalsTransferred, label: t('transfers.animals') })}
          {renderItem({ value: roundTwoDecimals(transferSummary.averageWeight), label: t('transfers.parameters.averageWeight'), unit: weightUnits.G })}
          {renderItem({ value: roundTwoDecimals(transferSummary.transferredWeight), label: t('transfers.parameters.transferredInCapsule'), unit: weightUnits.KG })}
          {renderItem({ value: roundTwoDecimals(transferSummary.uniformityWeight), label: t('transfers.parameters.uniformityWeight'), unit: '%' })}
          {renderItem({ value: roundTwoDecimals(transferSummary.variationCoefficientWeight), label: t('transfers.parameters.variationCoefficientWeight'), unit: '%' })}
        </div>
      </div>
    );
  };

  const renderTransferData = () => {
    const items: JSX.Element[] = [];

    for (let index = 0; index < transfers.length; index++) {
      const transfer = transfers[index];

      const item = (
        <div className={styles.transferData}>
          <div className={styles.transferName}> {t('transfers.transferName')} {transfer.name}</div>

          <div className={styles.row}>
            {renderItem({ value: formatLongDateWithZone({ date: transfer.transferDate }), label: t('transfers.transferDate') })}
            {renderItem({ value: transfer.animalsTransferred, label: t('transfers.animals') })}
            {renderItem({ value: roundTwoDecimals(transfer.averageWeight), label: t('transfers.parameters.averageWeight'), unit: weightUnits.G })}
            {renderItem({ value: roundTwoDecimals(transfer.transferredWeight), label: t('transfers.parameters.transferredInCapsule'), unit: weightUnits.KG })}

            {renderItem({ value: roundTwoDecimals(transfer.uniformityWeight), label: t('transfers.parameters.uniformityWeight'), unit: '%' })}
            {renderItem({ value: roundTwoDecimals(transfer.variationCoefficientWeight), label: t('transfers.parameters.variationCoefficientWeight'), unit: '%' })}
            {renderItem({ value: `${transfer.animalsSmallerThan}%`, label: `${t('transfers.parameters.animalsSmallerThan', { value: transfer.maxSmallAnimalsWeight })} ${weightUnits.G}` })}
          </div>
        </div>
      );
      items.push(item);
    }

    return items;
  };

  return (
    <div className={styles.container}>
      <Header
        title={renderTitle()}
        subtitle={t('transfers.summary')}
      />

      {renderTransferSummaryData()}

      <div className={styles.histogram}>
        <Histogram
          histogram='transfer'
          primaryLimits={histogramWeight.limits}
          primaryDataset={dataset}
          secondaryLimits={[]}
          secondaryDataset={[]}
          renderPdf={true}
          width={1120}
          height={860}
        />
      </div>

      {renderTransferData()}

      <Footer/>
    </div>
  );
};
