export const getBackgroundColorCell = (count: number) => {
  switch (count) {
    case 0:
      return 'transparent';

    case 1:
      return '#D4EADC';

    case 2:
      return '#C1E1CC';

    case 3:
      return '#AED7BD';

    case 4:
      return '#9BCEAD';

    case 5:
      return '#88C59E';

    case 6:
      return '#75BC8E';

    case 7:
      return '#61B27F';
  
    default:
      return '#4EA970';
  }
};
