import moment from 'moment';
import { cloneDeep } from 'lodash';
import { Col, Form, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { filterOptionSelect } from '../../utils/select';
import { applyThousandsSeparator } from '../../utils/strings';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import ActionButton from '../../common/components/buttons/ActionButton';
import { LrvButton } from '../../common/components/LrvButton/LrvButton';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import { LrvDivider } from '../../common/components/LrvDivider/LrvDivider';
import { LrvCheckbox } from '../../common/components/LrvCheckbox/LrvCheckbox';
import { getStartDate } from '../../common/components/charts/ShadedPlot/helpers';
import { LrvDatePicker } from '../../common/components/LrvDatePicker/LrvDatePicker';
import { LrvInputNumber } from '../../common/components/LrvInputNumber/LrvInputNumber';
import { disabledEndDate, getUnitPhaseTypeFromStocking } from '../../helpers/stocking.helpers';
import { containerTypes, DATE_FORMATS, roundTwoDecimals, stockingPhaseTypes, stockingStatuses, transferTypes, unitStatuses, weightUnits } from '../../config/commons';
import { isNumber } from '../../utils/validations';

import styles from './FinishStocking.module.scss';
import * as finishStockingSlice from './finishStockingSlice';
import { defaultDestinationStocking } from './sowings.helpers';
import DestinationStockingForm from './DestinationStockingForm';
import * as transferStockingSlice from './transferStockingSlice';
import { DataDestinationStocking, TransferStockingDataProps, TransferStockingProps } from './interfaces';

interface Props {
  theme?: 'dark' | 'light';
  pathname: string;
}

const { Option } = Select;

export const TransferForm = (props: Props) => {
  const { theme = 'dark', pathname } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [formTransferStocking] = Form.useForm();

  const {
    plgHarvest: plgHarvestDefault,
    averageWeight,
    showModalFinish: showModal,
    isLoadingFinish,
    dataDestinationStockings,
    stockingFinishedData,
  } = useSelector((state: Store) => state.finishStocking);

  const { company, phaseType } = useSelector((state: Store) => state.header);
  const { stockingsToShow } = useSelector((state: Store) => state.stockings.filters);
  const { isLoadingTransfer } = useSelector((state: Store) => state.transferStocking);
  const { selectedStocking, selectedCampus, selectedModuleId, selectedTankId, currentPage } = useSelector((state: Store) => state.stockings);

  const [endDate, setEndDate] = useState<moment.Moment>(moment());

  const [fullTransfer, setFullTransfer] = useState<boolean>(true);
  const [newStockingPhaseType, setNewStockingPhaseType] = useState<string>('');

  const isCampusInactive = selectedStocking.campusId.status === unitStatuses.INACTIVE;

  /* useEffect section */
  useEffect(() => {
    const fillFormFinishStocking = () => {
      const endDateStocking = moment();
      setEndDate(endDateStocking);

      formTransferStocking.setFieldsValue({
        endDate: endDateStocking,
        phaseType: '',
      });
    };

    if (selectedStocking._id !== '' && showModal === true) {
      fillFormFinishStocking();
    }
  }, [dispatch, formTransferStocking, averageWeight, plgHarvestDefault, selectedStocking, showModal]);

  useEffect(() => {
    if (!selectedStocking?._id || !showModal) {
      return;
    }
  
    const plgTransferred = selectedStocking.status === stockingStatuses.ACTIVE || selectedStocking.status === transferTypes.PARTIAL_TRANSFER ? plgHarvestDefault : undefined;
    const averageHarvestedWeight = selectedStocking.status === stockingStatuses.ACTIVE || selectedStocking.status === transferTypes.PARTIAL_TRANSFER ? averageWeight : 0;
    const dataDestinationStockingCopy = cloneDeep(dataDestinationStockings);
    dataDestinationStockingCopy[0].plgTransferred = plgTransferred;
    dataDestinationStockingCopy[0].averageHarvestedWeight = averageHarvestedWeight;
    
    dispatch(finishStockingSlice.setDataDestinationStocking([defaultDestinationStocking]));
  }, [dispatch, plgHarvestDefault, averageWeight, selectedStocking, showModal]);
    
  useEffect(() => {
    if (!showModal) {
      formTransferStocking.resetFields();
    }
  }, [showModal]);

  useEffect(() => {
    if (phaseType === stockingPhaseTypes.ADULT) {
      onChangeStockingPhaseType(stockingPhaseTypes.ADULT);
      return;
    }

    setNewStockingPhaseType('');
    dispatch(finishStockingSlice.setDataDestinationStocking([defaultDestinationStocking]));
  }, [phaseType]);
  /* End of useEffect section */

  const isValidAverageHarvestedWeight = (value: string | number) => {
    switch (selectedStocking.phaseType) {
      case stockingPhaseTypes.LARVAE:
        return (isNumber(value) && Number(value) >= stockingFinishedData.larvae.larvaePerGram.min && Number(value) <= stockingFinishedData.larvae.larvaePerGram.max);
  
      case stockingPhaseTypes.JUVENILE:
        return (isNumber(value) && Number(value) >= stockingFinishedData.juvenile.averageHarvestedWeight.min);
  
      case stockingPhaseTypes.ADULT:
        return (isNumber(value) && Number(value) >= stockingFinishedData.growOut.averageHarvestedWeight.min);
  
      default:
        return false;
    }
  };

  /* Functions section */
  const isValidDestinationStocking = (dataDestinationStocking: DataDestinationStocking) => {
    const isValidPlg = selectedStocking.phaseType === stockingPhaseTypes.LARVAE ? !!dataDestinationStocking.plgTransferred : true;
    const filledValues = !!dataDestinationStocking.animalsNumber && isValidPlg &&
      !!dataDestinationStocking.campusId && !!dataDestinationStocking.moduleId && !!dataDestinationStocking.tankId &&
      !!dataDestinationStocking.code && !!dataDestinationStocking.name && !!dataDestinationStocking.volume;

    const { averageHarvestedWeight } = dataDestinationStocking;
    const validAverageHarvestedWeight = !!averageHarvestedWeight && isValidAverageHarvestedWeight(averageHarvestedWeight);

    return filledValues && validAverageHarvestedWeight;
  };

  const isValidDataDestinationStocking = () => {
    let isValid = true;

    for (let index = 0; index < dataDestinationStockings.length; index++) {
      const dataDestinationStocking = dataDestinationStockings[index];
      const { animalsNumber, averageHarvestedWeight, plgTransferred, transferRecord } = dataDestinationStocking;
      if (dataDestinationStocking.stockingId) {
        const isValidPlg = selectedStocking.phaseType === stockingPhaseTypes.LARVAE ? !!plgTransferred : true;
        const isTransferRecord = transferRecord === true;
        isValid = isTransferRecord && isValidPlg && isValidAverageHarvestedWeight(averageHarvestedWeight) && !!animalsNumber;
        // eslint-disable-next-line max-depth
        if (!isValid) {
          break;
        }
        continue;
      }

      if (!isValidDestinationStocking(dataDestinationStocking)) {
        isValid = false;
        break;
      }
    }

    return isValid;
  };

  const getDefaultDestinationStocking = () => {
    const plgTransferred = selectedStocking.status === stockingStatuses.ACTIVE || selectedStocking.status === transferTypes.PARTIAL_TRANSFER ? plgHarvestDefault : 0;
    const averageHarvestedWeight = selectedStocking.status === stockingStatuses.ACTIVE || selectedStocking.status === transferTypes.PARTIAL_TRANSFER ? averageWeight : 0;
    let newDataDestinationStocking = defaultDestinationStocking;
    newDataDestinationStocking = {
      ...newDataDestinationStocking,
      averageHarvestedWeight,
      plgTransferred,
    };
    return newDataDestinationStocking;
  };

  const addDestinationStocking = () => {
    const data: DataDestinationStocking[] = [...dataDestinationStockings];
    const newDataDestinationStocking = getDefaultDestinationStocking();
    data.push(newDataDestinationStocking);
    dispatch(finishStockingSlice.setDataDestinationStocking(data));
  };

  const onChangeStockingPhaseType = (value: string) => {
    setNewStockingPhaseType(value);
    const defaultDataDestinationStocking = getDefaultDestinationStocking();
    dispatch(finishStockingSlice.setDataDestinationStocking([defaultDataDestinationStocking]));
    const unitPhaseType = getUnitPhaseTypeFromStocking(value);
    dispatch(transferStockingSlice.fetchCampuses({ companyId: company._id, phaseType: unitPhaseType }));
  };

  const isValidTransferForm = () => {
    const endDate = formTransferStocking.getFieldValue('endDate');
    return !!endDate && isValidDataDestinationStocking();
  };

  const disabledFinishForm = () => {
    if (isCampusInactive) {
      return true;
    }

    return !isValidTransferForm();
  };

  const getStartDateTransfer = (props: { endDate: string }) => {
    const { endDate } = props;

    if (selectedStocking.phaseType === newStockingPhaseType) {
      switch (selectedStocking.phaseType) {
        case stockingPhaseTypes.LARVAE:
          return selectedStocking.startDate;

        case stockingPhaseTypes.JUVENILE:
          return selectedStocking.startDateJuvenile;

        case stockingPhaseTypes.ADULT:
          return selectedStocking.startDateGrowOut;
      }
    }

    return endDate;
  };

  const injectVolume = (props: { data: DataDestinationStocking; tankType?: string; volume?: number }) => {
    const { data, tankType, volume } = props;

    switch (tankType) {
      case containerTypes.TANK:
        data.litersNumber = Number(volume);
        break;

      case containerTypes.RACEWAY:
        data.cubicMeters = Number(volume);
        break;

      case containerTypes.PRE_HATCHERY:
      case containerTypes.POOL:
        data.hectares = Number(volume);
        break;
    }

    return data;
  };
  const getDataDestinationStockings = () => {
    const date = endDate.format(DATE_FORMATS.YYYY_MM_DD).toString();

    return dataDestinationStockings.map((destinationStocking) => {
      const data: DataDestinationStocking = {
        customStage: selectedStocking.phaseType === stockingPhaseTypes.LARVAE && selectedStocking.phaseType === newStockingPhaseType ? selectedStocking.customStage : undefined,
        animalsNumber: destinationStocking.animalsNumber,
        campusId: destinationStocking.campusId,
        code: destinationStocking.code,
        moduleId: destinationStocking.moduleId,
        name: destinationStocking.name,
        startDate: getStartDateTransfer({ endDate: date }),
        tankId: destinationStocking.tankId,
        stockingId: destinationStocking.stockingId,
        averageHarvestedWeight: destinationStocking.averageHarvestedWeight,
        kilogramsTransferred: destinationStocking?.kilogramsTransferred,
        poundsTransferred: destinationStocking?.poundsTransferred,
        plgTransferred: selectedStocking.phaseType === stockingPhaseTypes.LARVAE ? destinationStocking.plgTransferred : undefined,
      };

      return injectVolume({ data, tankType: destinationStocking.tankType, volume: destinationStocking.volume });
    });
  };

  const getStockingData = () => {
    const date = endDate.format(DATE_FORMATS.YYYY_MM_DD).toString();

    const stockingData: TransferStockingDataProps = {
      originPhaseType: selectedStocking.phaseType,
      destinationPhaseType: newStockingPhaseType,
      transferDate: date,
      status: fullTransfer ? transferTypes.FULL_TRANSFER : transferTypes.PARTIAL_TRANSFER,
      destinationStockings: getDataDestinationStockings(),
    };

    return stockingData;
  };

  const onSubmit = () => {
    const paramsToFetchStocking = { companyId: company._id, campusId: selectedCampus?._id, moduleId: selectedModuleId, tankId: selectedTankId, page: currentPage, phaseType: selectedStocking.phaseType, stockingsToShow };
    const makeFetchStockings = pathname === '/production/stockings';
    const stockingData: TransferStockingDataProps = getStockingData();

    const params: TransferStockingProps = {
      stockingId: selectedStocking?._id,
      stockingData,
      makeFetchStockings,
      paramsToFetchStocking,
    };

    dispatch(transferStockingSlice.transferStocking(params));
  };
  /* End of functions section */

  /* Rendered functions */
  const renderNewDestinationStockingButton = () => {
    return (
      <Row align='middle' justify='end'>
        <ActionButton
          id='btn_new_reference_curve'
          className={styles.button}
          icon={<Icon name='add' />}
          onClick={addDestinationStocking}
          disabled={!isValidDataDestinationStocking()}
          theme='light'
          type='ghost'
        >
          {t('referenceCurve.new')}
        </ActionButton>
      </Row>
    );
  };

  const renderPhaseDropdownOptions = () => {
    switch (selectedStocking.phaseType) {
      case stockingPhaseTypes.LARVAE: {
        return Object.values(stockingPhaseTypes).map((phaseType) =>
          <Option key={phaseType} value={phaseType}>{t(`stockings.phaseTypes.${phaseType}`)}</Option>
        );
      }

      case stockingPhaseTypes.JUVENILE: {
        return (
          <>
            <Option key={stockingPhaseTypes.JUVENILE} value={stockingPhaseTypes.JUVENILE}>{t(`stockings.phaseTypes.${stockingPhaseTypes.JUVENILE}`)}</Option>
            <Option key={stockingPhaseTypes.ADULT} value={stockingPhaseTypes.ADULT}>{t(`stockings.phaseTypes.${stockingPhaseTypes.ADULT}`)}</Option>
          </>
        );
      }

      default:
        return null;
    }
  };

  const renderPhaseDropdown = () => {
    if (selectedStocking.phaseType === stockingPhaseTypes.ADULT) {
      return ;
    }

    return (
      <Col span={12}>
        <Form.Item
          name='phaseType'
          label={t('stockings.phaseType')}
          required
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <LrvSelect
            theme={theme}
            showSearch
            value={newStockingPhaseType}
            onChange={onChangeStockingPhaseType}
            suffixIcon={<Icon name='arrow-down-s' />}
            filterOption={filterOptionSelect}
            dropdownMatchSelectWidth={false}
          >
            {renderPhaseDropdownOptions()}
          </LrvSelect>
        </Form.Item>
      </Col>
    );
  };

  const renderBodyDestinationStockings = () => {
    return dataDestinationStockings.map((destinationStocking, index) => {
      return (
        <DestinationStockingForm destinationStocking={destinationStocking} index={index} theme={theme} newStockingPhaseType={newStockingPhaseType} />
      );
    });
  };

  const renderFinishStocking = () => {
    return (
      <Form.Item
        name='finishStocking'
      >
        <LrvCheckbox
          theme={theme}
          checked={fullTransfer}
          onChange={event => {
            const checked = event.target.checked;
            setFullTransfer(checked);
          }}
        >
          <LrvText text={t('stockings.radio.close')} theme={theme} />
        </LrvCheckbox>
        {
          fullTransfer && <div className={styles.closeInfoMessage}>
            <LrvText text={t('stockings.closeInfoMessage')} theme={theme} />
          </div>
        }
      </Form.Item>
    );
  };

  const renderTotalKilogramsTransferred = () => {
    const label = 'stockings.finishStockingLabels.totalKilogramsTransferred';
    let totalKilogramsTransferred = dataDestinationStockings.reduce((sum, item) => (sum + (item?.kilogramsTransferred ?? 0)), 0);
    totalKilogramsTransferred = roundTwoDecimals(totalKilogramsTransferred);
    const hidden = dataDestinationStockings.some(item => !item?.kilogramsTransferred || item?.kilogramsTransferred === 0);

    return (
      <Col
        span={12}
        hidden={hidden}
      >
        <Form.Item
          label={t(label)}
        >
          <LrvInputNumber
            readOnly
            theme={theme}
            value={applyThousandsSeparator(totalKilogramsTransferred)}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderTotalPoundsTransferred = () => {
    const label = 'stockings.finishStockingLabels.totalPoundsTransferred';
    let totalPoundsTransferred = dataDestinationStockings.reduce((sum, item) => (sum + (item?.poundsTransferred ?? 0)), 0);
    totalPoundsTransferred = roundTwoDecimals(totalPoundsTransferred);
    const hidden = dataDestinationStockings.some(item => !item?.poundsTransferred || item?.poundsTransferred === 0);

    return (
      <Col
        span={12}
        hidden={hidden}
      >
        <Form.Item
          label={t(label)}
        >
          <LrvInputNumber
            readOnly
            theme={theme}
            value={applyThousandsSeparator(totalPoundsTransferred)}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderTotalAverageHarvestedWeight = () => {
    const unit = phaseType === stockingPhaseTypes.LARVAE ? weightUnits.MG : weightUnits.G;
    const label = 'stockings.finishStockingLabels.transferWeight';
    const totalAnimalsTransferred = dataDestinationStockings.reduce((sum, item) => (sum + item.animalsNumber), 0);
    const sumProductAverageWeight = dataDestinationStockings.reduce((sum, item) => (sum + (item.animalsNumber * item.averageHarvestedWeight)), 0);

    let weightedAverageWeight = 0;
    if (sumProductAverageWeight !== 0) {
      weightedAverageWeight = roundTwoDecimals(sumProductAverageWeight / totalAnimalsTransferred);
    }

    return (
      <Col span={12}>
        <Form.Item
          label={`${t(label)} ${unit}`}
        >
          <LrvInputNumber
            readOnly
            theme={theme}
            value={applyThousandsSeparator(weightedAverageWeight)}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderTotalAnimalsTransferred = () => {
    const label = 'stockings.finishStockingLabels.totalAnimalsTransferred';
    const totalAnimalsTransferred = dataDestinationStockings.reduce((sum, item) => (sum + item.animalsNumber), 0);

    return (
      <Col span={12}>
        <Form.Item
          label={t(label)}
        >
          <LrvInputNumber
            readOnly
            theme={theme}
            value={applyThousandsSeparator(totalAnimalsTransferred)}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderTransferFooter = () => {
    const showTransferFooter = dataDestinationStockings.length > 1;

    return showTransferFooter && (
      <>
        <LrvDivider theme={theme} />
        <Row gutter={16}>
          {renderTotalKilogramsTransferred()}
          {renderTotalPoundsTransferred()}
          {renderTotalAverageHarvestedWeight()}
          {renderTotalAnimalsTransferred()}
        </Row>
      </>
    );
  };

  const renderDestinationStocking = () => {
    return (
      <>
        <LrvDivider theme={theme} />
        <Row className={styles.titleNewStocking} align='middle' justify='space-between'>
          <LrvText text={t('stockings.finishStockingLabels.destinationStocking')} theme={theme} />
        </Row>

        {renderBodyDestinationStockings()}
        {renderNewDestinationStockingButton()}
        {renderTransferFooter()}
        {renderFinishStocking()}
      </>
    );
  };

  const renderEndDateInput = () => {
    const span = selectedStocking.phaseType === stockingPhaseTypes.ADULT ? 24 : 12;

    return (
      <Col span={span}>
        <Form.Item
          name='endDate'
          label={t('stockings.transferDate')}
          required
          rules={[{ required: true, message: t('stockings.form.endDate') }]}
        >
          <LrvDatePicker
            theme={theme}
            placeholder={t('stockings.selectEndDate')}
            disabledDate={(currentDate) => disabledEndDate({ currentDate, stockingStartDate: getStartDate(selectedStocking) })}
            value={moment(endDate)}
            onChange={(date) => setEndDate(date ?? moment())}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderCancelButton = () => {
    return (
      <LrvButton
        className={styles.cancelButton}
        theme={theme}
        type='text'
        onClick={() => {
          formTransferStocking.resetFields();
          dispatch(finishStockingSlice.setShowModalFinish(false));
          const defaultDataDestinationStocking = getDefaultDestinationStocking();
          dispatch(finishStockingSlice.setDataDestinationStocking([defaultDataDestinationStocking]));
        }}
      >
        <LrvText theme={theme} text={t('stockings.cancel')} />
      </LrvButton>
    );
  };

  const renderSubmitButton = () => {
    return (
      <ActionButton
        className={styles.submitButton}
        theme='light'
        type='primary'
        htmlType='submit'
        onClick={onSubmit}
        disabled={disabledFinishForm()}
        loading={isLoadingFinish || isLoadingTransfer}
      >
        <LrvText theme='dark' text={t('stockings.finishStockingLabels.transfer')} />
      </ActionButton>
    );
  };
  /* End of rendered functions */

  return (
    <div className={styles.formContainer}>
      <LrvForm
        className={styles.formTransferStocking}
        theme={theme}
        form={formTransferStocking}
        name='formTransferStocking'
        id='formTransferStocking'
        layout='vertical'
      >
        <Row gutter={16}>
          {renderEndDateInput()}
          {renderPhaseDropdown()}
        </Row>
        {renderDestinationStocking()}
      </LrvForm>

      <div className={styles.buttonsContainer}>
        {renderCancelButton()}
        {renderSubmitButton()}
      </div>
    </div>
  );
};
