import cx from 'classnames';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

import { Store } from '../../../state/store.interfaces';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { THEME, weightUnits, weightUnitsByCompany } from '../../../config/commons';
import { LrvTextAnimated } from '../../../common/components/LrvText/LrvTextAnimated';

import styles from './ProgressBar.module.scss';

interface Props {
  theme?: 'light' | 'dark';
  biomass: number;
  color: string;
  percentage: number;
}

export const ProgressBar = (props: Props) => {
  const { theme = 'light', biomass, color, percentage } = props;

  const isLightTheme = theme === THEME.LIGHT;

  const barRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const [isInside, setIsInside] = useState(true);

  const { company } = useSelector((state: Store) => state.header);

  useEffect(() => {
    const updateTextPosition = () => {
      setTimeout(() => {
        if (barRef.current && textRef.current) {
          const barWidth = barRef.current.offsetWidth;
          const textWidth = textRef.current.offsetWidth;
          const isInside = textWidth + 20 < barWidth;

          setIsInside(isInside);
        }
      }, 0);
    };

    const resizeObserver = new ResizeObserver(updateTextPosition);
    if (barRef.current) {
      resizeObserver.observe(barRef.current);
    }

    updateTextPosition();

    return () => {
      resizeObserver.disconnect();
    };
  }, [biomass, percentage, barRef.current, textRef.current]);

  return (
    <div className={cx(styles.container, isLightTheme ? styles.lightContainer : styles.darkContainer)}>
      <div
        ref={barRef}
        className={styles.progress}
        style={{ width: `${percentage}%`, backgroundColor: color }}
      >
        <div
          ref={textRef}
          className={cx(styles.containerBiomass, { [styles.outside]: !isInside })}
        >
          <LrvTextAnimated
            value={biomass}
            className={cx(styles.biomass, styles.biomassColor)}
          />&nbsp;

          <LrvText
            className={cx(styles.biomassUnit, styles.biomassUnitColor)}
            text={`${company.weightUnit === weightUnitsByCompany.KILOGRAM ? weightUnits.KG : weightUnits.LB}`}
            theme={theme}
          />
        </div>
      </div>
    </div>
  );
};
