import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import { LrvForm } from '../../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../../common/components/LrvInput/LrvInput';
import { LrvSwitch } from '../../../common/components/LrvSwitch/LrvSwitch';

import { Packer } from './interfaces';
import * as packerSlice from './packerSlice';
import styles from './EditPackerForm.module.scss';

interface Props {
  theme?: 'dark' | 'light';
}

export const EditPackerForm = (props: Props) => {
  const { theme = 'dark' } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const { company } = useSelector((state: Store) => state.header);
  const {
    showUpdatePackerModal,
    isUpdating,
    packer,
  } = useSelector((state: Store) => state.packers);

  const [active, setActive] = useState<boolean>();
  const [name, setName] = useState<string>();

  const companyId = company._id;

  useEffect(() => {
    if (!packer?._id) {
      return;
    }

    setName(packer.name);
    setActive(packer.active);

    form.setFieldsValue({
      active,
      name: packer.name,
    });
  }, [form, packer]);

  const closeModal = () => {
    form.resetFields();
    setName(undefined);
    dispatch(packerSlice.setShowUpdatePackerModal(false));
  };

  const updatePacker = () => {
    if (!name) {
      return;
    }

    const body: Packer = {
      _id: packer?._id,
      companyId,
      name,
      active,
    };

    const props = {
      body,
      onSuccess: closeModal,
    };

    dispatch(packerSlice.updatePacker(props));
  };

  const renderPackerName = () => {
    return (
      <Form.Item
        name='name'
        label={t('packers.name')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvInput
          theme={theme}
          value={name}
          placeholder={t('packers.name')}
          onChange={(e) => setName(e.target.value)}
        />
      </Form.Item>
    );
  };

  const renderSwitch = () => {
    return (
      <Form.Item>
        <div className={styles.switch}>
          <div className={styles.status}>
            <LrvText text={t('packers.active')} theme={theme} />
          </div>

          <LrvSwitch
            theme={theme}
            checked={active}
            onChange={(value) => setActive(value)}
          />
        </div>
      </Form.Item>
    );
  };

  return (
    <LrvModal
      theme={theme}
      title={t('packers.editPacker')}
      open={showUpdatePackerModal}
      destroyOnClose={true}
      okButtonProps={{
        id: 'submit-packer',
        htmlType: 'submit',
        form: 'form',
        loading: isUpdating,
        disabled: !name,
      }}
      onOk={updatePacker}
      okText={t('packers.edit')}
      cancelText={t('packers.cancel')}
      onCancel={closeModal}
      className={styles.editPacker}
    >
      <LrvForm
        theme={theme}
        form={form}
        name='form'
        id='form-edit-packer'
        layout='vertical'
      >
        {renderSwitch()}
        {renderPackerName()}
      </LrvForm>
    </LrvModal>
  );
};