import * as d3 from 'd3';
import cx from 'classnames';

import { getCurrentTheme } from '../../../../helpers/theme';
import { Company } from '../../../../pages/AppHeader/interfaces';
import { THEME, weightUnitsByCompany } from '../../../../config/commons';
import { ChartParameter, IPoint, PredictionPoint } from '../../../../pages/OptimalHarvestPoint/interfaces';

import styles from './MiniForecastMetricD3.module.scss';
import { generateLines, getYDomainData, renderTickFormat } from './helpers';

const TICKS_COUNT = 3;
const TICK_PADDING = -10;
const TIME_TRANSITION = 300;

interface Props {
  companyData: Company;
  container: HTMLDivElement | null;
  allPredictions: PredictionPoint[];
  chartParameter: string;
  firstStage: number;
  lastStage: number;
  height: number;
  width: number;
  packerId?: string;
}

interface RefreshProps {
  lastStage: number;
  firstStage: number;
  chartParameter: string;
  allPredictions: PredictionPoint[];
  companyData: Company;
  packerId?: string;
  height: number;
  width: number;
}

export class MiniForecastMetricD3 {
  container: HTMLDivElement | null;
  svg: d3.Selection<SVGSVGElement, IPoint, null, undefined>;
  groupMain: d3.Selection<SVGGElement, IPoint, null, undefined>;

  pathShadow: d3.Selection<SVGPathElement, PredictionPoint[], null, undefined> = d3.select<SVGPathElement, PredictionPoint[]>(document.createElementNS('http://www.w3.org/2000/svg', 'path'));
  pathShadowSecondary: d3.Selection<SVGPathElement, PredictionPoint[], null, undefined> = d3.select<SVGPathElement, PredictionPoint[]>(document.createElementNS('http://www.w3.org/2000/svg', 'path'));
  pathLine: d3.Selection<SVGPathElement, PredictionPoint[], null, undefined> = d3.select<SVGPathElement, PredictionPoint[]>(document.createElementNS('http://www.w3.org/2000/svg', 'path'));
  pathLineSecondary: d3.Selection<SVGPathElement, PredictionPoint[], null, undefined> = d3.select<SVGPathElement, PredictionPoint[]>(document.createElementNS('http://www.w3.org/2000/svg', 'path'));
  scaleLinearX: d3.ScaleLinear<number, number, never> = d3.scaleLinear();
  scaleLinearLeftY: d3.ScaleLinear<number, number, never> = d3.scaleLinear();
  scaleLinearRightY: d3.ScaleLinear<number, number, never> = d3.scaleLinear();

  margin = { top: 0, right: 2, bottom: 0, left: 2 };

  allPredictions: PredictionPoint[] = [];

  width: number;
  height: number;
  xAxis: d3.Selection<SVGGElement, IPoint, null, undefined> = d3.select<SVGGElement, IPoint>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  firstStage = 0;
  lastStage = 0;
  companyData: Company;
  chartParameter: string;
  packerId?: string;

  // eslint-disable-next-line
  constructor(props: Props) {
    const {
      allPredictions,
      chartParameter,
      companyData,
      container,
      firstStage,
      lastStage,
      height,
      width,
      packerId,
    } = props;

    this.container = container;
    this.chartParameter = chartParameter;
    this.allPredictions = allPredictions;
    this.companyData = companyData;
    this.packerId = packerId;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    d3.select(container).select('svg').remove();

    this.svg = d3.select<HTMLDivElement | null, IPoint>(container)
      .append('svg')
      .attr('id', 'svg')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom);

    this.groupMain = this.svg
      .append('g')
      .attr('id', 'content')
      .attr('transform', `translate( ${this.margin.left}, ${this.margin.top / 1.2} )`);

    this.firstStage = firstStage;
    this.lastStage = lastStage;

    this.createDataPoints();
  }

  createDataPoints = () => {
    this.buildAxisX();
    this.buildAxisLeftY();
    this.buildAxisRightY();
    
    this.renderCostPerDayLine();
    this.renderLine();
    this.drawXAxis();
    this.deleteDomainAxisX();
  };

  updateAxis = () => {
    const { height } = this;
    
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;
    
    this.buildAxisX();
    this.buildAxisLeftY();
    this.buildAxisRightY();

    const axisBottom = d3.axisBottom(this.scaleLinearX)
      .tickFormat((x) => renderTickFormat(x))
      .tickSize(-height)
      .ticks(TICKS_COUNT)
      .tickPadding(TICK_PADDING);

    this.xAxis
      .attr('class', cx(styles.axisX, isLightTheme ? styles.axisLight : styles.axisDark))
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisBottom);

    this.updatePositionLabelAxisX();
  };

  updateDataPoints = () => {
    this.updateAxis();
    this.updateCostPerDayLine();
    this.updateLine();
    this.deleteDomainAxisX();
  };

  refreshChart = (props: RefreshProps) => {
    const { allPredictions, companyData, chartParameter, firstStage, lastStage, packerId, width, height } = props;

    this.allPredictions = allPredictions;
    this.firstStage = firstStage;
    this.lastStage = lastStage;
    this.chartParameter = chartParameter;
    this.companyData = companyData;
    this.packerId = packerId;

    this.updateSize(width, height);
    this.updateDataPoints();
  };

  buildAxisX = () => {
    const { width, firstStage, lastStage } = this;

    const minX = firstStage;
    const maxX = lastStage;

    this.scaleLinearX = d3.scaleLinear()
      .domain([minX, maxX])
      .range([0, width]);
  };

  buildAxisLeftY = () => {
    const { height, allPredictions, packerId, chartParameter } = this;
    const { maxY, minY } = getYDomainData({ allPredictions, packerId, chartParameter });

    this.scaleLinearLeftY = d3.scaleLinear()
      .domain([minY, maxY])
      .range([height, 0]);
  };

  buildAxisRightY = () => {
    const { companyData, height, allPredictions, packerId } = this;

    const chartParameter = companyData.weightUnit === weightUnitsByCompany.KILOGRAM ? ChartParameter.COST_PER_DAY_KG : ChartParameter.COST_PER_DAY_LB;
    const { maxY, minY } = getYDomainData({ allPredictions, packerId, chartParameter });

    this.scaleLinearRightY = d3.scaleLinear()
      .domain([minY, maxY])
      .range([height, 0]);
  };

  renderLine = () => {
    const { groupMain, allPredictions, chartParameter, packerId, scaleLinearX, scaleLinearLeftY } = this;
    const { lineCurve } = generateLines({ scaleLinearX, scaleLinearLeftY, chartParameter, packerId });

    this.pathShadow = groupMain
      .append('path')
      .datum(allPredictions)
      .attr('class', cx(styles.shadowLine, 'shadowLine1'))
      .attr('d', lineCurve);

    this.pathLine = groupMain
      .append('path')
      .datum(allPredictions)
      .attr('class', styles.line)
      .attr('d', lineCurve);
  };

  renderCostPerDayLine = () => {
    const { companyData, groupMain, allPredictions, packerId, scaleLinearX, scaleLinearLeftY, scaleLinearRightY } = this;

    if (this.chartParameter !== ChartParameter.TOTAL_ACCUMULATED_COST) {
      return;
    }

    const chartParameter = companyData.weightUnit === weightUnitsByCompany.KILOGRAM ? ChartParameter.COST_PER_DAY_KG : ChartParameter.COST_PER_DAY_LB;
    const { lineCurve } = generateLines({ scaleLinearX, scaleLinearLeftY, scaleLinearRightY, chartParameter, packerId });
    
    this.pathShadowSecondary = groupMain
      .append('path')
      .datum(allPredictions)
      .attr('class', cx(styles.shadowLine, 'shadowLine2'))
      .attr('d', lineCurve);

    this.pathLineSecondary = groupMain
      .append('path')
      .datum(allPredictions)
      .attr('class', cx(styles.costPerDayLine, 'costPerDayLine'))
      .attr('d', lineCurve);
  };

  updateLine = () => {
    const { allPredictions, chartParameter, packerId, scaleLinearX, scaleLinearLeftY } = this;
    const { lineCurve } = generateLines({ scaleLinearX, scaleLinearLeftY, chartParameter, packerId });

    this.pathShadow
      .datum(allPredictions)
      .transition()
      .duration(TIME_TRANSITION)
      .attr('d', lineCurve);

    this.pathLine
      .datum(allPredictions)
      .transition()
      .duration(TIME_TRANSITION)
      .attr('d', lineCurve);
  };

  updateCostPerDayLine = () => {
    const { companyData, allPredictions, packerId, scaleLinearX, scaleLinearLeftY, scaleLinearRightY } = this;

    if (this.chartParameter !== ChartParameter.TOTAL_ACCUMULATED_COST) {
      return;
    }

    const chartParameter = companyData.weightUnit === weightUnitsByCompany.KILOGRAM ? ChartParameter.COST_PER_DAY_KG : ChartParameter.COST_PER_DAY_LB;
    const { lineCurve } = generateLines({ scaleLinearX, scaleLinearLeftY, scaleLinearRightY, chartParameter, packerId });

    this.pathShadowSecondary
      .datum(allPredictions)
      .transition()
      .duration(TIME_TRANSITION)
      .attr('d', lineCurve);
    
    this.pathLineSecondary
      .datum(allPredictions)
      .transition()
      .duration(TIME_TRANSITION)
      .attr('d', lineCurve);
  };

  deleteDomainAxisX = () => {
    const { container } = this;
    d3.select(container).select('#content').select('#axisX').selectAll('.domain').remove();
  };

  drawXAxis = () => {
    const { height, groupMain } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    const axis = d3.axisBottom(this.scaleLinearX)
      .tickFormat((x) => renderTickFormat(x))
      .tickSize(-height)
      .ticks(TICKS_COUNT)
      .tickPadding(TICK_PADDING);

    this.xAxis = groupMain.append('g')
      .attr('id', 'axisX')
      .attr('class', cx(styles.axisX, isLightTheme ? styles.axisLight : styles.axisDark))
      .attr('transform', `translate(0, ${this.height})`)
      .call(axis);

    this.updatePositionLabelAxisX();
  };

  updatePositionLabelAxisX = () => {
    this.xAxis
      .selectAll('text')
      .attr('text-anchor', 'end')
      .attr('dx', '-2px');
  };

  updateSize = (width: number, height: number) => {
    const { container } = this;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    const _width = this.width + this.margin.left + this.margin.right;
    const _height = this.height + this.margin.top + this.margin.bottom;

    d3.select(container).select('svg')
      .attr('width', _width)
      .attr('height', _height);
  };
}
