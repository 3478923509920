import cx from 'classnames';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Space } from 'antd';

import { THEME } from '../../config/commons';
import { Store } from '../../state/store.interfaces';
import { GenericParam } from '../../common/interfaces/commons';
import { LrvTable } from '../../common/components/LrvTable/LrvTable';
import { getCommercialSizeDataPoints } from '../../common/components/charts/OptimalHarvestPoint/helpers';
import ActionButton from '../../common/components/buttons/ActionButton';
import { formatLongDateWithOffset } from '../../utils/date';

import styles from './OptimalHarvestPointTable.module.scss';
import { CommercialSizeData, ProfitType } from './interfaces';
import * as optimalHarvestPointSlice from './optimalHarvestPointSlice';

type RowData = {
  x: number;
  predictionDate: string;
  [key: string]: number | string ;
};

interface Props {
  height: number;
  profitType: ProfitType;
  theme?: 'dark' | 'light';
  currencySymbol: string;
}

export const OptimalHarvestPointTable = (props: Props) => {
  const { height, profitType, theme = 'dark', currencySymbol } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();
  const isLightTheme = theme === THEME.LIGHT;

  const {
    allPredictions,
    bestPackers,
    predictionSelected,
    pocPoint,
  } = useSelector((state: Store) => state.optimalHarvestPoint);

  const points = getCommercialSizeDataPoints({ allPredictions, bestPackers });
  const packerIds = Array.from(new Set(points.map(item => item.packerId)));

  const renderCircle = (props: {packerData: CommercialSizeData | undefined; showDollar?: boolean; value: number; }) => {
    const { packerData, showDollar = false, value } = props;
        
    return (
      <div className={styles.containerCircle}>
        <span>{value.toFixed(2)}</span>
        &nbsp;
        
        <div className={styles.circle} style={{ borderColor: packerData?.color }}>
          {showDollar && <span style={{ color: packerData?.color }}>{currencySymbol}</span>}
        </div>
      </div>
    );
  };

  const renderSelectButton = (props: {packerId: string; x: number}) => {
    const { packerId, x } = props;

    return (
      <ActionButton
        type='default'
        size='small'
        className={styles.button}
        onClick={() => {
          const point = points.find((point) => point.packerId === packerId && point.x === x);
          dispatch(optimalHarvestPointSlice.setPredictionSelected(point));
        }}
      >
        {t('optimalHarvestPoint.select')}
      </ActionButton>
    );
  };

  const columns: ColumnsType<CommercialSizeData> = [
    {
      key: 'x',
      title: t('optimalHarvestPoint.day'),
      dataIndex: 'x',
      fixed: 'left',
      width: '10%',
    },
    {
      key: 'predictionDate',
      title: t('optimalHarvestPoint.harvestDay'),
      dataIndex: 'predictionDate',
      fixed: 'left',
      width: '21%',
      render: (record: string) => {
        return formatLongDateWithOffset(record);
      },
    },
    ...packerIds.map((packerId) => {
      const packer = points.find((point) => point.packerId === packerId);
      const packerName = packer?.packerName || '';
      
      return {
        key: `packer-${packerId}`,
        title: packerName,
        dataIndex: `packer-${packerId}`,
        width: '23%',
        render: (value: number, record: GenericParam) => {
          const packerDataPoc = points.find((point) => point.packerId === pocPoint?.packerId && point.x === record.x);
          if (pocPoint?.x === packerDataPoc?.x && pocPoint?.packerId === packer?.packerId) {
            return (
              <Space className={styles.containerOptions}>
                {renderCircle({ packerData: packerDataPoc, showDollar: true, value })}
                {
                  predictionSelected?.packerId && (predictionSelected.x !== pocPoint?.x || predictionSelected.packerId !== pocPoint?.packerId) &&
                  renderSelectButton({ packerId, x: record.x })
                }
              </Space>
            );
          }

          const packerDataPrediction = points.find((point) => point.packerId === predictionSelected?.packerId && point.x === record.x);
          if (predictionSelected?.x === packerDataPrediction?.x && predictionSelected?.packerId === packer?.packerId) {
            return renderCircle({ packerData: packerDataPrediction, showDollar: false, value });
          }

          return (
            <div className={styles.containerOptions}>
              <div className={styles.value}>{value.toFixed(2)}</div>&nbsp;&nbsp;
              
              {renderSelectButton({ packerId, x: record.x })}
            </div>
          );
        },
      };
    }),
  ];
  
  const groupedData: RowData[] = points.reduce((acc: RowData[], item) => {
    let row = acc.find(row => row.x === item.x);
  
    if (!row) {
      row = { x: item.x, predictionDate: `${item.predictionDate}` };
      acc.push(row);
    }
  
    row[`packer-${item.packerId}`] = profitType === ProfitType.PROFIT_PER_DAY ? item.presentValue.potentialGainByDayHectarea : item.presentValue.potentialGain;
    return acc;
  }, []);

  const getRowClassName = (record: RowData) => {
    if (!isLightTheme) {
      return '';
    }

    const pointX = predictionSelected?.x || pocPoint?.x;
    if ((!predictionSelected?.x || predictionSelected?.x === pocPoint?.x) && record.x === pointX) {
      return styles.mainRow;
    }

    if (predictionSelected?.x === record.x) {
      return styles.secondaryRow;
    }

    return '';
  };

  return (
    <LrvTable
      className={cx(styles.table, isLightTheme ? styles.tableLight : styles.tableDark)}
      columns={columns}
      dataSource={groupedData}
      scroll={{ y: height }}
      theme={theme}
      size='small'
      pagination={false}
      rowClassName={getRowClassName}
    />
  );
};
