import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PACKERS_URL } from '../../../config/config.api';
import { paginationPackers } from '../../../config/commons';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { openSuccessNotification } from '../../../common/notification/Notification';

import { Packer, PackerPayload, PackerState } from './interfaces';

const initialState: PackerState = {
  total: 0,
  skip: 0,
  limit: 0,
  currentPage: 0,
  packers: [],
  isLoading: false,
  isCreating: false,
  showCreatePackerModal: false,
  isUpdating: false,
  showUpdatePackerModal: false,
};

export const packerSlice = createSlice({
  name: 'packers',
  initialState,
  reducers: {
    setPackers: (state: PackerState, action: PayloadAction<PackerPayload>) => {
      state.total = action.payload.total;
      state.skip = action.payload.skip;
      state.limit = action.payload.limit;
      state.packers = action.payload.data;
      state.currentPage = (action.payload.skip / action.payload.limit) + 1;
    },
    setPacker: (state: PackerState, action: PayloadAction<Packer>) => {
      state.packer = action.payload;
    },
    setCurrentPage: (state: PackerState, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setIsLoading: (state: PackerState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsCreating: (state: PackerState, action: PayloadAction<boolean>) => {
      state.isCreating = action.payload;
    },
    setShowCreatePackerModal: (state: PackerState, action: PayloadAction<boolean>) => {
      state.showCreatePackerModal = action.payload;
    },
    setIsUpdating: (state: PackerState, action: PayloadAction<boolean>) => {
      state.isUpdating = action.payload;
    },
    setShowUpdatePackerModal: (state: PackerState, action: PayloadAction<boolean>) => {
      state.showUpdatePackerModal = action.payload;
    },
  },
});

export const {
  setPackers,
  setPacker,
  setCurrentPage,
  setIsLoading,
  setIsCreating,
  setShowCreatePackerModal,
  setIsUpdating,
  setShowUpdatePackerModal,
} = packerSlice.actions;

export const fetchPackers = (props: {companyId: string; page: number; }) => async (dispatch: Function) => {
  const { companyId, page } = props;

  let skip = 0;
  if (page !== 0) {
    skip = paginationPackers * (page - 1);
  }

  const params = {
    companyId,
    $select: ['name', 'active', 'companyId'],
    '$sort[name]': 1,
    $limit: paginationPackers,
    $skip: skip,
  };

  dispatch(setIsLoading(true));

  try {
    const response = await axios.get<PackerPayload>(PACKERS_URL, { params });
    dispatch(setPackers(response.data));
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsLoading(false));
};

export const fetchPacker = (props: {packerId: string}) => async (dispatch: Function) => {
  const { packerId } = props;

  const params = {
    $select: ['name', 'active', 'commercialSizes', 'companyId'],
  };

  const url = `${PACKERS_URL}/${packerId}`;

  try {
    const response = await axios.get<Packer>(url, { params });
    dispatch(setPacker(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const createPacker = (props: { body: Packer; onSuccess: () => void; }) => async (dispatch: Function) => {
  const { body, onSuccess } = props;
  dispatch(setIsCreating(true));

  try {
    await axios.post(PACKERS_URL, body);
    dispatch(fetchPackers({ companyId: body.companyId, page: 0 }));
    onSuccess();
    openSuccessNotification(i18next.t('packers.created'));
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsCreating(false));
};

export const updatePacker = (props: { body: Packer; onSuccess: () => void; }) => async (dispatch: Function) => {
  const { body, onSuccess } = props;
  dispatch(setIsUpdating(true));
  const url = `${PACKERS_URL}/${body._id}`;

  try {
    await axios.patch(url, body);
    dispatch(fetchPackers({ companyId: body.companyId, page: 0 }));
    onSuccess();
    openSuccessNotification(i18next.t('packers.updated'));
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsUpdating(false));
};

export default packerSlice.reducer;
