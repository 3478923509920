import moment from 'moment';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { DATE_FORMATS } from '../../../config/commons';
import { Store } from '../../../state/store.interfaces';
import { LrvCalendar } from '../../../common/components/LrvCalendar/LrvCalendar';

import { getBackgroundColorCell } from './helpers';
import styles from './MonthlyUserActivity.module.scss';

export default function MonthlyUserActivity () {
  const { filters, userActivity } = useSelector((state: Store) => state.userActivityDashboard);
  const { dateSelected } = filters;

  const renderDateCell = (date: moment.Moment) => {
    const activity = userActivity.find((activity) => activity.day === date.format(DATE_FORMATS.YYYY_MM_DD).toString());

    const count = activity?.count || 0;

    return (
      <div
        className={styles.cell}
        style={{
          backgroundColor: getBackgroundColorCell(count)
        }}
      >
        <div className={styles.day}>
          {date.date()}
        </div>

        <div className={styles.count}>
          {count || ''}
        </div>
      </div>
    );
  };

  const disabledCalendarDate = (current: moment.MomentInput) => {
    const start = moment().startOf('month');
    const end = moment().endOf('month');
    return !(start.isSameOrBefore(current) && end.isAfter(current));
  };

  return (
    <div className={cx(styles.content, 'monthlyUserActivity')}>
      <LrvCalendar
        theme='light'
        className={styles.calendar}
        dateFullCellRender={renderDateCell}
        fullscreen={true}
        value={moment(dateSelected)}
        headerRender={() => null}
        disabledDate={disabledCalendarDate}
      />
    </div>
  );
}